import NavigationContaiter from './Navigation/NavigationContaiter';

import '../index.scss';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const PrimaryLayout: React.FC = () => {
    document.title = 'Admin | PSN Group';

    const location  = useLocation();

    if(location.pathname==='/')
        return <Navigate to="/news" />

    return (
        <div className="PrimaryLayout">
            <NavigationContaiter />
            <main>
                <Outlet />
            </main>
        </div>
    );
};
