import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { complexService } from '@/services/complex.service';
import { Complex } from '@/types/api/complexes/incom';

import { FC, Suspense, useState } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { ServiceForm as IServiceForm } from '@/types/api/services/request-body';
import { servicesService } from '@/services/services.service';
import { ServiceForm } from '@/components/forms/services/service-form';
import { serviceCategoriesService } from '@/services/service-categories.service';
import { ServiceCategory } from '@/types/api/service-categories/incom';
import { Service } from '@/types/api/services/incom';
import { Box } from '@mui/system';
import { Tab, Tabs } from '@mui/material';
import { ServiceJobsForm } from '@/components/forms/services/service-jobs-form';
import { jobsService } from '@/services/jobs.service';
import { ServiceComposition } from '@/types/api/jobs/incom';

export const EditService: FC = () => {
    const { loaderData } = useLoaderData() as {
        loaderData: [Service, Complex[], ServiceCategory[], ServiceComposition];
    };
    const [pending, setPending] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<0 | 1>(0);

    const navigate = useNavigate();
    const params = useParams();

    const onSubmit = async (form: IServiceForm): Promise<void> => {
        setPending(true);
        try {
            await servicesService.update(+(params.id || 0), form);
            setPending(false);
            navigate('/services');
        } catch (e: any) {
            console.error(e);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Услуги / Редактировать услугу',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={loaderData}>
                    {([service, complexList, serviceCategoriesList, serviceJobsInfo]: [
                        Service,
                        Complex[],
                        ServiceCategory[],
                        ServiceComposition,
                    ]) => (
                        <>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={currentTab}
                                    onChange={(e, v) => setCurrentTab(v)}
                                >
                                    <Tab label="Свойства" />

                                    <Tab label="Состав" />
                                </Tabs>
                            </Box>

                            <div hidden={currentTab !== 0}>
                                <ServiceForm
                                    initialData={service}
                                    complexList={complexList}
                                    serviceCategoriesList={serviceCategoriesList}
                                    submitCallback={onSubmit}
                                    cancelCallback={() => navigate('/services')}
                                />
                            </div>

                            <div hidden={currentTab !== 1}>
                                <ServiceJobsForm
                                    service={service}
                                    jobs={serviceJobsInfo.works}
                                    jobPrices={serviceJobsInfo.work_prices}
                                    jobTypes={serviceJobsInfo.work_types}
                                    complexList={complexList}
                                />
                            </div>
                        </>
                    )}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const editServiceLoader: LoaderFunction = ({ params }) => {
    const serviceId = +params.id!;

    return defer({
        loaderData: Promise.all([
            servicesService.one(serviceId),
            complexService.list(),
            serviceCategoriesService.list(),
            jobsService.list(serviceId),
        ]),
    });
};
