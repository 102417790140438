import { Component } from 'react';
import { Table, TableBody, TableCell, TableRow, Paper, Checkbox } from '@mui/material';

import EnhancedTableToolbar from '@/components/Table/TableToolbar';
import EnhancedTableHead from '@/components/Table/TableHead';
import { CButton } from '@/components/Button/CButton';

import './ListPage.scss';
export default class FAQListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            data: props.docObjects,
            page: 0,
            rowsPerPage: 10,
        };
    }

    handleOpenFile(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.docObjects !== this.props.docObjects) {
            this.setState({ data: nextProps.docObjects });
        }
    }

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.state.data.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleChoseItem = (event, id, name) => {
        const selectedIndex = this.state[name].indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state[name], id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state[name].slice(1));
        } else if (selectedIndex === this.state[name].length - 1) {
            newSelected = newSelected.concat(this.state[name].slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state[name].slice(0, selectedIndex),
                this.state[name].slice(selectedIndex + 1),
            );
        }

        this.setState({ [name]: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    isSelected = (id, name) => this.state[name].indexOf(id) !== -1;

    handleDelete = () => {
        this.state.selected.forEach(el => this.props.handleDeleteDoc(el));
        this.setState({
            selected: [],
        });
    };

    render() {
        const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
        const { nameOfAction, typeOfAction, actionCreate } = this.props;
        const filteredData = data;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const columnData = [
            { id: 'original_name', align: 'right', label: 'Название' },
            { id: 'size', align: 'right', label: 'Размер(килобайт)' },
            { id: 'created_at', align: 'right', label: 'Дата добавления' },
        ];
        const tableStyle = { textAlign: 'center' };

        return (
            <div className="listPage">
                <div className="buttonUi">
                    <CButton
                        variant="contained"
                        label={nameOfAction}
                        href={typeOfAction !== 'func' ? actionCreate : null}
                        action={typeOfAction === 'func' ? actionCreate : null}
                    />
                </div>
                <div className="filterCheckbox" />
                <Paper>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleDelete={this.handleDelete}
                        title="Все документы"
                        deleteTitle="Внимание!"
                        deleteMessage="Вы действительно хотите удалить выделенное?"
                    />
                    <div>
                        <Table>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={this.handleSelectAllClick}
                                rowCount={data.length}
                                columnData={columnData}
                            />
                            <TableBody>
                                {filteredData.map(n => {
                                    const isSelected = this.isSelected(n.id, 'selected');
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                            style={tableStyle}
                                        >
                                            <TableCell
                                                onClick={event =>
                                                    this.handleChoseItem(event, n.id, 'selected')
                                                }
                                            >
                                                <Checkbox checked={isSelected} />
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => this.handleOpenFile(n.url)}
                                            >
                                                {n.original_name}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => this.handleOpenFile(n.url)}
                                            >
                                                {Math.round(n.size / 1000)}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => this.handleOpenFile(n.url)}
                                            >
                                                {n.created_at}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={4} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}
