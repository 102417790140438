import { FC, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './TextEditor.scss';

interface Props {
    title?: string;
    value?: string;
    error?: boolean;
    changeCallback: (value: string, validation?: any) => void;
}

export const TextEditor: FC<Props> = props => {
    const quillRef = useRef<any>();

    const [text, setText] = useState<string>(props.value || '');

    const imageHandler = (): void => {
        const range = quillRef.current.getEditor().getSelection() || {
            index: 0,
            length: 0,
        };
        const value = prompt('Введите URL изображения');

        if (value) {
            const fullWidth = window.confirm('Растягивать изображение ?');

            quillRef.current.getEditor().insertEmbed(range.index, 'image', value, 'user');

            let regexp = new RegExp(/<img src/g);
            let input = document.querySelector('.ql-editor');
            if (!input) return;
            if (!fullWidth)
                input.innerHTML = input.innerHTML.replace(
                    regexp,
                    '<img nofullwidth style="width:auto; max-width:100%" src',
                );
            else input.innerHTML = input.innerHTML.replace(regexp, '<img fullwidth src');
        }
    };

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
                    ['link', 'image'],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: { matchVisual: false },
        }),
        [],
    );

    return (
        <div className="PageForm">
            <p>{props?.title || ''}</p>

            <ReactQuill
                style={
                    props.error
                        ? {
                              border: 'solid 1px #d32f2f',
                          }
                        : {}
                }
                modules={modules}
                value={text}
                onChange={(value, delta, source, editor) => {
                    if (!quillRef?.current?.getEditor) return;
                    setText(value);
                    props.changeCallback(value, quillRef.current.getEditor().editor.delta.ops);
                }}
                ref={quillRef}
            />
        </div>
    );
};
