import { FC, useEffect, useState } from 'react';

import {
    Stack,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';
import { CImageUpload } from '@/components/inputs/c-image-upload/c-image-upload';
import { ServiceComplexesStateControl } from './service-complexes-state-control/service-complexes-state-control';
import { CFormControl } from './c-form-control';
import { formValidationConfig, FormValidator } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { AbstractForm } from '@/validators/FormValidator';
import { sortOptionList } from '@/constants/sort-option-list';
import { ServiceCategoryForm as RequestServiceCategoryForm } from '@/types/api/service-categories/request-body';
import { ServiceCategory } from '@/types/api/service-categories/incom';
import { Complex as IComplex } from '@/types/api/complexes/incom';
import { AvailableComplex } from './service-complexes-state-control/service-complexes-state-control';
import { CSelect } from '../inputs/c-select';
import { trimStringValues } from '@/helpers/transformers';

type FieldName = 'name' | 'icon_id' | 'sort' | 'complexes_ids' | 'is_active';

interface Form extends Omit<RequestServiceCategoryForm, 'icon_id'>, AbstractForm {
    icon_id: number | null;
}

interface Props {
    initialValues: ServiceCategory;
    editMode: boolean;
    complexes: IComplex[];
    submitCallback: (form: RequestServiceCategoryForm) => void;
    cancelCallback: () => void;
}

const initialValuesToForm = (data: ServiceCategory) => {
    return {
        name: data.name,
        complexes_ids: data.complexes,
        icon_id: data.icon?.id || null,
        sort: data.sort < 0 ? 0 : data.sort > 2 ? 2 : data.sort,
        is_active: data.is_active,
    };
};

const getValidatorConfig = (): formValidationConfig<Form> => ({
    name: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        maxLength: {
            order: 2,
            config: [errorTexts.max.string(128), 128],
        },
    },
    icon_id: {
        required: {
            order: 1,
            config: [errorTexts.required.image],
        },
    },
    complexes_ids: {},
    sort: {},
    is_active: {},
});

export const ServiceCategoryForm: FC<Props> = props => {
    const [form, setForm] = useState<Form>(initialValuesToForm(props.initialValues));

    const validator: FormValidator<Form> = new FormValidator<Form>(getValidatorConfig());

    const [validationState, setValidationState] = useState<Record<keyof Partial<Form>, string>>(
        validator.validateAsSimple(form, true),
    );

    const handleChange = (
        name: FieldName,
        value: string | number | boolean | AvailableComplex[] | null,
    ): void => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form) as Form;
        setForm(trimmedForm);
        validator.updateValidationConfig(getValidatorConfig());
        setValidationState(validator.validateAsSimple(trimmedForm));
        if (validator.isValid) props.submitCallback(trimmedForm as RequestServiceCategoryForm);
    };

    useEffect(() => {
        setForm(initialValuesToForm(props.initialValues));
    }, [props.initialValues]);

    return (
        <EntityContentWithAside>
            {{
                content: (
                    <Stack
                        component="form"
                        spacing={3}
                        noValidate
                    >
                        <TextField
                            fullWidth
                            label="Название"
                            required
                            value={form.name}
                            error={!!validationState.name.length}
                            helperText={validationState.name}
                            onChange={event => {
                                handleChange('name', event.target.value);
                            }}
                        />

                        <>
                            <Typography variant="h5">
                                {!!form.icon_id ? 'Иконка' : 'Выберите иконку *'}
                            </Typography>

                            <FormControl error={validationState.icon_id.length > 0}>
                                <Stack spacing={2}>
                                    <CImageUpload
                                        name="category-service-icon"
                                        entityName="categoryServices"
                                        initialData={
                                            props.initialValues.icon
                                                ? [
                                                      {
                                                          id: props.initialValues.icon.id,
                                                          url: props.initialValues.icon.url,
                                                      },
                                                  ]
                                                : []
                                        }
                                        error={validationState.icon_id}
                                        filesChangeCallback={fields =>
                                            handleChange('icon_id', fields[0] || null)
                                        }
                                    />
                                </Stack>
                            </FormControl>
                        </>

                        <CSelect
                            items={sortOptionList.map(el => ({ id: el.value, name: el.name }))}
                            value={[form.sort]}
                            label="Приоритетность *"
                            placeholder="Выбрать приоритетность"
                            changeCallback={ids =>
                                setForm({
                                    ...form,
                                    sort: ids[0] ?? 0,
                                })
                            }
                        />

                        <ServiceComplexesStateControl
                            allComplexes={props.complexes}
                            categoryComplexes={form.complexes_ids}
                            changeCallback={value => handleChange('complexes_ids', value)}
                        />
                    </Stack>
                ),
                aside: (
                    <Stack spacing={3}>
                        <CFormControl
                            controls={[
                                {
                                    label: 'Сохранить',
                                    callback: handleSubmit,
                                    buttonProps: {
                                        variant: 'contained',
                                    },
                                },
                                {
                                    label: 'Отмена',
                                    callback: props.cancelCallback,
                                    buttonProps: {
                                        variant: 'outlined',
                                    },
                                },
                            ]}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.is_active}
                                    onChange={() => handleChange('is_active', !form.is_active)}
                                />
                            }
                            label="Активность категории"
                        />
                    </Stack>
                ),
            }}
        </EntityContentWithAside>
    );
};
