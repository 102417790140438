import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { complexService } from '@/services/complex.service';
import { Complex } from '@/types/api/complexes/incom';

import { FC, Suspense, useState } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate } from 'react-router-dom';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { ServiceForm as IServiceForm } from '@/types/api/services/request-body';
import { servicesService } from '@/services/services.service';
import { ServiceForm } from '@/components/forms/services/service-form';
import { servicePlaceHolder } from '@/placeholders/services/incom.placeholder';
import { serviceCategoriesService } from '@/services/service-categories.service';
import { ServiceCategory } from '@/types/api/service-categories/incom';

export const CreateService: FC = () => {
    const { loaderData } = useLoaderData() as { loaderData: [Complex[], ServiceCategory[]] };
    const [pending, setPending] = useState<boolean>(false);

    const navigate = useNavigate();

    const onSubmit = async (form: IServiceForm): Promise<void> => {
        setPending(true);
        try {
            await servicesService.create(form);
            setPending(false);
            navigate('/services');
        } catch (e: any) {
            console.error(e);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Услуги / Создать услугу',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={loaderData}>
                    {([complexList, serviceCategoriesList]: [Complex[], ServiceCategory[]]) => (
                        <ServiceForm
                            initialData={servicePlaceHolder}
                            complexList={complexList}
                            serviceCategoriesList={serviceCategoriesList}
                            submitCallback={onSubmit}
                            cancelCallback={() => navigate('/services')}
                        />
                    )}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const createServiceLoader: LoaderFunction = () => {
    return defer({
        loaderData: Promise.all([complexService.list(), serviceCategoriesService.list()]),
    });
};
