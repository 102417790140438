import Base from './Base';

export default class AdvicesApi extends Base {
    createAdvice(data) {
        return this.apiClient.post('api/advices', data);
    }

    fetchAdvices(query) {
        const request = query.limit
            ? this.apiClient.get(`api/advices/page/${query.page}?limit=${query.limit}`)
            : this.apiClient.get(`api/advices/page/${query.page}`);
        return request;
    }

    uploadImage(image) {
        return this.apiClient.post(`api/storage/image/advices`, image, 'file');
    }

    uploadDocument(document) {
        return this.apiClient.post(`/api/storage/pdf`, document, 'file');
    }

    fetchAdvice(adviceId) {
        return this.apiClient.get(`api/advices/${adviceId}`);
    }

    deleteAdvice(adviceId) {
        return this.apiClient.delete(`api/advices`, adviceId);
    }

    editAdvice(data, id) {
        return this.apiClient.put(`api/advices/${id}`, data);
    }

    publishAdvice(adviceId) {
        return this.apiClient.patch(`api/advices/push/${adviceId}`);
    }
}
