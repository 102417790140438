import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';

import {
    fetchSettings,
    uploadHtmlFile,
    createSettings,
    editSettings,
    fetchHtmlFile,
    createHtmlFile,
} from '../../../actions/adminCommunication';
import { adminCommunication } from '../../../etc/notificationMessages';

import AdminCommunicationPage from '../../../components/pages/AdminCommunication/AdminCommunicationPage';
import HeaderContainer from '../../Header/HeaderContainer';
import { CSpinner } from '../../../components/layout/c-spinner/c-spinner';
import SnackBar from '../../../components/SnackBar/SnackBar';
import Dialog from '../../../components/layout/Dialog/Dialog';
import { TextEditor } from '@/components/inputs/TextEditor/TextEditor';

class AdminCommunicationPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackBarIsOpen: false,
            snackBarMessage: '',
            textOfHtmlFile: '',
            titleOfFile: '',
            modalWindowIsOpened: false,
            nameOfChangingFile: '',
            titleOfPrivacyPolicy: '',
            titleOfTermsOfUse: '',
            validText: true,
        };
        this.closeSnackBar = this.closeSnackBar.bind(this);
    }

    componentDidMount() {
        this.props.fetchSettings();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const thisObjects = this.props.adminCommunication;
        const nextObjects = nextProps.adminCommunication;

        if (!thisObjects.edited && nextObjects.edited) {
            this.setState({
                snackBarIsOpen: true,
                snackBarMessage: adminCommunication.editSuccess,
            });
        }
        if (!thisObjects.editFailed && nextObjects.editFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: adminCommunication.editFail });
        }
        if (
            thisObjects.filesOnServer.id !== nextObjects.filesOnServer.id ||
            (Object.keys(thisObjects.filesOnServer).length === 0 &&
                Object.keys(nextObjects.filesOnServer).length !== 0)
        ) {
            this.setState({
                snackBarIsOpen: true,
                snackBarMessage: adminCommunication.uploadSuccess,
            });
        }
        if (!thisObjects.uploadingFailed && nextObjects.uploadingFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: adminCommunication.uploadFail });
        }
        if (thisObjects.privacy_policy !== nextObjects.privacy_policy) {
            const startOfTitle = nextObjects.privacy_policy.indexOf('title') + 6;
            const endOfTitle = nextObjects.privacy_policy.indexOf('/title') - 1;

            if (startOfTitle !== -1 && endOfTitle !== -1) {
                this.setState({
                    titleOfPrivacyPolicy: nextObjects.privacy_policy.substring(
                        startOfTitle,
                        endOfTitle,
                    ),
                });
            }
        }
        if (thisObjects.terms_of_use !== nextObjects.terms_of_use) {
            const startOfTitle = nextObjects.terms_of_use.indexOf('title') + 6;
            const endOfTitle = nextObjects.terms_of_use.indexOf('/title') - 1;

            if (startOfTitle !== -1 && endOfTitle !== -1) {
                this.setState({
                    titleOfTermsOfUse: nextObjects.terms_of_use.substring(startOfTitle, endOfTitle),
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const prevSettings = prevProps.adminCommunication;
        const thisSettings = this.props.adminCommunication;

        if (!prevSettings.edited && thisSettings.edited) {
            this.props.fetchSettings();
        }
        if (prevSettings.loading && !thisSettings.loading) {
            this.props.fetchHtmlFile(thisSettings.item.terms_of_use.url, 'terms_of_use');
            this.props.fetchHtmlFile(thisSettings.item.privacy_policy.url, 'privacy_policy');
        }
        if (!prevSettings.item.terms_of_use && thisSettings.item.terms_of_use) {
            if (thisSettings.item.terms_of_use.url && thisSettings.item.terms_of_use.url !== '')
                this.props.fetchHtmlFile(thisSettings.item.terms_of_use.url, 'terms_of_use');
        }
        if (!prevSettings.item.privacy_policy && thisSettings.item.privacy_policy) {
            if (thisSettings.item.privacy_policy.url && thisSettings.item.privacy_policy.url !== '')
                this.props.fetchHtmlFile(thisSettings.item.privacy_policy.url, 'privacy_policy');
        }
    }

    handleInputsChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handleOpenModalWindow = (event, fileName, nameOfIdField) => {
        const { adminCommunication } = this.props;
        const { titleOfPrivacyPolicy, titleOfTermsOfUse } = this.state;

        if (fileName === 'terms_of_use' && titleOfTermsOfUse !== '') {
            this.setState({ titleOfFile: titleOfTermsOfUse });
        }
        if (fileName === 'privacy_policy' && titleOfPrivacyPolicy !== '') {
            this.setState({ titleOfFile: titleOfPrivacyPolicy });
        }

        let content;
        if (fileName === 'terms_of_use') {
            const startOfBody = adminCommunication.terms_of_use.indexOf('body') + 5;
            const endOfBody = adminCommunication.terms_of_use.indexOf('/body') - 1;
            content = adminCommunication[fileName].substring(startOfBody, endOfBody);
        }
        if (fileName === 'privacy_policy') {
            const startOfBody = adminCommunication.privacy_policy.indexOf('body') + 5;
            const endOfBody = adminCommunication.privacy_policy.indexOf('/body') - 1;
            content = adminCommunication[fileName].substring(startOfBody, endOfBody);
        }

        this.setState({
            modalWindowIsOpened: true,
            textOfHtmlFile: content,
            nameOfChangingFile: nameOfIdField,
        });
    };

    handleCloseDialog = () => {
        this.setState({
            modalWindowIsOpened: false,
            textFieldValue: '',
            validText: true,
            titleOfFile: '',
        });
    };

    closeSnackBar() {
        this.setState({ snackBarIsOpen: false, snackBarMessage: '' });
    }

    handleEditForm = (form, id) => {
        const { adminCommunication } = this.props;

        Object.keys(adminCommunication.item).length !== 0
            ? this.props.editSettings(form, id)
            : this.props.createSettings(form);
    };

    handleUploadHtmlFile = file => {
        this.props.uploadHtmlFile(file);
    };

    onValidateAndSubmit = () => {
        const { nameOfChangingFile, titleOfFile, article } = this.state;

        if (article.length >= 1) {
            const postForm = {
                file: article,
                description: nameOfChangingFile,
                title: titleOfFile,
            };

            this.props.createHtmlFile(postForm);
            this.setState({ modalWindowIsOpened: false, validText: true });
        } else {
            this.setState({ validText: false });
        }
    };

    renderModalWindow = () => {
        const { textOfHtmlFile, modalWindowIsOpened, titleOfFile, validText } = this.state;

        return (
            <Dialog
                dialogIsOpen={modalWindowIsOpened}
                handleCloseDialog={this.handleCloseDialog}
                handleAction={this.onValidateAndSubmit}
                type="delete"
                title="Произведите изменения в файле"
                submitButtonText="Изменить"
            >
                <TextField
                    label="Название файла"
                    type="text"
                    value={titleOfFile}
                    onChange={this.handleInputsChange('titleOfFile')}
                    fullWidth={true}
                />
                <TextEditor
                    title=""
                    wrapperClassName="wrapperForTextEditor"
                    changeCallback={v =>
                        this.handleInputsChange('article')({ target: { value: v } })
                    }
                    value={textOfHtmlFile}
                />
                {validText ? null : <p className="notValid">Это поле обязательно для заполнения</p>}
            </Dialog>
        );
    };

    render() {
        const { adminCommunication } = this.props;
        const { snackBarIsOpen, snackBarMessage, modalWindowIsOpened } = this.state;
        const headerOptions = {
            nameOfSection: 'Административные связи',
            nameOfAction: '',
        };

        return (
            <div>
                <HeaderContainer options={headerOptions} />
                {adminCommunication.loading || adminCommunication.editing ? (
                    <CSpinner />
                ) : (
                    <AdminCommunicationPage
                        adminCommunication={adminCommunication}
                        handleEditForm={this.handleEditForm}
                        handleUploadHtmlFile={this.handleUploadHtmlFile}
                        handleOpenModalWindow={this.handleOpenModalWindow}
                    />
                )}
                {snackBarIsOpen ? (
                    <SnackBar
                        snackBarIsOpen={snackBarIsOpen}
                        message={snackBarMessage}
                        closeSnackBar={this.closeSnackBar}
                    />
                ) : null}
                {modalWindowIsOpened ? this.renderModalWindow() : null}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    adminCommunication: state.adminCommunication,
});

const mapDispatchToProps = dispatch => ({
    fetchSettings: () => dispatch(fetchSettings()),
    createSettings: form => dispatch(createSettings(form)),
    editSettings: (form, id) => dispatch(editSettings(form, id)),
    uploadHtmlFile: file => dispatch(uploadHtmlFile(file)),
    fetchHtmlFile: (link, fileName) => dispatch(fetchHtmlFile(link, fileName)),
    createHtmlFile: file => dispatch(createHtmlFile(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminCommunicationPageContainer);
