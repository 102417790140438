import {
    FETCH_TYPES_START,
    FETCH_TYPES_SUCCESS,
    FETCH_TYPES_FAIL,
    CREATE_TYPE_START,
    CREATE_TYPE_SUCCESS,
    CREATE_TYPE_FAIL,
    EDIT_TYPE_START,
    EDIT_TYPE_SUCCESS,
    EDIT_TYPE_FAIL,
    DELETE_TYPES_START,
    DELETE_TYPES_SUCCESS,
    DELETE_TYPES_FAIL,
} from '../actions/typesOfAdminObj';

const initialState = {
    creating: false,
    createFailed: false,
    created: false,
    editing: false,
    edited: false,
    editFailed: false,
    loading: false,
    loadingFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    items: [],
};

const typesOfAdminObjects = (state = initialState, { type, data }) => {
    switch (type) {
        case CREATE_TYPE_START:
            return {
                ...state,
                creating: true,
                created: false,
                createFailed: false,
            };

        case CREATE_TYPE_SUCCESS:
            return {
                ...state,
                creating: false,
                created: true,
            };

        case CREATE_TYPE_FAIL:
            return {
                ...state,
                creating: false,
                createFailed: true,
            };
        case FETCH_TYPES_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.types_administrative_objects,
            };

        case FETCH_TYPES_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case EDIT_TYPE_START:
            return {
                ...state,
                editing: true,
                edited: false,
                editFailed: false,
            };
        case EDIT_TYPE_SUCCESS:
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case EDIT_TYPE_FAIL:
            return {
                ...state,
                editing: false,
                editFailed: true,
            };
        case DELETE_TYPES_START:
            return {
                ...state,
                deleted: false,
                deleting: true,
                deleteFailed: false,
            };

        case DELETE_TYPES_SUCCESS:
            return {
                ...state,
                deleted: true,
                deleting: false,
            };
        case DELETE_TYPES_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };
        default:
            return state;
    }
};

export default typesOfAdminObjects;
