import { FC, useEffect, useState } from 'react';
import {
    Stack,
    FormControl,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Paper,
} from '@mui/material';
import { LocationSelect } from '@/components/forms/location-select';
import { Complex } from '@/types/api/complexes/incom';
import { isEqual } from 'lodash';

export interface PublicationForm {
    // "site", "mobile"
    publicationVariants: string[];
    activeComplexesId: string[];
    activeHouseId: string;
    activeEntrancesId: string[];
}

interface Props {
    config: PublicationForm;
    complexList: Complex[];
    locationError?: string;
    publishToError?: string;
    changeCallback: (data: PublicationForm) => void;
}

export const PublicationForm: FC<Props> = props => {
    const [form, setForm] = useState<PublicationForm>(props.config);

    const variantSwitcher = (key: 'mobile' | 'site'): void => {
        setForm({
            ...form,
            publicationVariants: form.publicationVariants.includes(key)
                ? form.publicationVariants.filter(el => el !== key)
                : [...form.publicationVariants, key],
        });
    };

    useEffect(() => {
        props.changeCallback(form);
    }, [form]);

    useEffect(() => {
        if (isEqual(props.config, form)) return;
        setForm(props.config);
    }, [props]);

    return (
        <Stack spacing={3}>
            <Paper sx={{ p: 2 }}>
                <Stack
                    component="form"
                    noValidate
                >
                    <FormControl error={!!props.publishToError}>
                        <FormHelperText error={!!props.publishToError}>
                            Публикация для клиентов:*
                        </FormHelperText>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.publicationVariants.includes('mobile')}
                                    onChange={() => variantSwitcher('mobile')}
                                    value="mobile"
                                />
                            }
                            label="Мобильное приложение"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={form.publicationVariants.includes('site')}
                                    onChange={() => variantSwitcher('site')}
                                    value="site"
                                />
                            }
                            label="Сайт"
                        />

                        <FormHelperText error={!!props.publishToError}>
                            {props.publishToError}
                        </FormHelperText>
                    </FormControl>
                </Stack>
            </Paper>

            <LocationSelect
                error={props.locationError}
                complexes={props.complexList}
                activeComplexesId={form.activeComplexesId}
                activeHouseId={form?.activeHouseId || ''}
                activeEntrancesId={form?.activeEntrancesId || []}
                // @ts-ignore
                activeComplexesChangeCallback={data => setForm({ ...form, ...data })}
            />
        </Stack>
    );
};
