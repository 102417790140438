import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';
import { PerformedJobForm as IPerformedJobForm } from '@/types/api/performed-jobs/request-body';
import { Stack, TextField, FormControl, FormHelperText, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { TextEditor } from '@/components/inputs/TextEditor/TextEditor';
import { CImageUpload } from '@/components/inputs/c-image-upload/c-image-upload';
import { PerformedJob } from '@/types/api/performed-jobs/incom';
import { Complex } from '@/types/api/complexes/incom';
import { PublicationForm } from './publication-form';
import { EnhancedTag } from '@/types/api/tags/incom';
import { CSelect } from '../inputs/c-select';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { CFormControl } from './c-form-control';
import { FormValidator, formValidationConfig } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { API_DATETIME_MASK, TABLE_DATETIME_MASK, TABLE_DATE_MASK } from '@/constants/date-masks';
import { formatDate, trimStringValues } from '@/helpers/transformers';
import Clear from '@mui/icons-material/Clear';
import InsertInvitation from '@mui/icons-material/InsertInvitation';

interface Props {
    initialData: PerformedJob;
    complexList: Complex[];
    tagList: EnhancedTag[];
    submitCallback(form: IPerformedJobForm): any;
    cancelCallback(): any;
    publishCallback?: (id: number) => void;
    deleteCallback?: (id: number) => void;
}

const getValidatorConfig = (): formValidationConfig<IPerformedJobForm> => ({
    title: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        maxLength: {
            order: 2,
            config: [errorTexts.max.string(255), 255],
        },
    },
    images_ids: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.image],
        },
    },
    image_cover_id: {
        requiredId: {
            order: 1,
            config: [errorTexts.required.cover],
        },
    },
    description: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        maxLength: {
            order: 2,
            config: [errorTexts.max.string(255), 255],
        },
    },
    article: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    housing_complexes_ids: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.complex],
        },
    },
    work_date: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    published_to: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
});

const jobToJobForm = (job: PerformedJob): IPerformedJobForm => {
    return {
        title: job.title,
        images_ids: job.images.map(el => el.id),
        image_cover_id: job.image_cover.id,
        description: job.description,
        article: job.article,
        housing_complexes_ids: job.housing_complexes.map(el => el.id),
        push: job.push,
        published_to: job.published_to,
        published_at: job.published_at,
        publish_on: job.publish_on,
        work_date: job.work_date,
        tags_ids: job.tags_id.map(el => el.id),
        buildings: job.buildings ? job.buildings : [],
    };
};

export const PerformedJobForm: FC<Props> = props => {
    const [form, setForm] = useState<IPerformedJobForm>(jobToJobForm(props.initialData));
    const [delayedPublicationDPState, setDelayedPublicationDPState] = useState<boolean>(false);
    const [jobDateDPState, setJobDateDPState] = useState<boolean>(false);

    const validator: FormValidator<IPerformedJobForm> = new FormValidator<IPerformedJobForm>(
        getValidatorConfig(),
    );

    const [validationState, setValidationState] = useState<
        Record<keyof Partial<IPerformedJobForm>, string>
    >(validator.validateAsSimple(form, true));

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form);
        setForm(trimmedForm);
        validator.updateValidationConfig(getValidatorConfig());
        setValidationState(validator.validateAsSimple(trimmedForm));
        if (validator.isValid) props.submitCallback(trimmedForm);
    };

    useEffect(() => setForm(jobToJobForm(props.initialData)), [props.initialData]);

    return (
        <EntityContentWithAside blockClassName="createArticle">
            {{
                content: (
                    <Stack
                        component="form"
                        noValidate
                        spacing={3}
                    >
                        <TextField
                            required
                            label="Заголовок"
                            value={form.title}
                            onChange={e => {
                                setForm({ ...form, title: e.target.value });
                            }}
                            error={!!validationState.title}
                            helperText={validationState.title}
                        />

                        <CImageUpload
                            name="cji"
                            multiple
                            entityName="completedWorks"
                            activeFileId={form.image_cover_id || 0}
                            checkboxLabel="Сделать обложкой"
                            initialData={props.initialData.images}
                            error={validationState.images_ids || validationState.image_cover_id}
                            activeFileChangeCallback={id =>
                                setForm({ ...form, image_cover_id: id })
                            }
                            filesChangeCallback={(ids: Array<number>) => {
                                setForm({ ...form, images_ids: ids });
                            }}
                        />

                        <CSelect
                            items={props.tagList}
                            value={form.tags_ids}
                            label="Теги"
                            multiple
                            placeholder="Добавить тег"
                            changeCallback={ids =>
                                setForm({
                                    ...form,
                                    tags_ids: ids,
                                })
                            }
                        />

                        <TextField
                            required
                            label="Краткое описание статьи"
                            value={form.description}
                            multiline
                            minRows="4"
                            maxRows="7"
                            error={!!validationState.description}
                            helperText={validationState.description}
                            onChange={e => setForm({ ...form, description: e.target.value })}
                        />
                        <FormControl error={!!validationState.article}>
                            <FormHelperText error={!!validationState.article}>
                                Текст статьи *
                            </FormHelperText>

                            <TextEditor
                                error={!!validationState.article}
                                value={form.article}
                                changeCallback={data => {
                                    setForm({ ...form, article: data });
                                }}
                            />

                            <FormHelperText error={!!validationState.article}>
                                {validationState.article}
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                ),
                aside: (
                    <Stack spacing={3}>
                        <CFormControl
                            controls={[
                                {
                                    label: 'Сохранить',
                                    callback: handleSubmit,
                                    buttonProps: {
                                        variant: 'contained',
                                    },
                                },
                                {
                                    label: 'Отмена',
                                    callback: props.cancelCallback,
                                    buttonProps: {
                                        variant: 'outlined',
                                    },
                                },
                                ...(props.publishCallback
                                    ? [
                                          {
                                              label: 'Опубликовать',
                                              callback: () =>
                                                  // @ts-ignore
                                                  props.publishCallback(props.initialData.id),
                                              buttonProps: {
                                                  variant: 'contained',
                                                  disabled: !!props.initialData.published_at,
                                              },
                                          },
                                      ]
                                    : []),
                                ...(props.deleteCallback
                                    ? [
                                          {
                                              label: 'Удалить',
                                              callback: () =>
                                                  // @ts-ignore
                                                  props.deleteCallback(props.initialData.id),
                                              buttonProps: {
                                                  variant: 'contained',
                                              },
                                          },
                                      ]
                                    : []),
                            ]}
                        />

                        <PublicationForm
                            complexList={props.complexList}
                            config={{
                                publicationVariants: form.published_to,
                                activeComplexesId: form.housing_complexes_ids.length
                                    ? form.housing_complexes_ids
                                    : form.buildings.map(building => building.complex_id),
                                activeHouseId: form.buildings.length
                                    ? form.buildings[0].building_id
                                    : '',
                                activeEntrancesId: form.buildings.length
                                    ? form.buildings[0].entrance_id
                                    : [],
                            }}
                            publishToError={validationState.published_to}
                            locationError={validationState.housing_complexes_ids}
                            changeCallback={data => {
                                setForm({
                                    ...form,
                                    published_to: data.publicationVariants,
                                    housing_complexes_ids: data.activeComplexesId,
                                    buildings: data.activeHouseId
                                        ? [
                                              {
                                                  complex_id: data.activeComplexesId.length
                                                      ? data.activeComplexesId[0]
                                                      : '',
                                                  building_id: data.activeHouseId,
                                                  entrance_id: data.activeEntrancesId,
                                              },
                                          ]
                                        : [],
                                });
                            }}
                        />

                        <DatePicker
                            open={jobDateDPState}
                            inputFormat={TABLE_DATE_MASK}
                            label="Дата работы УК *"
                            value={form.work_date || null}
                            onOpen={() => setJobDateDPState(true)}
                            onClose={() => setJobDateDPState(false)}
                            onChange={value => {
                                setForm({
                                    ...form,
                                    work_date: value ? formatDate(value, API_DATETIME_MASK) : null,
                                });
                            }}
                            renderInput={params => (
                                <FormControl error={!!validationState.work_date}>
                                    <TextField
                                        {...params}
                                        error={!!validationState.work_date}
                                        fullWidth
                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                        focused={false}
                                        onClick={() => setJobDateDPState(true)}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {form.work_date && (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setForm({
                                                                    ...form,
                                                                    work_date: '',
                                                                })
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )}
                                                    <IconButton size="small">
                                                        <InsertInvitation />
                                                    </IconButton>
                                                </>
                                            ),
                                        }}
                                    />
                                    <FormHelperText error={!!validationState.work_date}>
                                        {validationState.work_date}
                                    </FormHelperText>
                                </FormControl>
                            )}
                        />

                        {!props.initialData.published_at ? (
                            <DateTimePicker
                                open={delayedPublicationDPState}
                                inputFormat={TABLE_DATETIME_MASK}
                                label="Отложенная публикация"
                                value={form.publish_on || null}
                                onOpen={() => setDelayedPublicationDPState(true)}
                                onClose={() => setDelayedPublicationDPState(false)}
                                onChange={value => {
                                    setForm({
                                        ...form,
                                        publish_on: value
                                            ? formatDate(value, API_DATETIME_MASK)
                                            : null,
                                    });
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                        focused={false}
                                        onClick={() => setDelayedPublicationDPState(true)}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {form.publish_on && (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setForm({
                                                                    ...form,
                                                                    publish_on: '',
                                                                })
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )}
                                                    <IconButton size="small">
                                                        <InsertInvitation />
                                                    </IconButton>
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        ) : null}
                    </Stack>
                ),
            }}
        </EntityContentWithAside>
    );
};
