import { gFetch } from '@/helpers/api/g-fetch';
import { Tag } from '@/types/Shared';
import { EnhancedTag } from '@/types/api/tags/incom';

class TagService {
    async list(): Promise<EnhancedTag[]> {
        const result = await gFetch<{
            tags: EnhancedTag[];
        }>('main', 'tags');

        return result.tags;
    }

    async filterList(limit: number, search?: string): Promise<Tag[]> {
        const result = await gFetch<{
            tags: Tag[];
        }>('main', 'filterTags', {
            params: {
                limit,
                search: search || '',
            },
        });

        return result.tags;
    }

    async create(name: string, sort: number): Promise<EnhancedTag> {
        const result = await gFetch<{
            tag: EnhancedTag;
        }>('main', 'createTag', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify({ name, sort }),
            },
        });

        return result.tag;
    }

    async update(id: number, name: string, sort: number, isActive: boolean): Promise<EnhancedTag> {
        const result = await gFetch<{
            tag: EnhancedTag;
        }>('main', 'updateTag', {
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify({ name, is_active: isActive, sort }),
            },
            params: {
                id,
            },
        });

        return result.tag;
    }

    async updateActivity(ids: number[], isActive: boolean): Promise<EnhancedTag[]> {
        const result = await gFetch<{
            tags: EnhancedTag[];
        }>('main', 'updateTagsActivity', {
            fetchConfig: {
                method: 'PATCH',
                body: JSON.stringify({ ids, is_active: isActive }),
            },
        });

        return result.tags;
    }
}

export const tagService = new TagService();
