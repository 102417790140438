import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import CreateDocModal from '../../../components/pages/FAQ/CreateFAQModal/CreateDocModal';

import { fetchComplexes, fetchAdminObjects } from '../../../actions/contacts';
import { fetchFaqs, deleteFaq } from '../../../actions/faq';
import { fetchDocs, deleteDoc } from '../../../actions/doc';

import { faq, doc } from '../../../etc/notificationMessages';

import { filterId } from '../../../actions/operations';
import connectDataFetchers from '../../../lib/connectDataFetchers';

import ListPage from '../../../components/pages/FAQ/ListPage/ListPage';
import ListPageDoc from '../../../components/pages/FAQ/ListPage/ListPageDoc';

import HeaderContainer from '../../Header/HeaderContainer';
import CreateFaqModalContainer from './FAQCreatePageContainer';
import { CSpinner } from '../../../components/layout/c-spinner/c-spinner';
import SnackBar from '../../../components/SnackBar/SnackBar';

class FAQListPageContainer extends Component {
    constructor() {
        super();
        this.state = {
            isCreateModalOpen: false,
            isCreateModalDocOpen: false,
            modalQuestion: '',
            modalAnswer: '',
            modalComlexesIds: 'selectCleanField',
            faqToEditId: null,
            snackBarIsOpen: false,
            snackBarMessage: '',
        };
        this.closeSnackBar = this.closeSnackBar.bind(this);
    }
    closeSnackBar() {
        this.setState({ snackBarIsOpen: false, snackBarMessage: '' });
    }

    handleFilterContacts = contacts => {
        this.props.dispatch(filterId(contacts));
    };

    handleCloseOpenModal = modalState => {
        if (modalState) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        this.setState({
            isCreateModalOpen: modalState,
            modalQuestion: '',
            modalAnswer: '',
            modalComlexesIds: 'selectCleanField',
            faqToEditId: null,
        });
    };
    handleCloseOpenModalDoc = modalState => {
        if (modalState) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        this.setState({
            isCreateModalDocOpen: modalState,
            modalComlexesIds: 'selectCleanField',
            faqToEditId: null,
        });
    };
    handleOpenEditModal = id => {
        document.body.style.overflow = 'hidden';
        const { faq } = this.props;
        for (let i = 0; i < faq.faqObjects.items.length; i++) {
            if (faq.faqObjects.items[i].id === id) {
                this.setState({
                    isCreateModalOpen: true,
                    modalQuestion: faq.faqObjects.items[i].question,
                    modalAnswer: faq.faqObjects.items[i].answer,
                    modalComlexesIds: faq.faqObjects.items[i].housing_complexes,
                    faqToEditId: id,
                });
                break;
            }
        }
    };

    openModal = () => this.handleCloseOpenModal(true);
    openModalDoc = () => this.handleCloseOpenModalDoc(true);

    handleDeleteFaq = id => {
        this.props.dispatch(deleteFaq(id));
    };
    handleDeleteDoc = id => {
        this.props.dispatch(deleteDoc(id));
    };

    componentDidUpdate(prevProps) {
        if (
            (!prevProps.faq.faqObjects.created && this.props.faq.faqObjects.created) ||
            (!prevProps.faq.faqObjects.edited && this.props.faq.faqObjects.edited) ||
            (!prevProps.faq.faqObjects.deleted && this.props.faq.faqObjects.deleted)
        ) {
            //this.props.fetchTypes();
            this.props.dispatch(fetchAdminObjects());
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        /* if (this.props.contacts.deleting && nextProps.contacts.deleted) {
      this.props.dispatch(fetchAdminObjects());
    }*/
        this.setState({
            isCreateModalOpen: false,
            isCreateModalDocOpen: false,
        });
        document.body.style.overflow = 'auto';

        const thisTypes = this.props.faq.faqObjects;
        const nextTypes = nextProps.faq.faqObjects;

        if (!thisTypes.created && nextTypes.created) {
            this.setState({
                snackBarIsOpen: true,
                snackBarMessage: faq.createSuccess,
            });
        }
        if (!thisTypes.createFailed && nextTypes.createFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: faq.createFail });
        }
        if (!thisTypes.deleted && nextTypes.deleted) {
            this.setState({
                snackBarIsOpen: true,
                snackBarMessage: faq.deleteSeccess,
            });
        }
        if (!thisTypes.deleteFailed && nextTypes.deleteFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: faq.deleteFail });
        }
        if (!thisTypes.edited && nextTypes.edited) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: faq.editSuccess });
        }
        if (!thisTypes.editFailed && nextTypes.editFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: faq.editFail });
        }

        const thisTypesDoc = this.props.doc.docObjects;
        const nextTypesDoc = nextProps.doc.docObjects;

        if (!thisTypesDoc.created && nextTypesDoc.created) {
            this.setState({
                snackBarIsOpen: true,
                snackBarMessage: doc.createSuccess,
            });
        }
        if (!thisTypesDoc.createFailed && nextTypesDoc.createFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: faq.createFail });
        }
        if (!thisTypesDoc.deleted && nextTypesDoc.deleted) {
            this.setState({
                snackBarIsOpen: true,
                snackBarMessage: doc.deleteSeccess,
            });
        }
        if (!thisTypesDoc.deleteFailed && nextTypesDoc.deleteFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: doc.deleteFail });
        }
    }

    render() {
        const { contacts, operations, faq, doc } = this.props;
        const headerOptions = {
            nameOfSection1: 'FAQ',
            nameOfSection2: 'Документы',
        };
        const { snackBarIsOpen } = this.state;

        return (
            <div>
                <HeaderContainer
                    options={headerOptions}
                    FAQ={true}
                    activeMode={faq.faqObjects.mode}
                />

                {faq.faqObjects.mode === 'faq' ? (
                    contacts.houseComplexes.loading || faq.faqObjects.loading ? (
                        <CSpinner />
                    ) : (
                        <Fragment>
                            {this.state.isCreateModalOpen ? (
                                <CreateFaqModalContainer
                                    closeOrOpenModal={this.handleCloseOpenModal}
                                    handleOpenEditModal={this.handleOpenEditModal}
                                    question={this.state.modalQuestion}
                                    answer={this.state.modalAnswer}
                                    complexesIds={this.state.modalComlexesIds}
                                    faqToEditId={this.state.faqToEditId}
                                    docObjects={doc.docObjects.items.slice().reverse()}
                                    //operations={operations}
                                />
                            ) : null}

                            <ListPage
                                handleDeleteFaq={this.handleDeleteFaq}
                                handleOpenEditModal={this.handleOpenEditModal}
                                houseComplexes={contacts.houseComplexes.items}
                                handleFilterContacts={this.handleFilterContacts}
                                faqObjects={faq.faqObjects.items}
                                operations={operations}
                                actionCreate={this.openModal}
                                nameOfAction={'Добавить FAQ'}
                                typeOfAction={'func'}
                            />
                        </Fragment>
                    )
                ) : doc.docObjects.loading || doc.docObjects.loadingDoc ? (
                    <CSpinner />
                ) : (
                    <Fragment>
                        {this.state.isCreateModalDocOpen ? (
                            <CreateDocModal closeOrOpenModal={this.handleCloseOpenModalDoc} />
                        ) : null}
                        <ListPageDoc
                            handleDeleteDoc={this.handleDeleteDoc}
                            handleOpenEditModal={this.handleOpenEditModal}
                            docObjects={doc.docObjects.items.slice().reverse()}
                            operations={operations}
                            actionCreate={this.openModalDoc}
                            nameOfAction={'Добавить документ'}
                            typeOfAction={'func'}
                        />
                    </Fragment>
                )}
                {snackBarIsOpen ? (
                    <SnackBar
                        snackBarIsOpen={snackBarIsOpen}
                        message={this.state.snackBarMessage}
                        closeSnackBar={this.closeSnackBar}
                    />
                ) : null}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    contacts: state.contacts,
    operations: state.operations,
    faq: state.faq,
    doc: state.doc,
});

export default connect(mapStateToProps)(
    connectDataFetchers(FAQListPageContainer, [
        fetchFaqs,
        fetchDocs,
        deleteFaq,
        fetchComplexes,
        fetchAdminObjects,
    ]),
);
