import { SelectItem } from '@/components/inputs/c-select';

export const activityStatusList: SelectItem<number>[] = [
    {
        name: 'Не активный',
        id: 0,
    },
    {
        name: 'Активный',
        id: 1,
    },
];
