import { emailPattern, emptyLinePattern } from '../etc/regexpPatterns';

export default function (data) {
    return data.map(item => {
        if (item.pattern === emptyLinePattern) {
            return {
                ...item,
                status: !item.pattern.test(item.field),
            };
        }
        if (item.pattern === emailPattern) {
            return {
                ...item,
                status: item.pattern.test(item.field),
            };
        }
        if (item.pattern === 'isHasLenghLesThen255Pattern') {
            return {
                ...item,
                status: item.field.length < 255,
            };
        }
        if (item.pattern === 'articlePattern') {
            return {
                ...item,
                status: item.field.length > 1 || item.field[0].insert.length > 1,
            };
        }
        if (item.pattern === 'null') {
            return {
                ...item,
                status: item.field !== null,
            };
        }
        if (item.pattern === 'emptyArrayPattern') {
            return {
                ...item,
                status: item.field.length !== 0,
            };
        }
        if (item.pattern === 'pinningPattern') {
            return {
                ...item,
                status:
                    !item.field.is_pinned ||
                    (Date.parse(item.field.pin_date_from) <= Date.parse(item.field.pin_date_to) &&
                        Date.parse(item.field.pin_date_to) >= Date.now()),
            };
        }
        if (item.pattern === 'locationPattern') {
            return {
                ...item,
                status: item.field.latitude !== 0 && item.field.longitude !== 0,
            };
        }
        if (item.pattern === 'datePattern') {
            return {
                ...item,
                status: Date.parse(item.field.date_begin) <= Date.parse(item.field.date_end),
            };
        }
        if (item.pattern === 'publishDatePattern') {
            return {
                ...item,
                status: Date.parse(item.field) > Date.now(),
            };
        }
        if (item.pattern === 'emptyStringInEmptyArrayPattern') {
            const arrayIsEmpty = item.field.length !== 0;

            if (!arrayIsEmpty) {
                return {
                    ...item,
                    status: false,
                };
            }
            const emptyStrings = item.field.map(itemField => {
                let valid = true;
                for (const key in itemField) {
                    if (key !== 'id') {
                        const emptyLine = emptyLinePattern.test(itemField[key]);
                        if (emptyLine) valid = false;
                    }
                }
                return valid;
            });
            return {
                ...item,
                status: emptyStrings.every(item => !!item),
            };
        }
        return item;
    });
}
