import Base from './Base';

export default class TypesOfContactsApi extends Base {
    createType(data) {
        return this.apiClient.post('api/types_administrative_objects', data);
    }

    fetchTypes() {
        return this.apiClient.get(`api/types_administrative_objects`);
    }

    editType(id, data) {
        return this.apiClient.put(`api/types_administrative_objects/${id}`, data);
    }

    deleteTypes(data) {
        return this.apiClient.delete(`api/types_administrative_objects`, data);
    }
}
