import {
    FETCH_ADVICES_START,
    FETCH_ADVICES_SUCCESS,
    FETCH_ADVICES_FAIL,
    CREATE_ADVICE_START,
    CREATE_ADVICE_SUCCESS,
    CREATE_ADVICE_FAIL,
    UPLOAD_IMAGE_START,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAIL,
    CLEAR_UPLOAD_STATE,
    CLEAR_CURRENT_ITEM,
    FETCH_ADVICE_START,
    FETCH_ADVICE_SUCCESS,
    FETCH_ADVICE_FAIL,
    UPLOAD_DOCUMENT_START,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAIL,
    EDIT_ADVICE_START,
    EDIT_ADVICE_SUCCESS,
    EDIT_ADVICE_FAIL,
    DELETE_ADVICE_START,
    DELETE_ADVICE_SUCCESS,
    DELETE_ADVICE_FAIL,
    PUBLISH_ADVICE_START,
    PUBLISH_ADVICE_SUCCESS,
    PUBLISH_ADVICE_FAIL,
} from '../actions/advices';

const initialState = {
    creating: false,
    createFailed: false,
    created: false,
    editing: false,
    edited: false,
    editFailed: false,
    loading: false,
    loadingFailed: false,
    deleting: false,
    deleted: false,
    publishing: false,
    published: false,
    publishFailed: false,
    deleteFailed: false,
    uploading: false,
    uploadingFailed: false,
    uploadingDocument: false,
    uploadingDocumentFailed: false,
    items: [],
    currentItem: {},
    imagesOnServer: {},
    documentsOnServer: {},
    columnsToSearch: [
        { id: 0, label: 'Заголовок', fieldName: 'title' },
        { id: 1, label: 'Краткое описание', fieldName: 'description' },
    ],
    page: 1,
    limit: 10,
    total: 0,
};

function advices(state = initialState, { type, data }) {
    switch (type) {
        case CREATE_ADVICE_START:
            return {
                ...state,
                created: false,
                creating: true,
                createFailed: false,
            };
        case CREATE_ADVICE_SUCCESS:
            return {
                ...state,
                creating: false,
                created: true,
            };
        case CREATE_ADVICE_FAIL:
            return {
                ...state,
                creating: false,
                createFailed: true,
            };
        case FETCH_ADVICES_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };
        case FETCH_ADVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.advices,
                page: data.page,
                limit: data.limit,
                total: data.total,
            };
        case FETCH_ADVICES_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case UPLOAD_IMAGE_START:
            return {
                ...state,
                uploading: true,
            };
        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                uploading: false,
                imagesOnServer: {
                    ...data.storage,
                },
            };
        case UPLOAD_IMAGE_FAIL:
            return {
                ...state,
                uploading: false,
                uploadingFailed: true,
            };
        case UPLOAD_DOCUMENT_START:
            return {
                ...state,
                uploadingDocument: true,
            };
        case UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadingDocument: false,
                documentsOnServer: {
                    ...data.storage,
                },
            };
        case UPLOAD_DOCUMENT_FAIL:
            return {
                ...state,
                uploadingDocument: false,
                uploadingDocumentFailed: true,
            };
        case CLEAR_UPLOAD_STATE:
            return {
                ...state,
                imagesOnServer: [],
            };
        case CLEAR_CURRENT_ITEM:
            return {
                ...state,
                currentItem: {},
            };
        case FETCH_ADVICE_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };
        case FETCH_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                currentItem: data.advices,
            };
        case FETCH_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case EDIT_ADVICE_START:
            return {
                ...state,
                edited: false,
                editing: true,
                editFailed: false,
            };
        case EDIT_ADVICE_SUCCESS:
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case EDIT_ADVICE_FAIL:
            return {
                ...state,
                edited: false,
                editFailed: true,
            };
        case DELETE_ADVICE_START:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_ADVICE_SUCCESS:
            return {
                ...state,
                deleted: true,
                items: state.items,
                deleting: false,
            };
        case DELETE_ADVICE_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };
        case PUBLISH_ADVICE_START:
            return {
                ...state,
                publishing: true,
            };
        case PUBLISH_ADVICE_SUCCESS:
            return {
                ...state,
                published: true,
                publishing: false,
                currentItem: data.advices,
            };
        case PUBLISH_ADVICE_FAIL:
            return {
                ...state,
                publishFailed: true,
                publishing: false,
            };

        default:
            return state;
    }
}

export default advices;
