import { FC, useEffect, useState } from 'react';
import { TextField, Stack, FormControlLabel, Checkbox } from '@mui/material';
import { FormValidator } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { trimStringValues } from '@/helpers/transformers';
import { JobType } from '@/types/api/jobs/incom';
import { JobTypeForm as IJobTypeForm } from '@/types/api/jobs/request-body';
import { CFormControl } from './c-form-control';

interface Props {
    serviceId: number;
    initialValue: JobType;
    submitCallback: (form: IJobTypeForm) => void;
    cancelCallback: () => void;
}

const jobTypeTojobTypeForm = (jobType: JobType): IJobTypeForm => {
    const result: any = { ...jobType };
    delete result.id;

    return result;
};

export const JobTypeForm: FC<Props> = props => {
    const [form, setForm] = useState<IJobTypeForm>(jobTypeTojobTypeForm(props.initialValue));
    const [validationState, setValidationState] = useState<{ [key: string]: string }>({
        name: '',
        sort: '',
        isActive: '',
    });

    const validator = new FormValidator<IJobTypeForm>({
        name: {
            required: {
                order: 1,
                config: [errorTexts.required.default],
            },
            maxLength: {
                order: 2,
                config: [errorTexts.max.string(128), 128],
            },
        },
        is_active: {},
        service_id: {},
    });

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form);
        const result = validator.validateAsSimple(trimmedForm);
        setValidationState(result);
        if (validator.isValid) props.submitCallback({ ...form, service_id: props.serviceId });
    };

    useEffect(() => setForm(jobTypeTojobTypeForm(props.initialValue)), [props.initialValue]);

    return (
        <Stack
            component="form"
            spacing={3}
            noValidate
        >
            <TextField
                fullWidth
                label="Название"
                required
                error={!!validationState.name.length}
                helperText={validationState.name}
                value={form.name}
                onChange={event => {
                    setForm({ ...form, name: event.target.value });
                }}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={form.is_active}
                        onChange={() => {
                            setForm({ ...form, is_active: !form.is_active });
                        }}
                    />
                }
                label="Активность типа работ"
            />

            <CFormControl
                stackOptions={{
                    direction: 'row',
                }}
                controls={[
                    {
                        label: 'Сохранить',
                        callback: handleSubmit,
                        buttonProps: {
                            variant: 'contained',
                        },
                    },
                    {
                        label: 'Отмена',
                        callback: props.cancelCallback,
                        buttonProps: {
                            variant: 'outlined',
                        },
                    },
                ]}
            />
        </Stack>
    );
};
