import Base from './Base';

export default class ContactsApi extends Base {
    createFaq(faq) {
        return this.apiClient.post(`api/faq`, faq);
    }

    fetchFaqs() {
        return this.apiClient.get(`api/faq`);
    }

    deleteFaq(id) {
        return this.apiClient.delete(`api/faq/${id}`);
    }

    editFaq(id, data) {
        return this.apiClient.put(`api/faq/${id}`, data);
    }

    uploadDocument(document) {
        return this.apiClient.post(`/api/storage/target/faq`, document, 'file');
    }

    fetchDocs() {
        return this.apiClient.get(`/api/storage/target/faq`);
    }

    deleteDoc(id) {
        return this.apiClient.delete(`/api/storage/${id}`);
    }
}
