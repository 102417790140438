import './c-file-upload.scss';
import { FC, useState } from 'react';
import {
    FormHelperText,
    Typography,
    Stack,
    CircularProgress,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Link,
} from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';
import { UploadedFileInfo } from '@/types/api/file/incom';
import { Document } from '@/types/api/advices/incom';
import { gFetch } from '@/helpers/api/g-fetch';

interface Props {
    name: string;
    entityName: 'advices';
    multiple?: boolean;
    required?: boolean;
    error?: string;
    accept?: string;
    initialData: Array<Document>;
    filesChangeCallback: (fileIds: number[]) => void;
}

export const CFileUpload: FC<Props> = props => {
    const [files, setFiles] = useState<Array<Document>>(props.initialData); // TODO
    const [pending, setPending] = useState<boolean>(false);

    const handleRemove = (id: number) => {
        const result: number[] = [];
        setFiles(
            files.filter(file => {
                const r = file.id !== id;
                if (r) result.push(id);
                return r;
            }),
        );

        props.filesChangeCallback(result);
    };

    const handleFileAdding = async (e: React.ChangeEvent): Promise<void> => {
        if (!(e.target instanceof HTMLInputElement)) return;
        setPending(true);

        const formsList: FormData[] = [];

        for (let i = 0; i < e.target.files!.length; ++i) {
            const form = new FormData();
            form.append('file', e.target.files![i], e.target.files![i].name);
            formsList.push(form);
        }

        try {
            const result = await Promise.all(
                formsList.map(form =>
                    gFetch<UploadedFileInfo>('main', 'uploadImage', {
                        params: {
                            type: 'pdf',
                            entityName: props.entityName,
                        },
                        fetchConfig: {
                            body: form,
                            method: 'POST',
                        },
                    }),
                ),
            );

            if (!props.multiple) {
                props.filesChangeCallback([result[0].storage.id]);
                setFiles([
                    {
                        id: result[0].storage.id,
                        original_name: result[0].storage.original_name,
                        url: result[0].storage.url,
                    },
                ]);
            } else {
                props.filesChangeCallback([
                    ...files.map(image => image.id),
                    ...result.map(el => el.storage.id),
                ]);
                setFiles([
                    ...files,
                    ...result.map(el => ({
                        id: result[0].storage.id,
                        original_name: result[0].storage.original_name,
                        url: result[0].storage.url,
                    })),
                ]);
            }
        } catch (e) {
            console.error(e);
        }

        setPending(false);
    };

    return (
        <div className={`c-file-upload ${props.error ? 'c-file-upload--error' : ''}`}>
            <Stack>
                <label
                    className="c-file-upload-label"
                    htmlFor={props.name}
                >
                    {!pending ? (
                        <>
                            <input
                                className="c-file-upload__native"
                                id={props.name}
                                name={props.name}
                                type="file"
                                multiple={props.multiple}
                                accept={props.accept}
                                onChange={handleFileAdding}
                            />

                            <div className="c-file-upload-label__top c-file-upload-label__top--icon-scaled">
                                <CloudUpload />
                            </div>

                            <div className="c-file-upload-label__bottom">
                                <Typography>
                                    {`Выберите ${props.multiple ? 'файлы' : 'файл'} ${
                                        props.required && files.length === 0 ? '*' : ''
                                    }`}
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="c-file-upload-label__top">
                                <CircularProgress />
                            </div>

                            <div className="c-file-upload-label__bottom">
                                <Typography>Загрузка...</Typography>
                            </div>
                        </>
                    )}
                </label>
                {files.length > 0 && (
                    <List>
                        {files.map(file => (
                            <ListItem key={file.id}>
                                <Link href={file.url}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{ alignItems: 'center' }}
                                    >
                                        <ListItemText primary={file.original_name} />
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleRemove(file.id)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Stack>
            {!!props?.error && (
                <FormHelperText error={!!props?.error}>{props?.error}</FormHelperText>
            )}
        </div>
    );
};
