import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    createAdvice,
    uploadImage,
    uploadDocument,
    clearUploadState,
} from '../../../actions/advices';
import { fetchComplexes } from '../../../actions/contacts';

import AdvicesCreatePage from '../../../components/pages/Advices/CreatePage/CreatePage';
import HeaderContainer from '../../Header/HeaderContainer';
import { onLinkClick } from '../../../lib/urlUtil';

class AdvicesCreatePageContainer extends Component {
    componentDidMount() {
        const { houseComplexes } = this.props.contacts;

        if (houseComplexes.items.length === 0) this.props.fetchComplexes();
    }

    handleCreateCancel = props => ({ to: '/advices', ...props });

    handleSubmitCreate = data => this.props.createAdvice(data);

    handleUploadImage = image => this.props.uploadImage(image);

    handleUploadDocument = doc => this.props.uploadDocument(doc);

    clearUploadState = () => this.props.clearUploadState();

    componentWillUnmount() {
        this.props.clearUploadState();
    }

    render() {
        const { contacts } = this.props;
        const headerOptions = {
            nameOfSection: 'Советы / Создание совета',
            nameOfAction: '',
        };
        return (
            <div>
                <HeaderContainer options={headerOptions} />
                <AdvicesCreatePage
                    handleCreateCancel={this.handleCreateCancel}
                    handleSubmitCreate={this.handleSubmitCreate}
                    handleUploadImage={this.handleUploadImage}
                    handleUploadDocument={this.handleUploadDocument}
                    clearUploadState={this.clearUploadState}
                    advices={this.props.advices}
                    contacts={contacts.houseComplexes}
                    onLinkClick={onLinkClick}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    advices: state.advices,
    contacts: state.contacts,
});

const mapDispatchToProps = dispatch => ({
    fetchComplexes: () => dispatch(fetchComplexes()),
    createAdvice: (data, id) => dispatch(createAdvice(data, id)),
    uploadImage: image => dispatch(uploadImage(image)),
    uploadDocument: doc => dispatch(uploadDocument(doc)),
    clearUploadState: () => dispatch(clearUploadState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvicesCreatePageContainer);
