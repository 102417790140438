import React, { Component } from 'react';
import { CButton } from '../../../Button/CButton';

import { Table, TableBody, TableCell, TableRow, Paper, Checkbox } from '@mui/material';

import EnhancedTableToolbar from '@/components/Table/TableToolbar';
import EnhancedTableHead from '@/components/Table/TableHead';

import './ListPage.scss';
export default class FAQListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            data: props.faqObjects,
            page: 0,
            rowsPerPage: 10,
            filterComplexes: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.faqObjects !== this.props.faqObjects) {
            this.setState({ data: nextProps.faqObjects });
        }
    }

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.state.data.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleChoseItem = (event, id, name) => {
        const selectedIndex = this.state[name].indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state[name], id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state[name].slice(1));
        } else if (selectedIndex === this.state[name].length - 1) {
            newSelected = newSelected.concat(this.state[name].slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state[name].slice(0, selectedIndex),
                this.state[name].slice(selectedIndex + 1),
            );
        }
        if (name === 'filterComplexes') {
            this.props.handleFilterContacts(newSelected);
        }

        this.setState({ [name]: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    isSelected = (id, name) => this.state[name].indexOf(id) !== -1;

    handleDelete = () => {
        this.state.selected.forEach(el => this.props.handleDeleteFaq(el));
        this.setState({
            selected: [],
        });
    };
    renderLin = cellData => {
        return React.createElement('div', {
            dangerouslySetInnerHTML: { __html: cellData },
        });
    };
    render() {
        const { data, selected, rowsPerPage, page } = this.state;
        const {
            handleOpenEditModal,
            houseComplexes,
            operations,
            nameOfAction,
            typeOfAction,
            actionCreate,
        } = this.props;

        const filteredData = data.filter(item =>
            operations.filterId.length === 0
                ? true
                : operations.filterId.some(
                      id => JSON.stringify(item.housing_complexes).indexOf(id) !== -1, // :)
                  ),
        );
        /*
    filteredData.forEach((el, i, arr) => {
      return (arr[i].houseComplex = houseComplexes.filter(
        complex => el.housing_complexes.indexOf(complex.IntegrationId)!==-1
        //complex.IntegrationId === el.housing_complex_id
      )[0].Name);
    });*/

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const columnData = [
            { id: 'housing_complexes', align: 'right', label: 'ЖКХ' },
            { id: 'question', align: 'right', label: 'Вопрос' },
            { id: 'answer', align: 'right', label: 'Ответ' },
        ];
        const tableStyle = { textAlign: 'center' };

        return (
            <div className="listPage">
                <div className="buttonUi">
                    <CButton
                        variant="contained"
                        label={nameOfAction}
                        href={typeOfAction !== 'func' ? actionCreate : null}
                        action={typeOfAction === 'func' ? actionCreate : null}
                    />
                </div>
                <div className="filterCheckbox">
                    {houseComplexes.map(item => {
                        const isSelected = this.isSelected(item.IntegrationId, 'filterComplexes');
                        return (
                            <div
                                key={item.IntegrationId}
                                onClick={event =>
                                    this.handleChoseItem(
                                        event,
                                        item.IntegrationId,
                                        'filterComplexes',
                                    )
                                }
                            >
                                <Checkbox
                                    id={String(item.IntegrationId)}
                                    color="primary"
                                    checked={isSelected}
                                />
                                <p>{item.Name}</p>
                            </div>
                        );
                    })}
                </div>
                <Paper>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleDelete={this.handleDelete}
                        title="Таблица часто задаваемых вопросов"
                        deleteTitle="Внимание!"
                        deleteMessage="Вы действительно хотите удалить выделенное?"
                    />
                    <div>
                        <Table>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={this.handleSelectAllClick}
                                rowCount={data.length}
                                columnData={columnData}
                            />
                            <TableBody>
                                {filteredData.map(n => {
                                    const isSelected = this.isSelected(n.id, 'selected');
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.id}
                                            selected={isSelected}
                                            style={tableStyle}
                                        >
                                            <TableCell
                                                onClick={event =>
                                                    this.handleChoseItem(event, n.id, 'selected')
                                                }
                                            >
                                                <Checkbox checked={isSelected} />
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => handleOpenEditModal(n.id)}
                                            >
                                                {n.housing_complexes.map(el => el.Name).join(', ')}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => handleOpenEditModal(n.id)}
                                            >
                                                {n.question}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                //onClick={() => handleOpenEditModal(n.id)}
                                            >
                                                {this.renderLin(n.answer)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={4} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}
