import { FC } from 'react';
import { TableRow, TableCell, Checkbox } from '@mui/material';

export interface RowData {
    id: string;
    name: string;
    isAvailable: boolean;
    isRecommended: boolean;
}

interface Props {
    data: RowData;
    changeCallback: (data: RowData) => void;
}

export const ServiceComplexesStateControlRow: FC<Props> = ({ data, changeCallback }) => {
    return (
        <TableRow>
            <TableCell>{data.name}</TableCell>

            <TableCell>
                <Checkbox
                    color="primary"
                    checked={data.isAvailable}
                    onChange={() => changeCallback({ ...data, isAvailable: !data.isAvailable })}
                />
            </TableCell>

            <TableCell>
                <Checkbox
                    color="primary"
                    disabled={!data.isAvailable}
                    checked={data.isRecommended}
                    onChange={() => changeCallback({ ...data, isRecommended: !data.isRecommended })}
                />
            </TableCell>
        </TableRow>
    );
};
