import React, { Component } from 'react';
import { connect } from 'react-redux';

import { logout } from '../../actions/user';

import Navigation from '../../components/layout/Navigation/Navigation';

class NavigationContaiter extends Component {
    logoutUser = () => {
        this.props.dispatch(logout());
    };

    render() {
        const { showMenu } = this.props;
        return (
            <Navigation
                showMenu={showMenu}
                logoutUser={this.logoutUser}
            />
        );
    }
}

const mapStateToProps = state => ({
    showMenu: state.operations.showMenu,
});

export default connect(mapStateToProps, null, null, )(NavigationContaiter);
