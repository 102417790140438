import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';

export default class СustomSnackBar extends Component {
    render() {
        const { snackBarIsOpen, message, closeSnackBar } = this.props;

        return (
            <div className="SnackBar">
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={snackBarIsOpen}
                    autoHideDuration={3000}
                    onClose={closeSnackBar}
                    ContentProps={{ 'aria-describedby': 'message-id' }}
                    message={<span id="message-id">{message}</span>}
                />
            </div>
        );
    }
}
