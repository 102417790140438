import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
    fetchAdvice,
    uploadImage,
    uploadDocument,
    clearCurrentItem,
    clearUploadState,
    editAdvice,
} from '@/actions/advices';
import { cancelNotFound } from '@/actions/operations';

import AdvicesCreatePage from '@/components/pages/Advices/CreatePage/CreatePage';
import HeaderContainer from '@/containers/Header/HeaderContainer';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import NotFound from '@/components/layout/NotFound/NotFound';
import { onLinkClick } from '@/lib/urlUtil';

const AdvicesEditPageContainer = props => {
    const params = useParams();

    useEffect(() => {
        props.fetchAdvice(params.id);
        return () => {
            if (props.operations.notFoundPage) {
                props.cancelNotFound();
            }
            props.clearUploadState();
            props.clearCurrentItem();
        };
    }, []);

    const handleCreateCancel = props => ({
        to: `/advices/${params.id}`,
        ...props,
    });

    const handleSubmitCreate = data => {
        props.editAdvice(data, params.id);
    };

    const handleUploadImage = image => {
        props.uploadImage(image);
    };

    const handleUploadDocument = doc => {
        props.uploadDocument(doc);
    };

    const headerOptions = {
        nameOfSection: 'Советы / Изменение совета',
        nameOfAction: '',
    };

    if (props.operations.notFoundPage) {
        return (
            <div>
                <HeaderContainer options={headerOptions} />
                <NotFound />
            </div>
        );
    }

    return (
        <div>
            <HeaderContainer options={headerOptions} />
            {props.advices.loading ||
            props.contacts.loading ||
            Object.keys(props.advices.currentItem).length === 0 ? (
                <CSpinner />
            ) : (
                <AdvicesCreatePage
                    advices={props.advices}
                    contacts={props.contacts}
                    clearUploadState={props.clearUploadState}
                    handleCreateCancel={handleCreateCancel}
                    handleSubmitCreate={handleSubmitCreate}
                    handleUploadImage={handleUploadImage}
                    handleUploadDocument={handleUploadDocument}
                    onLinkClick={onLinkClick}
                />
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    advices: state.advices,
    contacts: state.contacts.houseComplexes,
    operations: state.operations,
});

const mapDispatchToProps = dispatch => ({
    fetchAdvice: id => dispatch(fetchAdvice(id)),
    editAdvice: (data, id) => dispatch(editAdvice(data, id)),
    clearCurrentItem: () => dispatch(clearCurrentItem()),
    clearUploadState: () => dispatch(clearUploadState()),
    cancelNotFound: () => dispatch(cancelNotFound()),
    uploadImage: image => dispatch(uploadImage(image)),
    uploadDocument: doc => dispatch(uploadDocument(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvicesEditPageContainer);
