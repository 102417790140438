import api from '../apiSingleton';

export const AUTHORIZATION_USER_SUCCESS = 'AUTHORIZATION_USER_SUCCESS';
export const AUTHORIZATION_USER_FAIL = 'AUTHORIZATION_USER_FAIL';

export function authorization(data) {
    return dispatch => {
        return api.user
            .authorization(data)
            .then(data => {
                localStorage.setItem('auth_token', data.token);
                return dispatch({ type: AUTHORIZATION_USER_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: AUTHORIZATION_USER_FAIL });
            });
    };
}

export function autothenticate() {
    return dispatch => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            return api.user
                .authorization({ token: token })
                .then(() => {
                    return dispatch({ type: AUTHORIZATION_USER_SUCCESS });
                })
                .catch(() => {
                    return dispatch({ type: AUTHORIZATION_USER_FAIL });
                });
        }
    };
}

export const LOGOUT_USER_START = 'LOGOUT_USER_START';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export function logout() {
    return dispatch => {
        dispatch({ type: LOGOUT_USER_START });
        return api.user
            .logout()
            .then(() => {
                localStorage.removeItem('auth_token');
                return dispatch({ type: LOGOUT_USER_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: LOGOUT_USER_FAIL });
            });
    };
}

export const CANCEL_USER_AUTHRIZATION_FAILED = 'CANCEL_USER_AUTHRIZATION_FAILED';

export function cancelUserAuthrizationFailed() {
    return dispatch => {
        dispatch({ type: CANCEL_USER_AUTHRIZATION_FAILED });
    };
}
