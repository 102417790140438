export function searchToObject(search) {
    const pairs = search.substring(1).split('&');
    const obj = {};
    let pair;
    let i;

    for (i in pairs) {
        if (pairs[i] === '') continue;

        pair = pairs[i].split('=');
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
}

export const onLinkClick = (event, url) => {
    event.preventDefault();
    window.open(url);
};
