import { API } from '@/constants/api/index';
import { parameterParser } from '@/helpers/api/parameter-parser';
import { queryParser } from '@/helpers/api/query-parser';

export type apiParamList = Record<string, string | number>;
export type apiQueryList = Partial<Record<string, string | number | Array<string | number>>>;

export function getRoute(
    service: string,
    route: string,
    params?: apiParamList,
    query?: apiQueryList | string,
): string {
    const currentService = API[service] || null;

    if (!currentService) throw new Error('getRoute: ' + service + ': no such service');

    if (!currentService.routes[route])
        throw new Error('getRoute: ' + route + ': no such route in service ' + service);

    let p = params
        ? parameterParser(currentService.routes[route], params)
        : currentService.routes[route];

    let q = typeof query === 'string' ? query : queryParser(query || {});

    return `${currentService.domain}/${p}${q}`;
}
