import { Domain } from '@/types/ApiService';

export const domainParser = (domain: Domain, withoutProtocol: boolean = false): string => {
    return (
        (withoutProtocol ? '' : `http${domain.secure ? 's' : ''}://`) +
        domain.domain +
        (domain.port ? `:${domain.port}` : '') +
        (domain.prefix ? `/${domain.prefix}` : '')
    );
};
