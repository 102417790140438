import { combineReducers } from 'redux';
import {
    FETCH_FAQS_START,
    FETCH_FAQS_SUCCESS,
    FETCH_FAQS_FAIL,
    CREATE_FAQ_START,
    CREATE_FAQ_SUCCESS,
    CREATE_FAQ_FAIL,
    EDIT_FAQ_START,
    EDIT_FAQ_SUCCESS,
    EDIT_FAQ_FAIL,
    DELETE_FAQ_START,
    DELETE_FAQ_SUCCESS,
    DELETE_FAQ_FAIL,
    CHANGE_FAQ_MODE,
} from '../actions/faq';


const initialState = {
    creating: false,
    createFailed: false,
    created: false,
    loading: false,
    loadingFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    items: [],
    editing: false,
    edited: false,
    editFailed: false,
    mode: 'faq',
};

const faqObjects = (state = initialState, { type, data }) => {
    switch (type) {
        case CREATE_FAQ_START:
            return {
                ...state,
                created: false,
                creating: true,
                createFailed: false,
            };

        case CREATE_FAQ_SUCCESS:
            state.items.unshift(data.faq);
            return {
                ...state,
                creating: false,
                created: true,
            };

        case CREATE_FAQ_FAIL:
            return {
                ...state,
                creating: false,
                createFailed: true,
            };
        case FETCH_FAQS_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_FAQS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.faq,
            };

        case FETCH_FAQS_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case EDIT_FAQ_START:
            return {
                ...state,
                editing: true,
                edited: false,
                editFailed: false,
            };
        case EDIT_FAQ_SUCCESS:
            const { editedFaq, id } = data;
            for (let i = 0; i < state.items.length; i++) {
                if (state.items[i].id === id) {
                    state.items[i] = editedFaq.faq;
                    break;
                }
            }
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case EDIT_FAQ_FAIL:
            return {
                ...state,
                editing: false,
                editFailed: true,
            };
        case DELETE_FAQ_START:
            return {
                ...state,
                deleted: false,
                deleting: true,
                deleteFailed: false,
            };

        case DELETE_FAQ_SUCCESS:
            const newItems = state.items.filter(el => el.id !== data);
            return {
                ...state,
                deleted: true,
                deleting: false,
                items: [...newItems],
            };
        case DELETE_FAQ_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };
        case CHANGE_FAQ_MODE:
            return {
                ...state,
                mode: data.mode,
            };
        default:
            return state;
    }
};

export default combineReducers({
    faqObjects,
});
