import { operations } from './operations';
import news from './news';
import advices from './advices';
import user from './user';
import contacts from './contacts';
import adminCommunication from './adminCommunication';
import typesOfAdminObjects from './typesOfAdminObj';
import faq from './faq';
import doc from './doc';
import appVersions from './appVersions';
import { combineReducers } from 'redux';

export default combineReducers({
    operations,
    news,
    user,
    advices,
    contacts,
    adminCommunication,
    typesOfAdminObjects,
    faq,
    doc,
    appVersions,
});
