import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { createAdminObject } from '../../../actions/contacts';
import { fetchComplexes, fetchTypeAdminObjects } from '../../../actions/contacts';
import connectDataFetchers from '../../../lib/connectDataFetchers';

import HeaderContainer from '../../Header/HeaderContainer';
import CreatePage from '../../../components/pages/Contacts/EditPage/EditPage';
import { CSpinner } from '../../../components/layout/c-spinner/c-spinner';

class ContactsCreatePageContainer extends Component {
    handleSaveChanges = contactData => {
        this.props.dispatch(createAdminObject(contactData));
    };

    handleRedirect = props => (
        <Link
            to="/contacts"
            {...props}
        />
    );

    render() {
        const { contacts } = this.props;
        const headerOptions = {
            nameOfSection: 'Контакты / Создание объекта',
            nameOfAction: '',
        };

        return (
            <div>
                <HeaderContainer options={headerOptions} />
                {contacts.houseComplexes.loading ? (
                    <CSpinner />
                ) : (
                    <CreatePage
                        handleRedirect={this.handleRedirect}
                        handleSaveChanges={this.handleSaveChanges}
                        contacts={contacts}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contacts: state.contacts,
});

export default connect(mapStateToProps)(
    connectDataFetchers(ContactsCreatePageContainer, [fetchComplexes, fetchTypeAdminObjects]),
);
