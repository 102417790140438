import { FC, useState } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    Stack,
    Button,
    Box,
    FormHelperText,
    FormControl,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { formValidationConfig, FormValidator } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { TagFormData } from '../pages/tags/tags';
import { tagFormPlaceholder } from '@/placeholders/tags/form';
import { sortOptionList } from '@/constants/sort-option-list';
import { trimStringValues } from '@/helpers/transformers';

type Name = 'name' | 'sort' | 'isActive';

interface Props {
    initialValues: {
        name: string;
        sort: number;
        isActive: boolean;
    };
    editMode: boolean;
    submitCallback: (form: TagFormData) => void;
    cancelCallback: () => void;
}

const getValidatorConfig = (): formValidationConfig<TagFormData> => ({
    name: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        maxLength: {
            order: 2,
            config: [errorTexts.max.string(128), 128],
        },
    },
    sort: {},
    isActive: {},
});

export const TagForm: FC<Props> = props => {
    const [form, setForm] = useState<TagFormData>({
        ...(props.initialValues ? props.initialValues : tagFormPlaceholder),
    });

    const validator: FormValidator<TagFormData> = new FormValidator<TagFormData>(
        getValidatorConfig(),
    );

    const [validationState, setValidationState] = useState<
        Record<keyof Partial<TagFormData>, string>
    >(validator.validateAsSimple(form, true));

    const handleChange = (name: Name, value: string | number | boolean) => {
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form);
        setForm(trimmedForm);
        validator.updateValidationConfig(getValidatorConfig());
        setValidationState(validator.validateAsSimple(trimmedForm));
        if (validator.isValid) props.submitCallback(form);
    };

    return (
        <Box sx={{ width: 500, maxWidth: '100%', paddingTop: 1 }}>
            <Stack
                component="form"
                spacing={3}
                noValidate
            >
                <TextField
                    fullWidth
                    label="Название"
                    required
                    error={!!validationState.name.length}
                    helperText={validationState.name}
                    value={form.name}
                    onChange={event => {
                        handleChange('name', event.target.value);
                    }}
                />

                <FormControl error={false}>
                    <InputLabel id="tag-form-sort-label">Приоритетность *</InputLabel>

                    <Select
                        required
                        fullWidth
                        labelId="tag-form-sort-label"
                        label="Приоритетность"
                        value={form.sort}
                        onChange={event => {
                            handleChange('sort', event.target.value);
                        }}
                    >
                        {sortOptionList.map(option => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                            >
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {props.editMode && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.isActive}
                                onChange={() => {
                                    handleChange('isActive', !form.isActive);
                                }}
                            />
                        }
                        label="Активность тега"
                    />
                )}

                <Stack
                    direction="row"
                    spacing={3}
                >
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Сохранить
                    </Button>

                    <Button onClick={props.cancelCallback}>Отмена</Button>
                </Stack>
            </Stack>
        </Box>
    );
};
