import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Service } from '@/types/api/services/incom';
import { Job, JobType } from '@/types/api/jobs/incom';
import { CSelect } from '@/components/inputs/c-select';
import { JobTypeForm } from '../job-type-form';
import { jobTypePlaceholder } from '@/placeholders/jobs/incom';
import { JobTypeForm as IJobTypeForm } from '@/types/api/jobs/request-body';
import { jobTypeService } from '@/services/job-type.service';
import { ServiceJobsControlTable } from './service-jobs-control-table';
import { PriceExtended } from '@/types/api/price/incom';
import { Complex } from '@/types/api/complexes/incom';
import { CDialogWithLoading } from '@/components/layout/c-dialog-with-loading';

interface Props {
    service: Service;
    jobTypes: Array<JobType>;
    jobs: Array<Job>;
    jobPrices: Array<PriceExtended>;
    complexList: Complex[];
}

export const ServiceJobsForm: FC<Props> = props => {
    const [pending, setPending] = useState<boolean>(false);

    const [jobTypes, setJobTypes] = useState<Array<JobType>>(props.jobTypes);
    const [currentJobType, setCurrentJobType] = useState<JobType>(
        jobTypes && jobTypes.length ? jobTypes[0] : jobTypePlaceholder,
    );
    const [jobTypeModalVisivility, setJobTypeModalVisivility] = useState<boolean>(false);
    const [editingJobType, setEditingJobType] = useState<JobType>(jobTypePlaceholder);

    const editJobType = async (jobTypeId: number, jobTypeForm: IJobTypeForm): Promise<void> => {
        setPending(true);
        try {
            const result = await jobTypeService.update(editingJobType.id, {
                ...jobTypeForm,
                service_id: props.service.id,
            });
            setJobTypes(jobTypes.map(el => (el.id === editingJobType.id ? result : el)));
            setCurrentJobType(result);
        } catch (e) {
            console.error(e);
        }
        setJobTypeModalVisivility(false);
        setPending(false);
    };

    const addJobType = async (jobTypeForm: IJobTypeForm): Promise<void> => {
        setPending(true);
        try {
            const result = await jobTypeService.create({
                ...jobTypeForm,
                service_id: props.service.id,
            });
            setJobTypes([...jobTypes, result]);
            setCurrentJobType(result);
        } catch (e) {
            console.error(e);
        }
        setJobTypeModalVisivility(false);
        setPending(false);
    };

    useEffect(() => setJobTypes(props.jobTypes), [props.jobTypes]);

    // Jobs

    const [jobs, setJobs] = useState<Array<Job>>(props.jobs);

    const editJob = (job: Job) => {
        setJobs(jobs.map(item => (item.id === job.id ? { ...item, ...job } : item)));
    };

    const addJob = (job: Job) => {
        setJobs([...jobs, job]);
    };

    // Prices

    const [jobPrices, setJobPrices] = useState<Array<PriceExtended>>(props.jobPrices);

    const editJobPrice = (replaceId: number, jobPrice: PriceExtended) => {
        setJobPrices(
            jobPrices.map(item => (item.price_id === replaceId ? { ...item, ...jobPrice } : item)),
        );
    };

    const addJobPrice = (jobPrice: PriceExtended) => {
        setJobPrices([...jobPrices, jobPrice]);
    };

    useEffect(() => setJobPrices(props.jobPrices), [props.jobPrices]);

    return (
        <Stack spacing={3}>
            <Stack
                component="form"
                noValidate
                spacing={3}
                width={500}
            >
                <CSelect<number>
                    value={currentJobType ? [currentJobType.id] : []}
                    items={jobTypes}
                    label="Тип работ"
                    placeholder="выбрать тип работы"
                    editCallback={(jobTypeId: number) => {
                        setEditingJobType(jobTypes.find(el => el.id === jobTypeId)!);
                        setJobTypeModalVisivility(true);
                    }}
                    addCallback={() => {
                        setEditingJobType(jobTypePlaceholder);
                        setJobTypeModalVisivility(true);
                    }}
                    changeCallback={id => {
                        if (id[0] !== 0) setCurrentJobType(jobTypes.find(el => el.id === id[0])!);
                    }}
                />
            </Stack>

            <div hidden={!currentJobType}>
                <ServiceJobsControlTable
                    service={props.service}
                    jobType={currentJobType}
                    jobPrices={jobPrices}
                    complexList={props.complexList}
                    jobs={jobs.filter(job => job.work_type_id === currentJobType.id)}
                    jobsAddCallback={addJob}
                    jobsChangeCallback={editJob}
                    jobPricesAddCallback={addJobPrice}
                    jobPricesChangeCallback={editJobPrice}
                />
            </div>

            <CDialogWithLoading
                pending={pending}
                open={jobTypeModalVisivility}
                title={editingJobType.id ? 'Изменение типа работы' : 'Добавить тип работы'}
                body={
                    <JobTypeForm
                        initialValue={editingJobType}
                        serviceId={props.service.id}
                        submitCallback={form => {
                            editingJobType.id
                                ? editJobType(editingJobType.id, form)
                                : addJobType(form);
                        }}
                        cancelCallback={() => setJobTypeModalVisivility(false)}
                    />
                }
            />
        </Stack>
    );
};
