import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
    fetchAppVersions,
    createAppVersion,
    editAppVersion,
    deleteAppVersions,
} from '@/actions/appVersions';
import { cancelUserAuthrizationFailed } from '@/actions/user';
import { appVersions as appVersionsMessages } from '@/etc/notificationMessages';
import connectDataFetchers from '@/lib/connectDataFetchers';

import ListPage from '@/components/pages/AppVersion/ListPage';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import HeaderContainer from '../../Header/HeaderContainer';
import SnackBar from '@/components/SnackBar/SnackBar';
import AppVersionDialog from './AppVersionDialog';
import { WithRouter } from '@/components/hoc/WithRouter';

class AppVersionPageContainer extends Component {
    state = {
        modalWindowIsOpened: false,
        snackBarIsOpen: false,
        snackBarMessage: '',
        appVersionsLoad: {
            created: false,
            createFailed: false,
            deleted: false,
            deleteFailed: false,
        },
    };

    static getDerivedStateFromProps(nextProps, { appVersionsLoad }) {
        const state = {};

        let snackBarMessage = '';
        let changedField = '';
        let isUpdatePage = false;

        if (appVersionsLoad.created !== nextProps.appVersionsLoad.created) {
            snackBarMessage = appVersionsMessages.createSuccess;
            changedField = 'created';
            isUpdatePage = true;
        }

        if (appVersionsLoad.createFailed !== nextProps.appVersionsLoad.createFailed) {
            snackBarMessage = appVersionsMessages.createFail;
            changedField = 'createFailed';
            isUpdatePage = true;
        }

        if (appVersionsLoad.edited !== nextProps.appVersionsLoad.edited) {
            snackBarMessage = appVersionsMessages.editSuccess;
            changedField = 'edited';
            isUpdatePage = true;
        }

        if (appVersionsLoad.editFailed !== nextProps.appVersionsLoad.editFailed) {
            snackBarMessage = appVersionsMessages.editFail;
            changedField = 'editFailed';
        }

        if (appVersionsLoad.deleted !== nextProps.appVersionsLoad.deleted) {
            snackBarMessage = appVersionsMessages.deleteSeccess;
            changedField = 'deleted';
            isUpdatePage = true;
        }

        if (appVersionsLoad.deleteFailed !== nextProps.appVersionsLoad.deleteFailed) {
            snackBarMessage = appVersionsMessages.deleteFail;
            changedField = 'deleteFailed';
        }

        if (snackBarMessage && changedField) {
            state.snackBarIsOpen = true;
            state.snackBarMessage = snackBarMessage;
            state.appVersionsLoad = {
                ...state.appVersionsLoad,
                ...appVersionsLoad,
                [changedField]: nextProps.appVersionsLoad[changedField],
            };

            if (isUpdatePage) {
                nextProps.dispatch(
                    fetchAppVersions({
                        query: {
                            page: 1,
                        },
                    }),
                );
            }
        }

        return state;
    }

    closeSnackBar = () => {
        this.setState({
            snackBarIsOpen: false,
            snackBarMessage: '',
        });
    };

    componentDidMount() {
        const { user } = this.props;
        if (user.authorizateFailed) {
            this.props.dispatch(cancelUserAuthrizationFailed());
        }
    }

    handleChangeLimitRows = rowsPerPage => {
        const query = {
            limit: rowsPerPage,
            page: 1,
        };
        this.props.navigate(`${this.props.location.pathname}?${queryString.stringify(query)}`);
        this.props.dispatch(fetchAppVersions({ limit: rowsPerPage }));
    };

    handleChangePage = page => {
        const search = queryString.parse(this.props.location.search);

        const query = {
            page: page,
        };

        if (search.limit) {
            query.limit = search.limit;
        }

        this.props.navigate(`${this.props.location.pathname}?${queryString.stringify(query)}`);
        this.props.dispatch(fetchAppVersions({ page }));
    };

    handleCloseDialog = () => {
        this.setState({
            modalWindowIsOpened: false,
        });
    };

    handleOpenModalWindowForCreateType = () => {
        this.setState({
            modalWindowIsOpened: true,
            typeOfModalWindow: 'create',
            appVersion: undefined,
        });
    };

    handleOpenModalWindowForEditType = (idOfEditItem, appVersion) => {
        this.setState({
            modalWindowIsOpened: true,
            typeOfModalWindow: 'edit',
            idOfEditItem,
            appVersion,
        });
    };

    render() {
        const {
            appVersions,
            appVersionsLoad,
            createAppVersion,
            editAppVersion,
            deleteAppVersions,
        } = this.props;
        const { modalWindowIsOpened, typeOfModalWindow, snackBarIsOpen, idOfEditItem, appVersion } =
            this.state;
        const headerOptions = {
            nameOfSection: 'Версии приложений',
            nameOfAction: 'Добавить версию',
            typeOfAction: 'func',
        };

        return (
            <div>
                <HeaderContainer
                    options={headerOptions}
                    actionCreate={this.handleOpenModalWindowForCreateType}
                />
                {appVersionsLoad.loading || appVersionsLoad.deleting || appVersionsLoad.creating ? (
                    <CSpinner />
                ) : (
                    <ListPage
                        handleChangePage={this.handleChangePage}
                        handleChangeLimitRows={this.handleChangeLimitRows}
                        onDelete={deleteAppVersions}
                        onEdit={this.handleOpenModalWindowForEditType}
                        appVersions={appVersions}
                        appVersionsLoad={appVersionsLoad}
                    />
                )}
                <AppVersionDialog
                    modalWindowIsOpened={modalWindowIsOpened}
                    typeOfModalWindow={typeOfModalWindow}
                    idOfEditItem={idOfEditItem}
                    appVersion={appVersion}
                    onCloseDialog={this.handleCloseDialog}
                    onCreate={createAppVersion}
                    onEdit={editAppVersion}
                />
                {snackBarIsOpen ? (
                    <SnackBar
                        snackBarIsOpen={snackBarIsOpen}
                        message={this.state.snackBarMessage}
                        closeSnackBar={this.closeSnackBar}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    appVersionsLoad: state.appVersions,
    appVersions: state.appVersions.items,
    operations: state.operations,
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    fetchAppVersions: query => dispatch(fetchAppVersions(query)),
    createAppVersion: version => dispatch(createAppVersion(version)),
    editAppVersion: (id, version) => dispatch(editAppVersion(id, version)),
    deleteAppVersions: ids => dispatch(deleteAppVersions(ids)),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(connectDataFetchers(WithRouter(AppVersionPageContainer), [fetchAppVersions]));
