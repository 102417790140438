import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';
import { Stack, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { CImageUpload } from '@/components/inputs/c-image-upload/c-image-upload';
import { Complex } from '@/types/api/complexes/incom';
import { CSelect } from '@/components/inputs/c-select';
import { FormValidator, formValidationConfig } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { Service } from '@/types/api/services/incom';
import { ServiceForm as IServiceForm } from '@/types/api/services/request-body';
import { sortOptionList } from '@/constants/sort-option-list';
import { ServiceCategory } from '@/types/api/service-categories/incom';
import { ServiceComplexesStateControl } from '@/components/forms/service-complexes-state-control/service-complexes-state-control';
import { activityStatusList } from '@/constants/activity-status-list';
import { CFormControl } from '@/components/forms/c-form-control';
import { trimStringValues } from '@/helpers/transformers';

interface Props {
    initialData: Service;
    complexList: Complex[];
    serviceCategoriesList: ServiceCategory[];
    submitCallback: (form: IServiceForm) => void;
    cancelCallback: () => void;
}

const getValidatorConfig = (): formValidationConfig<IServiceForm> => ({
    name: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        maxLength: {
            order: 2,
            config: [errorTexts.max.string(128), 128],
        },
    },
    image_id: {
        requiredId: {
            order: 1,
            config: [errorTexts.required.image],
        },
    },
    complexes_ids: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.complex],
        },
    },
    service_category_id: {
        requiredId: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },

    price_min: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        min: {
            order: 2,
            config: [errorTexts.min.price, 0],
        },
    },
    is_active: {},
    sort: {},
});

const serviceToServiceForm = (service: Service): IServiceForm => {
    return {
        name: service.name,
        image_id: service?.image?.id || 0,
        complexes_ids: service.complexes,
        service_category_id: service.service_category.id,
        sort: service.sort,
        price_min: service.min_price,
        is_active: service.is_active,
    };
};

export const ServiceForm: FC<Props> = props => {
    const [form, setForm] = useState<IServiceForm>(serviceToServiceForm(props.initialData));

    const validator: FormValidator<IServiceForm> = new FormValidator<IServiceForm>(
        getValidatorConfig(),
    );

    const [validationState, setValidationState] = useState<
        Record<keyof Partial<IServiceForm>, string>
    >(validator.validateAsSimple(form, true));

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form) as IServiceForm;
        setForm(trimmedForm);
        const result = validator.validateAsSimple(trimmedForm);
        setValidationState(result);
        
        if (validator.isValid) props.submitCallback(form);
    };

    return (
        <EntityContentWithAside blockClassName="createArticle">
            {{
                content: (
                    <Stack
                        component="form"
                        noValidate
                        spacing={3}
                    >
                        <TextField
                            required
                            label="Название"
                            value={form.name}
                            onChange={e => {
                                setForm({ ...form, name: e.target.value });
                            }}
                            error={!!validationState.name}
                            helperText={validationState.name}
                        />

                        <CImageUpload
                            name="cji"
                            entityName="services"
                            initialData={
                                props.initialData?.image?.url ? [props.initialData.image] : []
                            }
                            error={validationState.image_id}
                            activeFileChangeCallback={id => setForm({ ...form, image_id: id })}
                            filesChangeCallback={items => {
                                setForm({ ...form, image_id: items[0] || 0 });
                            }}
                        />

                        <CSelect
                            items={props.serviceCategoriesList}
                            value={form.service_category_id ? [form.service_category_id] : []}
                            label="Категория услуги *"
                            placeholder="выбрать категорию услуги"
                            error={validationState.service_category_id}
                            changeCallback={ids =>
                                setForm({
                                    ...form,
                                    service_category_id: ids[0] || 0,
                                })
                            }
                        />

                        <CSelect
                            items={sortOptionList.map(el => ({ id: el.value, name: el.name }))}
                            value={[form.sort]}
                            label="Приоритетность *"
                            placeholder="выбрать приоритетность"
                            changeCallback={ids =>
                                setForm({
                                    ...form,
                                    sort: ids[0] ?? 0,
                                })
                            }
                        />

                        <CSelect
                            items={activityStatusList}
                            value={form.is_active ? [1] : [0]}
                            label="Статус активности *"
                            placeholder="выбрать статус"
                            changeCallback={ids =>
                                setForm({
                                    ...form,
                                    is_active: !!ids[0],
                                })
                            }
                        />

                        <TextField
                            required
                            label="Минимальная цена"
                            type="number"
                            value={form.price_min}
                            onChange={e => {
                                setForm({
                                    ...form,
                                    // @ts-ignore
                                    price_min: isNaN(e.target.value)
                                        ? e.target.value
                                        : +e.target.value,
                                });
                            }}
                            error={!!validationState.price_min}
                            helperText={validationState.price_min}
                        />

                        <ServiceComplexesStateControl
                            allComplexes={props.complexList}
                            categoryComplexes={form.complexes_ids}
                            changeCallback={data => {
                                setForm({ ...form, complexes_ids: data });
                            }}
                            error={validationState.complexes_ids}
                        />
                    </Stack>
                ),
                aside: (
                    <CFormControl
                        controls={[
                            {
                                label: 'Сохранить',
                                callback: handleSubmit,
                                buttonProps: {
                                    variant: 'contained',
                                },
                            },
                            {
                                label: 'Отмена',
                                callback: props.cancelCallback,
                                buttonProps: {
                                    variant: 'outlined',
                                },
                            },
                        ]}
                    />
                ),
            }}
        </EntityContentWithAside>
    );
};
