import { NewsForm } from '@/components/forms/news-form';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { complexService } from '@/services/complex.service';
import { newsService } from '@/services/news.service';
import { tagService } from '@/services/tags.service';
import { Complex } from '@/types/api/complexes/incom';
import { News } from '@/types/api/news/incom';
import { NewsForm as INewsForm } from '@/types/api/news/request-body';
import { EnhancedTag } from '@/types/api/tags/incom';
import { FC, Suspense, useState } from 'react';
import { Await, defer, LoaderFunction, useLoaderData, useNavigate, useParams } from 'react-router';

export const EditNewsPage: FC = () => {
    const { summary } = useLoaderData() as { summary: [Array<Complex>, Array<EnhancedTag>, News] };

    const [pending, setPending] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();

    // Потому что с бэка прилетает с секундами, а закидывать обратно нужно без
    // Применимо только к полю published_at
    const fixedNews = (news: News): News => {
        if (!news.published_at) return news;
        return {
            ...news,
            published_at: news.published_at.slice(0, -3),
        };
    };

    const handleSubmit = async (form: INewsForm): Promise<void> => {
        setPending(true);
        try {
            await newsService.update(+(params.id || 0), form);
            setPending(false);
            navigate('/news');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    const handlePublish = async (id: number): Promise<void> => {
        setPending(true);
        try {
            await newsService.publish(id);
            setPending(false);
            navigate('/news');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    const handleDelete = async (id: number): Promise<void> => {
        setPending(true);
        try {
            await newsService.delete(id);
            setPending(false);
            navigate('/news');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Новости / Редактирование новости',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={summary}>
                    {([complexList, tagList, news]: [Array<Complex>, Array<EnhancedTag>, News]) => (
                        <NewsForm
                            initialData={fixedNews(news)}
                            complexList={complexList}
                            tagList={tagList}
                            submitCallback={handleSubmit}
                            cancelCallback={() => navigate('/news')}
                            publishCallback={handlePublish}
                            deleteCallback={handleDelete}
                        />
                    )}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const editNewsLoader: LoaderFunction = ({ params }) => {
    return defer({
        summary: Promise.all([
            complexService.list(),
            tagService.list(),
            newsService.one(+(params.id || 0)),
        ]),
    });
};
