import api from '../apiSingleton';

export const CREATE_ARTICLE_START = 'CREATE_ARTICLE_START';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAIL = 'CREATE_ARTICLE_FAIL';

export function createNews(news) {
    return dispatch => {
        dispatch({ type: CREATE_ARTICLE_START });

        return api.news
            .createNews(news)
            .then(() => {
                return dispatch({ type: CREATE_ARTICLE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: CREATE_ARTICLE_FAIL });
            });
    };
}

export const EDIT_ARTICLE_START = 'EDIT_ARTICLE_START';
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS';
export const EDIT_ARTICLE_FAIL = 'EDIT_ARTICLE_FAIL';

export function editNews(news, id) {
    return dispatch => {
        dispatch({ type: EDIT_ARTICLE_START });

        return api.news
            .editNews(news, id)
            .then(() => {
                return dispatch({ type: EDIT_ARTICLE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: EDIT_ARTICLE_FAIL });
            });
    };
}

export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL';

export function fetchNews({ query }, ...props) {
    if (Object.keys(query).length === 0) {
        query.page = 1;
    }
    return dispatch => {
        dispatch({ type: FETCH_NEWS_START });
        return api.news
            .fetchNews(query)
            .then(data => {
                return dispatch({ type: FETCH_NEWS_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_NEWS_FAIL });
            });
    };
}

export const FETCH_ARTICLE_START = 'FETCH_ARTICLE_START';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAIL = 'FETCH_ARTICLE_FAIL';

export function fetchArticle(id) {
    return dispatch => {
        dispatch({ type: FETCH_ARTICLE_START });

        return api.news
            .fetchArticle(id)
            .then(data => {
                return dispatch({ type: FETCH_ARTICLE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_ARTICLE_FAIL });
            });
    };
}

export const DELETE_NEWS_START = 'DELETE_NEWS_START';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL';

export function deleteNews(newsId) {
    return dispatch => {
        dispatch({ type: DELETE_NEWS_START });
        return api.news
            .deleteNews(newsId)
            .then(() => {
                return dispatch({ type: DELETE_NEWS_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: DELETE_NEWS_FAIL });
            });
    };
}

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export function uploadImage(image) {
    return dispatch => {
        dispatch({ type: UPLOAD_IMAGE_START });
        return api.news
            .uploadImage(image)
            .then(data => {
                return dispatch({ type: UPLOAD_IMAGE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: UPLOAD_IMAGE_FAIL });
            });
    };
}

export const PUBLISH_NEWS_START = 'PUBLISH_NEWS_START';
export const PUBLISH_NEWS_SUCCESS = 'PUBLISH_NEWS_SUCCESS';
export const PUBLISH_NEWS_FAIL = 'UPLOAD_IMAGE_FAIL';

export function publishNews(newsId) {
    return dispatch => {
        dispatch({ type: PUBLISH_NEWS_START });
        return api.news
            .publishNews(newsId)
            .then(data => {
                return dispatch({ type: PUBLISH_NEWS_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: PUBLISH_NEWS_FAIL });
            });
    };
}

export const CLEAR_UPLOAD_STATE = 'CLEAR_UPLOAD_STATE';

export function clearUploadState(image) {
    return dispatch => {
        dispatch({ type: CLEAR_UPLOAD_STATE });
    };
}

export const CLEAR_CURRENT_ITEM = 'CLEAR_CURRENT_ITEM';

export function clearCurrentItem() {
    return dispatch => {
        dispatch({ type: CLEAR_CURRENT_ITEM });
    };
}
