import { NewsForm } from '@/components/forms/news-form';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { newsPlaceholder } from '@/placeholders/news/incom';
import { complexService } from '@/services/complex.service';
import { newsService } from '@/services/news.service';
import { tagService } from '@/services/tags.service';
import { Complex } from '@/types/api/complexes/incom';
import { NewsForm as INewsForm } from '@/types/api/news/request-body';
import { EnhancedTag } from '@/types/api/tags/incom';
import { FC, Suspense, useState } from 'react';
import { Await, defer, LoaderFunction, useLoaderData, useNavigate } from 'react-router';

export const CreateNewsPage: FC = () => {
    const { summary } = useLoaderData() as { summary: [Array<Complex>, Array<EnhancedTag>] };

    const [pending, setPending] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleSubmit = async (form: INewsForm): Promise<void> => {
        setPending(true);
        try {
            await newsService.create(form);
            setPending(false);
            navigate('/news');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Новости / Создание новости',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={summary}>
                    {([complexList, tagList]: [Array<Complex>, Array<EnhancedTag>]) => (
                        <NewsForm
                            initialData={newsPlaceholder}
                            complexList={complexList}
                            tagList={tagList}
                            submitCallback={handleSubmit}
                            cancelCallback={() => navigate('/news')}
                        />
                    )}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const createNewsLoader: LoaderFunction = () => {
    return defer({
        summary: Promise.all([complexService.list(), tagService.list()]),
    });
};
