import React from 'react';

import { CButton } from '../../Button/CButton';

import './Header.scss';

export default function Header(props) {
    const { options, actionCreate } = props;

    const button = (
        <CButton
            variant="contained"
            label={options.nameOfAction}
            href={options.typeOfAction !== 'func' ? actionCreate : null}
            action={options.typeOfAction === 'func' ? actionCreate : null}
        />
    );

    return (
        <header>
            <div className="sectionName">
                <h2>{options.nameOfSection}</h2>
            </div>
            {options.nameOfAction !== '' ? button : null}
        </header>
    );
}
