import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import type { FC, MouseEvent } from 'react';

interface FCButton {
    label: string;
    callback: (e?: MouseEvent) => void;
    buttonProps: { [key: string]: any };
}

interface Props {
    controls: FCButton[];
    stackOptions?: Record<string, any>;
}

export const CFormControl: FC<Props> = ({ controls, stackOptions }) => {
    return (
        <Stack
            spacing={1}
            {...(stackOptions || {})}
        >
            {controls.map((control, i) => (
                <Button
                    key={i}
                    disabled={control.buttonProps.disabled}
                    onClick={control.callback}
                    {...control.buttonProps}
                >
                    {control.label}
                </Button>
            ))}
        </Stack>
    );
};
