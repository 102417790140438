import NewsApi from './News';
import AdvicesApi from './Advices';
import UserApi from './User';
import ContactsApi from './Contacts';
import AdminCommunicationApi from './AdminCommunication';
import TypesOfAdminObj from './TypesOfAdminObj';
import FaqApi from './FAQ';
import AppVersion from './AppVersion';

import ApiClient from './ApiClient';

export default function () {
    const api = new ApiClient({ prefix: process.env.REACT_APP_API });

    return {
        apiClient: api,
        news: new NewsApi({ apiClient: api }),
        user: new UserApi({ apiClient: api }),
        advices: new AdvicesApi({ apiClient: api }),
        contacts: new ContactsApi({ apiClient: api }),
        adminCommunication: new AdminCommunicationApi({ apiClient: api }),
        typesOfAdminObj: new TypesOfAdminObj({ apiClient: api }),
        faq: new FaqApi({ apiClient: api }),
        appVersion: new AppVersion({ apiClient: api }),
    };
}
