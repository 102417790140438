import { FC, useState } from 'react';
import {
    Stack,
    Typography,
    Button,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';

interface Control {
    buttonLabel: string;
    buttonProps?: Record<string, any>;
    confimation?: {
        title: string;
        text: string;
    };
    submitCallback: () => void;
}

interface Props {
    title?: string;
    selectedItemsCount: number;
    controls: Control[];
}

export const CTableToolbar: FC<Props> = ({ title, selectedItemsCount, controls }) => {
    const [open, setOpen] = useState<boolean>(false);

    const [confirmationTitle, setConfirmationTitle] = useState<string>('');
    const [confirmationText, setConfirmationText] = useState<string>('');
    const [confirmationAction, setConfirmationAction] = useState<{ f: () => void }>({
        f: () => {},
    });

    const doActionWithConfirmation = (
        confirmationTitle: string,
        confirmationText: string,
        submitCallback: () => void,
    ) => {
        setConfirmationTitle(confirmationTitle);
        setConfirmationText(confirmationText);
        setConfirmationAction({ f: submitCallback });
        setOpen(true);
    };

    const onConfirm = async () => {
        setOpen(false);
        confirmationAction.f();
    };

    return (
        <>
            <Stack
                spacing={3}
                style={{ padding: '20px' }}
            >
                {title && <Typography variant="h2">{title}</Typography>}
                <Stack
                    direction="row"
                    spacing={3}
                    alignItems="center"
                >
                    <div>
                        <Typography color="inherit">{selectedItemsCount || 0} выделено</Typography>
                    </div>

                    <div>
                        {controls.map((control, i) => (
                            <Button
                                key={i}
                                onClick={() => {
                                    control.confimation
                                        ? doActionWithConfirmation(
                                              control.confimation.title,
                                              control.confimation.text,
                                              control.submitCallback,
                                          )
                                        : control.submitCallback();
                                }}
                                {...(control?.buttonProps || {})}
                            >
                                {control.buttonLabel}
                            </Button>
                        ))}
                    </div>
                </Stack>
            </Stack>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{confirmationTitle}</DialogTitle>
                {confirmationText.length > 0 && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmationText}
                        </DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Отмена</Button>

                    <Button
                        onClick={onConfirm}
                        autoFocus
                    >
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
