import { gFetch } from '@/helpers/api/g-fetch';
import { WithPagination } from '@/types/IncomWrapper';
import {
    PerformedJob,
    PerformedJobFromList,
    PerformedJobIncomWrapper,
    PerformedJobListIncomWrapper,
} from '@/types/api/performed-jobs/incom';
import { PerformedJobForm } from '@/types/api/performed-jobs/request-body';
import { PerformedJobQuery } from '@/types/api/performed-jobs/request-query';

class PerformedJobService {
    async list(
        page?: number,
        query?: PerformedJobQuery | string,
    ): Promise<WithPagination<PerformedJobFromList[]>> {
        const result = await gFetch<PerformedJobListIncomWrapper>('main', 'performedJobList', {
            params: {
                page: page || 1,
            },
            query,
        });

        return {
            result: result.completed_works,
            total: result.total,
            limit: result.limit,
            page: result.page,
        };
    }

    async one(id: number): Promise<PerformedJob> {
        const result = await gFetch<PerformedJobIncomWrapper>('main', 'performedJob', {
            params: { id },
        });

        return result.completed_works;
    }

    async create(form: PerformedJobForm): Promise<PerformedJob> {
        const result = await gFetch<PerformedJobIncomWrapper>('main', 'createPerformedJob', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return result.completed_works;
    }

    async update(id: number, form: PerformedJobForm): Promise<PerformedJob> {
        const result = await gFetch<PerformedJobIncomWrapper>('main', 'updatePerformedJob', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return result.completed_works;
    }

    async publish(id: number): Promise<PerformedJobForm> {
        const result = await gFetch<{ completed_works: PerformedJobForm }>(
            'main',
            'publishPerformedJob',
            {
                params: {
                    id,
                },
                fetchConfig: {
                    method: 'PATCH',
                },
            },
        );

        return result.completed_works;
    }

    async remove(id: number): Promise<true> {
        await gFetch<{}>('main', 'removePerformedJob', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'DELETE',
            },
            type: 'none',
        });

        return true;
    }

    async removeMany(idList: number[]): Promise<true> {
        await gFetch<{}>('main', 'removePerformedJobList', {
            fetchConfig: {
                method: 'DELETE',
                body: JSON.stringify({ id: idList }),
            },
            type: 'none',
        });

        return true;
    }
}

export const performedJobService = new PerformedJobService();
