export const news = {
    deleteSeccess: 'Новость успешно удалена',
    deleteFail: 'Произошла ошибка при удалении новости',
    createSuccess: 'Новость успешно создана',
    createFail: 'Произошла ошибка при создании новости',
    editSuccess: 'Новость успешно изменена',
    editFail: 'Произошла ошибка при изменении новости',
    publishSuccess: 'Новость успешно опубликована',
    publishFail: 'Произошла ошибка при публикации новости',
};

export const appVersions = {
    deleteSeccess: 'Версия успешно удалена',
    deleteFail: 'Произошла ошибка при удалении версии',
    createSuccess: 'Версия успешно создана',
    createFail: 'Произошла ошибка при создании версии',
    editSuccess: 'Версии успешно изменена',
    editFail: 'Произошла ошибка при изменении версии',
};

export const advices = {
    deleteSeccess: 'Совет успешно удален',
    deleteFail: 'Произошла ошибка при удалении совета',
    createSuccess: 'Совет успешно создан',
    createFail: 'Произошла ошибка при создании совета',
    editSuccess: 'Совет успешно изменен',
    editFail: 'Произошла ошибка при изменении совета',
};

export const faq = {
    deleteSeccess: 'FAQ успешно удален',
    deleteFail: 'Произошла ошибка при удалении FAQ',
    createSuccess: 'FAQ успешно создан',
    createFail: 'Произошла ошибка при создании FAQ',
    editSuccess: 'FAQ успешно изменен',
    editFail: 'Произошла ошибка при изменении FAQ',
};

export const doc = {
    deleteSeccess: 'Документ успешно удален',
    deleteFail: 'Произошла ошибка при удалении документа',
    createSuccess: 'Документ успешно создан',
    createFail: 'Произошла ошибка при создании документа',
};

export const adminObj = {
    deleteSeccess: 'Административный объект успешно удален',
    deleteFail: 'Произошла ошибка при удалении объекта',
    createSuccess: 'Административный объект успешно создан',
    createFail: 'Произошла ошибка при создании объекта',
    editSuccess: 'Административный объект успешно изменен',
    editFail: 'Произошла ошибка при изменении объекта',
};

export const adminCommunication = {
    editSuccess: 'Модуль административных связей успешно изменен',
    editFail: 'Произошла ошибка при изменении административных связей',
    uploadSuccess: 'Файл успешно загружен',
    uploadFail: 'Произошла ошибка при загрузке файла',
};

export const housingComplexes = {
    syncSuccess: 'Жилые комплексы успешно синхронизированы',
    syncFail: 'Произошла ошибка при синхронизации жилых комплексов',
};

export const typesOfAdminObj = {
    deleteSeccess: 'Тип(ы) административного объекта успешно удален(ы)',
    deleteFail: 'Произошла ошибка при удалении типа объекта',
    createSuccess: 'Тип административного объекта успешно создан',
    createFail: 'Произошла ошибка при создании типа объекта',
    editSuccess: 'Тип административного объекта успешно изменен',
    editFail: 'Произошла ошибка при изменении типа объекта',
};
