import { FC, Suspense, useState } from 'react';
import {
    LoaderFunction,
    defer,
    useLoaderData,
    useNavigate,
    useLocation,
    Await,
} from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, Box, Stack } from '@mui/material';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CTable, CTableRow, columnId } from '@/components/Table/c-table';
import { CTableToolbar } from '@/components/Table/c-table-toolbar';
import { TagForm } from '@/components/forms/tags-form';
import { CListPageWrapper } from '@/components/layout/c-list-page-wrapper/c-list-page-wrapper';
import { tagService } from '@/services/tags.service';
import { tagFormPlaceholder } from '@/placeholders/tags/form';
import { AbstractForm } from '@/validators/FormValidator';
import { EnhancedTag } from '@/types/api/tags/incom';
import { sortOptionList } from '@/constants/sort-option-list';

export interface TagFormData extends AbstractForm {
    name: string;
    sort: number;
    isActive: boolean;
}

export interface TagSortOptions {
    name: string;
    value: number;
}

export const tagSortOptionList: TagSortOptions[] = [
    {
        name: 'Высокая',
        value: 0,
    },
    {
        name: 'Обычная',
        value: 1,
    },
    {
        name: 'Низкая',
        value: 2,
    },
];

function tagToTableRow(data: EnhancedTag): CTableRow {
    return [
        data.id,
        [
            {
                data: data.name,
            },
            {
                data: data.is_active ? 'Да' : 'Нет',
            },
            {
                data: sortOptionList.find(el => el.value === +data.sort)?.name || 'Обычная',
            },
        ],
    ];
}

export const Tags: FC = () => {
    const [formDialogVisibility, setFormDialogVisibility] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<number[]>([]);
    const [editingTagId, setEditingTagId] = useState<number | null>();
    const { tags } = useLoaderData() as {
        tags: EnhancedTag[];
    };
    const navigateTo = useNavigate();
    const location = useLocation();

    const headerOptions = {
        nameOfSection: 'Теги',
        nameOfAction: 'Добавить тег',
        typeOfAction: 'func',
    };

    const columnData = [
        { id: 'name', label: 'Название', sortable: false },
        { id: 'activity', label: 'Активность тега', sortable: false },
        { id: 'sort', label: 'Приоритетность', sortable: false },
    ];

    const handleSubmit = async (formData: TagFormData): Promise<void> => {
        try {
            if (editingTagId)
                await tagService.update(
                    editingTagId,
                    formData.name,
                    formData.sort,
                    formData.isActive,
                );
            else await tagService.create(formData.name, +formData.sort);

            navigateTo(location.pathname);
        } catch (e: any) {
            console.error(e?.toString() || e);
        }

        setFormDialogVisibility(false);
    };

    const handleCancel = (): void => {
        setFormDialogVisibility(false);
        setEditingTagId(null);
    };

    const handleRowClick = (id: columnId): void => {
        setEditingTagId(+id);
        setFormDialogVisibility(true);
    };

    const handleDeactivateConfirm = async (): Promise<void> => {
        try {
            await tagService.updateActivity(selectedTags, false);

            navigateTo(location.pathname);

            setSelectedTags([]);
        } catch (e: any) {
            console.error(e?.toString() || e);
        }
    };

    return (
        <Suspense fallback={<CSpinner />}>
            <Await resolve={tags}>
                {(tags: EnhancedTag[]) => {
                    const formValues = (): TagFormData => {
                        if (editingTagId) {
                            const editingTag = tags.find(el => el.id === editingTagId);

                            if (editingTag)
                                return {
                                    name: editingTag.name,
                                    sort:
                                        editingTag.sort >= 0 && editingTag.sort <= 2
                                            ? editingTag.sort
                                            : tagFormPlaceholder.sort,
                                    isActive: editingTag.is_active,
                                };
                            else return tagFormPlaceholder;
                        }
                        return tagFormPlaceholder;
                    };

                    return (
                        <CListPageWrapper
                            headerContainerProps={{
                                options: headerOptions,
                                actionCreate: () => setFormDialogVisibility(true),
                            }}
                        >
                            <>
                                <Box sx={{ padding: 3 }}>
                                    <Stack spacing={3}>
                                        <CTable
                                            columns={columnData}
                                            rows={tags.map(tagToTableRow)}
                                            selectedItems={selectedTags}
                                            toolbar={
                                                <CTableToolbar
                                                    controls={[
                                                        {
                                                            buttonLabel: 'Деактивировать',
                                                            buttonProps: {
                                                                disabled: !selectedTags.length,
                                                            },
                                                            confimation: {
                                                                title: 'Вы действительно хотите деактивировать выбранные теги?',
                                                                text: '',
                                                            },
                                                            submitCallback: handleDeactivateConfirm,
                                                        },
                                                    ]}
                                                    selectedItemsCount={selectedTags.length}
                                                />
                                            }
                                            selectedItemsChangeCallback={value =>
                                                setSelectedTags(value as number[])
                                            }
                                            rowClickCallback={handleRowClick}
                                        />
                                    </Stack>
                                </Box>
                                <Dialog open={formDialogVisibility}>
                                    <DialogTitle>
                                        {editingTagId ? 'Изменение тега' : 'Добавить тег'}
                                    </DialogTitle>

                                    <DialogContent>
                                        <TagForm
                                            submitCallback={handleSubmit}
                                            cancelCallback={handleCancel}
                                            initialValues={{
                                                name: formValues().name,
                                                sort: formValues().sort,
                                                isActive: formValues().isActive,
                                            }}
                                            editMode={!!editingTagId}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </>
                        </CListPageWrapper>
                    );
                }}
            </Await>
        </Suspense>
    );
};

export const tagsLoader: LoaderFunction = async () => {
    return defer({
        tags: tagService.list(),
    });
};
