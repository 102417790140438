import { Component } from 'react';
import { Toolbar, Typography } from '@mui/material';

import { CButton } from '../Button/CButton';
import CDialog from '../layout/Dialog/Dialog';

export default class EnhancedTableToolbar extends Component {
    constructor() {
        super();
        this.state = {
            dialogIsOpen: false,
        };
    }

    handleOpenDialog = () => this.setState({ dialogIsOpen: true });

    handleCloseDialog = () => this.setState({ dialogIsOpen: false });

    handleDelete = () => {
        this.setState({ dialogIsOpen: false });
        this.props.handleDelete({ id: this.state.selected });
    };

    render() {
        const { numSelected, title, deleteMessage } = this.props;
        const { dialogIsOpen } = this.state;
        const buttonStyle = { color: 'black' };

        return (
            <Toolbar>
                <div>
                    {numSelected > 0 ? (
                        <Typography
                            color="inherit"
                            variant="subheading"
                        >
                            {numSelected} выделено
                        </Typography>
                    ) : (
                        <Typography variant="title">{title}</Typography>
                    )}
                </div>
                <div>
                    {numSelected > 0 ? (
                        <CButton
                            label="Удалить"
                            action={this.handleOpenDialog}
                            variant="text"
                            style={buttonStyle}
                        />
                    ) : null}
                </div>
                {dialogIsOpen ? (
                    <CDialog
                        dialogIsOpen={dialogIsOpen}
                        handleCloseDialog={this.handleCloseDialog}
                        handleAction={this.handleDelete}
                        title={deleteMessage}
                        submitButtonText="Удалить"
                        type="delete"
                    />
                ) : null}
            </Toolbar>
        );
    }
}
