import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchAdminObject, clearCurrentItem, deleteAdminObjects } from '@/actions/contacts';
import { cancelNotFound } from '@/actions/operations';
import connectDataFetchers from '@/lib/connectDataFetchers';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { adminObj } from '@/etc/notificationMessages';

import HeaderContainer from '@/containers/Header/HeaderContainer';
import ContactsItemPage from '@/components/pages/Contacts/ItemPage/ItemPage';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import SnackBar from '@/components/SnackBar/SnackBar';
import NotFound from '@/components/layout/NotFound/NotFound';

const ContactsItemPageContainer = props => {
    const params = useParams();
    const navigate = useNavigate();

    const [snackBarIsOpen, setSnackBarIsOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState(false);

    const headerOptions = {
        nameOfSection: 'Контакты / Просмотр объекта',
        nameOfAction: '',
    };

    const closeSnackBar = () => {
        setSnackBarIsOpen(false);
        setSnackBarMessage('');
    };

    const handleEditContact = props => ({
        to: `/contacts/${params.id}/edit-contact`,
        ...props,
    });

    const deleteAdminObject = newsId => {
        props.dispatch(deleteAdminObjects(newsId));
    };

    useEffect(() => {
        return () => {
            props.dispatch(clearCurrentItem());
            if (props.operations.notFoundPage) props.dispatch(cancelNotFound());
        };
    }, []);

    useEffect(() => {
        if (props.contacts.adminObjects.deleted) navigate('/contacts');
    }, [props.contacts.adminObjects.deleted]);

    useEffect(() => {
        if (props.contacts.adminObjects.edited) {
            setSnackBarIsOpen(true);
            setSnackBarMessage(adminObj.editSuccess);
        }
    }, [props.contacts.adminObjects.edited]);

    useEffect(() => {
        if (props.contacts.adminObjects.editFailed) {
            setSnackBarIsOpen(true);
            setSnackBarMessage(adminObj.editFail);
        }
    }, [props.contacts.adminObjects.editFailed]);

    if (props.operations.notFoundPage) {
        return (
            <div>
                <HeaderContainer options={headerOptions} />
                <NotFound />
            </div>
        );
    }

    return (
        <div>
            <HeaderContainer options={headerOptions} />
            {props.contacts.currentItem.loading ? (
                <CSpinner />
            ) : (
                <ContactsItemPage
                    contacts={props.contacts.currentItem}
                    handleEditContact={handleEditContact}
                    deleteAdminObject={deleteAdminObject}
                />
            )}
            {snackBarIsOpen ? (
                <SnackBar
                    snackBarIsOpen={snackBarIsOpen}
                    message={snackBarMessage}
                    closeSnackBar={closeSnackBar}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = state => ({
    contacts: state.contacts,
    operations: state.operations,
});

export default connect(mapStateToProps)(
    connectDataFetchers(ContactsItemPageContainer, [fetchAdminObject]),
);
