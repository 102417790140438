import { API_DATETIME_MASK, TABLE_DATETIME_MASK } from '@/constants/date-masks';
import { formatDate } from '@/helpers/transformers';
import {
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Paper,
    TextField,
} from '@mui/material';
import { Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import Clear from '@mui/icons-material/Clear';
import InsertInvitation from '@mui/icons-material/InsertInvitation';

interface Props {
    checkboxLabel?: string;
    checked?: boolean;
    dateFromValue: string | null;
    dateToValue: string | null;
    errorText?: string;
    filter?: boolean;
    checkboxChangeCallback?: (checked: boolean) => void;
    dateFromChangeCallback: (date: string) => void;
    dateToChangeCallback: (date: string) => void;
}

export const CDateRangeForm: FC<Props> = props => {
    const [open, setOpen] = useState({ from: false, to: false });

    return (
        <Paper
            sx={props.filter ? {} : { p: 2 }}
            elevation={props.filter ? 0 : 1}
        >
            <Stack
                spacing={3}
                direction={props.filter ? 'row' : 'column'}
            >
                {props.checkboxLabel && props.checkboxChangeCallback ? (
                    <FormControlLabel
                        control={<Checkbox defaultChecked={props.checked} />}
                        label={props.checkboxLabel}
                        onChange={(event: React.SyntheticEvent) =>
                            // @ts-ignore
                            props.checkboxChangeCallback(event.target.checked)
                        }
                        sx={{ width: 'fit-content' }}
                    />
                ) : null}

                <DateTimePicker
                    open={open.from}
                    ampm={false}
                    inputFormat={TABLE_DATETIME_MASK}
                    label="Начало периода"
                    value={props.dateFromValue || null}
                    maxDateTime={props.dateToValue && dayjs(props.dateToValue)}
                    onOpen={() => setOpen({ ...open, from: true })}
                    onClose={() => setOpen({ ...open, from: false })}
                    onChange={value =>
                        props.dateFromChangeCallback(
                            // @ts-ignore
                            value ? formatDate(value, API_DATETIME_MASK) : '',
                        )
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            inputProps={{ ...params.inputProps, readOnly: true }}
                            focused={false}
                            onClick={() => setOpen({ ...open, from: true })}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {props.dateFromValue && (
                                            <IconButton
                                                size="small"
                                                onClick={() => props.dateFromChangeCallback('')}
                                            >
                                                <Clear />
                                            </IconButton>
                                        )}
                                        <IconButton size="small">
                                            <InsertInvitation />
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    )}
                />

                <DateTimePicker
                    open={open.to}
                    ampm={false}
                    inputFormat={TABLE_DATETIME_MASK}
                    label="Окончание периода"
                    value={props.dateToValue || null}
                    minDateTime={props.dateFromValue && dayjs(props.dateFromValue)}
                    onOpen={() => setOpen({ ...open, to: true })}
                    onClose={() => setOpen({ ...open, to: false })}
                    onChange={value =>
                        props.dateToChangeCallback(
                            // @ts-ignore
                            value ? formatDate(value, API_DATETIME_MASK) : '',
                        )
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            inputProps={{ ...params.inputProps, readOnly: true }}
                            focused={false}
                            onClick={() => setOpen({ ...open, to: true })}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {props.dateToValue && (
                                            <IconButton
                                                size="small"
                                                onClick={() => props.dateToChangeCallback('')}
                                            >
                                                <Clear />
                                            </IconButton>
                                        )}
                                        <IconButton size="small">
                                            <InsertInvitation />
                                        </IconButton>
                                    </>
                                ),
                            }}
                        />
                    )}
                />

                {!props.filter ? (
                    <FormHelperText error={!!props.errorText}>{props.errorText}</FormHelperText>
                ) : (
                    ''
                )}
            </Stack>
        </Paper>
    );
};
