import React, { Component, Fragment } from 'react';
import { CButton } from '../../../Button/CButton';
import { uploadDocument } from '@/actions/doc';
import { connect } from 'react-redux';

import './CreateFAQModal.scss';

class CreateDocModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            error: '',
        };
        this.dropArea = React.createRef();
    }
    preventDefaults = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    componentDidMount() {
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            this.dropArea.current.addEventListener(eventName, this.preventDefaults, false);
        });
        ['dragenter', 'dragover'].forEach(eventName => {
            this.dropArea.current.addEventListener(eventName, this.highlight, false);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            this.dropArea.current.addEventListener(eventName, this.unhighlight, false);
        });

        this.dropArea.current.addEventListener('drop', this.handleDrop, false);
    }

    close = () => {
        this.props.closeOrOpenModal(false);
    };
    chooseFiles = e => {
        if (e.target.files.length) {
            if (e.target.files[0].type !== 'application/pdf') {
                this.setState(
                    {
                        file: null,
                        error: 'Неверный формат документа',
                    },
                    () => {
                        this.unhighlight(e);
                        setTimeout(() => {
                            this.setState({
                                file: null,
                                error: '',
                            });
                        }, 4000);
                    },
                );
            } else if (e.target.files[0].size > 1048576 * 30) {
                this.setState(
                    {
                        file: null,
                        error: 'Документ имеет слишком большой размер, максимально допустимый размер - 30 мб.',
                    },
                    () => {
                        this.unhighlight(e);
                        setTimeout(() => {
                            this.setState({
                                file: null,
                                error: '',
                            });
                        }, 4000);
                    },
                );
            } else {
                this.setState(
                    {
                        file: e.target.files[0],
                    },
                    () => {
                        this.highlight(e);
                    },
                );
            }
        }
    };
    handleDrop = e => {
        let dt = e.dataTransfer;
        if (dt.files[0].type !== 'application/pdf') {
            this.setState(
                {
                    file: null,
                    error: 'Неверный формат документа',
                },
                () => {
                    this.unhighlight(e);
                    setTimeout(() => {
                        this.setState({
                            file: null,
                            error: '',
                        });
                    }, 4000);
                },
            );
        } else if (dt.files[0].size > 1048576 * 30) {
            this.setState(
                {
                    file: null,
                    error: 'Документ имеет слишком большой размер, максимально допустимый размер - 30 мб.',
                },
                () => {
                    this.unhighlight(e);
                    setTimeout(() => {
                        this.setState({
                            file: null,
                            error: '',
                        });
                    }, 4000);
                },
            );
        } else {
            this.setState(
                {
                    file: dt.files[0],
                },
                () => {
                    this.highlight(e);
                },
            );
        }
    };
    highlight = e => {
        this.dropArea.current.classList.add('highlight');
    };
    unhighlight = e => {
        this.dropArea.current.classList.remove('highlight');
    };
    handleSaveChanges = () => {
        const doc = new FormData();
        doc.append('file', this.state.file, this.state.file.name);
        this.props.uploadDocument(doc);
    };

    render() {
        const buttonStyle = { margin: '0 12px 12px' };
        return (
            <Fragment>
                <div
                    className="fon"
                    onClick={this.close}
                />
                <section className="modalWindow">
                    <h2 className="selectFile">Загрузите файл</h2>

                    <div
                        id="drop-area"
                        ref={this.dropArea}
                    >
                        <form className="my-form">
                            <p className="info">
                                Загрузите pdf файл (не более 30 мб) с помощью выбора файлов или
                                перетащив нужный файл в выделенную область
                            </p>
                            <input
                                type="file"
                                id="fileElem"
                                name={this.state.file ? this.state.file.name : ''}
                                accept="application/pdf"
                                onChange={this.chooseFiles}
                                key={this.state.file ? this.state.file.name : 'file'}
                            />
                            <label
                                className="button"
                                htmlFor="fileElem"
                            >
                                Выбрать файл
                            </label>
                            {this.state.file && (
                                <React.Fragment>
                                    <span>{this.state.file.name}</span>{' '}
                                    <span
                                        onClick={e => {
                                            this.unhighlight(e);
                                            this.setState({ file: null });
                                        }}
                                        className="delete-doc"
                                    >
                                        &#10006;
                                    </span>
                                </React.Fragment>
                            )}
                            {this.state.error && (
                                <p className="error-message">{this.state.error}</p>
                            )}
                        </form>
                    </div>
                    <div className="boolProps">
                        <CButton
                            label="Сохранить"
                            action={this.handleSaveChanges}
                            variant="contained"
                            style={buttonStyle}
                            disable={!this.state.file}
                        />
                        <CButton
                            label="Отмена"
                            variant="contained"
                            style={buttonStyle}
                            action={this.close}
                        />
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    doc: state.doc,
});

export default connect(mapStateToProps, { uploadDocument })(CreateDocModal);
