import { FC } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Radio, Typography } from '@mui/material';

import './c-image-card.scss';

export const CImageCard: FC<{
    id: number;
    url: string;
    checkable?: boolean;
    checked?: boolean;
    checkboxLabel?: string;
    checkCallback?: () => void;
    removeCallback: () => void;
}> = props => {
    const copyURLToClipboard = (): void => {
        navigator.clipboard.writeText(props.url);
        alert('Сcылка изображения cкопирована в буффер обмена.');
    };

    return (
        <div
            className="c-image-card"
            style={{ backgroundImage: `url(${props.url})` }}
        >
            <div className="c-image-card__inner">
                <div className="c-image-card__top">
                    <DeleteIcon
                        className="c-image-card__light-icon c-image-card__hoverable"
                        onClick={props.removeCallback}
                    />
                </div>

                <div className="c-image-card__middle">
                    <ContentCopyIcon
                        className="c-image-card__light-icon c-image-card__hoverable"
                        onClick={copyURLToClipboard}
                    />
                </div>

                {props.checkable && (
                    <div
                        className="c-image-card__bottom"
                        style={{
                            visibility: typeof props.checked === 'boolean' ? 'visible' : 'hidden',
                        }}
                        onClick={props.checkCallback}
                    >
                        <Typography
                            color="aliceblue"
                            variant="caption"
                            display="block"
                        >
                            {props.checkboxLabel || ' '}
                        </Typography>

                        <Radio
                            className="c-image-card__light-checkbox c-image-card__hoverable"
                            checked={props.checked}
                            value={props.id}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
