import { ConfirmDialog } from '@/components/layout/Dialog/confirm-dialog';
import { CSearchField } from '@/components/forms/c-search-field';
import { CSelect, SelectItem } from '@/components/inputs/c-select';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CPagination } from '@/components/Table/c-pagination/c-pagination';
import { CTable, CTableColumn, CTableRow } from '@/components/Table/c-table';
import { CTableToolbar } from '@/components/Table/c-table-toolbar';
import HeaderContainer from '@/containers/Header/HeaderContainer';
import { queryParser } from '@/helpers/api/query-parser';
import { urlSearchParamsToObject } from '@/helpers/api/url-search-params-to-object';
import { servicesQueryPlaceholder } from '@/placeholders/services/request-query.placeholder';
import { serviceCategoriesService } from '@/services/service-categories.service';
import { servicesService } from '@/services/services.service';
import { ServiceCategory } from '@/types/api/service-categories/incom';
import { Service } from '@/types/api/services/incom';
import { ServicesQuery } from '@/types/api/services/request-query';
import { WithPagination } from '@/types/IncomWrapper';
import { Paper, Box, Stack, Grid } from '@mui/material';
import { FC, Suspense, useEffect, useState } from 'react';
import { Await, defer, LoaderFunction, useLoaderData, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { isEqual } from 'lodash';

const TABLE_COLUMNS: Array<CTableColumn> = [
    {
        id: 1,
        label: 'Картинка',
        sortable: false,
    },
    {
        id: 2,
        label: 'Название',
        sortable: true,
    },
    {
        id: 3,
        label: 'Категория услуги',
        sortable: true,
    },
    {
        id: 4,
        label: 'Активность услуги',
        sortable: false,
    },
];

const ACTIVITY_SELECT_ITEMS: Array<SelectItem<number>> = [
    {
        id: -1,
        name: 'Все услуги',
    },
    {
        id: 1,
        name: 'Активные услуги',
    },
    {
        id: 0,
        name: 'Неактивные услуги',
    },
];

function serviceToTableRow(data: Service): CTableRow {
    return [
        data.id,
        [
            {
                data: data?.image?.url || '',
                type: 'image',
            },
            {
                data: data.name,
            },
            {
                data: data.service_category.name,
            },
            {
                data: data.is_active ? 'Да' : 'Нет',
            },
        ],
    ];
}

export const Services: FC = () => {
    const { pageData } = useLoaderData() as {
        pageData: [WithPagination<Array<Service>>, Array<ServiceCategory>];
    };
    const navigate = useNavigate();
    const { page } = useParams();
    const [searchParams] = useSearchParams();

    const [filters, setFilters] = useState<Required<ServicesQuery>>({
        ...servicesQueryPlaceholder,
        ...urlSearchParamsToObject(searchParams),
    });
    const [selectedServices, setSelectedServices] = useState<Array<number>>([]);

    const parsedCategories = (categories: Array<ServiceCategory>): Array<SelectItem<number>> => {
        return categories.reduce((acc: Array<SelectItem<number>>, category) => {
            acc.push({ id: category.id, name: category.name });
            return acc;
        }, []);
    };

    const pageChanged = (page: number): void => {
        setFilters({ ...filters, page });
    };

    const limitChanged = (limit: number): void => {
        setFilters({ ...filters, page: 1, limit });
    };

    const handleDeactivate = async (): Promise<void> => {
        try {
            await servicesService.batchUpdateActivity({ ids: selectedServices, is_active: 0 });
            navigate(location.pathname + queryParser({ ...filters, page: 1 } as any));
            setSelectedServices([]);
        } catch (e: any) {
            console.error(e?.toString() || e);
        }
    };

    const handleActivityFilterChange = (selected: Array<number>): void => {
        selected[0] !== 0 && selected[0] !== 1
            ? setFilters({ ...filters, is_active: undefined, page: 1 })
            : setFilters({ ...filters, is_active: selected[0], page: 1 });
        setSelectedServices([]);
    };

    const handleCategoryFilterChange = (selected: Array<number>): void => {
        setFilters({ ...filters, service_category: selected, page: 1 });
        setSelectedServices([]);
    };

    const handleSearchChange = (q: string): void => {
        setFilters({ ...filters, q, page: 1 });
        setSelectedServices([]);
    };

    useEffect(() => {
        if (isEqual(filters, servicesQueryPlaceholder)) return;
        navigate(location.pathname + queryParser(filters as any));
    }, [filters]);

    return (
        <Suspense fallback={<CSpinner />}>
            <Await resolve={pageData}>
                {([services, categories]: [
                    WithPagination<Array<Service>>,
                    Array<ServiceCategory>,
                ]) => {
                    return (
                        <>
                            <HeaderContainer
                                options={{
                                    nameOfSection: 'Услуги',
                                    nameOfAction: 'Добавить услугу',
                                }}
                                actionCreate="/services/create-article"
                            ></HeaderContainer>

                            <Box sx={{ p: 3 }}>
                                <Stack spacing={3}>
                                    <Grid
                                        container
                                        columns={3}
                                        columnSpacing={2}
                                        direction="row"
                                    >
                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <CSearchField
                                                label="Поиск по названию"
                                                value={filters.q}
                                                changeCallback={handleSearchChange}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <CSelect
                                                value={[filters.is_active ?? -1]}
                                                items={ACTIVITY_SELECT_ITEMS}
                                                changeCallback={handleActivityFilterChange}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <CSelect
                                                multiple
                                                placeholder={'Поиск по категории'}
                                                value={filters.service_category}
                                                items={parsedCategories(categories)}
                                                changeCallback={handleCategoryFilterChange}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Paper elevation={3}>
                                        <CTable
                                            toolbar={
                                                <CTableToolbar
                                                    selectedItemsCount={selectedServices.length}
                                                    controls={[
                                                        {
                                                            buttonLabel: 'Деактивировать',
                                                            buttonProps: {
                                                                disabled: !selectedServices.length,
                                                            },
                                                            confimation: {
                                                                title: 'Деактивация',
                                                                text: 'Вы уверены, что хотите деактивировать выбранные элементы?',
                                                            },
                                                            submitCallback: handleDeactivate,
                                                        },
                                                    ]}
                                                />
                                            }
                                            pagination={
                                                <CPagination
                                                    data={{
                                                        limit: filters.limit,
                                                        page: +(services.page || page || 1),
                                                        total: services.total,
                                                    }}
                                                    pageChangeCallback={pageChanged}
                                                    limitChangeCallback={limitChanged}
                                                />
                                            }
                                            columns={TABLE_COLUMNS}
                                            rows={services.result.map(serviceToTableRow)}
                                            selectedItems={selectedServices}
                                            selectedItemsChangeCallback={value =>
                                                setSelectedServices(value as number[])
                                            }
                                            rowClickCallback={id => navigate(`/services/${id}`)}
                                        />
                                    </Paper>
                                </Stack>
                            </Box>
                        </>
                    );
                }}
            </Await>
        </Suspense>
    );
};

export const servicesLoader: LoaderFunction = async ({ request }) => {
    const url = new URL(request.url);
    return defer({
        pageData: Promise.all([
            servicesService.listByPage(+(url.searchParams.get('page') || 1), url.search),
            serviceCategoriesService.list(),
        ]),
    });
};
