import HeaderContainer, { HeaderContinerProps } from '@/containers/Header/HeaderContainer';
import { Stack } from '@mui/system';
import React, { FC } from 'react';

import './c-entity-page-wrapper.scss';
import { CSpinner } from '../c-spinner/c-spinner';

interface Props {
    children: React.ReactElement;
    headerContainerProps: HeaderContinerProps;
    pending?: boolean;
}

export const CEntityPageWrapper: FC<Props> = props => {
    return (
        <>
            <CSpinner style={!props.pending ? { display: 'none' } : {}} />

            <div style={props.pending ? { display: 'none' } : {}}>
                <HeaderContainer {...props.headerContainerProps} />

                <div className="c-entity-page-wrapper">
                    <Stack spacing={3}>{props.children}</Stack>
                </div>
            </div>
        </>
    );
};
