import { PerformedJob, PerformedJobFromList } from '@/types/api/performed-jobs/incom';

export const performedJobFromListPlaceholder: Readonly<PerformedJobFromList> = {
    id: 0,
    title: '',
    description: '',
    image_cover: {
        id: 0,
        url: '',
    },
    published_at: null,
    work_date: null,
};

export const performedJobPlaceholder: Readonly<PerformedJob> = {
    ...performedJobFromListPlaceholder,
    article: '',
    images: [],
    housing_complexes: [],
    push: false,
    created_at: null,
    updated_at: null,
    published_to: [],
    publish_on: null,
    tags_id: [],
    buildings: [{
        complex_id: '',
        building_id: '',
        entrance_id: [],
    }],
};
