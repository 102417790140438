import { Job, JobType } from '@/types/api/jobs/incom';

export const jobTypePlaceholder: Readonly<JobType> = {
    id: 0,
    name: '',
    is_active: true,
    service_id: 0,
};

export const jobPlaceholder: Job = {
    id: 0,
    name: '',
    is_active: true,
    work_type_id: 0,
};
