import React, { FC, useState } from 'react';

import './c-image-upload.scss';
import { gFetch } from '@/helpers/api/g-fetch';
import { UploadedFileInfo } from '@/types/api/file/incom';
import { Image } from '@/types/Shared';
import { CImageCard } from './c-image-card';
import { FormHelperText, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    name: string;
    entityName: 'news' | 'advices' | 'request' | 'completedWorks' | 'categoryServices' | 'services';
    activeFileId?: number;
    multiple?: boolean;
    required?: boolean;
    checkboxLabel?: string; // Использовать как обоожку
    initialData: Array<Image & { name?: string }>;
    error?: string;
    filesChangeCallback: (fileIds: number[]) => void;
    activeFileChangeCallback?: (id: number) => void;
}

export const CImageUpload: FC<Props> = props => {
    const [files, setFiles] = useState<Array<Image & { name?: string }>>(props.initialData);
    const [pending, setPending] = useState<boolean>(false);

    const handleRemove = (id: number): void => {
        const result: number[] = files.filter(image => image.id !== id).map(image => image.id);

        props.filesChangeCallback(result);

        setFiles(files.filter(image => result.includes(image.id)));

        // сброс обложки, если исходная картинка удаляется из галереи
        if (id === props.activeFileId) handleCheck(0);
    };
    const handleCheck = (id: number): void => {
        if (props.activeFileChangeCallback) props.activeFileChangeCallback(id);
    };

    const handleFileAdding = async (e: React.ChangeEvent): Promise<void> => {
        if (!(e.target instanceof HTMLInputElement)) return;
        setPending(true);

        const formsList: FormData[] = [];

        for (let i = 0; i < e.target.files!.length; ++i) {
            const form = new FormData();
            form.append('file', e.target.files![i], e.target.files![i].name);
            formsList.push(form);
        }

        try {
            const result = await Promise.all(
                formsList.map(form =>
                    gFetch<UploadedFileInfo>('main', 'uploadImage', {
                        params: {
                            type: 'image',
                            entityName: props.entityName,
                        },
                        query: {
                            is_new_version: 1,
                        },
                        fetchConfig: {
                            body: form,
                            method: 'POST',
                        },
                    }),
                ),
            );

            if (!props.multiple) {
                props.filesChangeCallback([result[0].storage.id]);
                setFiles([
                    {
                        id: result[0].storage.id,
                        name: result[0].storage.original_name,
                        url: result[0].storage.url,
                    },
                ]);
            } else {
                props.filesChangeCallback([
                    ...files.map(image => image.id),
                    ...result.map(el => el.storage.id),
                ]);
                setFiles([
                    ...files,
                    ...result.map(el => ({
                        id: el.storage.id,
                        name: el.storage.original_name,
                        url: el.storage.url,
                    })),
                ]);
            }
        } catch (e) {
            console.error(e);
        }

        setPending(false);
    };

    return (
        <div className={`c-image-upload ${props.error ? 'c-image-upload--error' : ''}`}>
            <div className="c-image-upload__grid">
                {files.map(image => (
                    <CImageCard
                        key={image.id}
                        id={image.id}
                        url={image.url}
                        checkable={props.multiple}
                        checked={props.activeFileId === image.id}
                        checkboxLabel={props.checkboxLabel}
                        removeCallback={() => handleRemove(image.id)}
                        checkCallback={() => handleCheck(image.id)}
                    />
                ))}

                <label
                    className="c-image-upload-label"
                    htmlFor={props.name}
                >
                    {!pending ? (
                        <>
                            <input
                                className="c-image-upload__native"
                                id={props.name}
                                name={props.name}
                                type="file"
                                multiple={props.multiple}
                                accept={'.jpg, .jpeg, .png'}
                                onChange={handleFileAdding}
                            />

                            <div className="c-image-upload-label__top c-image-upload-label__top--icon-scaled">
                                <CloudUploadIcon />
                            </div>

                            <div className="c-image-upload-label__bottom">
                                <Typography>
                                    {`Выберите ${props.multiple ? 'изображения' : 'изображение'} ${
                                        props.required && files.length === 0 ? '*' : ''
                                    }`}
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="c-image-upload-label__top">
                                <CircularProgress />
                            </div>

                            <div className="c-image-upload-label__bottom">
                                <Typography>Загрузка...</Typography>
                            </div>
                        </>
                    )}
                </label>
            </div>

            <FormHelperText error={!!props?.error}>{props?.error}</FormHelperText>
        </div>
    );
};
