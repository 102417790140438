import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { createFaq, editFaq } from '../../../actions/faq';
import { fetchComplexes } from '../../../actions/contacts';

import CreateModal from '../../../components/pages/FAQ/CreateFAQModal/CreateFAQModal';
import { CSpinner } from '../../../components/layout/c-spinner/c-spinner';

class ContactsCreatePageContainer extends Component {
    handleCreateFaq = faqData => {
        this.props.createFaq(faqData);
    };

    handleEditFaq = (id, faqData) => {
        this.props.editFaq(id, faqData);
    };

    handleRedirect = () => <Link to="/faq" />;

    openModal = () => {
        this.props.closeOrOpenModal(true);
    };

    componentDidMount() {
        const { houseComplexes } = this.props.contacts;
        if (houseComplexes.items.length === 0) this.props.fetchComplexes();
    }

    render() {
        const {
            handleOpenEditModal,
            closeOrOpenModal,
            contacts,
            question,
            answer,
            complexesIds,
            faqToEditId,
            docObjects,
            operations,
        } = this.props;

        return (
            <Fragment>
                {contacts.houseComplexes.loading ? (
                    <CSpinner />
                ) : (
                    <CreateModal
                        handleRedirect={this.handleRedirect}
                        handleCreateFaq={this.handleCreateFaq}
                        handleEditFaq={this.handleEditFaq}
                        closeOrOpenModal={closeOrOpenModal}
                        handleOpenEditModal={handleOpenEditModal}
                        contacts={contacts.houseComplexes}
                        question={question}
                        answer={answer}
                        complexesIds={complexesIds}
                        faqToEditId={faqToEditId}
                        docObjects={docObjects}
                        //operations={operations}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    contacts: state.contacts,
});

const mapDispatchToProps = dispatch => ({
    fetchComplexes: () => dispatch(fetchComplexes()),
    createFaq: data => dispatch(createFaq(data)),
    editFaq: (id, data) => dispatch(editFaq(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsCreatePageContainer);
