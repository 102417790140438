import { PriceExtended, PriceHistoryItem } from '@/types/api/price/incom';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { TABLE_DATETIME_MASK } from '@/constants/date-masks';
import { formatDate } from '@/helpers/transformers';

interface Props {
    price: PriceExtended;
    priceHistory: Array<PriceHistoryItem>;
}

export const PriceHistoryTable: FC<Props> = ({ price, priceHistory }) => {
    return (
        <>
            <Typography variant="subtitle1">{`Текущая цена ${price.price_value}`}</Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Цена</TableCell>

                        <TableCell align="center">Дата окончания действия</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {priceHistory &&
                        priceHistory.map(item => (
                            <TableRow key={item.id}>
                                <TableCell align="center">{item.price_value}</TableCell>

                                <TableCell align="center">
                                    {item.date_closed
                                        ? formatDate(item.date_closed, TABLE_DATETIME_MASK)
                                        : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </>
    );
};
