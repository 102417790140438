export const sortOptionList: {
    name: string;
    value: number;
}[] = [
    {
        name: 'Высокая',
        value: 0,
    },
    {
        name: 'Обычная',
        value: 1,
    },
    {
        name: 'Низкая',
        value: 2,
    },
];
