import { Price, PriceExtended } from '@/types/api/price/incom';

export const pricePlaceHolder: Readonly<Price> = {
    id: 0,
    work_price_id: 0,
    price: 0,
};

export const priceExtendedPlaceHolder: Readonly<PriceExtended> = {
    id: 0,
    work_id: 0,
    complex: {
        id: '',
        name: '',
    },
    external_key: '',
    is_active: false,
    price_id: 0,
    price_value: 0,
};
