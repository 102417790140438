import Base from './Base';

export default class ContactsApi extends Base {
    createAdminObject(contact) {
        return this.apiClient.post(`api/administrative_objects`, contact);
    }

    fetchComplexes() {
        return this.apiClient.get(`api/complexes`);
    }

    fetchAdminObject(id) {
        return this.apiClient.get(`api/administrative_objects/${id}`);
    }

    deleteAdminObjects(data) {
        return this.apiClient.delete(`api/administrative_objects`, data);
    }

    editAdminObject(id, data) {
        return this.apiClient.put(`api/administrative_objects/${id}`, data);
    }

    fetchAdminObjects() {
        return this.apiClient.get(`api/administrative_objects`);
    }

    fetchTypeAdminObjects() {
        return this.apiClient.get(`api/types_administrative_objects`);
    }
    syncHousingComplexes() {
        return this.apiClient.get(`api/complexes-sync`);
    }
}
