import { Job, JobType } from '@/types/api/jobs/incom';
import { FC, Fragment, useEffect, useState } from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    Checkbox,
    Grid,
    Typography,
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { JobForm } from '../job-form';
import { jobPlaceholder, jobTypePlaceholder } from '@/placeholders/jobs/incom';
import { Service } from '@/types/api/services/incom';
import { JobForm as IJobForm } from '@/types/api/jobs/request-body';
import { jobsService } from '@/services/jobs.service';
import { PriceExtended, PriceHistoryItem } from '@/types/api/price/incom';
import { priceExtendedPlaceHolder } from '@/placeholders/price/incom';
import { priceService } from '@/services/price.service';
import { PriceForm } from '../price-form';
import { PriceForm as IPriceForm } from '@/types/api/price/request-body';
import { Complex } from '@/types/api/complexes/incom';
import HistoryIcon from '@mui/icons-material/History';
import { CDialogWithLoading } from '@/components/layout/c-dialog-with-loading';
import { PriceHistoryTable } from './price-history-table';

interface Column {
    id: 'job' | 'complex' | 'foreignKey' | 'price';
    label: string;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    format?: (value: number) => string;
}

const columns: Column[] = [
    {
        id: 'job',
        label: 'Работа',
    },
    {
        id: 'complex',
        label: 'ЖК',
    },
    {
        id: 'foreignKey',
        label: 'Внешний ключ',
    },
    {
        id: 'price',
        label: 'Цена',
    },
];

const BuildRows: FC<{
    jobs: Array<Job>;
    jobPrices: Array<PriceExtended>;
    jobType: JobType;
    editJobCallback(job: Job): void;
    patchJobCallback(job: Job): void;
    addPriceCallback(jobPrice: Partial<PriceExtended>): void;
    editPriceCallback(price: PriceExtended): void;
    patchPriceCallback(price: PriceExtended): void;
    showPriceHistoryCallback(price: PriceExtended, job: Job, jobType: JobType): void;
}> = ({
    jobs,
    jobPrices,
    jobType,
    patchJobCallback,
    editJobCallback,
    addPriceCallback,
    editPriceCallback,
    patchPriceCallback,
    showPriceHistoryCallback,
}) => {
    const transformData = () => {
        const pricesGroupedByJob: Record<number, [Job, PriceExtended[]]> = jobs.reduce<
            Record<number, [Job, PriceExtended[]]>
        >((acc, job) => {
            acc[job.id] = [job, []];
            return acc;
        }, {});

        jobPrices.forEach(price => {
            if (pricesGroupedByJob[price.work_id]) pricesGroupedByJob[price.work_id][1].push(price);
        });

        return pricesGroupedByJob;
    };

    const [pricesGroupedByJob, setPricesGroupedByJob] = useState(transformData());

    useEffect(() => setPricesGroupedByJob(transformData()), [jobs, jobPrices]);

    return (
        <>
            {Object.entries(pricesGroupedByJob).map(([workId, [job, prices]]) => {
                return !prices.length ? (
                    <TableRow key={job.id}>
                        <TableCell>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                columns={3}
                                columnSpacing={1}
                            >
                                <Grid
                                    item
                                    xs="auto"
                                >
                                    <Checkbox
                                        checked={job.is_active}
                                        value={job.is_active}
                                        onChange={(e, v) => {
                                            patchJobCallback({ ...job, is_active: v });
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs="auto"
                                >
                                    {job.name}
                                </Grid>
                                <Grid
                                    item
                                    xs="auto"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => editJobCallback(job)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell colSpan={3}>
                            <Button
                                color="primary"
                                onClick={() =>
                                    addPriceCallback({
                                        work_id: job.id,
                                    })
                                }
                            >
                                Добавить цену
                                <AddIcon />
                            </Button>
                        </TableCell>
                    </TableRow>
                ) : (
                    <Fragment key={`${workId}_${job.id}`}>
                        {prices.map((price, j) => (
                            <TableRow key={`${workId}_${price.id}`}>
                                <TableCell>
                                    {!j ? (
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            columns={3}
                                            columnSpacing={1}
                                        >
                                            <Grid
                                                item
                                                xs="auto"
                                            >
                                                <Checkbox
                                                    checked={job.is_active}
                                                    value={job.is_active}
                                                    onChange={(e, v) => {
                                                        patchJobCallback({ ...job, is_active: v });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs="auto"
                                            >
                                                {job.name}
                                            </Grid>
                                            <Grid
                                                item
                                                xs="auto"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => editJobCallback(job)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </TableCell>
                                <TableCell>{price.complex.name}</TableCell>
                                <TableCell>{price.external_key}</TableCell>
                                <TableCell>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        columns={4}
                                        columnSpacing={1}
                                    >
                                        <Grid
                                            item
                                            xs="auto"
                                        >
                                            <Checkbox
                                                checked={price.is_active}
                                                value={price.is_active}
                                                onChange={(e, v) => {
                                                    patchPriceCallback({ ...price, is_active: v });
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs="auto"
                                        >
                                            <Typography>{price.price_value}</Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs="auto"
                                        >
                                            <IconButton
                                                color="primary"
                                                onClick={() => editPriceCallback(price)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid
                                            item
                                            xs="auto"
                                        >
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    showPriceHistoryCallback(price, job, jobType)
                                                }
                                            >
                                                <HistoryIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        addPriceCallback({
                                            work_id: job.id,
                                        })
                                    }
                                >
                                    Добавить цену
                                    <AddIcon />
                                </Button>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </Fragment>
                );
            })}
        </>
    );
};

interface Props {
    jobs: Array<Job>;
    jobsChangeCallback(job: Job): void;
    jobsAddCallback(job: Job): void;
    jobType: JobType;
    service: Service;
    jobPrices: Array<PriceExtended>;
    jobPricesChangeCallback(replaceId: number, jobPrice: PriceExtended): void;
    jobPricesAddCallback(jobPrice: PriceExtended): void;
    complexList: Complex[];
}

export const ServiceJobsControlTable: FC<Props> = props => {
    const [pending, setPending] = useState(false);

    //// работы ////

    const [jobModalVisivility, setJobModalVisivility] = useState(false);
    const [editingJob, setEditingJob] = useState(jobPlaceholder);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);

    const editJob = async (form: IJobForm): Promise<void> => {
        setPending(true);

        try {
            const result = await jobsService.update(editingJob.id, {
                ...form,
            });
            // TODO зменить на result
            props.jobsChangeCallback(result);
        } catch (e) {
            console.error(e);
        }
        setJobModalVisivility(false);
        setPending(false);
    };

    const addJob = async (form: IJobForm): Promise<void> => {
        setPending(true);

        form = { ...form, work_type_id: props.jobType.id };
        try {
            const result = await jobsService.create(form);
            props.jobsAddCallback(result);
        } catch (e) {
            console.error(e);
        }
        setJobModalVisivility(false);
        setPending(false);
    };

    const changeJobActivity = async (job: Job): Promise<void> => {
        setPending(true);

        try {
            const result = await jobsService.update(job.id, {
                ...job,
            });
            // TODO зменить на result
            props.jobsChangeCallback(result);
        } catch (e) {
            console.error(e);
        }
        setJobModalVisivility(false);
        setPending(false);
    };

    // Цены
    const [jobPriceModalVisivility, setJobPriceModalVisivility] = useState(false);
    const [editingJobPrice, setEditingJobPrice] = useState(priceExtendedPlaceHolder);

    const editJobPrice = async (form: IPriceForm): Promise<void> => {
        setPending(true);

        try {
            const result = await priceService.updateValue(editingJobPrice.price_id, {
                new_price: form.price,
            });
            // TODO зменить на result
            props.jobPricesChangeCallback(editingJobPrice.price_id, result);
        } catch (e) {
            console.error(e);
        }
        setJobPriceModalVisivility(false);
        setPending(false);
    };

    const addJobPrice = async (form: IPriceForm): Promise<void> => {
        setPending(true);

        form = { ...form };
        try {
            const result = await priceService.create(form);
            props.jobPricesAddCallback(result);
        } catch (e) {
            const complexName =
                props.complexList.find(complex => complex.IntegrationId === form.complex_id)
                    ?.Name || '';
            const jobPrice =
                props.jobPrices.find(price => price.complex.id === form.complex_id)?.price_value ||
                0;

            setErrorMessage(
                `По данной работе в ЖК ${complexName} уже действует цена ${jobPrice}. Её можно отредактировать.`,
            );
            setErrorMessageVisibility(true);

            console.error(e);
        }
        setJobPriceModalVisivility(false);
        setPending(false);
    };

    const changeJobPriceActivity = async (form: PriceExtended): Promise<void> => {
        setPending(true);

        try {
            const result = await priceService.updateActivity(form.id, {
                is_active: form.is_active,
            });

            props.jobPricesChangeCallback(form.price_id, { ...form, ...result });
        } catch (e) {
            console.error(e);
        }
        setPending(false);
    };

    // История цен

    const [priceHistoryModalVisivility, setPriceHistoryModalVisivility] = useState<boolean>(false);
    const [priceHistory, setPriceHistory] = useState<Array<PriceHistoryItem>>([]);
    const [viewingItem, setViewingItem] = useState<{
        price: PriceExtended;
        job: Job;
        jobType: JobType;
    }>({
        price: priceExtendedPlaceHolder,
        job: jobPlaceholder,
        jobType: jobTypePlaceholder,
    });

    const handleHistoryClick = async (price: PriceExtended): Promise<void> => {
        setPending(true);

        try {
            const result = await priceService.list(price.id);
            setPriceHistory(result);
        } catch (e) {
            console.error(e);
        }

        setPending(false);
    };

    const handleErrorMessageClosing = () => {
        setErrorMessage('');
        setErrorMessageVisibility(false);
    };

    return (
        <>
            <Stack spacing={3}>
                {props.jobs.length ? (
                    <TableContainer component={Paper}>
                        <Table
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            {...column}
                                            key={column.id}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <BuildRows
                                    jobs={props.jobs}
                                    jobPrices={props.jobPrices}
                                    jobType={props.jobType}
                                    editJobCallback={job => {
                                        setEditingJob(job);
                                        setJobModalVisivility(true);
                                    }}
                                    patchJobCallback={changeJobActivity}
                                    addPriceCallback={data => {
                                        setEditingJobPrice({
                                            ...priceExtendedPlaceHolder,
                                            ...data,
                                        });
                                        setJobPriceModalVisivility(true);
                                    }}
                                    editPriceCallback={jobPrice => {
                                        setEditingJobPrice(jobPrice);
                                        setJobPriceModalVisivility(true);
                                    }}
                                    patchPriceCallback={changeJobPriceActivity}
                                    showPriceHistoryCallback={(price, job, jobType) => {
                                        handleHistoryClick(price);
                                        setViewingItem({ price, job, jobType });
                                        setPriceHistoryModalVisivility(true);
                                    }}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : null}

                <div>
                    <Button
                        color="primary"
                        onClick={() => {
                            setEditingJob(jobPlaceholder);

                            setJobModalVisivility(true);
                        }}
                    >
                        Добавить работу <AddIcon />
                    </Button>
                </div>
            </Stack>

            <CDialogWithLoading
                open={jobModalVisivility}
                title={editingJob.id ? 'Изменение работы' : 'Добавить работу'}
                body={
                    <JobForm
                        initialValue={editingJob.id ? editingJob : jobPlaceholder}
                        submitCallback={form => {
                            editingJob.id ? editJob(form) : addJob(form);
                        }}
                        cancelCallback={() => setJobModalVisivility(false)}
                    />
                }
                pending={pending}
            />

            <CDialogWithLoading
                open={jobPriceModalVisivility}
                title={editingJobPrice.price_id ? 'Изменение цены' : 'Добавить цену'}
                body={
                    <PriceForm
                        initialValue={editingJobPrice}
                        complexes={props.complexList}
                        submitCallback={form =>
                            editingJobPrice.price_id ? editJobPrice(form) : addJobPrice(form)
                        }
                        cancelCallback={() => setJobPriceModalVisivility(false)}
                    />
                }
                pending={pending}
            />

            <CDialogWithLoading
                pending={pending}
                open={priceHistoryModalVisivility}
                title={`${viewingItem.jobType.name} / ${viewingItem.job.name} / ${viewingItem.price.complex.name}`}
                body={
                    <PriceHistoryTable
                        price={viewingItem.price}
                        priceHistory={priceHistory}
                    />
                }
                closeCallback={() => setPriceHistoryModalVisivility(false)}
            />

            <Snackbar
                open={errorMessageVisibility}
                autoHideDuration={5000}
                onClose={handleErrorMessageClosing}
            >
                <Alert
                    onClose={handleErrorMessageClosing}
                    sx={{ width: '100%' }}
                    severity="error"
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};
