import { FC, Suspense, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { AdviceForm } from '@/components/forms/advice-form';
import { advicesService } from '@/services/advices.service';
import { AdviceForm as IAdviceForm } from '@/types/api/advices/request-body';
import { adviceIncomPlaceholder } from '@/placeholders/advices/incom';

export const CreateAdvice: FC = () => {
    const [pending, setPending] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (form: any) => {
        setPending(true);
        try {
            setPending(false);
            await advicesService.create(form);
            navigate('/advices');
        } catch (e: any) {
            console.error(e);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Полезные советы / Создать совет',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <AdviceForm
                    initialValue={adviceIncomPlaceholder}
                    submitCallback={(form: IAdviceForm) => {
                        onSubmit(form);
                    }}
                />
            </Suspense>
        </CEntityPageWrapper>
    );
};
