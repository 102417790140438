import { Component } from 'react';

import { CButton } from '../../../Button/CButton';
import Dialog from '../../../layout/Dialog/Dialog';

import 'react-quill/dist/quill.core.css';
import './ItemPage.scss';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';
import { formatDate } from '@/helpers/transformers';
import { TABLE_DATETIME_MASK } from '@/constants/date-masks';

export default class AdvicesItemPage extends Component {
    constructor() {
        super();
        this.state = {
            dialogIsOpen: false,
        };
    }

    handleDeleteAdvice = () => {
        this.props.deleteAdvice({ id: [this.props.currentItem.id] });
        this.setState({ dialogIsOpen: false });
    };

    handleOpenDialog = () => this.setState({ dialogIsOpen: true });

    handleCloseDialog = () => this.setState({ dialogIsOpen: false });

    render() {
        const { currentItem, handleToEditPage } = this.props;
        const { dialogIsOpen } = this.state;
        const createText = () => ({
            __html: currentItem.article,
        });

        return (
            <>
                <EntityContentWithAside blockClassName="itemPage">
                    {{
                        content: (
                            <>
                                <div className="propsOfArticle">
                                    <p>Заголовок</p>
                                    <p>{currentItem.title}</p>
                                    <hr />
                                    <ImageView {...this.props} />
                                    <hr />
                                    <DocumentView {...this.props} />
                                    <hr />
                                    <p>Краткое описание статьи</p>
                                    <p>{currentItem.description}</p>
                                </div>
                                <div className="propsOfArticle">
                                    <p>Текст Статьи</p>
                                    <div
                                        className="article"
                                        dangerouslySetInnerHTML={createText()}
                                    ></div>
                                </div>
                            </>
                        ),
                        aside: (
                            <>
                                <center>
                                    <CButton
                                        label="Удалить"
                                        action={this.handleOpenDialog}
                                        variant="contained"
                                    />

                                    <CButton
                                        label="Изменить"
                                        link={handleToEditPage}
                                        variant="contained"
                                    />
                                </center>
                                <div className="propsOfArticle">
                                    <p>Опубликовано</p>
                                    {formatDate(currentItem.created_at, TABLE_DATETIME_MASK, true)}
                                    {!currentItem.date_of_event ? null : (
                                        <div className="dateAndTime">
                                            <div>
                                                <p>Начало события</p>
                                                {formatDate(
                                                    currentItem.date_of_event.date_begin,
                                                    TABLE_DATETIME_MASK,
                                                    true,
                                                )}
                                            </div>
                                            <div>
                                                <p>Окончание события</p>
                                                {formatDate(
                                                    currentItem.date_of_event.date_end,
                                                    TABLE_DATETIME_MASK,
                                                    true,
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ),
                    }}
                </EntityContentWithAside>
                {dialogIsOpen ? (
                    <Dialog
                        dialogIsOpen={dialogIsOpen}
                        handleCloseDialog={this.handleCloseDialog}
                        handleAction={this.handleDeleteAdvice}
                        title="Вы действительно хотитие удалить эту новость?"
                        submitButtonText="Удалить"
                        type="delete"
                    />
                ) : null}
            </>
        );
    }
}

const ImageView = ({ currentItem }) => {
    return (
        <>
            <p>Изображения</p>
            <ul>
                {currentItem.images.map(image => (
                    <li key={image.id}>
                        <figure>
                            <img
                                src={image.url}
                                alt={currentItem.title}
                                width="200"
                                height="150"
                            />
                            {image.id === currentItem.image_cover.id ? (
                                <figcaption>Обложка</figcaption>
                            ) : null}
                        </figure>
                    </li>
                ))}
            </ul>
        </>
    );
};

const DocumentView = ({ currentItem, onLinkClick }) => {
    if (!currentItem.documents) return null;
    return (
        <>
            <p>Документы</p>
            <ul>
                {currentItem.documents.length
                    ? currentItem.documents.map(document => (
                          <li key={document.id}>
                              <figure>
                                  <a
                                      href={document.url}
                                      onClick={event => onLinkClick(event, document.url)}
                                  >
                                      {document.original_name}
                                  </a>
                              </figure>
                          </li>
                      ))
                    : 'нет вложенных документов'}
            </ul>
        </>
    );
};
