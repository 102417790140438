import './c-table.scss';
import {
    Table,
    TableBody,
    TableRow,
    Checkbox,
    TableCell,
    Typography,
    TableContainer,
    Paper,
} from '@mui/material';
import { FC, useEffect, useState, createRef, ReactElement } from 'react';
import EnhancedTableHead from './TableHead';
import { TABLE_DATE_MASK, TABLE_DATETIME_MASK } from '@/constants/date-masks';
import { formatDate } from '@/helpers/transformers';

export type columnId = string | number;

export type itemId = string | number;

export interface CTableColumn {
    id: columnId;
    label: string;
    sortable: boolean;
}

interface СTableContentItem {
    type?: 'text' | 'image' | 'dateTime' | 'date'; // default: text
    data: string | number | null;
}

export type CTableRow = [itemId, СTableContentItem[]];

interface Props {
    columns: Array<CTableColumn>;
    rows: Array<CTableRow>;
    selectedItems?: Array<itemId>;
    toolbar?: ReactElement;
    pagination?: ReactElement;
    selectedItemsChangeCallback?: (ids: Array<itemId>) => void;
    rowClickCallback?: (id: columnId) => void;
}

const fomatDataByType = ({ data, type }: СTableContentItem): string | number => {
    if (!data) return '';
    let result: string | number = data;
    if (type === 'date') result = formatDate(data, TABLE_DATE_MASK);
    if (type === 'dateTime') result = formatDate(data, TABLE_DATETIME_MASK);

    return result === 'Invalid Date' ? '' : result;
};

export const CTable: FC<Props> = props => {
    const [sItemsAsSet, setSItemsAsSet] = useState<Set<itemId>>(new Set(props.selectedItems));

    const handleSelectAll = (): void => {
        if (!props.selectedItemsChangeCallback) return;

        props.selectedItemsChangeCallback(
            props.selectedItems?.length === props.rows.length ? [] : props.rows.map(el => el[0]),
        );
    };

    const itemChecked = (id: itemId): void => {
        if (!props.selectedItemsChangeCallback) return;
        const copy = new Set(sItemsAsSet.values());
        sItemsAsSet.has(id) ? copy.delete(id) : copy.add(id);

        props.selectedItemsChangeCallback(Array.from(copy.values()));
    };

    useEffect(() => {
        setSItemsAsSet(new Set(props.selectedItems));
    }, [props.selectedItems]);

    return (
        <TableContainer component={Paper}>
            <>
                {props.toolbar}

                <Table className="c-table">
                    <EnhancedTableHead
                        numSelected={props.selectedItems?.length ?? 0}
                        selectAllCallback={handleSelectAll}
                        rowCount={props.rows.length}
                        columnData={props.columns}
                    />
                    <TableBody>
                        {props.rows.length ? (
                            props.rows.map(([id, content]) => {
                                const checkboxRef = createRef<HTMLInputElement>();

                                return (
                                    <TableRow
                                        hover
                                        style={{
                                            cursor: props.rowClickCallback ? 'pointer' : 'default',
                                        }}
                                        key={id}
                                        onClick={event => {
                                            if (event.target === checkboxRef.current) return;

                                            props.rowClickCallback
                                                ? props.rowClickCallback(id)
                                                : null;
                                        }}
                                    >
                                        <TableCell
                                            width="5%"
                                            onChange={() => itemChecked(id)}
                                        >
                                            <Checkbox
                                                checked={sItemsAsSet.has(id)}
                                                inputRef={checkboxRef}
                                            />
                                        </TableCell>
                                        {content.map(({ type, data }, index) => {
                                            if (type === 'image')
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        width={`${Math.floor(
                                                            95 / (props.columns.length || 1),
                                                        )}%`}
                                                    >
                                                        <img
                                                            style={{
                                                                display: 'block',
                                                                maxHeight: '120px',
                                                                maxWidth: '160px',
                                                            }}
                                                            src={data as string}
                                                        />
                                                    </TableCell>
                                                );

                                            return (
                                                <TableCell
                                                    className="c-table__cell--break-word"
                                                    key={index}
                                                    width={`${Math.floor(
                                                        95 / (props.columns.length || 1),
                                                    )}%`}
                                                >
                                                    {fomatDataByType({ type, data })}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={props.columns.length + 1}>
                                    <center>
                                        <Typography
                                            variant="h6"
                                            component="h3"
                                            m={3}
                                        >
                                            Данные не найдены
                                        </Typography>
                                    </center>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {props.pagination}
            </>
        </TableContainer>
    );
};
