import React, { Component } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import {
    FormControl,
    InputLabel,
    Input,
    TextField,
    Select,
    MenuItem,
    ListItemText,
    Fab,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import validation from '@/lib/validation';
import { emailPattern, emptyLinePattern } from '@/etc/regexpPatterns';

import { CButton } from '../../../Button/CButton';
import pin from '@/assets/images/pin.png';

import './EditPage.scss';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';

export default class ContactsEditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latitude: 0,
            longitude: 0,
            address: '',
            name: '',
            phone_numbers: [{ id: 0, phone: '' }],
            schedule: [{ id: 0, days: '', times: '' }],
            housing_complex_id: null,
            type: 2,
            email: '',
            validComplexes: true,
            validName: true,
            validEmail: true,
            validAddress: true,
            validType: true,
            validPhones: true,
            validSchedule: true,
            validLocation: true,
        };
    }

    componentDidMount() {
        const contact = this.props.contacts.currentItem;
        const { types } = this.props.contacts.adminObjects;
        if (contact.item.name && types.length !== 0) {
            const phones = contact.item.phone_numbers.map((item, index) => ({
                id: index,
                phone: String(item),
            }));
            const schedule = contact.item.schedule.map((item, index) => ({
                id: index,
                days: item.days,
                times: item.times,
            }));

            this.setState({
                latitude: contact.item.latitude,
                longitude: contact.item.longitude,
                address: contact.item.address || '',
                name: contact.item.name,
                phone_numbers: phones,
                housing_complex_id: contact.item.housing_complex.IntegrationId,
                email: contact.item.email,
                type: contact.item.type.id,
                schedule: schedule,
            });
        }
    }

    handleSetMarker = event => {
        const coords = event.get('coords');
        this.setState({
            latitude: coords[0],
            longitude: coords[1],
        });
    };

    handleChangeTextField = field => event => {
        this.setState({ [field]: event.target.value });
    };

    handleAddPhone = () => {
        const { phone_numbers } = this.state;

        this.setState({
            phone_numbers: [...phone_numbers, { id: phone_numbers.length, phone: '' }],
        });
    };

    handleChangePhones = (index, event) => {
        const { phone_numbers } = this.state;
        const phonesArray = phone_numbers.map(number => {
            if (number.id === index && event.target.value.match(/[A-zА-яЁё]/) === null) {
                return { id: number.id, phone: String(event.target.value) };
            }

            return number;
        });

        this.setState({ phone_numbers: phonesArray });
    };

    handleAddScheduleItem = () => {
        const { schedule } = this.state;
        schedule.length < 7
            ? this.setState({
                  schedule: [...schedule, { id: schedule.length, days: '', times: '' }],
              })
            : null;
    };

    handleChangeSchedule = (index, field, event) => {
        const { schedule } = this.state;
        const scheduleArray = schedule.map(item => {
            if (item.id === index) {
                return { ...item, [field]: event.target.value };
            }

            return item;
        });

        this.setState({ schedule: scheduleArray });
    };

    handleDeleteStateArrayItem = (index, field) => {
        const changed = this.state[field].filter(({ id }) => id !== index);

        this.setState({ [field]: changed });
    };

    handleSelectChange = field => event => {
        const { value } = event.target;
        value === 'selectCleanField'
            ? this.setState({ [field]: null })
            : this.setState({ [field]: value });
    };

    onValidate(validateFields) {
        validation(validateFields).forEach(item => {
            this.setState({ [item.validField]: item.status });
        });
    }

    handlePublish = () => {
        const { handleSaveChanges, contacts } = this.props;
        const {
            latitude,
            longitude,
            address,
            name,
            phone_numbers,
            housing_complex_id,
            email,
            schedule,
            type,
        } = this.state;
        const { id } = contacts.currentItem.item;
        const phones = phone_numbers.map(item => item.phone);
        const postForm = {
            latitude: Number(latitude).toFixed(6),
            longitude: Number(longitude).toFixed(6),
            address,
            name,
            phone_numbers: phones,
            housing_complex_id,
            email,
            schedule,
            type_id: type,
        };

        handleSaveChanges(postForm, id);
    };

    render() {
        const { contacts } = this.props;
        const { id } = contacts.currentItem.item;
        const {
            latitude,
            longitude,
            address,
            name,
            phone_numbers,
            housing_complex_id,
            email,
            type,
            validEmail,
            validAddress,
            validComplexes,
            validName,
            validType,
            validPhones,
            validSchedule,
            validLocation,
            schedule,
        } = this.state;

        const mapCenter =
            latitude !== 0 && longitude !== 0 ? [latitude, longitude] : [55.754734, 37.583314];
        const cleanFieldValidMessage = (
            <span className="notValid"> Это поле обязательно для заполнения</span>
        );
        const emailValidMessage = (
            <span className="notValid"> Введите e-mail в формате abc@gmail.com</span>
        );
        const locationValidMessage = (
            <span className="notValid"> Координаты обязательны к заполнению</span>
        );
        let validateFields = [
            {
                field: address,
                pattern: emptyLinePattern,
                validField: 'validAddress',
                status: true,
            },
            {
                field: name,
                pattern: emptyLinePattern,
                validField: 'validName',
                status: true,
            },
            {
                field: email,
                pattern: emailPattern,
                validField: 'validEmail',
                status: true,
            },
            {
                field: housing_complex_id,
                pattern: 'null',
                validField: 'validComplexes',
                status: true,
            },
            {
                field: type,
                pattern: 'null',
                validField: 'validType',
                status: true,
            },
            {
                field: phone_numbers,
                pattern: 'emptyStringInEmptyArrayPattern',
                validField: 'validPhones',
                status: true,
            },
            {
                field: schedule,
                pattern: 'emptyStringInEmptyArrayPattern',
                validField: 'validSchedule',
                status: true,
            },
            {
                field: { latitude, longitude },
                pattern: 'locationPattern',
                validField: 'validLocation',
                status: true,
            },
        ];
        const validAll = validation(validateFields).every(({ status }) => status === true);

        return (
            <EntityContentWithAside blockClassName="EditPage">
                {{
                    content: (
                        <>
                            <div className="propsOfArticle">
                                <p>Геопозиция</p>
                                <TextField
                                    label="Адрес"
                                    value={address}
                                    onChange={this.handleChangeTextField('address')}
                                    fullWidth={true}
                                    required
                                    helperText={!validAddress ? cleanFieldValidMessage : null}
                                />
                                <div className="map handleValid">
                                    <YMaps query={{ load: 'package.full' }}>
                                        <Map
                                            defaultState={{
                                                center: mapCenter,
                                                zoom: 10,
                                                controls: ['default'],
                                            }}
                                            width="100%"
                                            height="60vh"
                                            onClick={this.handleSetMarker}
                                        >
                                            {latitude && longitude ? (
                                                <Placemark
                                                    geometry={[latitude, longitude]}
                                                    options={{
                                                        iconLayout: 'default#image',
                                                        iconImageHref: pin,
                                                    }}
                                                />
                                            ) : null}
                                        </Map>
                                    </YMaps>
                                    {!validLocation ? locationValidMessage : null}
                                </div>
                            </div>
                        </>
                    ),
                    aside: (
                        <>
                            <center>
                                <CButton
                                    label="Сохранить"
                                    href={validAll ? `/contacts/${id}` : null}
                                    action={
                                        validAll
                                            ? this.handlePublish
                                            : () => this.onValidate(validateFields)
                                    }
                                    variant="contained"
                                />
                                <CButton
                                    label="Отмена"
                                    href={`/contacts/${id}`}
                                    variant="contained"
                                />
                            </center>
                            <div className="propsOfArticle">
                                <div>
                                    <TextField
                                        label="Название"
                                        value={name}
                                        onChange={this.handleChangeTextField('name')}
                                        required
                                        helperText={!validName ? cleanFieldValidMessage : null}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        label="E-mail"
                                        value={email}
                                        onChange={this.handleChangeTextField('email')}
                                        helperText={!validEmail ? emailValidMessage : null}
                                        required
                                    />
                                </div>
                                <div>
                                    <FormControl className="formControl handleValid">
                                        <InputLabel htmlFor="selectHk">Выбрать ЖК</InputLabel>
                                        <Select
                                            value={housing_complex_id || -1}
                                            onChange={this.handleSelectChange('housing_complex_id')}
                                            input={<Input id="selectHk" />}
                                            renderValue={selected =>
                                                selected !== -1
                                                    ? contacts.houseComplexes.items.find(
                                                          complex =>
                                                              complex.IntegrationId === selected,
                                                      ).Name
                                                    : null
                                            }
                                        >
                                            {contacts.houseComplexes.items.map(complex => (
                                                <MenuItem
                                                    key={complex.IntegrationId}
                                                    value={complex.IntegrationId}
                                                >
                                                    <ListItemText primary={complex.Name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {!validComplexes ? cleanFieldValidMessage : null}
                                    </FormControl>
                                </div>
                                <div>
                                    <FormControl className="formControl handleValid">
                                        <InputLabel htmlFor="selectType">
                                            Выбрать тип объекта
                                        </InputLabel>
                                        <Select
                                            value={type || -1}
                                            onChange={this.handleSelectChange('type')}
                                            input={<Input id="selectType" />}
                                        >
                                            {contacts.adminObjects.types.map(complex => (
                                                <MenuItem
                                                    key={complex.id}
                                                    value={complex.id}
                                                >
                                                    <ListItemText primary={complex.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {!validType ? cleanFieldValidMessage : null}
                                    </FormControl>
                                </div>
                                <div className="geolocation">
                                    <label htmlFor="latitude">Широта</label>
                                    <p id="latitude">{Number(latitude).toFixed(6)}</p>
                                </div>
                                <div className="geolocation">
                                    <label htmlFor="longitude">Долгота</label>
                                    <p id="longitude">{Number(longitude).toFixed(6)}</p>
                                </div>
                                <div className="phoneNumbers handleValid">
                                    {phone_numbers.map(item => (
                                        <div
                                            key={item.id}
                                            className="phoneNumbersItem"
                                        >
                                            <TextField
                                                label="Телефон"
                                                value={item.phone}
                                                onChange={this.handleChangePhones.bind(
                                                    this,
                                                    item.id,
                                                )}
                                                required
                                            />
                                            <div className="phoneDeleteButton">
                                                <Fab
                                                    onClick={() =>
                                                        this.handleDeleteStateArrayItem(
                                                            item.id,
                                                            'phone_numbers',
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    ))}
                                    {phone_numbers.length === 0 ? (
                                        <div>
                                            <p>Номера отсутствуют</p>
                                        </div>
                                    ) : null}
                                    {!validPhones ? cleanFieldValidMessage : null}
                                </div>
                                <center>
                                    <CButton
                                        label="Добавить телефон"
                                        action={this.handleAddPhone}
                                        variant="text"
                                    />
                                </center>
                                <div className="schedule handleValid">
                                    {schedule.map(item => (
                                        <div
                                            key={item.id}
                                            className="scheduleItem"
                                        >
                                            <TextField
                                                label="Дни работы"
                                                value={item.days}
                                                fullWidth={true}
                                                onChange={this.handleChangeSchedule.bind(
                                                    this,
                                                    item.id,
                                                    'days',
                                                )}
                                                required
                                            />
                                            <TextField
                                                label="Время работы"
                                                value={item.times}
                                                fullWidth={true}
                                                onChange={this.handleChangeSchedule.bind(
                                                    this,
                                                    item.id,
                                                    'times',
                                                )}
                                                required
                                            />
                                            <div className="scheduleDeleteButton">
                                                <Fab
                                                    onClick={() =>
                                                        this.handleDeleteStateArrayItem(
                                                            item.id,
                                                            'schedule',
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </Fab>
                                            </div>
                                        </div>
                                    ))}
                                    {schedule.length === 0 ? (
                                        <div>
                                            <p>Расписание работы отсутствует</p>
                                        </div>
                                    ) : null}
                                    {!validSchedule ? cleanFieldValidMessage : null}
                                </div>
                                <center>
                                    <CButton
                                        label="Добавить период"
                                        action={this.handleAddScheduleItem}
                                        variant="text"
                                    />
                                </center>
                            </div>
                        </>
                    ),
                }}
            </EntityContentWithAside>
        );
    }
}
