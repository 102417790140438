import { PureComponent, Fragment } from 'react';
import { TextEditor } from '../../../inputs/TextEditor/TextEditor';

import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Radio, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Clipboard from 'react-clipboard.js';

import { emptyLinePattern, imageNamePattern, documentNamePattern } from '@/etc/regexpPatterns';
import validation from '@/lib/validation';

import { CButton } from '@/components/Button/CButton';
import upload from '@/assets/images/upload.png';

import './CreatePage.scss';
import { WithRouter } from '@/components/hoc/WithRouter';
import { useNavigate } from 'react-router';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';

export default WithRouter(
    class AdvicesCreatePage extends PureComponent {
        constructor(props) {
            super(props);
            const { currentItem } = props.advices;

            this.state = {
                title: currentItem.title || '',
                image_cover_id: currentItem.image_cover ? currentItem.image_cover.id : null,
                images_ids: currentItem.images || [],
                documents_ids: currentItem.documents || [],
                description: currentItem.description || '',
                article: currentItem.article || '',
                published_to: currentItem.published_to || [],
                getHtmlEditorsContentTrigger: false,
                validPublishedTo: true,
                validTitle: true,
                validImages: true,
                validDocuments: true,
                validDescription: true,
                validArticle: true,
                validDate: true,
                validDescriptionLength: true,
                validTitleLength: true,
            };
            this.updateImageDisplay = this.updateImageDisplay.bind(this);
            this.updateDocumentDisplay = this.updateDocumentDisplay.bind(this);
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const { images_ids, documents_ids } = this.state;
            const prevImages = this.props.advices.imagesOnServer;
            const nextImages = nextProps.advices.imagesOnServer;
            const prevDocuments = this.props.advices.documentsOnServer;
            const nextDocuments = nextProps.advices.documentsOnServer;

            if (
                Object.keys(prevImages).length === 0 &&
                Object.keys(nextImages).length !== 0 &&
                images_ids.length === 0
            ) {
                this.setState({
                    image_cover_id: nextImages.id,
                    images_ids: [...images_ids, { ...nextImages }],
                });
            }
            if (prevImages.id !== nextImages.id && nextImages.length !== 0) {
                this.setState({
                    images_ids: [...images_ids, { ...nextImages }],
                });
            }

            if (prevDocuments.id !== nextDocuments.id && nextDocuments.length !== 0) {
                nextDocuments;
                this.setState({
                    documents_ids: [...documents_ids, { ...nextDocuments }],
                });
            }
        }

        handleInputsChange = name => event => this.setState({ [name]: event.target.value });

        handleImageCoverChange = event =>
            this.setState({ image_cover_id: Number(event.target.value) });

        handleHousingСomplexChange = event => {
            const { value } = event.target;
            const { contacts } = this.props;

            if (value.some(item => item === 'selectAll')) {
                this.setState({
                    housing_complexes_ids: contacts.items.map(item => item.IntegrationId),
                });
            }
            if (value.some(item => item === 'selectCleanField')) {
                this.setState({ housing_complexes_ids: [] });
            }
            if (value.every(item => item !== 'selectCleanField' && item !== 'selectAll')) {
                this.setState({
                    housing_complexes_ids: value.filter(
                        item => item !== 'selectCleanField' && item !== 'selectAll',
                    ),
                });
            }
        };

        handleDeleteImage = imageId => {
            const { images_ids, image_cover_id } = this.state;
            const filteredImages = images_ids.filter(({ id }) => id !== imageId);

            if (imageId !== image_cover_id) {
                this.setState({ images_ids: filteredImages });
            } else {
                this.setState({
                    images_ids: filteredImages,
                    image_cover_id: filteredImages.length !== 0 ? filteredImages[0].id : null,
                });
                this.props.clearUploadState();
            }
        };

        handleDeleteDocument = documentId => {
            const { documents_ids } = this.state;

            this.setState({
                documents_ids: documents_ids.filter(item => item.id !== documentId),
            });
            this.props.clearUploadState();
        };

        handleDevicePublishChange = event => {
            const { published_to } = this.state;
            const { value } = event.target;
            const currentItem = published_to.indexOf(value);

            if (currentItem === -1) {
                this.setState({ published_to: [...published_to, value] });
            } else {
                const stateCopy = [...published_to];
                stateCopy.splice(currentItem, 1);
                this.setState({ published_to: stateCopy });
            }
        };

        handleChangeDate = name => date => this.setState({ [name]: date });

        onValidate() {
            const { title, images_ids, description } = this.state;
            let validateFields = [
                {
                    field: title,
                    pattern: emptyLinePattern,
                    validField: 'validTitle',
                    status: true,
                },
                {
                    field: description,
                    pattern: emptyLinePattern,
                    validField: 'validDescription',
                    status: true,
                },
                {
                    field: title,
                    pattern: 'isHasLenghLesThen255Pattern',
                    validField: 'validTitleLength',
                    status: true,
                },
                {
                    field: description,
                    pattern: 'isHasLenghLesThen255Pattern',
                    validField: 'validDescriptionLength',
                    status: true,
                },
                {
                    field: article,
                    pattern: 'articlePattern',
                    validField: 'validArticle',
                    status: true,
                },
                {
                    field: images_ids,
                    pattern: 'emptyArrayPattern',
                    validField: 'validImages',
                    status: true,
                },
            ];

            validation(validateFields).forEach(item => {
                this.setState({ [item.validField]: item.status });
            });

            if (validation(validateFields).every(({ status }) => status === true)) {
                this.handleSubmit();
            } else {
            }
        }

        updateDocumentDisplay(event) {
            for (let index = 0; index < event.target.files.length; index++) {
                const file = event.target.files[index];

                if (!file.name.match(documentNamePattern)) return;
                event.target.value = '';

                const doc = new FormData();
                doc.append('file', file, file.name);
                this.props.handleUploadDocument(doc);
            }
        }

        updateImageDisplay(event) {
            for (let index = 0; index < event.target.files.length; index++) {
                const file = event.target.files[index];

                if (!file.name.match(imageNamePattern)) return;
                event.target.value = '';

                const img = new FormData();
                img.append('file', file, file.name);
                this.props.handleUploadImage(img);
            }
        }

        handleSubmit = () => {
            const { title, image_cover_id, description, documents_ids, images_ids, article } =
                this.state;

            const postForm = {
                title,
                images_ids: images_ids.map(item => item.id),
                description,
                article,
                image_cover_id,
            };

            if (documents_ids && documents_ids.length !== 0) {
                postForm['documents_ids'] = documents_ids.map(item => item.id);
            }

            this.props.handleSubmitCreate(postForm);
            this.props.navigate('/advices');
        };

        render() {
            const {
                title,
                description,
                getHtmlEditorsContentTrigger,
                published_to,
                validTitle,
                validDescription,
                validArticle,
                validTitleLength,
                validDescriptionLength,
            } = this.state;
            const { handleCreateCancel, advices } = this.props;

            const validMessage = (
                <span className="notValid">Это поле обязательно для заполнения</span>
            );
            const toBigMessage = (
                <span className="notValid">Максимально допустимая длинна 255 символов</span>
            );

            return (
                <EntityContentWithAside blockClassName="createArticle">
                    {{
                        content: (
                            <>
                                <div className="propsOfArticle">
                                    <TextField
                                        label="Заголовок"
                                        value={title}
                                        onChange={this.handleInputsChange('title')}
                                        autoFocus={true}
                                        fullWidth={true}
                                        error={validTitle === ''}
                                        required
                                        helperText={
                                            !validTitle
                                                ? validMessage
                                                : !validTitleLength
                                                ? toBigMessage
                                                : null
                                        }
                                    />
                                    <hr />
                                    <ImagesEdit
                                        {...this.state}
                                        {...this.props}
                                        {...this}
                                    />
                                    <hr />
                                    <DocumentsEdit
                                        {...this.state}
                                        {...this.props}
                                        {...this}
                                    />
                                    <hr />
                                    <TextField
                                        value={description}
                                        multiline
                                        rows="4"
                                        label="Краткое описание статьи"
                                        fullWidth={true}
                                        onChange={this.handleInputsChange('description')}
                                        helperText={
                                            !validDescription
                                                ? validMessage
                                                : !validDescriptionLength
                                                ? toBigMessage
                                                : null
                                        }
                                        required
                                    />
                                </div>
                                <div className="propsOfArticle handleValid">
                                    <TextEditor
                                        title="Текст статьи"
                                        value={advices.currentItem.article}
                                        changeCallback={v =>
                                            this.handleInputsChange('article')({
                                                target: { value: v },
                                            })
                                        }
                                    />
                                    {!validArticle ? validMessage : null}
                                </div>
                            </>
                        ),
                        aside: (
                            <>
                                <center>
                                    <CButton
                                        label="Сохранить"
                                        action={this.prepareDataForValidation}
                                        variant="contained"
                                    />
                                    <CButton
                                        label="Отмена"
                                        link={handleCreateCancel}
                                        variant="contained"
                                    />
                                </center>
                            </>
                        ),
                    }}
                </EntityContentWithAside>
            );
        }
    },
);

const DocumentsEdit = params => {
    const navigate = useNavigate();
    const progressStyle = {
        color: '#f15f1b',
    };
    const { documents_ids, advices, updateDocumentDisplay, handleDeleteDocument, onLinkClick } =
        params;

    return (
        <Fragment>
            <div>
                <label
                    className="toFile"
                    htmlFor="document_uploads"
                >
                    <h2>Выберите документ</h2>
                    <img
                        src={upload}
                        alt="Upload"
                    />
                </label>
                <input
                    type="file"
                    onChange={updateDocumentDisplay}
                    id="document_uploads"
                    accept=".pdf"
                    multiple
                />
            </div>
            <div className="handleValid">
                {documents_ids.length === 0 ? (
                    <p>На данный момент нет загруженных документов</p>
                ) : null}
                <div className="upload-list">
                    <ol>
                        {documents_ids.length !== 0
                            ? documents_ids.map(document => (
                                  <li key={document.id}>
                                      <a
                                          href={document.url}
                                          onClick={event => onLinkClick(event, document.url)}
                                      >
                                          {document.original_name}
                                      </a>
                                      <DeleteIcon />
                                  </li>
                              ))
                            : null}
                        {advices.uploadingDocument ? (
                            <li>
                                <CircularProgress
                                    style={progressStyle}
                                    size={75}
                                />
                            </li>
                        ) : null}
                    </ol>
                </div>
            </div>
        </Fragment>
    );
};

const ImagesEdit = params => {
    const imagesValidMessage = <span className="notValid">Требуется загрузить картинку</span>;
    const progressStyle = {
        color: '#f15f1b',
    };
    const {
        image_cover_id,
        images_ids,
        validImages,
        advices,
        updateImageDisplay,
        handleDeleteImage,
        handleImageCoverChange,
    } = params;

    return (
        <Fragment>
            <div>
                <label
                    className="toFile"
                    htmlFor="image_uploads"
                >
                    <h2>Выберите фото</h2>
                    <img
                        src={upload}
                        alt="Upload"
                    />
                </label>
                <input
                    type="file"
                    onChange={updateImageDisplay}
                    id="image_uploads"
                    accept=".jpg, .jpeg, .png"
                    multiple
                />
            </div>
            <div className="handleValid">
                {images_ids.length === 0 ? (
                    <p>На данный момент нет загруженных фотографий</p>
                ) : null}
                {!validImages && images_ids.length === 0 ? imagesValidMessage : null}
                <div className="upload-list">
                    <ol>
                        {images_ids.length !== 0
                            ? images_ids.map(image => (
                                  <li key={image.id}>
                                      <div
                                          className="images"
                                          style={{ backgroundImage: `url(${image.url})` }}
                                      >
                                          <div className="funcHover">
                                              <div className="delete">
                                                  <DeleteIcon />
                                              </div>
                                              <div className="copyLink">
                                                  <Clipboard
                                                      data-clipboard-text={image.url}
                                                      button-title="Копировать ссылку изображения"
                                                  >
                                                      <ContentCopyIcon />
                                                  </Clipboard>
                                              </div>
                                              <div>
                                                  <label htmlFor="imageCover">
                                                      Использовать как обложку
                                                  </label>
                                                  <Radio
                                                      checked={image_cover_id === image.id}
                                                      onChange={handleImageCoverChange}
                                                      value={String(image.id)}
                                                      name="imageCover"
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              ))
                            : null}
                        {advices.uploading ? (
                            <li>
                                <CircularProgress
                                    style={progressStyle}
                                    size={75}
                                />
                            </li>
                        ) : null}
                    </ol>
                </div>
            </div>
        </Fragment>
    );
};
