export const MAIN_SERVICE_ROUTES = {
    // TODO сделать разделение по сервисам
    // Получение
    performedJobList: 'completed-works/page/%page%',
    performedJob: 'completed-works/%id%',
    tags: 'tags',
    filterTags: 'tags/list',
    jobs: 'works/%id%',
    prices: 'price/%work_price_id%',
    serviceCategoriesByPage: 'category-services/page/%page%',
    serviceCategories: 'category-services',
    serviceCategory: 'category-services/%id%',
    servicesByPage: 'services/page/%page%',
    services: 'services',
    service: 'services/%id%',
    complex: 'complexes/%id%',
    complexList: 'complexes',
    newsByPage: 'news/page/%page%',
    newsList: 'news',
    news: 'news/%id%',
    nextNews: 'news/%id%/next',
    prevNews: 'news/%id%/prev',
    advice: 'advices/%id%',
    adviceList: 'advices',
    advicesByPage: 'advices/page/%page%',
    // Создание
    createPerformedJob: 'completed-works',
    createTag: 'tags',
    createJob: 'works',
    createJobType: 'work-types',
    createPrice: 'price',
    createServiceCategory: 'category-services',
    createService: 'services',
    createNews: 'news',
    createAdvice: 'advices',
    // Изменение
    updatePerformedJob: 'completed-works/%id%',
    publishPerformedJob: 'completed-works/push/%id%',
    updateTagsActivity: 'tags',
    updateTag: 'tags/%id%',
    updateJob: 'works/%id%',
    updateJobType: 'work-types/%id%',
    updatePriceValue: 'price/%id%',
    updateJobPriceActivity: 'work-price/%id%',
    updateServiceCategoriesActivity: 'category-services',
    updateServiceCategory: 'category-services/%id%',
    updateServicesActivity: 'services',
    updateService: 'services/%id%',
    updateServiceActivity: 'services/%id%',
    updateNews: 'news/%id%',
    publishNews: 'news/push/%id%',
    editAdvice: 'advices/%id%',
    // Удаление
    removePerformedJob: 'completed-works/%id%',
    removePerformedJobList: 'completed-works',
    removeNews: 'news/%id%',
    removeNewsList: 'news',
    removeAdviceList: 'advices',
    // Загрузка изображений для сущностей
    uploadImage: 'storage/%type%/%entityName%', // POST type = image | pdf
};
