import { FC } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { CSpinner } from './c-spinner/c-spinner';
import CancelIcon from '@mui/icons-material/Cancel';

interface Props {
    open: boolean;
    title?: string;
    body: JSX.Element;
    pending?: boolean;
    closeCallback?: () => void;
}

export const CDialogWithLoading: FC<Props> = ({ open, title, body, pending, closeCallback }) => {
    return (
        <>
            <Dialog open={open}>
                <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                >
                    {title && (
                        <DialogTitle
                            // 80% для диалога с кнопкой закрытия
                            // 90% по дефолту
                            maxWidth={closeCallback ? '80%' : '90%'}
                            sx={{ wordBreak: 'break-word' }}
                        >
                            {title}
                        </DialogTitle>
                    )}

                    {closeCallback ? (
                        <IconButton
                            color="primary"
                            onClick={closeCallback}
                            // стили для корректного отображения эффекта при active
                            sx={{
                                marginRight: '8px!important',
                                height: 'fit-content',
                                alignSelf: 'center',
                            }}
                        >
                            <CancelIcon />
                        </IconButton>
                    ) : null}
                </Stack>

                <DialogContent>
                    <Box sx={{ width: 500, maxWidth: '100%', paddingTop: 1 }}>
                        <div
                            className=""
                            hidden={pending}
                        >
                            {body}
                        </div>
                        <div hidden={!pending}>
                            <CSpinner />
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
