import { FC, Suspense, useEffect, useState } from 'react';
import {
    Await,
    LoaderFunction,
    defer,
    useLoaderData,
    useNavigate,
    useParams,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import { Box, Stack, Grid } from '@mui/material';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CTable, CTableRow, CTableColumn } from '@/components/Table/c-table';
import { CTableToolbar } from '@/components/Table/c-table-toolbar';
import { CPagination } from '@/components/Table/c-pagination/c-pagination';
import { CListPageWrapper } from '@/components/layout/c-list-page-wrapper/c-list-page-wrapper';
import { CSearchField } from '@/components/forms/c-search-field';
import { CSelect, SelectItem } from '@/components/inputs/c-select';
import { serviceCategoriesService } from '@/services/service-categories.service';
import { urlSearchParamsToObject } from '@/helpers/api/url-search-params-to-object';
import { queryParser } from '@/helpers/api/query-parser';
import { requestQueryPlaceholder } from '@/placeholders/performed-jobs/request-query.placeholder';
import { WithPagination } from '@/types/IncomWrapper';
import { ServiceCategory } from '@/types/api/service-categories/incom';
import { ServiceCategoriesQuery } from '@/types/api/service-categories/request-query';
import { isEqual } from 'lodash';

const TABLE_COLUMNS: CTableColumn[] = [
    {
        id: 1,
        label: 'Иконка',
        sortable: false,
    },
    {
        id: 'title',
        label: 'Название',
        sortable: true,
    },
    {
        id: 'activity',
        label: 'Активность категории',
        sortable: false,
    },
    {
        id: 'created',
        label: 'Дата создания',
        sortable: false,
    },
];

function serviceCategoriesToTableRow(data: ServiceCategory): CTableRow {
    return [
        data.id,
        [
            {
                data: data.icon?.url || '',
                type: 'image',
            },
            {
                data: data.name,
            },
            {
                data: data.is_active ? 'Да' : 'Нет',
            },
            {
                data: data.created_at,
            },
        ],
    ];
}

const ACTIVITY_SELECT_ITEMS: Array<SelectItem<number>> = [
    {
        id: -1,
        name: 'Все категории услуг',
    },
    {
        id: 1,
        name: 'Активные категории услуг',
    },
    {
        id: 0,
        name: 'Неактивные категории услуг',
    },
];

export const ServiceCategories: FC = () => {
    const { serviceCategoriesList } = useLoaderData() as {
        serviceCategoriesList: WithPagination<ServiceCategory[]>;
    };
    const navigate = useNavigate();
    const { page } = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const [filters, setFilters] = useState<Required<ServiceCategoriesQuery>>({
        ...requestQueryPlaceholder,
        ...urlSearchParamsToObject(searchParams),
    });
    const [selectedServiceCategories, setSelectedServiceCategories] = useState<number[]>([]);

    const pageChanged = (page: number): void => {
        setFilters({ ...filters, page });
    };

    const limitChanged = (limit: number): void => {
        setFilters({ ...filters, page: 1, limit });
    };

    const handleDeactivate = async (): Promise<void> => {
        try {
            await serviceCategoriesService.updateActivity({
                ids: selectedServiceCategories,
                is_active: false,
            });
            navigate(location.pathname + queryParser({ ...filters, page: 1 } as any));
            setSelectedServiceCategories([]);
        } catch (e: any) {
            console.error(e?.toString() || e);
        }
    };

    const handleSearchChange = (q: string): void => {
        setFilters({ ...filters, q, page: 1 });
        setSelectedServiceCategories([]);
    };

    const handleActivityFilterChange = (selected: Array<number>): void => {
        selected[0] !== 0 && selected[0] !== 1
            ? // @ts-ignore
              setFilters({ ...filters, is_active: undefined, page: 1 })
            : // @ts-ignore
              setFilters({ ...filters, is_active: selected[0], page: 1 });
        setSelectedServiceCategories([]);
    };

    useEffect(() => {
        if (isEqual(filters, requestQueryPlaceholder)) return;
        navigate(location.pathname + queryParser(filters as any));
    }, [filters]);

    return (
        <Suspense fallback={<CSpinner />}>
            <Await resolve={serviceCategoriesList}>
                {(serviceCategoriesList: WithPagination<ServiceCategory[]>) => {
                    return (
                        <CListPageWrapper
                            headerContainerProps={{
                                options: {
                                    nameOfSection: 'Категории услуг',
                                    nameOfAction: 'ДОБАВИТЬ КАТЕГОРИЮ УСЛУГ',
                                },
                                actionCreate: '/service-categories/create-category',
                            }}
                        >
                            <>
                                <Grid
                                    container
                                    columns={2}
                                    columnSpacing={3}
                                    direction="row"
                                >
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CSearchField
                                            value={filters.q}
                                            changeCallback={handleSearchChange}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CSelect
                                            value={
                                                typeof filters.is_active === 'number'
                                                    ? [filters.is_active]
                                                    : [-1]
                                            }
                                            items={ACTIVITY_SELECT_ITEMS}
                                            changeCallback={handleActivityFilterChange}
                                        />
                                    </Grid>
                                </Grid>

                                <CTable
                                    columns={TABLE_COLUMNS}
                                    rows={serviceCategoriesList.result.map(
                                        serviceCategoriesToTableRow,
                                    )}
                                    selectedItems={selectedServiceCategories}
                                    toolbar={
                                        <CTableToolbar
                                            controls={[
                                                {
                                                    buttonLabel: 'Деактивировать',
                                                    confimation: {
                                                        title: 'Вы уверены что хотите деактивировать выбранные категории услуг?',
                                                        text: 'После деактивации категории услуг можно будет активировать при редактировании',
                                                    },
                                                    buttonProps: {
                                                        disabled: !selectedServiceCategories.length,
                                                    },
                                                    submitCallback: handleDeactivate,
                                                },
                                            ]}
                                            selectedItemsCount={selectedServiceCategories.length}
                                        />
                                    }
                                    pagination={
                                        <CPagination
                                            data={{
                                                limit: filters.limit,
                                                page: +(serviceCategoriesList.page || page || 1),
                                                total: serviceCategoriesList.total,
                                            }}
                                            pageChangeCallback={pageChanged}
                                            limitChangeCallback={limitChanged}
                                        />
                                    }
                                    selectedItemsChangeCallback={value =>
                                        setSelectedServiceCategories(value as number[])
                                    }
                                    rowClickCallback={id =>
                                        navigate(`/service-categories/${id}/edit-category`, {
                                            state: {
                                                previousPathname:
                                                    location.pathname + queryParser(filters as any),
                                            },
                                        })
                                    }
                                />
                            </>
                        </CListPageWrapper>
                    );
                }}
            </Await>
        </Suspense>
    );
};

export const serviceCategoriesLoader: LoaderFunction = ({ request }) => {
    const url = new URL(request.url);
    return defer({
        serviceCategoriesList: serviceCategoriesService.listByPage(
            +(url.searchParams.get('page') || 1),
            url.search,
        ),
    });
};
