import {
    Autocomplete,
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

export interface SelectItem<T extends string | number> {
    id: T;
    name: string;
}

interface Props<T extends string | number> {
    items: Array<SelectItem<T>>;
    value: Array<T>;
    label?: string;
    placeholder?: string;
    multiple?: boolean;
    error?: string;
    disabled?: boolean;
    editCallback?: (id: T) => void;
    addCallback?: () => void;
    changeCallback: (selected: Array<T>) => void;
}

const itemsToObject = <T extends string | number>(
    items: Array<SelectItem<T>>,
): Record<T, string> => {
    return items.reduce<Record<T, string>>((acc, item) => {
        acc[item.id] = item.name;
        return acc;
    }, {} as Record<T, string>);
};

export const CSelect = <T extends string | number>(props: Props<T>) => {
    const Comp = (props: Props<T>) => {
        const [itemsList, setItemsList] = useState<Record<T, string>>(itemsToObject(props.items));

        const handleChange = (event: SelectChangeEvent<T>): void => {
            const id: T = (
                isNaN(event.target.value as number) ? event.target.value : +event.target.value
            ) as T;

            if (id === '') return props.addCallback && props.addCallback();

            props.changeCallback([id]);
        };

        const handleMultipleChange = (value: Array<SelectItem<T>>): void => {
            const ids = value.reduce((acc: Array<T>, selectedItem) => {
                acc.push(selectedItem.id);
                return acc;
            }, []);
            props.changeCallback(ids);
        };

        useEffect(() => {
            setItemsList(itemsToObject(props.items));
        }, [props.items]);

        if (props.multiple)
            return (
                <Autocomplete
                    value={props.value.map(id => ({ id, name: itemsList[id] }))}
                    multiple
                    filterSelectedOptions
                    clearText={'Очистить'}
                    openText={'Открыть'}
                    closeText={'Закрыть'}
                    noOptionsText={'Пусто'}
                    limitTags={2}
                    disabled={!!props.disabled}
                    options={props.items.length ? props.items : []}
                    getOptionLabel={option => option.name}
                    onChange={(event, value) => handleMultipleChange(value)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) =>
                        itemsList[option.id] && (
                            <Box
                                component="li"
                                {...props}
                                key={option.id}
                            >
                                {option.name}
                            </Box>
                        )
                    }
                    renderInput={params => {
                        return (
                            <TextField
                                {...params}
                                label={props.label || ''}
                                placeholder={props.placeholder || ''}
                                error={!!props?.error}
                                helperText={props?.error || ''}
                            />
                        );
                    }}
                />
            );
        else
            return (
                <FormControl fullWidth>
                    <InputLabel id="tag-form-sort-label">{props.label || ''}</InputLabel>

                    <Select
                        fullWidth
                        label={props.label || ''}
                        value={props.value[0] ?? ''}
                        onChange={handleChange}
                        placeholder={props.placeholder || ''}
                        error={!!props?.error}
                        renderValue={(value: T) => <>{itemsList[value]}</>}
                        disabled={!!props.disabled}
                    >
                        {props.items.length &&
                            props.items.map(option => (
                                <MenuItem
                                    key={option.id}
                                    value={option.id}
                                >
                                    <ListItemText>{option.name}</ListItemText>
                                    {props?.editCallback && (
                                        <ListItemIcon>
                                            <EditIcon
                                                onClick={() =>
                                                    props?.editCallback &&
                                                    props.editCallback(option.id)
                                                }
                                            />
                                        </ListItemIcon>
                                    )}
                                </MenuItem>
                            ))}

                        {props?.addCallback && (
                            <MenuItem
                                key="add-item"
                                value={0}
                                onClick={() => props?.addCallback && props.addCallback()}
                            >
                                Добавить <AddIcon />
                            </MenuItem>
                        )}
                    </Select>

                    <FormHelperText error={!!props?.error}>{props?.error || ''}</FormHelperText>
                </FormControl>
            );
    };

    return <Comp {...props} />;
};
