import { gFetch } from '@/helpers/api/g-fetch';
import { Job, ServiceComposition } from '@/types/api/jobs/incom';
import { JobForm } from '@/types/api/jobs/request-body';

class JobsService {
    async list(serviceId: number): Promise<ServiceComposition> {
        const result = await gFetch<ServiceComposition>('main', 'jobs', {
            params: { id: serviceId },
        });

        return result;
    }

    async create(form: JobForm): Promise<Job> {
        const result = await gFetch<{ works: Job }>('main', 'createJob', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return result.works;
    }

    async update(jobId: number, form: JobForm): Promise<Job> {
        const result = await gFetch<{works:Job}>('main', 'updateJob', {
            params: {
                id: jobId,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return result.works;
    }
}

export const jobsService = new JobsService();
