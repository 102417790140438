import { Component, Fragment } from 'react';
import {
    FormControl,
    InputLabel,
    Input,
    TextField,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
} from '@mui/material';

import { CButton } from '../../../Button/CButton';

import './CreateFAQModal.scss';

export default class CreateFAQModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            question: props.question,
            answer: props.answer,
            isAllFieldsValid: true,
            showFilesModal: false,
            selectedWorld: '',
            housing_complexes_ids:
                props.complexesIds === 'selectCleanField'
                    ? []
                    : props.complexesIds.map(el => el.IntegrationId),
        };
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
    }

    close = () => {
        this.props.closeOrOpenModal(false);
    };

    handleHousingСomplexChange = event => {
        const { value } = event.target;
        const { contacts } = this.props;

        if (value.some(item => item === 'selectAll')) {
            this.setState({
                housing_complexes_ids: contacts.items.map(item => item.IntegrationId),
            });
        }
        if (value.some(item => item === 'selectCleanField')) {
            this.setState({ housing_complexes_ids: [] });
        }
        if (value.every(item => item !== 'selectCleanField' && item !== 'selectAll')) {
            this.setState({
                housing_complexes_ids: value.filter(
                    item => item !== 'selectCleanField' && item !== 'selectAll',
                ),
            });
        }
    };

    handleInputChange = event => {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value,
            isAllFieldsValid: true,
        });
    };

    handleSaveChanges = event => {
        if (!this.validation()) {
            return;
        }

        const { question, answer, housing_complexes_ids } = this.state;

        if (this.props.faqToEditId) {
            this.props.handleEditFaq(this.props.faqToEditId, {
                housing_complexes_ids: housing_complexes_ids,
                question: question,
                answer: answer,
            });
            this.props.closeOrOpenModal(false);
            return;
        }
        if (question && answer && housing_complexes_ids) {
            this.props.handleCreateFaq({
                housing_complexes_ids: housing_complexes_ids,
                question: question,
                answer: answer,
            });
            this.props.closeOrOpenModal(false);
        }
    };

    validation = () => {
        const { question, answer, housing_complexes_ids } = this.state;
        if (question && answer && housing_complexes_ids.length) {
            return true;
        } else {
            this.setState({
                isAllFieldsValid: false,
            });
            return false;
        }
    };
    onSelectAnswerText = e => {
        var world = null;
        this.setState({
            selectedWorld: world,
        });
        if (document.selection) {
            world = document.selection.createRange().text;
        } else {
            world = window.getSelection().toString();
        }

        if (world) {
            this.setState({
                selectedWorld: world,
                selectionStart: e.target.selectionStart,
            });
        }
    };
    closeFilesModal = () => {
        this.setState({
            showFilesModal: false,
            selectedWorld: '',
        });
    };
    linkFileToWorld = url => {
        let arr = [...this.state.answer];
        arr.splice(this.state.selectionStart + this.state.selectedWorld.length, 0, '</a>');
        arr.splice(this.state.selectionStart, 0, `<a target="_blank" href="${url}">`);
        this.setState({
            answer: arr.join(''),
        });
        this.closeFilesModal();
    };
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const { contacts } = this.props;
        const { question, answer, housing_complexes_ids } = this.state;

        const buttonStyle = { margin: '0 12px 12px' };
        const validMessage = <span className="notValid"> Это поле обязательно для заполнения</span>;
        const columnData = [
            { id: 'original_name', align: 'right', label: 'Название' },
            { id: 'size', align: 'right', label: 'Размер' },
            { id: 'created_at', align: 'right', label: 'Дата добавления' },
        ];
        return (
            <Fragment>
                <div
                    className="fon"
                    onClick={this.close}
                />
                <section className="modalWindow">
                    <div className="selectComplex">
                        <FormControl className="formControl handleValid">
                            <InputLabel htmlFor="select-multiple-checkbox-jk">
                                Выбрать ЖК
                            </InputLabel>
                            <Select
                                id="selectComplex"
                                multiple
                                value={housing_complexes_ids}
                                onChange={this.handleHousingСomplexChange}
                                input={<Input id="select-multiple-checkbox-jk" />}
                                renderValue={selected =>
                                    selected
                                        .map(item =>
                                            contacts.items.length !== 0
                                                ? contacts.items.find(complex => {
                                                      if (item.IntegrationId)
                                                          return (
                                                              complex.IntegrationId ===
                                                              item.IntegrationId
                                                          );
                                                      else return complex.IntegrationId === item;
                                                  }).Name
                                                : '',
                                        )
                                        .join(', ')
                                }
                            >
                                <MenuItem value="selectCleanField">
                                    <em>Выбрать ЖК</em>
                                </MenuItem>
                                <MenuItem value="selectAll">
                                    <em>Выбрать все</em>
                                </MenuItem>
                                {contacts.items.map(complex => (
                                    <MenuItem
                                        key={complex.IntegrationId}
                                        value={complex.IntegrationId}
                                    >
                                        <Checkbox
                                            checked={
                                                housing_complexes_ids.indexOf(
                                                    complex.IntegrationId,
                                                ) > -1
                                            }
                                        />

                                        <ListItemText primary={complex.Name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {this.state.isAllFieldsValid || housing_complexes_ids.length
                            ? null
                            : validMessage}
                    </div>
                    <div className="textProps">
                        <TextField
                            rows="4"
                            label="Вопрос"
                            value={question}
                            onChange={this.handleInputChange}
                            fullWidth={true}
                            name="question"
                            multiline
                            maxRows="7"
                        />
                        {this.state.isAllFieldsValid || this.state.question ? null : validMessage}
                        <TextField
                            rows="4"
                            label="Ответ"
                            value={answer}
                            onChange={this.handleInputChange}
                            fullWidth={true}
                            name="answer"
                            multiline
                            maxRows="7"
                            onSelect={e => this.onSelectAnswerText(e)}
                            onBlur={e => {
                                //жесть но хз как по другому
                                setTimeout(() => {
                                    if (!this.state.showFilesModal && this._isMounted)
                                        this.setState({ selectedWorld: null });
                                }, 300);
                            }}
                        />

                        {this.state.isAllFieldsValid || this.state.answer ? null : validMessage}
                        <CButton
                            label="Добавить ссылку на документ"
                            action={() => {
                                this.setState({
                                    showFilesModal: true,
                                });
                            }}
                            variant="contained"
                            style={buttonStyle}
                            disable={!this.state.selectedWorld}
                        />
                    </div>
                    <div className="boolProps">
                        <CButton
                            label="Сохранить"
                            action={this.handleSaveChanges}
                            variant="contained"
                            style={buttonStyle}
                        />
                        <CButton
                            label="Отмена"
                            variant="contained"
                            style={buttonStyle}
                            action={this.close}
                        />
                    </div>
                </section>

                {this.state.showFilesModal && (
                    <div className="filesModal">
                        <div className="filesModalInner">
                            <h3>Выберите документ</h3>
                            <div className="filesList">
                                {this.props.docObjects.map(n => {
                                    return (
                                        <div
                                            key={n.id}
                                            className="fileItem"
                                            onClick={() => this.linkFileToWorld(n.url)}
                                        >
                                            {n.original_name}
                                        </div>
                                    );
                                })}
                            </div>
                            <CButton
                                label="Отмена"
                                variant="contained"
                                style={buttonStyle}
                                action={() => this.closeFilesModal()}
                            />
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}
