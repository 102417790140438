import { combineReducers } from 'redux';
import {
    FETCH_DOCS_START,
    FETCH_DOCS_SUCCESS,
    FETCH_DOCS_FAIL,
    UPLOAD_DOC_START,
    UPLOAD_DOC_SUCCESS,
    UPLOAD_DOC_FAIL,
    DELETE_DOC_START,
    DELETE_DOC_SUCCESS,
    DELETE_DOC_FAIL,
} from '../actions/doc';

const initialState = {
    loading: false,
    loadingFailed: false,
    items: [],
    creating: false,
    createFailed: false,
    created: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
};

const docObjects = (state = initialState, { type, data }) => {
    switch (type) {
        case FETCH_DOCS_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_DOCS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.storage,
            };

        case FETCH_DOCS_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case UPLOAD_DOC_START:
            return {
                ...state,
                loading: true,
                created: false,
                creating: true,
                createFailed: false,
            };

        case UPLOAD_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
                creating: false,
                created: true,
            };

        case UPLOAD_DOC_FAIL:
            return {
                ...state,
                loading: false,
                creating: false,
                createFailed: true,
            };
        case DELETE_DOC_START:
            return {
                ...state,
                deleted: false,
                deleting: true,
                deleteFailed: false,
            };

        case DELETE_DOC_SUCCESS:
            const newItems = state.items.filter(el => el.id !== data);
            return {
                ...state,
                deleted: true,
                deleting: false,
                items: [...newItems],
            };
        case DELETE_DOC_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };
        default:
            return state;
    }
};

export default combineReducers({
    docObjects,
});
