import { FC, useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableRow,
    TableHead,
    TableCell,
    FormControl,
    FormHelperText,
} from '@mui/material';
import { ServiceComplexesStateControlRow } from './service-complexes-state-control-row';
import { CategoryComplex } from '@/types/api/service-categories/incom';
import { Complex as IComplex } from '@/types/api/complexes/incom';
import { RowData } from './service-complexes-state-control-row';

export interface AvailableComplex extends Omit<CategoryComplex, 'name'> {}

interface Props {
    categoryComplexes: AvailableComplex[];
    allComplexes: IComplex[];
    label?: string;
    error?: string;
    changeCallback: (data: AvailableComplex[]) => void;
}

const categoryComplexesToHashMap = (
    categoryComplexes: Array<AvailableComplex>,
): Record<string, boolean> => {
    return categoryComplexes.reduce<Record<string, boolean>>((acc, curr) => {
        acc[curr.id] = curr.is_recommended;
        return acc;
    }, {});
};

const allComplexesWithStates = (
    allComplexes: IComplex[],
    mappedCategoryComplexes: Record<string, boolean>,
): RowData[] => {
    return allComplexes.map(el => {
        const isAvailable = mappedCategoryComplexes.hasOwnProperty(el.IntegrationId);
        const isRecommended = isAvailable && mappedCategoryComplexes[el.IntegrationId];

        return {
            id: el.IntegrationId,
            name: el.Name,
            isAvailable,
            isRecommended,
        };
    });
};

export const ServiceComplexesStateControl: FC<Props> = props => {
    const [mappedCategoryComplexes, setMappedCategoryComplexes] = useState<Record<string, boolean>>(
        categoryComplexesToHashMap(props.categoryComplexes),
    );
    const [rows, setRows] = useState<RowData[]>(
        allComplexesWithStates(props.allComplexes, mappedCategoryComplexes),
    );

    const handleRowChange = (data: RowData): void => {
        const updatedResult = { ...mappedCategoryComplexes };

        if (data.isAvailable) updatedResult[data.id] = data.isRecommended;
        else delete updatedResult[data.id];

        props.changeCallback(
            Object.entries(updatedResult).map(([key, value]) => ({
                id: key,
                is_recommended: value,
            })),
        );
    };

    useEffect(() => {
        const updatedResult = categoryComplexesToHashMap(props.categoryComplexes);

        setMappedCategoryComplexes(updatedResult);

        setRows(allComplexesWithStates(props.allComplexes, updatedResult));
    }, [props.categoryComplexes, props.allComplexes]);

    return (
        <FormControl>
            <FormHelperText error={!!props.error}>{props.label || ''}</FormHelperText>
            <FormHelperText error={!!props.error}>{props.error}</FormHelperText>
            <Table>
                <TableHead>
                    <TableRow
                        sx={{ backgroundColor: props.error ? 'LavenderBlush' : 'transparent' }}
                    >
                        <TableCell>Комплексы</TableCell>
                        <TableCell>Категория доступна</TableCell>
                        <TableCell>Категория рекомендована</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(el => {
                        return (
                            <ServiceComplexesStateControlRow
                                key={el.id}
                                data={el}
                                changeCallback={handleRowChange}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </FormControl>
    );
};
