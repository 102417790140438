import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { PerformedJobForm as PerformedJobForm } from '@/components/forms/performed-job-form';
import HeaderContainer from '@/containers/Header/HeaderContainer';
import { performedJobPlaceholder } from '@/placeholders/performed-jobs/incom';
import { complexService } from '@/services/complex.service';
import { tagService } from '@/services/tags.service';
import { Complex } from '@/types/api/complexes/incom';
import { EnhancedTag } from '@/types/api/tags/incom';

import { FC, Suspense, useState } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate } from 'react-router-dom';
import { PerformedJobForm as IPerformedJobForm } from '@/types/api/performed-jobs/request-body';
import { performedJobService } from '@/services/performed-job.service';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';

export const CreatePerformedJob: FC = () => {
    const { summary } = useLoaderData() as { summary: [Complex[], EnhancedTag[]] };
    const [pending, setPending] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleSubmit = async (form: IPerformedJobForm): Promise<void> => {
        setPending(true);
        try {
            await performedJobService.create(form);
            setPending(false);
            navigate('/performed-jobs');
        } catch (e: any) {
            console.error(e);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Выполненные работы / Создание выполненной работы',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={summary}>
                    {([complexList, tagList]: [Complex[], EnhancedTag[]]) => (
                        <PerformedJobForm
                            initialData={performedJobPlaceholder}
                            complexList={complexList}
                            tagList={tagList}
                            submitCallback={handleSubmit}
                            cancelCallback={() => navigate('/performed-jobs')}
                        />
                    )}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const createPerformedJobLoader: LoaderFunction = () => {
    return defer({
        summary: Promise.all([complexService.list(), tagService.list()]),
    });
};
