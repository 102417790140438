import './EntityContentWithAside.scss';

import React, { FC } from 'react';

interface Props {
    blockClassName?: string;
    children: { content: React.ReactNode; aside: React.ReactNode };
}

export const EntityContentWithAside: FC<Props> = (props: Props) => {
    /**  
     классы textProps, boolProps, props.blockClassName нужны для совместимости
     со старыми компонентами, на новые не должно повлиять
    */
    return (
        <section className={`entity-content-with-aside ${props.blockClassName || ''}`}>
            <div className="entity-content-with-aside__content textProps">
                {props.children.content}
            </div>
            <div className="entity-content-with-aside__aside boolProps">{props.children.aside}</div>
        </section>
    );
};
