import HeaderContainer, { HeaderContinerProps } from '@/containers/Header/HeaderContainer';
import { Stack } from '@mui/system';
import React, { FC } from 'react';

import './c-list-page-wrapper.scss';
import { CSpinner } from '../c-spinner/c-spinner';

interface Props {
    children: React.ReactElement;
    headerContainerProps: HeaderContinerProps;
    pending?: boolean;
}

export const CListPageWrapper: FC<Props> = props => {
    if (props.pending) return <CSpinner />;

    return (
        <>
            <HeaderContainer {...props.headerContainerProps} />

            <div className="c-list-page-wrapper">
                <Stack spacing={3}>{props.children}</Stack>
            </div>
        </>
    );
};
