import { PureComponent } from 'react';
import TextField from '@mui/material/TextField';

import { CButton } from '../../Button/CButton';
import Dialog from '../../layout/Dialog/Dialog';

import { emptyLinePattern, emailPattern } from '@/etc/regexpPatterns';
import validation from '@/lib/validation';

import './AdminCommunicationPage.scss';

export default class AdminCommunicationPage extends PureComponent {
    constructor(props) {
        super(props);
        const { item } = props.adminCommunication;
        this.state = {
            email: item.email || '',
            phone_number: item.phone_number || '',
            privacy_policy_id: item.privacy_policy ? item.privacy_policy.id : null,
            terms_of_use_id: item.terms_of_use ? item.terms_of_use.id : null,
            dialogIsOpen: false,
            validPhone: true,
            validEmail: true,
            validPrivacyPolicy: true,
            validTermsOfUse: true,
        };
        this.uploadHtmlFile = this.uploadHtmlFile.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const thisSettings = this.props.adminCommunication.filesOnServer;
        const nextSettings = nextProps.adminCommunication.filesOnServer;

        if (
            thisSettings.id !== nextSettings.id ||
            (Object.keys(thisSettings).length === 0 && Object.keys(nextSettings).length !== 0)
        ) {
            this.setState({ [nextSettings.description]: nextSettings.id });
        }
    }

    handleOpenDialog = () => this.setState({ dialogIsOpen: true });

    handleCloseDialog = () => this.setState({ dialogIsOpen: false });

    handleInputsChange = name => event => {
        this.setState({ [name]: event.target.value });
    };

    handlePhonesChange = event => {
        if (event.target.value.match(/[A-zА-яЁё]/) === null) {
            this.setState({ phone_number: event.target.value });
        }
    };

    uploadHtmlFile(event, description) {
        const file = event.target.files[0];

        if (file) {
            if (file.type !== 'text/html') {
                this.setState({
                    validFile: false,
                    dialogIsOpen: true,
                });

                return;
            }

            const formData = new FormData();

            formData.append('file', file, file.name);
            formData.append('description', description);
            this.props.handleUploadHtmlFile(formData);
        }
    }

    onValidate(validateFields) {
        validation(validateFields).forEach(item => {
            this.setState({ [item.validField]: item.status });
        });
    }

    handleSubmit = () => {
        const { adminCommunication } = this.props;
        const { email, phone_number, privacy_policy_id, terms_of_use_id } = this.state;
        const postform = {
            email,
            phone_number,
            privacy_policy_id,
            terms_of_use_id,
        };

        this.props.handleEditForm(postform, adminCommunication.item.id);
    };

    render() {
        const {
            email,
            phone_number,
            validEmail,
            validPhone,
            dialogIsOpen,
            terms_of_use_id,
            privacy_policy_id,
        } = this.state;
        const { adminCommunication, handleOpenModalWindow } = this.props;
        const buttonStyle = { margin: '12px 0' };
        const validateFields = [
            {
                field: email,
                pattern: emailPattern,
                validField: 'validEmail',
                status: true,
            },
            {
                field: phone_number,
                pattern: emptyLinePattern,
                validField: 'validPhone',
                status: true,
            },
            {
                field: privacy_policy_id,
                pattern: 'null',
                validField: 'validPrivacyPolicy',
                status: true,
            },
            {
                field: terms_of_use_id,
                pattern: 'null',
                validField: 'validTermsOfUse',
                status: true,
            },
        ];
        const validAll = validation(validateFields).every(({ status }) => status === true);

        return (
            <section className="communication">
                <div className="submitBtn">
                    <CButton
                        label="Сохранить"
                        action={
                            validAll
                                ? this.handleSubmit
                                : this.onValidate.bind(this, validateFields)
                        }
                        variant="contained"
                        style={buttonStyle}
                    />
                </div>
                <div>
                    <div className="blocks">
                        <TextField
                            label="Контактный E-mail"
                            type="email"
                            value={email}
                            onChange={this.handleInputsChange('email')}
                            fullWidth={true}
                            required
                            error={!validEmail}
                        />
                    </div>
                    <p className="helpText">Введите e-mail в формате abc@gmail.com</p>
                </div>
                <div>
                    <div className="blocks">
                        <TextField
                            label="Контактный телефон"
                            type="tel"
                            value={phone_number}
                            onChange={this.handlePhonesChange}
                            fullWidth={true}
                            required
                            error={!validPhone}
                        />
                    </div>
                    <p className="helpText">Введите номер телефона в формате +749597556983</p>
                </div>
                <div>
                    {Object.keys(adminCommunication.item).length === 0 ? (
                        <p>Пользовательское соглашение</p>
                    ) : (
                        <a
                            href={adminCommunication.item.terms_of_use.url}
                            target="_blank"
                        >
                            Пользовательское соглашение
                        </a>
                    )}
                    <p>
                        Вы можете загрузить новое gользовательское соглашение нажав на кнопку
                        "Выбрать файл"
                    </p>
                    <CButton
                        label="Редактировать"
                        action={event =>
                            handleOpenModalWindow(event, 'terms_of_use', 'terms_of_use_id')
                        }
                        variant="contained"
                        style={buttonStyle}
                    />
                    <div className="fileInput">
                        <label
                            className="toFile"
                            htmlFor="uploadPrivacyPolicy"
                        >
                            <div>
                                <p>ВЫБРАТЬ ФАЙЛ</p>
                            </div>
                        </label>
                        <input
                            type="file"
                            onChange={event => this.uploadHtmlFile(event, 'terms_of_use_id')}
                            id="uploadPrivacyPolicy"
                            accept=".html"
                        />
                    </div>
                </div>
                <div>
                    {Object.keys(adminCommunication.item).length === 0 ? (
                        <p>Политика конфеденциальности</p>
                    ) : (
                        <a
                            href={adminCommunication.item.privacy_policy.url}
                            target="_blank"
                        >
                            Политика конфеденциальности
                        </a>
                    )}
                    <p>
                        Вы можете загрузить файл с новой политикой конфеденциальности нажав на
                        кнопку "Выбрать файл"
                    </p>
                    <CButton
                        label="Редактировать"
                        action={event =>
                            handleOpenModalWindow(event, 'privacy_policy', 'privacy_policy_id')
                        }
                        variant="contained"
                        style={buttonStyle}
                    />
                    <div className="fileInput">
                        <label
                            className="toFile"
                            htmlFor="uploadTermsOfUse"
                        >
                            <div>
                                <p>ВЫБРАТЬ ФАЙЛ</p>
                            </div>
                        </label>
                        <input
                            type="file"
                            onChange={event => this.uploadHtmlFile(event, 'privacy_policy_id')}
                            id="uploadTermsOfUse"
                            accept=".html"
                        />
                    </div>
                </div>
                {dialogIsOpen ? (
                    <Dialog
                        dialogIsOpen={dialogIsOpen}
                        handleCloseDialog={this.handleCloseDialog}
                        title="Файл должен быть в HTML формате"
                        submitButtonText="ОК"
                        type="submit"
                    />
                ) : null}
            </section>
        );
    }
}
