import { Component } from 'react';
import { Table, TableBody, TableCell, TableRow, Paper, Checkbox } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';

import EnhancedTableToolbar from '../../Table/TableToolbar';
import EnhancedTableHead from '../../Table/TableHead';
import { WithRouter } from '@/components/hoc/WithRouter';
import { CPagination } from '@/components/Table/c-pagination/c-pagination';

export default WithRouter(
    class AppVersionListPage extends Component {
        state = {
            selected: [],
        };

        handleChoseVersion = id => {
            const { selected } = this.state;
            const selectedIndex = selected.indexOf(id);

            if (selectedIndex === -1) {
                selected.push(id);
            } else {
                selected.splice(selectedIndex, 1);
            }

            this.setState({ selected });
        };

        handleDelete = () => {
            this.props.onDelete({
                id: this.state.selected,
            });
        };

        handleSelectAllClick = (event, checked) => {
            this.setState({
                selected: checked ? this.props.appVersions.map(version => version.id) : [],
            });
        };

        handleVersionPage = id => {
            this.props.navigate(`/apps-versions/${id}`);
        };

        isSelected = id => {
            return this.state.selected.indexOf(id) !== -1;
        };

        render() {
            const { selected } = this.state;
            const {
                appVersions,
                appVersionsLoad,
                handleChangePage,
                handleChangeLimitRows,
                onEdit,
            } = this.props;
            const dataForPagination = {
                total: appVersionsLoad.total,
                limit: appVersionsLoad.limit,
                page: appVersionsLoad.page,
            };
            const columnData = [
                { id: 'platform', label: 'Платформа' },
                { id: 'version', label: 'Версия' },
                { id: 'required', label: 'Обязательная версия' },
            ];

            return (
                <div className="listPage">
                    <Paper>
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            handleDelete={this.handleDelete}
                            title="Версии приложений"
                            deleteMessage="Вы действительно хотите удалить выбранные версии?"
                        />
                        <div>
                            <Table>
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    rowCount={appVersions.length}
                                    columnData={columnData}
                                />
                                <TableBody>
                                    {appVersions.map(appVersion => {
                                        const isSelected = this.isSelected(appVersion.id);
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={appVersion.id}
                                                selected={isSelected}
                                            >
                                                <TableCell
                                                    className="tableItemCheckbox"
                                                    onClick={() =>
                                                        this.handleChoseVersion(appVersion.id)
                                                    }
                                                >
                                                    <Checkbox checked={isSelected} />
                                                </TableCell>
                                                <TableCell
                                                    onClick={() =>
                                                        onEdit(appVersion.id, appVersion)
                                                    }
                                                >
                                                    {appVersion.platform}
                                                </TableCell>
                                                <TableCell
                                                    onClick={() =>
                                                        onEdit(appVersion.id, appVersion)
                                                    }
                                                >
                                                    {appVersion.version.major}.
                                                    {appVersion.version.minor}.
                                                    {appVersion.version.patch}
                                                </TableCell>
                                                <TableCell
                                                    onClick={() =>
                                                        onEdit(appVersion.id, appVersion)
                                                    }
                                                >
                                                    {appVersion.required && <CheckIcon />}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        <CPagination
                            data={dataForPagination}
                            pageChangeCallback={handleChangePage}
                            limitChangeCallback={handleChangeLimitRows}
                        />
                    </Paper>
                </div>
            );
        }
    },
);
