import { Component } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';

import { CButton } from '../../Button/CButton';

import './Dialog.scss';

export default class CDialog extends Component {
    render() {
        const {
            dialogIsOpen,
            handleCloseDialog,
            handleAction,
            title,
            type,
            message,
            validNameChange,
            submitButtonText,
            textFieldIsValid,
        } = this.props;
        let actions;

        if (type === 'submit') {
            actions = (
                <CButton
                    label="Oк"
                    action={handleCloseDialog}
                    variant="contained"
                />
            );
        } else if (type === 'delete') {
            actions = (
                <div>
                    <CButton
                        label={submitButtonText}
                        action={handleAction}
                        variant="contained"
                    />
                    <CButton
                        label="Отмена"
                        action={handleCloseDialog}
                        variant="contained"
                    />
                </div>
            );
        } else {
            actions = (
                <div>
                    <CButton
                        label={submitButtonText}
                        action={textFieldIsValid ? handleAction : validNameChange}
                        variant="contained"
                    />
                    <CButton
                        label="Отмена"
                        action={handleCloseDialog}
                        variant="contained"
                    />
                </div>
            );
        }

        return (
            <Dialog
                open={dialogIsOpen}
                keepMounted
                onClose={handleCloseDialog}
                maxWidth={false}
            >
                <DialogTitle id="alert-dialog-slide-title">{title || ''}</DialogTitle>
                <DialogContent>
                    {message ? message : null}
                    {this.props.children}
                </DialogContent>

                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }
}
