import { FC, Suspense, useState } from 'react';
import {
    Await,
    LoaderFunction,
    defer,
    useLoaderData,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { ServiceCategoryForm } from '@/components/forms/service-category-form';
import { serviceCategoriesService } from '@/services/service-categories.service';
import { complexService } from '@/services/complex.service';
import { ServiceCategory } from '@/types/api/service-categories/incom';
import { ServiceCategoryForm as RequestServiceCategoryForm } from '@/types/api/service-categories/request-body';
import { Complex } from '@/types/api/complexes/incom';

export const EditServiceCategory: FC = () => {
    const [pending, setPending] = useState<boolean>(false);

    const { data } = useLoaderData() as {
        data: [ServiceCategory, Complex[]];
    };
    const { id } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (form: RequestServiceCategoryForm): Promise<void> => {
        if (!id) return;
        setPending(true);
        try {
            await serviceCategoriesService.update(+id, form);
            setPending(false);
            navigate('/service-categories');
        } catch (e) {
            console.error(e);
        }

        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Категории услуг / Редактирование категории услуг',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={data}>
                    {(data: [ServiceCategory, Complex[]]) => {
                        return (
                            <Box sx={{ padding: 3 }}>
                                <Stack spacing={2}>
                                    <ServiceCategoryForm
                                        initialValues={data[0]}
                                        editMode={true}
                                        complexes={data[1]}
                                        submitCallback={handleSubmit}
                                        cancelCallback={() => navigate('/service-categories')}
                                    />
                                </Stack>
                            </Box>
                        );
                    }}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const editServiceCategoriesLoader: LoaderFunction = ({ params }) => {
    const id = +(params.id || 0);

    return defer({
        data: Promise.all([serviceCategoriesService.one(id), complexService.list()]),
    });
};
