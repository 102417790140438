import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableRow, Paper, Checkbox } from '@mui/material';

import EnhancedTableToolbar from '../../Table/TableToolbar';
import EnhancedTableHead from '../../Table/TableHead';

export default class TypesOfAdminObjPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            data: props.typesOfAdminObjects.items.sort((prev, curr) =>
                prev.id < curr.id ? 1 : -1,
            ),
            page: 0,
            rowsPerPage: 10,
            filterComplexes: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.contacts !== this.props.contacts) {
            this.setState({ data: nextProps.contacts });
        }
    }

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.state.data.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    };

    handleChoseItem = (event, id, name) => {
        const selectedIndex = this.state[name].indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(this.state[name], id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state[name].slice(1));
        } else if (selectedIndex === this.state[name].length - 1) {
            newSelected = newSelected.concat(this.state[name].slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                this.state[name].slice(0, selectedIndex),
                this.state[name].slice(selectedIndex + 1),
            );
        }
        if (name === 'filterComplexes') {
            this.props.handleFilterContacts(newSelected);
        }

        this.setState({ [name]: newSelected });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    isSelected = (id, name) => this.state[name].indexOf(id) !== -1;

    handleDelete = () => {
        this.props.handleDeleteTypes({ id: this.state.selected });
    };

    handleItemPage(event, id, name) {
        this.props.handleOpenModalWindowForEditType(id, name);
    }

    render() {
        const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
        const columnData = [{ id: 'name', label: 'Название' }];
        const tableStyle = { textAlign: 'center' };

        return (
            <div className="listPage">
                <Paper>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleDelete={this.handleDelete}
                        title="Таблица типов административных объектов"
                        deleteMessage="Вы действительно хотите удалить выбранные объекты?"
                    />
                    <div>
                        <Table>
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={this.handleSelectAllClick}
                                rowCount={data.length}
                                columnData={columnData}
                            />
                            <TableBody>
                                {data.map(n => {
                                    const isSelected = this.isSelected(n.id, 'selected');

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isSelected}
                                            tabIndex={-1}
                                            key={n.original_id || n.id}
                                            selected={isSelected}
                                            style={tableStyle}
                                        >
                                            <TableCell
                                                className="tableItemCheckbox"
                                                onClick={event =>
                                                    this.handleChoseItem(event, n.id, 'selected')
                                                }
                                            >
                                                <Checkbox checked={isSelected} />
                                            </TableCell>
                                            <TableCell
                                                onClick={event =>
                                                    this.handleItemPage(
                                                        event,
                                                        n.id,
                                                        n.name,
                                                        'selected',
                                                    )
                                                }
                                            >
                                                {n.name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}
