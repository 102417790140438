import React from 'react';

import './NotFound.scss';

export default function NotFound() {
    return (
        <section className="notFound">
            <h2>404 Страница не найдена</h2>
        </section>
    );
}
