import { News } from '@/types/api/news/incom';

export const newsPlaceholder: Readonly<News> = {
    title: '',
    description: '',
    article: '',
    status: 'Новость',
    keys_issued: false,
    push: false,
    published_to: [],
    published_at: null,
    publish_on: null,
    is_pinned: false,
    pin_date_from: null,
    pin_date_to: null,
    id: 0,
    created_at: null,
    updated_at: null,
    images: [],
    pin_to_top: 0,
    location_ids: [],
    image_cover: {
        id: 0,
        url: '',
    },
    housing_complexes: [],
    order_number: 0,
    tags: [],
    buildings: [],
};
