import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { fetchAdvice, deleteAdvice, clearCurrentItem } from '@/actions/advices';
import { cancelNotFound } from '@/actions/operations';
import { advices } from '@/etc/notificationMessages';

import AdvicesItemPage from '@/components/pages/Advices/ItemPage/ItemPage';
import HeaderContainer from '../../Header/HeaderContainer';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import SnackBar from '@/components/SnackBar/SnackBar';
import NotFound from '@/components/layout/NotFound/NotFound';
import { onLinkClick } from '@/lib/urlUtil';
import { WithPropsFromLocation } from '@/components/hoc/WithPropsFromLocation';

const AdvicesItemPageContainer = props => {
    const [snackBarIsOpen, setSnackBarIsOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');

    const navigate = useNavigate();

    const params = useParams();

    const closeSnackBar = () => {
        setSnackBarIsOpen(false);
        setSnackBarMessage('');
    };

    const deleteAdvice = newsId => props.deleteAdvice(newsId);

    const handleToEditPage = props => {
        return {
            to: `/advices/${params.id}/edit-article`,
            ...props,
        };
    };

    useEffect(() => {
        props.fetchAdvice(params.id);
        return () => {
            if (props.operations.notFoundPage) {
                props.cancelNotFound();
            }
            props.clearCurrentItem();
        };
    }, []);

    useEffect(() => {
        if (props.advices.deleted) navigate('/advices');
    }, [props.advices.deleted]);

    useEffect(() => {
        if (props.advices.deleted) setSnackBarIsOpen(true);
        setSnackBarMessage(advices.editSuccess);
    }, [props.advices.edited]);

    useEffect(() => {
        if (props.advices.editFailed) setSnackBarIsOpen(true);
        setSnackBarMessage(advices.editFail);
    }, [props.advices.editFailed]);

    useEffect(() => {
        if (props.advices.published) setSnackBarIsOpen(true);
        setSnackBarMessage(advices.publishSuccess);
    }, [props.advices.published]);

    useEffect(() => {
        if (props.advices.publishFailed) setSnackBarIsOpen(true);
        setSnackBarMessage(advices.publishFail);
    }, [props.advices.publishFailed]);

    const headerOptions = {
        nameOfSection: 'Советы / Просмотр совета',
        nameOfAction: '',
    };

    if (props.operations.notFoundPage) {
        return (
            <div>
                <HeaderContainer options={headerOptions} />
                <NotFound />
            </div>
        );
    }

    return (
        <div>
            <HeaderContainer options={headerOptions} />
            {props.advices.loading || props.advices.publishing ? (
                <CSpinner />
            ) : Object.keys(props.advices.currentItem).length !== 0 ? (
                <AdvicesItemPage
                    currentItem={props.advices.currentItem}
                    deleteAdvice={deleteAdvice}
                    handleToEditPage={handleToEditPage}
                    onLinkClick={onLinkClick}
                />
            ) : null}
            {snackBarIsOpen ? (
                <SnackBar
                    snackBarIsOpen={snackBarIsOpen}
                    message={snackBarMessage}
                    closeSnackBar={closeSnackBar}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = state => ({
    advices: state.advices,
    operations: state.operations,
});

const mapDispatchToProps = dispatch => ({
    fetchAdvice: id => dispatch(fetchAdvice(id)),
    deleteAdvice: id => dispatch(deleteAdvice(id)),
    clearCurrentItem: () => dispatch(clearCurrentItem()),
    cancelNotFound: () => dispatch(cancelNotFound()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WithPropsFromLocation(AdvicesItemPageContainer));
