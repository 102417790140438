import api from '../apiSingleton';

export const FETCH_DOCS_START = 'FETCH_DOCS_START';
export const FETCH_DOCS_SUCCESS = 'FETCH_DOCS_SUCCESS';
export const FETCH_DOCS_FAIL = 'FETCH_DOCS_FAIL';

export function fetchDocs() {
    return dispatch => {
        dispatch({ type: FETCH_DOCS_START });

        return api.faq
            .fetchDocs()
            .then(data => {
                return dispatch({ type: FETCH_DOCS_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_DOCS_FAIL });
            });
    };
}

export const UPLOAD_DOC_START = 'UPLOAD_DOC_START';
export const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS';
export const UPLOAD_DOC_FAIL = 'UPLOAD_DOC_FAIL';

export function uploadDocument(document) {
    return dispatch => {
        dispatch({ type: UPLOAD_DOC_START });
        return api.faq
            .uploadDocument(document)
            .then(() => {
                dispatch(fetchDocs());
                return dispatch({ type: UPLOAD_DOC_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: UPLOAD_DOC_FAIL });
            });
    };
}

export const DELETE_DOC_START = 'DELETE_DOC_START';
export const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS';
export const DELETE_DOC_FAIL = 'DELETE_DOC_FAIL';

export function deleteDoc(id) {
    return dispatch => {
        dispatch({ type: DELETE_DOC_START });

        return api.faq
            .deleteDoc(id)
            .then(data => {
                return dispatch({ type: DELETE_DOC_SUCCESS, data: id });
            })
            .catch(() => {
                return dispatch({ type: DELETE_DOC_FAIL });
            });
    };
}
