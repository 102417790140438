import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    value: string;
    label?: string;
    changeCallback: (value: string) => void;
}

export const CSearchField: FC<Props> = props => {
    const handleChange = debounce((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.changeCallback(event.target.value);
    }, 500);

    return (
        <TextField
            fullWidth
            defaultValue={props.value}
            label={props.label || 'Поиск'}
            onChange={handleChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
