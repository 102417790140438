import { gFetch } from '@/helpers/api/g-fetch';
import { Service, ServiceListIncomWrapper } from '@/types/api/services/incom';
import { ServiceActivity, ServiceForm, ServicesActivity } from '@/types/api/services/request-body';
import { ServicesQuery } from '@/types/api/services/request-query';
import { WithPagination } from '@/types/IncomWrapper';

class ServicesService {
    async listByPage(
        page: number,
        query: ServicesQuery | string,
    ): Promise<WithPagination<Array<Service>>> {
        const result = await gFetch<ServiceListIncomWrapper>('main', 'servicesByPage', {
            params: {
                page: page || 1,
            },
            query,
        });

        return {
            result: result.services,
            total: result.total,
            limit: result.limit,
            page: result.page,
        };
    }

    async list(): Promise<Array<Service>> {
        const result = await gFetch<{ services: Array<Service> }>('main', 'services');
        return result.services;
    }

    async one(id: number): Promise<Service> {
        const result = await gFetch<{ services: Service }>('main', 'service', {
            params: { id },
        });

        return result.services;
    }

    async create(form: ServiceForm): Promise<Service> {
        const result = await gFetch<{ services: Service }>('main', 'createService', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return result.services;
    }

    async update(id: number, form: ServiceForm): Promise<Service> {
        const result = await gFetch<{ services: Service }>('main', 'updateService', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return result.services;
    }

    async updateActivity(id: number, data: ServiceActivity): Promise<Service> {
        const result = await gFetch<{ services: Service }>('main', 'updateServiceActivity', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PATCH',
                body: JSON.stringify(data),
            },
        });

        return result.services;
    }

    async batchUpdateActivity(data: ServicesActivity): Promise<Array<Service>> {
        const result = await gFetch<{ services: Array<Service> }>(
            'main',
            'updateServicesActivity',
            {
                fetchConfig: {
                    method: 'PATCH',
                    body: JSON.stringify(data),
                },
            },
        );

        return result.services;
    }
}

export const servicesService = new ServicesService();
