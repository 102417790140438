import { combineReducers } from 'redux';
import {
    FETCH_COMPLEXES_START,
    FETCH_COMPLEXES_SUCCESS,
    FETCH_COMPLEXES_FAIL,
    CREATE_ADMIN_OBJECT_START,
    CREATE_ADMIN_OBJECT_SUCCESS,
    CREATE_ADMIN_OBJECT_FAIL,
    FETCH_ADMIN_OBJECTS_START,
    FETCH_ADMIN_OBJECTS_SUCCESS,
    FETCH_ADMIN_OBJECTS_FAIL,
    FETCH_ADMIN_OBJECT_START,
    FETCH_ADMIN_OBJECT_SUCCESS,
    FETCH_ADMIN_OBJECT_FAIL,
    EDIT_ADMIN_OBJECT_START,
    EDIT_ADMIN_OBJECT_SUCCESS,
    EDIT_ADMIN_OBJECT_FAIL,
    DELETE_ADMIN_OBJECTS_START,
    DELETE_ADMIN_OBJECTS_SUCCESS,
    DELETE_ADMIN_OBJECTS_FAIL,
    FETCH_ADMIN_OBJECTS_TYPE_START,
    FETCH_ADMIN_OBJECTS_TYPE_SUCCESS,
    FETCH_ADMIN_OBJECTS_TYPE_FAIL,
    CLEAR_CONTACT_CURRENT_ITEM,
    SYNC_HOUSING_COMPLEXES_START,
    SYNC_HOUSING_COMPLEXES_FAIL,
    SYNC_HOUSING_COMPLEXES_SUCCESS,
} from '../actions/contacts';

const initialState = {
    creating: false,
    createFailed: false,
    created: false,
    editing: false,
    edited: false,
    editFailed: false,
    loading: false,
    loadingFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    items: [],
    types: [],
};

const houseComplexes = (
    state = { ...initialState, synced: false, syncFailed: false },
    { type, data },
) => {
    switch (type) {
        case FETCH_COMPLEXES_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_COMPLEXES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.complexes,
            };

        case FETCH_COMPLEXES_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case SYNC_HOUSING_COMPLEXES_START:
            return {
                ...state,
                synced: false,
                syncFailed: false,
            };
        case SYNC_HOUSING_COMPLEXES_SUCCESS:
            return {
                ...state,
                synced: true,
                items: data.complexes,
            };

        case SYNC_HOUSING_COMPLEXES_FAIL:
            return {
                ...state,
                syncFailed: true,
            };
        default:
            return state;
    }
};

const adminObjects = (state = initialState, { type, data }) => {
    switch (type) {
        case CREATE_ADMIN_OBJECT_START:
            return {
                ...state,
                creating: true,
                created: false,
                createFailed: false,
            };

        case CREATE_ADMIN_OBJECT_SUCCESS:
            return {
                ...state,
                creating: false,
                created: true,
            };

        case CREATE_ADMIN_OBJECT_FAIL:
            return {
                ...state,
                creating: false,
                createFailed: true,
            };
        case FETCH_ADMIN_OBJECTS_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_ADMIN_OBJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.administrative_objects,
            };

        case FETCH_ADMIN_OBJECTS_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case EDIT_ADMIN_OBJECT_START:
            return {
                ...state,
                editing: true,
                edited: false,
                editFailed: false,
            };
        case EDIT_ADMIN_OBJECT_SUCCESS:
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case EDIT_ADMIN_OBJECT_FAIL:
            return {
                ...state,
                editing: false,
                editFailed: true,
            };
        case DELETE_ADMIN_OBJECTS_START:
            return {
                ...state,
                deleted: false,
                deleting: true,
                deleteFailed: false,
            };

        case DELETE_ADMIN_OBJECTS_SUCCESS:
            return {
                ...state,
                deleted: true,
                deleting: false,
            };
        case DELETE_ADMIN_OBJECTS_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };
        case FETCH_ADMIN_OBJECTS_TYPE_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_ADMIN_OBJECTS_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                types: data.types_administrative_objects,
            };

        case FETCH_ADMIN_OBJECTS_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        default:
            return state;
    }
};

const currentItemState = {
    loading: false,
    loadingFailed: false,
    item: {
        phone_numbers: [],
        housing_complex: {},
    },
};

const currentItem = (state = currentItemState, { type, data }) => {
    switch (type) {
        case FETCH_ADMIN_OBJECT_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_ADMIN_OBJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                item: { ...data.administrative_objects },
            };

        case FETCH_ADMIN_OBJECT_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case CLEAR_CONTACT_CURRENT_ITEM:
            return {
                ...state,
                item: {
                    phone_numbers: [],
                    housing_complex: {},
                },
            };
        default:
            return state;
    }
};

export default combineReducers({
    houseComplexes,
    adminObjects,
    currentItem,
});
