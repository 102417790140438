import {
    FETCH_SETTINGS_START,
    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAIL,
    CREATE_SETTINGS_START,
    CREATE_SETTINGS_SUCCESS,
    CREATE_SETTINGS_FAIL,
    EDIT_SETTINGS_START,
    EDIT_SETTINGS_SUCCESS,
    EDIT_SETTINGS_FAIL,
    UPLOAD_HTML_FILE_START,
    UPLOAD_HTML_FILE_SUCCESS,
    UPLOAD_HTML_FILE_FAIL,
    FETCH_HTML_FILE_START,
    FETCH_HTML_FILE_SUCCESS,
    FETCH_HTML_FILE_FAIL,
    CREATE_HTML_FILE_START,
    CREATE_HTML_FILE_SUCCESS,
    CREATE_HTML_FILE_FAIL,
} from '../actions/adminCommunication';

const initialState = {
    editing: false,
    edited: false,
    editFailed: false,
    loading: false,
    loadingFailed: false,
    uploading: false,
    uploadingFailed: false,
    filesOnServer: {},
    item: {},
    privacy_policy: '',
    terms_of_use: '',
};

function adminCommunication(state = initialState, { type, data, fileName }) {
    switch (type) {
        case FETCH_SETTINGS_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };
        case FETCH_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                item: data.settings || {},
            };
        case FETCH_SETTINGS_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case CREATE_SETTINGS_START:
        case EDIT_SETTINGS_START:
            return {
                ...state,
                editing: true,
                edited: false,
                editFailed: false,
            };
        case CREATE_SETTINGS_SUCCESS:
        case EDIT_SETTINGS_SUCCESS:
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case CREATE_SETTINGS_FAIL:
        case EDIT_SETTINGS_FAIL:
            return {
                ...state,
                editing: false,
                editFailed: true,
            };
        case UPLOAD_HTML_FILE_START:
            return {
                ...state,
                uploading: true,
            };

        case UPLOAD_HTML_FILE_SUCCESS:
            return {
                ...state,
                uploading: false,
                filesOnServer: {
                    ...data.storage,
                },
            };
        case UPLOAD_HTML_FILE_FAIL:
            return {
                ...state,
                uploading: false,
                uploadingFailed: true,
            };
        case FETCH_HTML_FILE_START:
            return {
                ...state,
            };
        case FETCH_HTML_FILE_SUCCESS:
            return {
                ...state,
                [fileName]: data,
            };
        case FETCH_HTML_FILE_FAIL:
            return {
                ...state,
                // loadingFailed: true
            };
        case CREATE_HTML_FILE_START:
            return {
                ...state,
                uploading: false,
                uploadingFailed: true,
            };
        case CREATE_HTML_FILE_SUCCESS:
            return {
                ...state,
                ...state,
                uploading: false,
                filesOnServer: {
                    ...data.storage,
                },
            };
        case CREATE_HTML_FILE_FAIL:
            return {
                ...state,
                uploading: false,
                uploadingFailed: true,
            };
        default:
            return state;
    }
}

export default adminCommunication;
