import api from '../apiSingleton';

export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAIL = 'FETCH_SETTINGS_FAIL';

export function fetchSettings() {
    return dispatch => {
        dispatch({ type: FETCH_SETTINGS_START });

        return api.adminCommunication
            .fetchSettings()
            .then(data => {
                return dispatch({ type: FETCH_SETTINGS_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_SETTINGS_FAIL });
            });
    };
}

export const CREATE_SETTINGS_START = 'CREATE_SETTINGS_START';
export const CREATE_SETTINGS_SUCCESS = 'CREATE_SETTINGS_SUCCESS';
export const CREATE_SETTINGS_FAIL = 'CREATE_SETTINGS_FAIL';

export function createSettings(form) {
    return dispatch => {
        dispatch({ type: CREATE_SETTINGS_START });

        return api.adminCommunication
            .createSettings(form)
            .then(() => {
                return dispatch({ type: CREATE_SETTINGS_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: CREATE_SETTINGS_FAIL });
            });
    };
}

export const EDIT_SETTINGS_START = 'EDIT_SETTINGS_START';
export const EDIT_SETTINGS_SUCCESS = 'EDIT_SETTINGS_SUCCESS';
export const EDIT_SETTINGS_FAIL = 'EDIT_SETTINGS_FAIL';

export function editSettings(form, id) {
    return dispatch => {
        dispatch({ type: EDIT_SETTINGS_START });

        return api.adminCommunication
            .editSettings(form, id)
            .then(() => {
                return dispatch({ type: EDIT_SETTINGS_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: EDIT_SETTINGS_FAIL });
            });
    };
}

export const UPLOAD_HTML_FILE_START = 'UPLOAD_HTML_FILE_START';
export const UPLOAD_HTML_FILE_SUCCESS = 'UPLOAD_HTML_FILE_SUCCESS';
export const UPLOAD_HTML_FILE_FAIL = 'UPLOAD_HTML_FILE_FAIL';

export function uploadHtmlFile(file) {
    return dispatch => {
        dispatch({ type: UPLOAD_HTML_FILE_START });
        return api.adminCommunication
            .uploadHtmlFile(file)
            .then(data => {
                return dispatch({ type: UPLOAD_HTML_FILE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: UPLOAD_HTML_FILE_FAIL });
            });
    };
}

export const CREATE_FILE_START = 'CREATE_FILE_START';
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS';
export const CREATE_FILE_FAIL = 'CREATE_FILE_FAIL';

export function createFile({ file, type, description }) {
    return dispatch => {
        dispatch({ type: CREATE_FILE_START });

        return api.adminCommunication
            .createFile({ file, type, description })
            .then(data => {
                return dispatch({ type: CREATE_FILE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: CREATE_FILE_FAIL });
            });
    };
}

export const CREATE_HTML_FILE_START = 'CREATE_HTML_FILE_START';
export const CREATE_HTML_FILE_SUCCESS = 'CREATE_HTML_FILE_SUCCESS';
export const CREATE_HTML_FILE_FAIL = 'CREATE_HTML_FILE_FAIL';

export function createHtmlFile(data) {
    return dispatch => {
        dispatch({ type: CREATE_HTML_FILE_START });

        return api.adminCommunication
            .createHtmlFile(data)
            .then(data => {
                return dispatch({ type: CREATE_HTML_FILE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: CREATE_HTML_FILE_FAIL });
            });
    };
}

export const FETCH_HTML_FILE_START = 'FETCH_HTML_FILE_START';
export const FETCH_HTML_FILE_SUCCESS = 'FETCH_HTML_FILE_SUCCESS';
export const FETCH_HTML_FILE_FAIL = 'FETCH_HTML_FILE_FAIL';

export function fetchHtmlFile(link, fileName) {
    return dispatch => {
        dispatch({ type: FETCH_HTML_FILE_START });
        return fetch(link)
            .then(res => res.text())
            .then(data => dispatch({ type: FETCH_HTML_FILE_SUCCESS, data, fileName }))
            .catch(() => dispatch({ type: FETCH_HTML_FILE_FAIL }));
    };
}
