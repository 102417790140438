import Base from './Base';

export default class UserApi extends Base {
    authorization(data) {
        return this.apiClient.post(`admin/login`, data);
    }

    logout() {
        return this.apiClient.post(`admin/logout`, null, 'logout');
    }
}
