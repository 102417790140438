import { useLocation } from 'react-router-dom';

export const WithPropsFromLocation = (Component: any) => {
    const Wrapper = (props: any) => {
        const location: any = useLocation();        

        return <Component {...{ ...props, ...location?.state?.props || {} }} />;
    };

    return Wrapper;
};
