import { gFetch } from '@/helpers/api/g-fetch';
import { News, NewsListIncomWrapper } from '@/types/api/news/incom';
import { NewsForm } from '@/types/api/news/request-body';
import { NewsQuery } from '@/types/api/news/request-query';
import { WithPagination } from '@/types/IncomWrapper';

class NewsService {
    async listByPage(
        page: number,
        query: NewsQuery | string,
    ): Promise<WithPagination<Array<News>>> {
        const result = await gFetch<NewsListIncomWrapper>('main', 'newsByPage', {
            params: {
                page: page || 1,
            },
            query,
        });

        return {
            result: result.news,
            total: result.total,
            limit: result.limit,
            page: result.page,
        };
    }

    async list(): Promise<Array<News>> {
        const result = await gFetch<{ services: Array<News> }>('main', 'newsList');
        return result.services;
    }

    async one(id: number): Promise<News> {
        const result = await gFetch<{ news: News }>('main', 'news', {
            params: { id },
        });

        return result.news;
    }

    async next(id: number): Promise<News> {
        const result = await gFetch<{ news: News }>('main', 'nextNews', {
            params: { id },
        });

        return result.news;
    }

    async prev(id: number): Promise<News> {
        const result = await gFetch<{ news: News }>('main', 'prevNews', {
            params: { id },
        });

        return result.news;
    }

    async create(form: NewsForm): Promise<News> {
        const result = await gFetch<{ news: News }>('main', 'createNews', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return result.news;
    }

    async update(id: number, form: NewsForm): Promise<News> {
        const result = await gFetch<{ news: News }>('main', 'updateNews', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return result.news;
    }

    async publish(id: number): Promise<News> {
        const result = await gFetch<{ news: News }>('main', 'publishNews', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PATCH',
            },
        });

        return result.news;
    }

    async delete(id: number): Promise<true> {
        await gFetch<{}>('main', 'removeNews', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'DELETE',
            },
            type: 'none',
        });

        return true;
    }

    async batchDelete(ids: Array<number>): Promise<true> {
        await gFetch<{}>('main', 'removeNewsList', {
            fetchConfig: {
                method: 'DELETE',
                body: JSON.stringify({ id: ids }),
            },
            type: 'none',
        });

        return true;
    }
}

export const newsService = new NewsService();
