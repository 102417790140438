import { SelectItem } from '@/components/inputs/c-select';
import { EnhancedTag } from '@/types/api/tags/incom';
import { AbstractForm } from '@/validators/FormValidator';
import dayjs from 'dayjs';
import {
    TABLE_DATE_MASK,
    TABLE_DATETIME_MASK,
    API_DATE_MASK,
    API_DATETIME_MASK,
} from '@/constants/date-masks';

export const tagListToSelectItemList = (tags: Array<EnhancedTag>): SelectItem<number>[] => {
    return tags.map(tag => ({ id: tag.id, name: tag.name }));
};

export const trimStringValues = <T extends AbstractForm>(form: T): T =>
    Object.entries(form).reduce<T>((acc, [key, value]: [keyof T, T[keyof T]]) => {
        if (Array.isArray(value)) acc[key] = value;
        else if (value && typeof value === 'object') acc[key] = trimStringValues(value);
        else if (typeof value === 'string') acc[key] = value.trim();
        else acc[key] = value;

        return acc;
    }, {} as T);

export const formatDate = (
    data: string | number,
    mask:
        | typeof TABLE_DATE_MASK
        | typeof TABLE_DATETIME_MASK
        | typeof API_DATE_MASK
        | typeof API_DATETIME_MASK,
    toLocalTime: boolean = false,
) => {
    return toLocalTime ? dayjs.utc(data).local().format(mask) : dayjs(data).format(mask);
};
