import api from '../apiSingleton';

export const FETCH_FAQS_START = 'FETCH_FAQS_START';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_FAIL = 'FETCH_FAQS_FAIL';
export const CREATE_FAQ_START = 'CREATE_FAQ_START';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAIL = 'CREATE_FAQ_FAIL';
export const DELETE_FAQ_START = 'DELETE_FAQ_START';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';

export const EDIT_FAQ_START = 'EDIT_FAQ_START';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';
export const EDIT_FAQ_FAIL = 'EDIT_FAQ_FAIL';

export const CHANGE_FAQ_MODE = 'CHANGE_FAQ_MODE';

export function fetchFaqs() {
    return dispatch => {
        dispatch({ type: FETCH_FAQS_START });

        return api.faq
            .fetchFaqs()
            .then(data => {
                return dispatch({ type: FETCH_FAQS_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_FAQS_FAIL });
            });
    };
}

export function createFaq(newFaq) {
    return dispatch => {
        dispatch({ type: CREATE_FAQ_START });

        return api.faq
            .createFaq(newFaq)
            .then(data => {
                return dispatch({ type: CREATE_FAQ_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: CREATE_FAQ_FAIL });
            });
    };
}

export function deleteFaq(id) {
    if (id.params)
        return dispatch => {
            dispatch({ type: '' });
        };
    return dispatch => {
        dispatch({ type: DELETE_FAQ_START });

        return api.faq
            .deleteFaq(id)
            .then(data => {
                return dispatch({ type: DELETE_FAQ_SUCCESS, data: id });
            })
            .catch(() => {
                return dispatch({ type: DELETE_FAQ_FAIL });
            });
    };
}

export function editFaq(id, data) {
    return dispatch => {
        dispatch({ type: EDIT_FAQ_START });

        return api.faq
            .editFaq(id, data)
            .then(data => {
                return dispatch({
                    type: EDIT_FAQ_SUCCESS,
                    data: { id, editedFaq: data },
                });
            })
            .catch(() => {
                return dispatch({ type: EDIT_FAQ_FAIL });
            });
    };
}

export function changeFAQMode(value) {
    return dispatch => {
        dispatch({ type: CHANGE_FAQ_MODE, data: { mode: value } });
    };
}
