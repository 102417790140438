import { USER_TOKEN } from '@/constants/storage';
import { HTTPError } from '@/global-classes/HTTPError';
import { getRoute, apiParamList, apiQueryList } from '@/helpers/api/get-route';
// import { userStore } from '@/store/user';
// import { userController } from '@/controllers/user.controller';

type HTTPMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export interface GFetchInit {
    fetchConfig?: RequestInit & { method?: HTTPMethods };
    params?: apiParamList;
    query?: apiQueryList | string;
    type?: 'json' | 'arrayBuffer' | 'none';
}

export interface FetchError {
    error: boolean;
    result: any;
    message: string;
}

async function addHeader(init: GFetchInit, headerKey: string, headerValue: string) {
    headerKey = headerKey.toLowerCase();
    if (!init?.fetchConfig) init.fetchConfig = {};

    // Если заголовков вообще нет
    if (!init.fetchConfig?.headers) init.fetchConfig.headers = [[headerKey, headerValue]];
    // Если заголовки переданы как массив
    else if (Array.isArray(init.fetchConfig.headers)) {
        if (!init.fetchConfig.headers.find(el => el[0].toLocaleLowerCase() === headerKey))
            init.fetchConfig.headers.push([headerKey, headerValue]);
        // Если заголовки переданы как `Headers`
    } else if (init.fetchConfig.headers instanceof Headers) {
        if (!init.fetchConfig.headers.has(headerKey))
            init.fetchConfig.headers.append(headerKey, headerValue);
        //
    } else if (!init.fetchConfig.headers[headerKey])
        init.fetchConfig.headers[headerKey] = headerValue;
}

export async function gFetch<T>(service: string, endpoint: string, init?: GFetchInit): Promise<T> {
    if (!init) init = {};

    const token = localStorage.getItem(USER_TOKEN);
    if (token) addHeader(init, 'authorization', `Bearer ${token}`);

    const bodyType = typeof init?.fetchConfig?.body;

    if (bodyType === 'undefined' || bodyType === 'string') {
        addHeader(init, 'content-type', 'application/json');
    }

    try {
        const response = await fetch(
            getRoute(service, endpoint, init?.params || {}, init?.query || {}),
            init?.fetchConfig,
        );

        if (!response.ok)
            throw new HTTPError(response.statusText || 'Сетевая ошибка', response.status);

        // if (result.status === 401) {
        //     await userController.logout();
        // }

        const parsedResult = init.type !== 'none' ? await response[init?.type || 'json']() : null;

        // if (!init?.type || init.type === 'json') if (parsedResult.error) throw parsedResult.message;
        // if (parsedResult.total || parsedResult.total === 0) return parsedResult;

        return parsedResult;
    } catch (e: any) {
        throw new HTTPError(`Сетевая ошибка: ${e?.message}`, 500);
    }
}
