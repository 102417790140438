import { API_DATETIME_MASK } from '@/constants/date-masks';
import { formatDate } from '@/helpers/transformers';
import dayjs from 'dayjs';

export type abstractField = string | number | boolean | null | any[] | any;
interface ValidationResult {
    valid: boolean;
    error: string;
}
export type fieldValidator = (
    output: abstractField,
    errorText: string,
    ...args: any
) => ValidationResult;

const required: fieldValidator = (output, errorText) => {
    const result: boolean = !!output || output === 0 || output === false;
    return {
        valid: result,
        error: result ? '' : errorText,
    };
};

const requiredDate: fieldValidator = (output: string, errorText) => {
    const result: boolean = typeof output === 'string' && output.toLowerCase() !== 'invalid date';
    return {
        valid: result,
        error: result ? '' : errorText,
    };
};

const requiredId: fieldValidator = (output, errorText) => {
    const result: boolean = typeof output === 'number' ? output > 0 : !!output;
    return {
        valid: result,
        error: result ? '' : errorText,
    };
};

const maxLength: fieldValidator = (output, errorText, max: number) => {
    if (typeof output === 'string') {
        const result: boolean = output.length <= max;

        return {
            valid: result,
            error: result ? '' : errorText,
        };
    }

    return {
        valid: true,
        error: '',
    };
};

const notEmptyArray: fieldValidator = (output: Array<any>, errorText) => {
    const result: boolean = !!output.length && !!output[0];

    return {
        valid: result,
        error: result ? '' : errorText,
    };
};

const max: fieldValidator = (output, errorText, max: number) => {
    const result: boolean =
        output === null ||
        ((!!output || output === 0) &&
            (typeof output === 'string' ? +output.replace(',', '.') : +output) <= max);

    return {
        valid: result,
        error: result ? '' : errorText,
    };
};

const min: fieldValidator = (output, errorText, min: number) => {
    const result: boolean =
        output === null ||
        ((!!output || output === 0) &&
            (typeof output === 'string' ? +output.replace(',', '.') : +output) >= min);

    return {
        valid: result,
        error: result ? '' : errorText,
    };
};

const dateRangeFrom: fieldValidator = (dateFrom: string, errorText, dateTo: string) => {
    if (dateFrom && dateTo) {
        const formattedFrom = formatDate(dateFrom, API_DATETIME_MASK);
        const formattedTo = formatDate(dateTo, API_DATETIME_MASK);

        const result: boolean =
            dayjs(formattedFrom).isSame(dayjs(formattedTo)) ||
            dayjs(formattedFrom).isBefore(dayjs(formattedTo));

        return {
            valid: result,
            error: result ? '' : errorText,
        };
    }

    return {
        valid: true,
        error: '',
    };
};

const dateRangeTo: fieldValidator = (dateTo: string, errorText, dateFrom: string) => {
    if (dateTo && dateFrom) {
        const formattedFrom = formatDate(dateFrom, API_DATETIME_MASK);
        const formattedTo = formatDate(dateTo, API_DATETIME_MASK);

        const result: boolean =
            dayjs(formattedTo).isSame(dayjs(formattedFrom)) ||
            dayjs(formattedTo).isAfter(dayjs(formattedFrom));

        return {
            valid: result,
            error: result ? '' : errorText,
        };
    }

    return {
        valid: true,
        error: '',
    };
};

export const defaultValidators = {
    required,
    requiredDate,
    maxLength,
    notEmptyArray,
    requiredId,
    max,
    min,
    dateRangeFrom,
    dateRangeTo,
};

export type ValidatorKeyList = keyof typeof defaultValidators;

export type ValidatorList = Record<ValidatorKeyList, fieldValidator>;
