import { gFetch } from '@/helpers/api/g-fetch';
import {
    PriceExtended,
    PriceHistoryItem,
    PriceIncomWrapper,
    PriceHistoryIncomWrapper,
} from '@/types/api/price/incom';
import { PriceActivity, PriceForm, PriceValue } from '@/types/api/price/request-body';

class PriceService {
    async list(work_price_id: number): Promise<Array<PriceHistoryItem>> {
        const result = await gFetch<PriceHistoryIncomWrapper>('main', 'prices', {
            params: { work_price_id },
        });
        return result.prices;
    }

    async create(form: PriceForm): Promise<PriceExtended> {
        const result = await gFetch<PriceIncomWrapper>('main', 'createPrice', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return result.work_prices;
    }

    async updateValue(id: number, form: PriceValue): Promise<PriceExtended> {
        const result = await gFetch<PriceIncomWrapper>('main', 'updatePriceValue', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return result.work_prices;
    }

    async updateActivity(id: number, form: PriceActivity): Promise<PriceActivity> {
        const result = await gFetch<PriceIncomWrapper>('main', 'updateJobPriceActivity', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PATCH',
                body: JSON.stringify(form),
            },
        });

        return result.work_prices;
    }
}

export const priceService = new PriceService();
