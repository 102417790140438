import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
    fetchAdminObject,
    editAdminObject,
    fetchComplexes,
    clearCurrentItem,
    fetchTypeAdminObjects,
} from '@/actions/contacts';
import { cancelNotFound } from '@/actions/operations';
import connectDataFetchers from '@/lib/connectDataFetchers';

import HeaderContainer from '@/containers/Header/HeaderContainer';
import ContactsEditPage from '@/components/pages/Contacts/EditPage/EditPage';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import NotFound from '@/components/layout/NotFound/NotFound';

const ContactsEditPageContainer = (props) => {
    const params = useParams();

    const headerOptions = {
        nameOfSection: 'Контакты / Изменение объекта',
        nameOfAction: '',
    };

    const handleSaveChanges = (contactId, contactData) => {
        props.dispatch(editAdminObject(contactId, contactData));
    };

    const handleRedirect = props => (
        <Link
            to={`/contacts/${params.id}`}
            {...props}
        />
    );

    useEffect(() => {
        return () => {
            props.dispatch(clearCurrentItem());
            if (props.operations.notFoundPage) {
                props.dispatch(cancelNotFound());
            }
        };
    }, []);

    if (props.operations.notFoundPage) {
        return (
            <div>
                <HeaderContainer options={headerOptions} />
                <NotFound />
            </div>
        );
    } else
        return (
            <div>
                <HeaderContainer options={headerOptions} />
                {props.contacts.currentItem.loading ||
                props.contacts.houseComplexes.loading ||
                props.contacts.adminObjects.loading ? (
                    <CSpinner />
                ) : (
                    <ContactsEditPage
                        contacts={props.contacts}
                        handleSaveChanges={handleSaveChanges}
                        handleRedirect={handleRedirect}
                    />
                )}
            </div>
        );
};

const mapStateToProps = state => ({
    contacts: state.contacts,
    operations: state.operations,
});

export default connect(mapStateToProps)(
    connectDataFetchers(ContactsEditPageContainer, [
        fetchAdminObject,
        fetchComplexes,
        fetchTypeAdminObjects,
    ]),
);
