import { gFetch } from '@/helpers/api/g-fetch';
import { Advice, AdviceListIncomWrapper } from '@/types/api/advices/incom';
import { AdvicesQuery } from '@/types/api/advices/request-query';
import { WithPagination } from '@/types/IncomWrapper';
import { AdviceForm } from '@/types/api/advices/request-body';

class AdvicesService {
    async one(id: number): Promise<Advice> {
        const { advices } = await gFetch<{
            advices: Advice;
        }>('main', 'advice', {
            params: {
                id,
            },
        });

        return advices;
    }

    async list(): Promise<Advice[]> {
        const { advices } = await gFetch<{
            advices: Advice[];
        }>('main', 'adviceList');

        return advices;
    }

    async listByPage(page = 1, query: AdvicesQuery | string): Promise<WithPagination<Advice[]>> {
        const result = await gFetch<AdviceListIncomWrapper>('main', 'advicesByPage', {
            params: { page },
            query,
        });

        return {
            result: result.advices,
            total: result.total,
            limit: result.limit,
            page: result.page,
        };
    }

    async create(form: AdviceForm): Promise<Advice> {
        const { advices } = await gFetch<{ advices: Advice }>('main', 'createAdvice', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return advices;
    }

    async edit(id: number, form: AdviceForm): Promise<Advice> {
        const { advices } = await gFetch<{ advices: Advice }>('main', 'editAdvice', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return advices;
    }

    async removeList(idList: number[]): Promise<true> {
        await gFetch<{}>('main', 'removeAdviceList', {
            fetchConfig: {
                method: 'DELETE',
                body: JSON.stringify({ id: idList }),
            },
            type: 'none',
        });

        return true;
    }
}

export const advicesService = new AdvicesService();
