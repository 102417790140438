import { NavLink, useNavigate } from 'react-router-dom';

import './Navigation.scss';

export default function Navigation(props) {
    const navigate = useNavigate();

    const handleLogout = event => props.logoutUser();
    const handleRedirectOnMainPage = () => navigate('/');

    const menu = [
        {
            id: 1,
            title: 'Новости',
            to: '/news',
        },

        {
            id: 2,
            title: 'Выполненные работы',
            to: '/performed-jobs',
        },
        {
            id: 3,
            title: 'Категории услуг',
            to: '/service-categories',
        },
        {
            id: 4,
            title: 'Услуги',
            to: '/services',
        },
        {
            id: 5,
            title: 'Теги',
            to: '/tags',
        },
        {
            id: 6,
            title: 'FAQ',
            to: '/faq',
        },
        {
            id: 7,
            title: 'Полезные советы',
            to: '/advices',
        },
        {
            id: 8,
            title: 'Административные объекты',
            to: '/contacts',
        },
        {
            id: 9,
            title: 'Типы административных объектов',
            to: '/types-of-contacts',
        },
        {
            id: 10,
            title: 'Административные связи',
            to: '/communication',
        },
        {
            id: 11,
            title: 'Версии приложений',
            to: '/apps-versions',
        },
    ];

    return (
        <aside>
            <div className="fixed">
                <div onClick={handleRedirectOnMainPage}>
                    <div className="logo" />
                </div>
                <nav>
                    <ul>
                        {menu.map(menuItem => (
                            <li key={menuItem.id}>
                                <NavLink
                                    to={menuItem.to}
                                    activeclassname="active"
                                >
                                    {menuItem.title}
                                </NavLink>
                            </li>
                        ))}

                        <li onClick={handleLogout}>
                            <div className="logout">
                                <p>Выход</p>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
}
