export const errorTexts = {
    required: {
        default: 'Поле обязательно для заполнения',
        cover: 'Необходимо выбрать обложку',
        complex: 'Необходимо выбрать хотя бы один объект',
        image: 'Требуется загрузить изображение',
    },
    min: {
        price: 'Необходимо ввести корректную стоимость',
    },
    minDate: {
        afterNow: 'Дата должна быть новее даты обновления записи',
    },
    max: {
        string(value: string | number) {
            return `Превышено максимальное количество символов (${value})`;
        },
    },
    dateRange: {
        from: 'Дата начала периода не должна быть позднее даты окончания',
        to: 'Дата окончания периода не должна быть ранее даты начала',
    },
};
