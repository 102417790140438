import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import { store } from '@/store';

import { autothenticate } from './actions/user';
import registerServiceWorker from './registerServiceWorker';

import LoginPageContainer from '@/containers/pages/Login/LoginPageContainer';
import AuthorizedRoute from '@/containers/AuthorizedRoute';
import './index.scss';

import AdvicesEditPageContainer from '@/containers/pages/Advices/AdvicesEditPageContainer';
import AdvicesListPageContainer from '@/containers/pages/Advices/AdvicesListPageContainer';
import AdvicesItemPageContainer from '@/containers/pages/Advices/AdvicesItemPageContainer';
import AdvicesCreatePageContainer from '@/containers/pages/Advices/AdvicesCreatePageContainer';

// import LocalizationPageContainer from './pages/Localization/LocalizationPageContainer';

import FAQListPageContainer from '@/containers/pages/FAQ/FAQListPageContainer';

import ContactsItemPageContainer from '@/containers/pages/Contacts/ContactsItemPageContainer';
import ContactsEditPageContainer from '@/containers/pages/Contacts/ContactsEditPageContainer';
import ContactsListPageContainer from '@/containers/pages/Contacts/ContactsListPageContainer';
import ContactsCreatePageContainer from '@/containers/pages/Contacts/ContactsCreatePageContainer';

import AppVersionPageContainer from '@/containers/pages/AppVersion/AppVersionPageContainer';

import AdminCommunicationPageContainer from '@/containers/pages/AdminCommunication/AdminCommunicationPageContainer';

import TypesOfAdminObjPageContainer from '@/containers/pages/TypesOfAdminObj/TypesOfAdminObjPageContainer';
import {
    PerformedJobs,
    performedJobsLoader,
} from '@/components/pages/performed-jobs/performed-jobs';
import {
    CreatePerformedJob,
    createPerformedJobLoader,
} from '@/components/pages/performed-jobs/create-performed-job';
import {
    EditPerformedJob,
    editPerformedJobLoader,
} from '@/components/pages/performed-jobs/edit-performed-job';

import { Tags, tagsLoader } from '@/components/pages/tags/tags';

import {
    ServiceCategories,
    serviceCategoriesLoader,
} from '@/components/pages/service-categories/service-categories';

import {
    EditServiceCategory,
    editServiceCategoriesLoader,
} from './components/pages/service-categories/edit-service-category';

import {
    CreateServiceCategory,
    createServiceCategoriesLoader,
} from './components/pages/service-categories/create-service-category';

import { Advices, advicesLoader } from './components/pages/Advices/advices';
import { CreateAdvice } from './components/pages/Advices/create-advice';
import { EditAdvice, editAdviceLoader } from './components/pages/Advices/edit-advice';

import { Services, servicesLoader } from './components/pages/services/services';
import { newsLoader, NewsPage } from './components/pages/News/news';
import { createNewsLoader, CreateNewsPage } from './components/pages/News/create-news';
import { editNewsLoader, EditNewsPage } from './components/pages/News/edit-news';
import { StrictMode } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CreateService, createServiceLoader } from './components/pages/services/create-service';
import { createTheme, ThemeProvider } from '@mui/material';
import { EditService, editServiceLoader } from './components/pages/services/edit-service';
import ru from 'dayjs/locale/ru'

dayjs.extend(utc);

const theme = createTheme({
    palette: {
        primary: {
            main: '#F15F1B',
            light: '#f37f48',
            dark: '#a84212',
        },
    },
});

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthorizedRoute />,
        children: [
            {
                path: 'news/:id/edit-article',
                element: <EditNewsPage />,
                loader: editNewsLoader,
            },
            {
                path: 'news/create-article',
                element: <CreateNewsPage />,
                loader: createNewsLoader,
            },
            {
                index: true,
                path: 'news/',
                element: <NewsPage />,
                loader: newsLoader,
            },
            {
                path: 'advices-old/:id/edit-article',
                element: <AdvicesEditPageContainer />,
            },
            {
                path: 'advices-old/create-article',
                element: <AdvicesCreatePageContainer />,
            },
            {
                path: 'advices-old/:id',
                element: <AdvicesItemPageContainer />,
            },
            {
                path: 'contacts/:id/edit-contact',
                element: <ContactsEditPageContainer />,
            },
            {
                path: 'contacts/create-contact',
                element: <ContactsCreatePageContainer />,
            },
            {
                path: 'contacts/:id',
                element: <ContactsItemPageContainer />,
            },
            {
                path: 'contacts',
                element: <ContactsListPageContainer />,
            },
            {
                path: 'communication',
                element: <AdminCommunicationPageContainer />,
            },
            {
                path: 'types-of-contacts',
                element: <TypesOfAdminObjPageContainer />,
            },
            {
                path: 'faq',
                element: <FAQListPageContainer />,
            },
            {
                path: 'apps-versions',
                element: <AppVersionPageContainer />,
            },
            {
                path: 'performed-jobs/',
                element: <PerformedJobs />,
                loader: performedJobsLoader,
            },
            {
                path: 'performed-jobs/create-article',
                element: <CreatePerformedJob />,
                loader: createPerformedJobLoader,
            },
            {
                path: 'performed-jobs/:id',
                element: <EditPerformedJob />,
                loader: editPerformedJobLoader,
            },
            {
                path: 'tags',
                element: <Tags />,
                loader: tagsLoader,
            },
            {
                path: 'service-categories',
                element: <Navigate to="/service-categories/1" />,
            },
            {
                path: 'service-categories/:page',
                element: <ServiceCategories />,
                loader: serviceCategoriesLoader,
            },
            {
                path: 'service-categories/:id/edit-category',
                element: <EditServiceCategory />,
                loader: editServiceCategoriesLoader,
            },
            {
                path: 'service-categories/create-category',
                element: <CreateServiceCategory />,
                loader: createServiceCategoriesLoader,
            },
            {
                path: 'services',
                element: <Services />,
                loader: servicesLoader,
            },
            {
                path: 'services/create-article',
                element: <CreateService />,
                loader: createServiceLoader,
            },
            {
                path: 'services/:id',
                element: <EditService />,
                loader: editServiceLoader,
            },
            {
                path: 'advices',
                element: <Navigate to="/advices/1" />,
            },
            {
                path: 'advices/:page',
                element: <Advices />,
                loader: advicesLoader,
            },
            {
                path: 'advices/create-advice',
                element: <CreateAdvice />,
            },
            {
                path: 'advices/:id/edit-advice',
                element: <EditAdvice />,
                loader: editAdviceLoader,
            },
        ],
    },
    {
        path: '/login',
        element: <LoginPageContainer />,
    },
]);

async function main() {
    // @ts-ignore
    await store.dispatch(autothenticate());
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <StrictMode>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={ru}
            >
                <Provider store={store}>
                    <ThemeProvider theme={theme}>
                        <RouterProvider router={router} />
                    </ThemeProvider>
                </Provider>
            </LocalizationProvider>
        </StrictMode>,
    );
    registerServiceWorker();
}

main();
