import { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { formValidationConfig, FormValidator } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { trimStringValues } from '@/helpers/transformers';
import { CFormControl } from './c-form-control';
import { PriceForm as IPriceForm } from '@/types/api/price/request-body';
import { Complex } from '@/types/api/complexes/incom';
import { CSelect } from '../inputs/c-select';
import { PriceExtended } from '@/types/api/price/incom';

interface Props {
    complexes: Array<Complex>;
    initialValue: PriceExtended;
    submitCallback: (form: IPriceForm) => void;
    cancelCallback: () => void;
}

interface ClearablePriceForm extends Omit<IPriceForm, 'price'> {
    price: number | string;
}

const extendedPriceToPriceForm = (price: PriceExtended): IPriceForm => {
    return {
        work_id: price.work_id,
        complex_id: price.complex.id,
        external_key: price.external_key,
        is_active: price.is_active,
        price: price.price_value,
    };
};

const getValidatorConfig = (): formValidationConfig<IPriceForm> => ({
    complex_id: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    price: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        min: {
            order: 2,
            config: [errorTexts.min.price, 0],
        },
    },
    work_id: {},
    external_key: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    is_active: {},
});

export const PriceForm: FC<Props> = props => {
    const [form, setForm] = useState<ClearablePriceForm>(
        extendedPriceToPriceForm(props.initialValue),
    );

    const validator: FormValidator<ClearablePriceForm> = new FormValidator<ClearablePriceForm>(
        getValidatorConfig(),
    );

    const [validationState, setValidationState] = useState<
        Record<keyof Partial<ClearablePriceForm>, string>
    >(validator.validateAsSimple(form, true));

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form) as ClearablePriceForm;
        setForm(trimmedForm);
        const result = validator.validateAsSimple(trimmedForm);
        setValidationState(result);
        if (validator.isValid) {
            const modifiedForm: IPriceForm = { ...form, price: +form.price };
            props.submitCallback(modifiedForm);
        }
    };

    useEffect(() => setForm(extendedPriceToPriceForm(props.initialValue)), [props.initialValue]);

    return (
        <Box sx={{ width: 500, maxWidth: '100%', paddingTop: 1 }}>
            <Stack
                component="form"
                spacing={3}
                noValidate
            >
                <FormControl>
                    <CSelect<string>
                        items={props.complexes.map(el => ({
                            id: el.IntegrationId,
                            name: el.Name,
                        }))}
                        value={form.complex_id ? [form.complex_id] : []}
                        label="Выберите ЖК *"
                        error={validationState.complex_id}
                        disabled={!!props?.initialValue?.complex?.id}
                        changeCallback={(ids: string[]) =>
                            setForm({
                                ...form,
                                complex_id: ids.length ? ids[0] : '',
                            })
                        }
                    />
                </FormControl>

                <TextField
                    fullWidth
                    required
                    type="number"
                    label="Цена"
                    value={form.price}
                    onChange={event => {
                        setForm({
                            ...form,
                            price: event.target.value,
                        });
                    }}
                    error={!!validationState.price}
                    helperText={validationState.price}
                />

                <TextField
                    fullWidth
                    required
                    label="Внешний ключ"
                    value={form.external_key}
                    error={!!validationState.external_key}
                    helperText={validationState.external_key}
                    disabled={!!props.initialValue.external_key}
                    onChange={event => {
                        setForm({ ...form, external_key: event.target.value });
                    }}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={form.is_active}
                            onChange={() => {
                                setForm({ ...form, is_active: !form.is_active });
                            }}
                        />
                    }
                    label="Активность цены"
                />

                <CFormControl
                    stackOptions={{ direction: 'row' }}
                    controls={[
                        {
                            label: 'Сохранить',
                            callback: handleSubmit,
                            buttonProps: {
                                variant: 'contained',
                            },
                        },
                        {
                            label: 'Отмена',
                            callback: props.cancelCallback,
                            buttonProps: {
                                variant: 'outlined',
                            },
                        },
                    ]}
                />
            </Stack>
        </Box>
    );
};
