import {
    CREATE_ARTICLE_START,
    CREATE_ARTICLE_SUCCESS,
    CREATE_ARTICLE_FAIL,
    EDIT_ARTICLE_START,
    EDIT_ARTICLE_SUCCESS,
    EDIT_ARTICLE_FAIL,
    DELETE_NEWS_START,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAIL,
    PUBLISH_NEWS_SUCCESS,
    PUBLISH_NEWS_FAIL,
    FETCH_NEWS_START,
    FETCH_NEWS_SUCCESS,
    FETCH_NEWS_FAIL,
    FETCH_ARTICLE_START,
    FETCH_ARTICLE_SUCCESS,
    FETCH_ARTICLE_FAIL,
    UPLOAD_IMAGE_START,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAIL,
    CLEAR_UPLOAD_STATE,
    CLEAR_CURRENT_ITEM,
    PUBLISH_NEWS_START,
} from '../actions/news';

export interface NewsItem {}

export interface SearchColumn {
    id: number;
    label: string;
    fieldName: string;
}

export interface NewsStore {
    creating: boolean;
    createFailed: boolean;
    created: false;
    editing: false;
    edited: false;
    editFailed: boolean;
    loading: false;
    loadingFailed: boolean;
    deleting: boolean;
    deleted: false;
    publishing: boolean;
    published: boolean;
    publishFailed: boolean;
    deleteFailed: boolean;
    uploading: boolean;
    uploadingFailed: boolean;
    items: Array<NewsItem>;
    currentItem: NewsItem;
    imagesOnServer: any;
    columnsToSearch: SearchColumn[];
    page: number;
    limit: number;
    total: number;
}

const initialState: NewsStore = {
    creating: false,
    createFailed: false,
    created: false,
    editing: false,
    edited: false,
    editFailed: false,
    loading: false,
    loadingFailed: false,
    deleting: false,
    deleted: false,
    publishing: false,
    published: false,
    publishFailed: false,
    deleteFailed: false,
    uploading: false,
    uploadingFailed: false,
    items: [],
    currentItem: {},
    imagesOnServer: {},
    columnsToSearch: [
        { id: 0, label: 'Заголовок', fieldName: 'title' },
        { id: 1, label: 'Категория', fieldName: 'status' },
        { id: 2, label: 'Дата публикации', fieldName: 'created_at' },
    ],
    page: 1,
    limit: 10,
    total: 0,
};

function news(state = initialState, { type, data }: { type: string; data: any }) {
    switch (type) {
        case CREATE_ARTICLE_START:
            return {
                ...state,
                created: false,
                creating: true,
                createFailed: false,
            };
        case CREATE_ARTICLE_SUCCESS:
            return {
                ...state,
                creating: false,
                created: true,
            };
        case CREATE_ARTICLE_FAIL:
            return {
                ...state,
                creating: false,
                createFailed: true,
            };
        case EDIT_ARTICLE_START:
            return {
                ...state,
                edited: false,
                editing: true,
                editFailed: false,
            };
        case EDIT_ARTICLE_SUCCESS:
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case EDIT_ARTICLE_FAIL:
            return {
                ...state,
                edited: false,
                editFailed: true,
            };
        case PUBLISH_NEWS_START:
            return {
                ...state,
                publishing: true,
            };
        case PUBLISH_NEWS_SUCCESS:
            return {
                ...state,
                published: true,
                publishing: false,
                currentItem: data.data,
            };
        case PUBLISH_NEWS_FAIL:
            return {
                ...state,
                publishFailed: true,
                publishing: false,
            };
        case FETCH_NEWS_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };
        case FETCH_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.news,
                page: data.page,
                limit: data.limit,
                total: data.total,
            };
        case FETCH_NEWS_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case FETCH_ARTICLE_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };

        case FETCH_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                currentItem: data.news,
            };
        case FETCH_ARTICLE_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case DELETE_NEWS_START:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_NEWS_SUCCESS:
            return {
                ...state,
                deleted: true,
                items: state.items,
                deleting: false,
            };
        case DELETE_NEWS_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };
        case UPLOAD_IMAGE_START:
            return {
                ...state,
                uploading: true,
            };

        case UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                uploading: false,
                imagesOnServer: {
                    ...data.storage,
                },
            };
        case UPLOAD_IMAGE_FAIL:
            return {
                ...state,
                uploading: false,
                uploadingFailed: true,
            };
        case CLEAR_UPLOAD_STATE:
            return {
                ...state,
                imagesOnServer: [],
            };
        case CLEAR_CURRENT_ITEM:
            return {
                ...state,
                currentItem: {},
            };

        default:
            return state;
    }
}

export default news;
