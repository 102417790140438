import React, { Component } from 'react';

import './Header.scss';
import { changeFAQMode } from '../../../actions/faq';
import { connect } from 'react-redux';

class HeaderFAQ extends Component {
    changeMode(value) {
        if (value !== this.props.activeMode) this.props.changeFAQMode(value);
    }
    render() {
        const { options, activeMode } = this.props;

        return (
            <header>
                <div className="sectionName">
                    <h2
                        className={activeMode === 'faq' ? 'active' : null}
                        onClick={() => this.changeMode('faq')}
                    >
                        {options.nameOfSection1}
                    </h2>
                    <h2
                        className={activeMode === 'doc' ? 'active' : null}
                        onClick={() => this.changeMode('doc')}
                    >
                        {options.nameOfSection2}
                    </h2>
                </div>
            </header>
        );
    }
}
const mapStateToProps = state => ({
    faq: state.faq,
});

export default connect(mapStateToProps, { changeFAQMode })(HeaderFAQ);
