import { Complex, Entrance } from '@/types/api/complexes/incom';
import {
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    ListItemText,
    Stack,
    FormHelperText,
} from '@mui/material';
import { FC } from 'react';

export interface AddressInfo {
    activeComplexesId: string[];
    activeHouseId: string | string[];
    activeEntrancesId: string[];
}

interface Props {
    complexes: Complex[];
    activeComplexesId: string[];
    activeHouseId: string | string[];
    activeEntrancesId: string[];
    error?: string;
    filter?: boolean;
    activeComplexesChangeCallback: (info: AddressInfo) => void;
}

export const LocationSelect: FC<Props> = props => {
    // Смена комплекса
    const handleActiveComplexesId = (event: any, child: any): void => {
        const value: string[] = event.target.value;

        let activeComplexesId: string[] = [];

        if (value.some(item => item === 'all'))
            activeComplexesId = props.complexes.map(item => item.IntegrationId);

        if (value.every(item => item !== 'all' && item !== 'clear')) activeComplexesId = value;

        props.activeComplexesChangeCallback({
            activeComplexesId: activeComplexesId.filter(el => el),
            activeHouseId: '',
            activeEntrancesId: [],
        });
    };

    const handleActiveHouseId = (event: any): void => {
        const value: string | string[] = event.target.value;

        let activeHousesId: string[] = [];
        let activeHouseId: string = '';

        if (Array.isArray(value)) {
            if (value.every(item => item !== 'clear')) activeHousesId = value;
        } else if (value !== 'clear') {
            activeHouseId = value;
        }

        props.activeComplexesChangeCallback({
            activeComplexesId: props.activeComplexesId,
            activeHouseId: Array.isArray(value) ? activeHousesId : activeHouseId,
            activeEntrancesId: [],
        });
    };

    const handleActiveEntrancesId = (event: any): void => {
        const value: string[] = event.target.value;

        const activeEntrancesId = value.some(item => item === 'clear') ? [] : value;

        props.activeComplexesChangeCallback({
            activeComplexesId: props.activeComplexesId,
            activeHouseId: props.activeHouseId,
            activeEntrancesId: activeEntrancesId,
        });
    };

    const activeComplexesList = (complexes: Complex[]): Complex[] => {
        return complexes.filter(
            complex => props.activeComplexesId.indexOf(complex.IntegrationId) + 1,
        );
    };

    const activeEntrancesList = (complexes: Complex[]): Entrance[] => {
        const result = activeComplexesList(complexes)[0].buildings.filter(
            building => building.id === props.activeHouseId,
        );
        return result && result[0] ? result[0].entrances : [];
    };

    return (
        <Stack
            component="form"
            noValidate
            spacing={3}
            direction={props.filter ? 'row' : 'column'}
        >
            {/* Выбор ЖК */}

            <FormControl sx={{ flexBasis: '50%' }}>
                <InputLabel required={!props.filter}>Выбор ЖК</InputLabel>

                <Select
                    error={!!props.error}
                    label="Выбор ЖК"
                    multiple
                    value={props.activeComplexesId}
                    onChange={handleActiveComplexesId}
                    renderValue={selected =>
                        activeComplexesList(props.complexes)
                            .map(item => item.Name)
                            .join(', ') || ''
                    }
                >
                    <MenuItem value="clear">
                        <em>Выбрать ЖК</em>
                    </MenuItem>

                    {!props.filter ? (
                        <MenuItem value="all">
                            <em>Выбрать все</em>
                        </MenuItem>
                    ) : (
                        ''
                    )}

                    {props.complexes.map(complex => (
                        <MenuItem
                            key={complex.IntegrationId}
                            value={complex.IntegrationId}
                        >
                            <Checkbox
                                checked={
                                    props.activeComplexesId.indexOf(complex.IntegrationId) > -1
                                }
                            />

                            <ListItemText primary={complex.Name} />
                        </MenuItem>
                    ))}
                </Select>

                <FormHelperText error>{props.error}</FormHelperText>
            </FormControl>
            {/* Выбор ЖК */}

            {/* Выбор Дома */}

            {activeComplexesList(props.complexes).length === 1 &&
            activeComplexesList(props.complexes)[0].buildings.length ? (
                <FormControl sx={{ flexBasis: '50%' }}>
                    <InputLabel>Выбрать дом</InputLabel>

                    <Select
                        label="Выбрать дом"
                        value={props.activeHouseId}
                        multiple={props.filter}
                        onChange={handleActiveHouseId}
                        renderValue={() => {
                            if (props.filter) {
                                return (
                                    activeComplexesList(props.complexes)[0]
                                        .buildings.filter(item =>
                                            props.activeHouseId.includes(item.id),
                                        )
                                        .map(item => item.name)
                                        .join(', ') || ''
                                );
                            } else {
                                const b = activeComplexesList(props.complexes)[0].buildings.find(
                                    building => building.id === props.activeHouseId,
                                );
                                return b ? b.name : '';
                            }
                        }}
                    >
                        <MenuItem value="clear">
                            <em>Выбрать дом</em>
                        </MenuItem>

                        {activeComplexesList(props.complexes)[0].buildings.map(building => (
                            <MenuItem
                                key={building.id}
                                value={building.id}
                            >
                                <Checkbox
                                    checked={
                                        props.filter
                                            ? props.activeHouseId.indexOf(building.id) > -1
                                            : building.id === props.activeHouseId
                                    }
                                />

                                <ListItemText primary={building.name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : null}
            {/* Выбор Дома */}

            {/* Выбор подъезда */}
            {activeComplexesList(props.complexes).length === 1 &&
            activeEntrancesList(props.complexes).length &&
            !props.filter ? (
                <FormControl>
                    <InputLabel>Выбрать подъезд</InputLabel>
                    <Select
                        label="Выбрать подъезд"
                        multiple
                        value={props.activeEntrancesId}
                        onChange={handleActiveEntrancesId}
                        renderValue={() =>
                            activeEntrancesList(props.complexes)
                                .filter(
                                    entrance => props.activeEntrancesId.indexOf(entrance.id) + 1,
                                )
                                .sort((a, b) => (parseInt(a.name) || 0) - (parseInt(b.name) || 0))
                                .map(item => item.name)

                                .join(', ') || ''
                        }
                    >
                        <MenuItem value="clear">
                            <em>Выбрать подъезд</em>
                        </MenuItem>

                        {activeEntrancesList(props.complexes)
                            .sort((a, b) => (parseInt(a.name) || 0) - (parseInt(b.name) || 0))
                            .map(entrance => (
                                <MenuItem
                                    key={entrance.id}
                                    value={entrance.id}
                                >
                                    <Checkbox
                                        checked={
                                            !!(props.activeEntrancesId.indexOf(entrance.id) + 1)
                                        }
                                    />

                                    <ListItemText primary={entrance.name} />
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            ) : null}
            {/* Выбор подъезда */}
        </Stack>
    );
};
