import { gFetch } from '@/helpers/api/g-fetch';
import { JobTypesForm } from '@/types/api/job-types/request-body';
import { JobType } from '@/types/api/jobs/incom';

class JobTypeService {
    async create(form: JobTypesForm): Promise<JobType> {
        const result = await gFetch<{ work_types: JobType }>('main', 'createJobType', {
            fetchConfig: {
                method: 'POST',
                body: JSON.stringify(form),
            },
        });

        return result.work_types;
    }

    async update(id: number, form: JobTypesForm): Promise<JobType> {
        const result = await gFetch<{ work_types: JobType }>('main', 'updateJobType', {
            params: {
                id,
            },
            fetchConfig: {
                method: 'PUT',
                body: JSON.stringify(form),
            },
        });

        return result.work_types;
    }
}

export const jobTypeService = new JobTypeService();
