import { API_DATETIME_MASK, TABLE_DATETIME_MASK } from '@/constants/date-masks';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';
import { Complex } from '@/types/api/complexes/incom';
import { News } from '@/types/api/news/incom';
import { NewsForm as INewsForm } from '@/types/api/news/request-body';
import { EnhancedTag } from '@/types/api/tags/incom';
import { errorTexts } from '@/validators/errorTexts';
import { formValidationConfig, FormValidator } from '@/validators/FormValidator';
import { FormControl, FormHelperText, IconButton, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers';
import { FC, useState } from 'react';
import { CImageUpload } from '../inputs/c-image-upload/c-image-upload';
import { TextEditor } from '../inputs/TextEditor/TextEditor';
import { CDateRangeForm } from './c-date-range-form';
import { CFormControl } from './c-form-control';
import { PublicationForm } from './publication-form';
import { CSelect } from '../inputs/c-select';
import { formatDate, trimStringValues } from '@/helpers/transformers';
import Clear from '@mui/icons-material/Clear';
import InsertInvitation from '@mui/icons-material/InsertInvitation';

interface Props {
    initialData: News;
    complexList: Array<Complex>;
    tagList: Array<EnhancedTag>;
    submitCallback: (form: INewsForm) => void;
    cancelCallback: () => void;
    publishCallback?: (id: number) => void;
    deleteCallback?: (id: number) => void;
}

const getValidatorConfig = (form: INewsForm): formValidationConfig<INewsForm> => ({
    title: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    description: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    article: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    published_to: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    pin_date_from: {},
    pin_date_to: {},
    images_ids: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.image],
        },
    },
    image_cover_id: {
        requiredId: {
            order: 1,
            config: [errorTexts.required.cover],
        },
    },
    housing_complexes_ids: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.complex],
        },
    },
    status: {},
    keys_issued: {},
    push: {},
    published_at: {},
    publish_on: {},
    is_pinned: {},
    tags_ids: {},
    buildings: {},
    pin_to_top: {},
});

const newsToForm = (news: News): INewsForm => {
    return {
        title: news.title,
        description: news.description,
        article: news.article,
        status: news.status,
        keys_issued: news.keys_issued,
        push: news.push,
        published_to: news.published_to,
        published_at: news.published_at,
        publish_on: news.publish_on,
        is_pinned: news.is_pinned,
        pin_date_from: news.pin_date_from,
        pin_date_to: news.pin_date_to,
        images_ids: news.images.map(image => image.id),
        image_cover_id: news.image_cover.id,
        housing_complexes_ids: news.housing_complexes.map(complex => complex.IntegrationId),
        tags_ids: news.tags.map(tag => tag.id),
        buildings: news.buildings,
        pin_to_top: news.pin_to_top,
    };
};

export const NewsForm: FC<Props> = props => {
    const [form, setForm] = useState<INewsForm>(newsToForm(props.initialData));
    const [delayedPublicationDPState, setDelayedPublicationDPState] = useState<boolean>(false);

    const validator: FormValidator<INewsForm> = new FormValidator<INewsForm>(
        getValidatorConfig(form),
    );

    const [validationState, setValidationState] = useState<
        Record<keyof Partial<INewsForm>, string>
    >(validator.validateAsSimple(form, true));

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form) as INewsForm;
        validator.updateValidationConfig(getValidatorConfig(trimmedForm));
        setValidationState(validator.validateAsSimple(trimmedForm));
        if (validator.isValid) props.submitCallback(trimmedForm);
    };

    return (
        <EntityContentWithAside>
            {{
                content: (
                    <Stack
                        component="form"
                        noValidate
                        spacing={3}
                    >
                        <TextField
                            required
                            label="Заголовок"
                            value={form.title}
                            onChange={e => {
                                setForm({ ...form, title: e.target.value });
                            }}
                            error={!!validationState.title}
                            helperText={validationState.title}
                        />

                        <CImageUpload
                            name="news-images"
                            multiple
                            entityName="news"
                            activeFileId={form.image_cover_id || 0}
                            checkboxLabel="Сделать обложкой"
                            initialData={props.initialData.images}
                            error={validationState.images_ids || validationState.image_cover_id}
                            activeFileChangeCallback={id =>
                                setForm({ ...form, image_cover_id: id })
                            }
                            filesChangeCallback={(ids: Array<number>) => {
                                setForm({ ...form, images_ids: ids });
                            }}
                        />

                        <CSelect
                            items={props.tagList}
                            value={form.tags_ids}
                            label="Теги"
                            multiple
                            placeholder="Добавить тег"
                            changeCallback={ids =>
                                setForm({
                                    ...form,
                                    tags_ids: ids,
                                })
                            }
                        />

                        <TextField
                            required
                            label="Краткое описание статьи"
                            value={form.description}
                            multiline
                            minRows="4"
                            maxRows="7"
                            error={!!validationState.description}
                            helperText={validationState.description}
                            onChange={event => {
                                setForm({ ...form, description: event.target.value });
                            }}
                        />
                        <FormControl error={!!validationState.article}>
                            <FormHelperText error={!!validationState.article}>
                                Текст статьи *
                            </FormHelperText>

                            <TextEditor
                                error={!!validationState.article}
                                value={form.article}
                                changeCallback={data => {
                                    setForm({ ...form, article: data });
                                }}
                            />

                            <FormHelperText error={!!validationState.article}>
                                {validationState.article}
                            </FormHelperText>
                        </FormControl>
                    </Stack>
                ),
                aside: (
                    <Stack spacing={3}>
                        <CFormControl
                            controls={[
                                {
                                    label: 'Сохранить',
                                    callback: handleSubmit,
                                    buttonProps: {
                                        variant: 'contained',
                                    },
                                },
                                {
                                    label: 'Отмена',
                                    callback: props.cancelCallback,
                                    buttonProps: {
                                        variant: 'outlined',
                                    },
                                },
                                ...(props.publishCallback
                                    ? [
                                          {
                                              label: 'Опубликовать',
                                              callback: () =>
                                                  // @ts-ignore
                                                  props.publishCallback(props.initialData.id),
                                              buttonProps: {
                                                  variant: 'contained',
                                                  disabled: !!props.initialData.published_at,
                                              },
                                          },
                                      ]
                                    : []),
                                ...(props.deleteCallback
                                    ? [
                                          {
                                              label: 'Удалить',
                                              callback: () =>
                                                  // @ts-ignore
                                                  props.deleteCallback(props.initialData.id),
                                              buttonProps: {
                                                  variant: 'contained',
                                              },
                                          },
                                      ]
                                    : []),
                            ]}
                        />

                        <PublicationForm
                            complexList={props.complexList}
                            config={{
                                publicationVariants: form.published_to ?? [],
                                activeComplexesId: form.housing_complexes_ids.length
                                    ? form.housing_complexes_ids
                                    : form.buildings && form.buildings.length
                                    ? form.buildings.map(building => building.complex_id)
                                    : [],
                                activeHouseId:
                                    form.buildings && form.buildings.length
                                        ? form.buildings[0].building_id
                                        : '',
                                activeEntrancesId:
                                    form.buildings && form.buildings.length
                                        ? form.buildings[0].entrance_id
                                        : [],
                            }}
                            publishToError={validationState.published_to}
                            locationError={validationState.housing_complexes_ids}
                            changeCallback={data => {
                                setForm({
                                    ...form,
                                    published_to: data.publicationVariants,
                                    housing_complexes_ids: data.activeComplexesId,
                                    buildings: data.activeHouseId
                                        ? [
                                              {
                                                  complex_id: data.activeComplexesId.length
                                                      ? data.activeComplexesId[0]
                                                      : '',
                                                  building_id: data.activeHouseId,
                                                  entrance_id: data.activeEntrancesId,
                                              },
                                          ]
                                        : [],
                                });
                            }}
                        />

                        <CDateRangeForm
                            checkboxLabel="Закрепить"
                            checked={!!form.pin_to_top}
                            dateFromValue={form.pin_date_from}
                            dateToValue={form.pin_date_to}
                            errorText={validationState.pin_date_from || validationState.pin_date_to}
                            checkboxChangeCallback={value => {
                                setForm({
                                    ...form,
                                    is_pinned: value,
                                });
                            }}
                            dateFromChangeCallback={value => {
                                setForm({
                                    ...form,
                                    pin_date_from: value || null,
                                });
                            }}
                            dateToChangeCallback={value => {
                                setForm({
                                    ...form,
                                    pin_date_to: value || null,
                                });
                            }}
                        />

                        {!props.initialData.published_at ? (
                            <DateTimePicker
                                open={delayedPublicationDPState}
                                inputFormat={TABLE_DATETIME_MASK}
                                label="Отложенная публикация"
                                value={form.publish_on || null}
                                onOpen={() => setDelayedPublicationDPState(true)}
                                onClose={() => setDelayedPublicationDPState(false)}
                                onChange={value => {
                                    setForm({
                                        ...form,
                                        publish_on: value
                                            ? formatDate(value, API_DATETIME_MASK)
                                            : null,
                                    });
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        error={!!validationState.publish_on}
                                        helperText={validationState.publish_on}
                                        fullWidth
                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                        focused={false}
                                        onClick={() => setDelayedPublicationDPState(true)}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    {form.publish_on && (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setForm({
                                                                    ...form,
                                                                    publish_on: '',
                                                                })
                                                            }
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    )}
                                                    <IconButton size="small">
                                                        <InsertInvitation />
                                                    </IconButton>
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        ) : null}
                    </Stack>
                ),
            }}
        </EntityContentWithAside>
    );
};
