import { Component } from 'react';

import { CButton } from '../../Button/CButton';
import { TextField, Stack } from '@mui/material';

import './LoginPage.scss';

export default class LoginPage extends Component {
    constructor() {
        super();
        this.state = {
            login: '',
            password: '',
            validLogin: true,
            validPassword: true,
        };
    }

    handleLoginChange = event => {
        this.setState({ login: event.target.value });
    };

    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
    };

    handleSubmit = event => {
        const { login, password } = this.state;
        if (login !== '' && password !== '') {
            this.props.handleAuthSubmit({ login, password });
            event.preventDefault();
        } else {
            if (login === '') this.setState({ validLogin: false });
            if (password === '') this.setState({ validPassword: false });
            event.preventDefault();
        }
    };

    render() {
        const { login, password, validLogin, validPassword } = this.state;
        const { errorAuth } = this.props;

        const errMessage = (
            <p className="errorAuth">
                Не удается войти.
                <br /> Пожалуйста, проверьте правильность
                <br /> логина и пароля.
            </p>
        );
        const validMessage = 'Заполните оба поля авторизации.';

        return (
            <div className="LoginPage">
                <div className="auth-container">
                    <form
                        onSubmit={this.handleSubmit}
                        className="auth-form"
                    >
                        <Stack spacing={3}>
                            <span className="auth-title">PSN admin</span>
                            <Stack spacing={2}>
                                <TextField
                                    id="loginInput"
                                    label="Login"
                                    value={login}
                                    onChange={this.handleLoginChange}
                                    autoFocus={true}
                                    fullWidth={true}
                                    error={!validLogin}
                                    required
                                />
                                <TextField
                                    id="passwordInput"
                                    label="Password"
                                    type="password"
                                    value={password}
                                    fullWidth={true}
                                    error={!validPassword}
                                    onChange={this.handlePasswordChange}
                                    helperText={!validPassword || !validLogin ? validMessage : null}
                                    required
                                />
                                {errorAuth && <div>{errMessage}</div>}
                            </Stack>
                            <CButton
                                action={this.handleSubmit}
                                variant="contained"
                                className="auth-button"
                                type="submit"
                                label="Войти"
                            />
                        </Stack>
                    </form>
                </div>
            </div>
        );
    }
}
