import CircularProgress from '@mui/material/CircularProgress';

import './c-spinner.scss';

interface Props {
    style?: Record<string, string>;
}

export const CSpinner = (props: Props) => {
    return (
        <div
            className="c-spinner"
            {...props}
        >
            <CircularProgress
                color="primary"
                size={80}
            />
        </div>
    );
};
