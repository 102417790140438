import Base from './Base';

export default class AppVersionApi extends Base {
    createVersion(data) {
        return this.apiClient.post('api/apps-versions', data);
    }

    editVersion(id, data) {
        return this.apiClient.put(`api/apps-versions/${id}`, data);
    }

    fetchVersions(query) {
        return query.limit
            ? this.apiClient.get(`api/apps-versions/page/${query.page}?limit=${query.limit}`)
            : this.apiClient.get(`api/apps-versions/page/${query.page}`);
    }

    deleteVersions(versionId) {
        return this.apiClient.delete(`api/apps-versions`, versionId);
    }
}
