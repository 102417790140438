import { FC, useState } from 'react';
import { JobForm as IJobForm } from '@/types/api/jobs/request-body';
import { Box, Stack } from '@mui/system';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { formValidationConfig, FormValidator } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { trimStringValues } from '@/helpers/transformers';
import { CFormControl } from './c-form-control';
import { Job } from '@/types/api/jobs/incom';

interface Props {
    initialValue: Job;
    submitCallback: (form: IJobForm) => void;
    cancelCallback: () => void;
}

const getValidatorConfig = (): formValidationConfig<IJobForm> => ({
    name: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
        maxLength: {
            order: 2,
            config: [errorTexts.max.string(128), 128],
        },
    },
    is_active: {},
    work_type_id: {},
});

export const JobForm: FC<Props> = props => {
    const [form, setForm] = useState<IJobForm>(props.initialValue);

    const validator: FormValidator<IJobForm> = new FormValidator<IJobForm>(getValidatorConfig());

    const [validationState, setValidationState] = useState<Record<keyof Partial<IJobForm>, string>>(
        validator.validateAsSimple(form, true),
    );

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form) as IJobForm;
        setForm(trimmedForm);
        const result = validator.validateAsSimple(trimmedForm);
        setValidationState(result);
        if (validator.isValid) props.submitCallback(form);
    };

    return (
        <Stack
            component="form"
            spacing={3}
            noValidate
        >
            <TextField
                fullWidth
                required
                label="Название работы"
                value={form.name}
                onChange={event => {
                    setForm({ ...form, name: event.target.value });
                }}
                error={!!validationState.name}
                helperText={validationState.name}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={form.is_active}
                        onChange={() => {
                            setForm({ ...form, is_active: !form.is_active });
                        }}
                    />
                }
                label="Активность работы"
            />

            <CFormControl
                stackOptions={{
                    direction: 'row',
                }}
                controls={[
                    {
                        label: 'Сохранить',
                        callback: handleSubmit,
                        buttonProps: {
                            variant: 'contained',
                        },
                    },
                    {
                        label: 'Отмена',
                        callback: props.cancelCallback,
                        buttonProps: {
                            variant: 'outlined',
                        },
                    },
                ]}
            />
        </Stack>
    );
};
