import { Advice } from '@/types/api/advices/incom';

export const adviceIncomPlaceholder: Advice = {
    title: '',
    description: '',
    article: '',
    id: 0,
    created_at: '',
    updated_at: '',
    image_cover: null,
    documents: [],
    images: [],
};
