import Base from './Base';

export default class AdminCommunicationApi extends Base {
    fetchSettings() {
        return this.apiClient.get(`api/settings/contact`);
    }

    uploadHtmlFile(file) {
        return this.apiClient.post(`api/storage/html`, file, 'file');
    }

    createSettings(form) {
        return this.apiClient.post(`api/settings/contact`, form);
    }

    editSettings(form, id) {
        return this.apiClient.put(`api/settings/contact/${id}`, form);
    }

    createFile({ file, type, description }) {
        return this.apiClient.post(`api/storage/base64/${type}`, { file, description });
    }

    createHtmlFile(data) {
        return this.apiClient.post('/api/storage/content/html', data);
    }
}
