import { connect } from 'react-redux';
import { showMenu } from '@/actions/operations';

import Header from '@/components/layout/Header/Header';
import HeaderFAQ from '@/components/layout/Header/HeaderFAQ';
import { FC } from 'react';

export interface HeaderContinerProps {
    options: {
        nameOfSection: string;
        nameOfAction: string;
    };
    actionCreate?: string | Function;
    FAQ?: any;
    showMenu?: () => void;
    handleShowMenu?: () => void;
}

const HeaderContainer: FC<HeaderContinerProps> = props => {
    if (props.FAQ)
        return (
            <HeaderFAQ
                {...props}
                handleShowMenu={props.showMenu}
                actionCreate={props.actionCreate}
            />
        );
    return (
        <Header
            {...props}
            handleShowMenu={props.showMenu}
            actionCreate={props.actionCreate}
        />
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    showMenu: () => dispatch(showMenu()),
});

export default connect(null, mapDispatchToProps)(HeaderContainer);
