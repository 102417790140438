import Base from './Base';

export default class NewsApi extends Base {
    createNews(data) {
        return this.apiClient.post('api/news', data);
    }

    fetchNews(query) {
        const request = query.limit
            ? this.apiClient.get(
                  `api/news/page/${query.page}?is_new_version=1&limit=${query.limit}`,
              )
            : this.apiClient.get(`api/news/page/${query.page}?is_new_version=1`);
        return request;
    }

    fetchArticle(newsId) {
        return this.apiClient.get(`api/news/${newsId}?is_new_version=1`);
    }

    editNews(data, id) {
        return this.apiClient.put(`api/news/${id}?is_new_version=1`, data);
    }

    deleteNews(newsId) {
        return this.apiClient.delete(`api/news?is_new_version=1`, newsId);
    }

    uploadImage(image) {
        return this.apiClient.post(`api/storage/image/news?is_new_version=1`, image, 'file');
    }

    publishNews(newsId) {
        return this.apiClient.patch(`api/news/push/${newsId}?is_new_version=1`);
    }
}
