import React, { useEffect } from 'react';
import Promise from 'bluebird';

import { searchToObject } from './urlUtil';
import { useLocation, useParams } from 'react-router';

let IS_FIRST_MOUNT_AFTER_LOAD = true;

export default function connectDataFetchers(Component, actionCreators) {
    return props => {
        const fetchData = ({ dispatch, params = {}, query = {}, locale }) => {
            return Promise.all(
                actionCreators.map(actionCreator =>
                    dispatch(actionCreator({ params, query, locale })),
                ),
            );
        };

        const params = useParams();
        const location = useLocation();

        // componentDidUpdate(prevProps) {
        //     const { location } = this.props;
        //     const { location: prevLocation } = prevProps;

        //     const isUrlChanged =
        //         location.pathname !== prevLocation.pathname ||
        //         location.search !== prevLocation.search;

        //     if (isUrlChanged) {
        //         this._fetchDataOnClient();
        //     }
        // }

        const _fetchDataOnClient = () => {
            fetchData({
                dispatch: props.dispatch,
                params,
                query: searchToObject(location.search),
            });
        };

        useEffect(() => {
            if (IS_FIRST_MOUNT_AFTER_LOAD) {
                // console.log({ IS_FIRST_MOUNT_AFTER_LOAD });
            }

            _fetchDataOnClient();

            IS_FIRST_MOUNT_AFTER_LOAD = false;
        }, []);

        return <Component {...props} />;
    };
}
