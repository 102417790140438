import { gFetch } from '@/helpers/api/g-fetch';
import {
    ServiceCategory,
    ServiceCategoryListIncomWrapper,
} from '@/types/api/service-categories/incom';
import {
    ServiceCategoriesActivity,
    ServiceCategoryForm,
} from '@/types/api/service-categories/request-body';
import { ServiceCategoriesQuery } from '@/types/api/service-categories/request-query';
import { WithPagination } from '@/types/IncomWrapper';

class ServiceCategoriesService {
    async listByPage(
        page: number,
        query: ServiceCategoriesQuery | string,
    ): Promise<WithPagination<Array<ServiceCategory>>> {
        const result = await gFetch<ServiceCategoryListIncomWrapper>(
            'main',
            'serviceCategoriesByPage',
            {
                params: {
                    page: page || 1,
                },
                query,
            },
        );

        return {
            result: result.category_services,
            total: result.total,
            limit: result.limit,
            page: result.page,
        };
    }

    async list(): Promise<Array<ServiceCategory>> {
        const result = await gFetch<{ category_services: Array<ServiceCategory> }>(
            'main',
            'serviceCategories',
        );

        return result.category_services;
    }

    async one(id: number): Promise<ServiceCategory> {
        const result = await gFetch<{ category_services: ServiceCategory }>(
            'main',
            'serviceCategory',
            {
                params: { id },
            },
        );

        return result.category_services;
    }

    async create(form: ServiceCategoryForm): Promise<ServiceCategory> {
        const result = await gFetch<{ category_services: ServiceCategory }>(
            'main',
            'createServiceCategory',
            {
                fetchConfig: {
                    method: 'POST',
                    body: JSON.stringify(form),
                },
            },
        );

        return result.category_services;
    }

    async updateActivity(form: ServiceCategoriesActivity): Promise<Array<ServiceCategory>> {
        const result = await gFetch<{ category_services: Array<ServiceCategory> }>(
            'main',
            'updateServiceCategoriesActivity',
            {
                fetchConfig: {
                    method: 'PATCH',
                    body: JSON.stringify(form),
                },
            },
        );

        return result.category_services;
    }

    async update(id: number, form: ServiceCategoryForm): Promise<ServiceCategory> {
        const result = await gFetch<{ category_services: ServiceCategory }>(
            'main',
            'updateServiceCategory',
            {
                params: {
                    id,
                },
                fetchConfig: {
                    method: 'PUT',
                    body: JSON.stringify(form),
                },
            },
        );

        return result.category_services;
    }
}

export const serviceCategoriesService = new ServiceCategoriesService();
