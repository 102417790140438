import api from '../apiSingleton';

export const CREATE_VERSION_START = 'CREATE_VERSION_START';
export const CREATE_VERSION_SUCCESS = 'CREATE_VERSION_SUCCESS';
export const CREATE_VERSION_FAIL = 'CREATE_VERSION_FAIL';

export function createAppVersion(version) {
    return dispatch => {
        dispatch({ type: CREATE_VERSION_START });

        return api.appVersion
            .createVersion(version)
            .then(() => {
                return dispatch({ type: CREATE_VERSION_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: CREATE_VERSION_FAIL });
            });
    };
}

export const EDIT_VERSION_START = 'EDIT_VERSION_START';
export const EDIT_VERSION_SUCCESS = 'EDIT_VERSION_SUCCESS';
export const EDIT_VERSION_FAIL = 'EDIT_VERSION_FAIL';

export function editAppVersion(id, version) {
    return dispatch => {
        dispatch({ type: EDIT_VERSION_START });

        return api.appVersion
            .editVersion(id, version)
            .then(() => {
                return dispatch({ type: EDIT_VERSION_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: EDIT_VERSION_FAIL });
            });
    };
}

export const FETCH_VERSION_START = 'FETCH_VERSION_START';
export const FETCH_VERSION_SUCCESS = 'FETCH_VERSION_SUCCESS';
export const FETCH_VERSION_FAIL = 'FETCH_VERSION_FAIL';

export function fetchAppVersions({ query }) {
    if (Object.keys(query).length === 0) {
        query.page = 1;
    }

    return dispatch => {
        dispatch({ type: FETCH_VERSION_START });

        return api.appVersion
            .fetchVersions(query)
            .then(data => {
                return dispatch({ type: FETCH_VERSION_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_VERSION_FAIL });
            });
    };
}

export const DELETE_VERSION_START = 'DELETE_VERSION_START';
export const DELETE_VERSION_SUCCESS = 'DELETE_VERSION_SUCCESS';
export const DELETE_VERSION_FAIL = 'DELETE_VERSION_FAIL';

export function deleteAppVersions(versions) {
    return dispatch => {
        dispatch({ type: DELETE_VERSION_START });

        return api.appVersion
            .deleteVersions(versions)
            .then(() => {
                return dispatch({ type: DELETE_VERSION_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: DELETE_VERSION_FAIL });
            });
    };
}
