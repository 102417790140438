import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { PrimaryLayout } from '@/containers/PrimaryLayout';

const AuthorizedRoute = (props: any) => {
    if (!props.user.authorized) return <Navigate to="/login" />;

    return <PrimaryLayout />;
};

const mapStateToProps = ({ user }: any) => ({
    user,
});

export default connect(mapStateToProps)(AuthorizedRoute);
