import { Service } from "@/types/api/services/incom";

export const servicePlaceHolder: Readonly<Service> = {
    id: 0,
    name: '',
    complexes: [],
    service_category: {
        id: 0,
        name: '',
    },
    is_active: false,
    image: {
        id: 0,
        url: '',
    },
    min_price: 0,
    sort: 0,
};