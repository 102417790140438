import {
    SEARCH,
    FILTER_ID,
    NOT_FOUND_PAGE,
    CANCEL_NOT_FOUND_PAGE,
    CLEAR_SEARCHLINE,
    CLEAR_FILTER_VALUE,
} from '../actions/operations';

const initialState = {
    showMenu: false,
    searchLine: '',
    searchField: '',
    filterId: [],
    notFoundPage: false,
};

export const operations = (state = initialState, { type, data }) => {
    switch (type) {
        case SEARCH:
            return {
                ...state,
                searchLine: data.searchLine,
                searchField: data.searchField,
            };
        case FILTER_ID:
            return {
                ...state,
                filterId: data,
            };
        case NOT_FOUND_PAGE:
            return {
                ...state,
                notFoundPage: true,
            };
        case CANCEL_NOT_FOUND_PAGE:
            return {
                ...state,
                notFoundPage: false,
            };
        case CLEAR_SEARCHLINE:
            return {
                ...state,
                searchField: '',
                searchLine: '',
            };
        case CLEAR_FILTER_VALUE:
            return {
                ...state,
                filterId: [],
            };
        default:
            return state;
    }
};
