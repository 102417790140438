type RemoveFirst<T extends unknown[]> = T extends [infer H, ...infer R] ? R : T;

import {
    ValidatorList,
    ValidatorKeyList,
    defaultValidators,
    abstractField,
    fieldValidator,
} from './validators';

// Конфиг валидации поля
type fieldValidationConfig = Partial<
    Record<ValidatorKeyList, { config: RemoveFirst<Parameters<fieldValidator>>; order: number }>
>;

type FieldValidationResult<FieldValidationConfig extends fieldValidationConfig> = Record<
    keyof FieldValidationConfig,
    ReturnType<fieldValidator>
>;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export type formValidationConfig<Form extends AbstractForm> = Record<
    keyof Form,
    fieldValidationConfig
>;

type formValidationResult<
    Form extends AbstractForm,
    FormValidationConfig extends formValidationConfig<Form>,
> = Record<keyof Form, FieldValidationResult<FormValidationConfig[keyof Form]>>;

export interface AbstractForm {
    [key: string | number | symbol]: abstractField;
}

export class FormValidator<Form extends AbstractForm> {
    constructor(
        validationConfig: formValidationConfig<Form>,
        validators: ValidatorList = defaultValidators,
    ) {
        this.validationConfig = validationConfig;
        this.validators = validators;
    }

    private validationConfig: formValidationConfig<Form>;
    private readonly validators: ValidatorList;
    public isValid = true;

    public validate(
        form: Form,
        genPlaceholder: boolean = false,
    ): formValidationResult<Form, formValidationConfig<Form>> {
        const result = {} as formValidationResult<Form, formValidationConfig<Form>>;
        this.isValid = true;

        Object.entries(form).forEach(([key, value]: [keyof Form, abstractField]) => {
            if (!this.validationConfig[key]) return;

            if (!result[key])
                result[key] = {} as FieldValidationResult<formValidationConfig<Form>[keyof Form]>;

            Object.entries(this.validationConfig[key])
                .sort((prev, next) => prev[1].order - next[1].order)
                .forEach(([vKey, vValue]) => {
                    // @ts-ignore
                    result[key][vKey] = genPlaceholder
                        ? { error: '', valid: true }
                        : // @ts-ignore
                          this.validators[vKey](value, ...(vValue.config || []));

                    // @ts-ignore
                    this.isValid = this.isValid && result[key][vKey].valid;
                });
        });

        return result;
    }

    public validateAsSimple(
        form: Form,
        genPlaceholder: boolean = false,
    ): Record<keyof Form, string> {
        const fullResult = this.validate(form, genPlaceholder);

        return Object.entries(fullResult).reduce(
            (
                acc,
                [key, value]: [
                    keyof Form,
                    FieldValidationResult<formValidationConfig<Form>[keyof Form]>,
                ],
            ) => {
                acc[key] = Object.values(value).find(el => el.error)?.error || '';
                return acc;
            },

            {} as Record<keyof Form, string>,
        );
    }

    public updateValidationConfig(config: formValidationConfig<Form>) {
        this.validationConfig = config;
    }
}
