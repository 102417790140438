import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CTable, CTableRow, CTableColumn } from '@/components/Table/c-table';
import { CPagination } from '@/components/Table/c-pagination/c-pagination';
import { performedJobService } from '@/services/performed-job.service';
import { WithPagination } from '@/types/IncomWrapper';
import { PerformedJob } from '@/types/api/performed-jobs/incom';
import { FC, Suspense, useEffect, useState } from 'react';
import {
    Await,
    LoaderFunction,
    defer,
    useLoaderData,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { urlSearchParamsToObject } from '@/helpers/api/url-search-params-to-object';
import { PerformedJobQuery } from '@/types/api/performed-jobs/request-query';
import { requestQueryPlaceholder } from '@/placeholders/performed-jobs/request-query.placeholder';
import { queryParser } from '@/helpers/api/query-parser';
import { Grid } from '@mui/material';
import { CTableToolbar } from '@/components/Table/c-table-toolbar';
import { CListPageWrapper } from '@/components/layout/c-list-page-wrapper/c-list-page-wrapper';
import { CSearchField } from '@/components/forms/c-search-field';
import { CSelect } from '@/components/inputs/c-select';
import { tagService } from '@/services/tags.service';
import { EnhancedTag } from '@/types/api/tags/incom';
import { tagListToSelectItemList } from '@/helpers/transformers';
import { isEqual } from 'lodash';

const TABLE_COLUMNS: CTableColumn[] = [
    {
        id: 1,
        label: 'Картинка',
        sortable: false,
    },
    {
        id: 'title',
        label: 'Название',
        sortable: true,
    },
    {
        id: 'description',
        label: 'Краткое описание',
        sortable: false,
    },
    {
        id: 'work_date',
        label: 'Дата работы',
        sortable: false,
    },
    {
        id: 'published_at',
        label: 'Дата публикации',
        sortable: false,
    },
];

function performedJobToTableRow(data: PerformedJob): CTableRow {
    return [
        data.id,
        [
            {
                data: data?.image_cover ? data?.image_cover.url || '' : '',
                type: 'image',
            },
            {
                data: data.title,
            },
            {
                data: data.description,
            },
            {
                data: data.work_date,
                type: 'date',
            },
            {
                data: data.published_at || data.publish_on || 'Не опубликовано',
                type: data.published_at || data.publish_on ? 'dateTime' : 'text',
            },
        ],
    ];
}

export const PerformedJobs: FC = () => {
    const { pageData } = useLoaderData() as {
        pageData: [WithPagination<PerformedJob[]>, EnhancedTag[]];
    };

    const location = useLocation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [filters, setFilters] = useState<Required<PerformedJobQuery>>({
        ...requestQueryPlaceholder,
        ...urlSearchParamsToObject(searchParams),
    });
    const [selectedPerformedJobs, setSelectedPerformedJobs] = useState<number[]>([]);

    const pageChanged = (page: number): void => {
        setFilters({ ...filters, page });
    };

    const limitChanged = (limit: number): void => {
        setFilters({ ...filters, page: 1, limit });
    };

    const searchWordChanged = (q: string): void => {
        setFilters({ ...filters, page: 1, q });
        setSelectedPerformedJobs([]);
    };

    const filteringTagsChanged = (ids: number[]): void => {
        setFilters({ ...filters, page: 1, tags: ids });
        setSelectedPerformedJobs([]);
    };

    const handleDelete = async (): Promise<void> => {
        try {
            await performedJobService.removeMany(selectedPerformedJobs);
            navigate(location.pathname + queryParser({ ...filters, page: 1 } as any));
            setSelectedPerformedJobs([]);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (isEqual(filters, requestQueryPlaceholder)) return;
        navigate(location.pathname + queryParser(filters as any));
    }, [filters]);

    return (
        <Suspense fallback={<CSpinner />}>
            <Await resolve={pageData}>
                {([performedJobsList, tags]: [WithPagination<PerformedJob[]>, EnhancedTag[]]) => {
                    return (
                        <CListPageWrapper
                            headerContainerProps={{
                                options: {
                                    nameOfSection: 'Выполненные работы',
                                    nameOfAction: 'Добавить работу УК',
                                },
                                actionCreate: '/performed-jobs/create-article',
                            }}
                        >
                            <>
                                <Grid
                                    container
                                    columns={2}
                                    columnSpacing={3}
                                    direction="row"
                                >
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CSearchField
                                            label="Поиск по названию"
                                            value={filters.q}
                                            changeCallback={searchWordChanged}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CSelect
                                            multiple
                                            placeholder={'Поиск по тегам'}
                                            value={filters.tags}
                                            items={tagListToSelectItemList(tags)}
                                            changeCallback={filteringTagsChanged}
                                        />
                                    </Grid>
                                </Grid>

                                <CTable
                                    toolbar={
                                        <CTableToolbar
                                            selectedItemsCount={selectedPerformedJobs.length}
                                            controls={[
                                                {
                                                    buttonLabel: 'Удалить',
                                                    buttonProps: {
                                                        disabled: !selectedPerformedJobs.length,
                                                    },
                                                    confimation: {
                                                        title: 'Удаление',
                                                        text: 'Вы уверены, что хотите удалить выбранные элементы?',
                                                    },
                                                    submitCallback: handleDelete,
                                                },
                                            ]}
                                        />
                                    }
                                    pagination={
                                        <CPagination
                                            data={{
                                                limit: filters.limit,
                                                page: +(
                                                    performedJobsList.page ||
                                                    filters.page ||
                                                    1
                                                ),
                                                total: performedJobsList.total,
                                            }}
                                            pageChangeCallback={pageChanged}
                                            limitChangeCallback={limitChanged}
                                        />
                                    }
                                    columns={TABLE_COLUMNS}
                                    rows={performedJobsList.result.map(performedJobToTableRow)}
                                    selectedItems={selectedPerformedJobs}
                                    selectedItemsChangeCallback={value =>
                                        setSelectedPerformedJobs(value as number[])
                                    }
                                    rowClickCallback={id =>
                                        navigate(`/performed-jobs/${id}`, {
                                            state: { fromPage: location },
                                        })
                                    }
                                />
                            </>
                        </CListPageWrapper>
                    );
                }}
            </Await>
        </Suspense>
    );
};

export const performedJobsLoader: LoaderFunction = ({ request }) => {
    const url = new URL(request.url);

    return defer({
        pageData: Promise.all([
            performedJobService.list(+(url.searchParams.get('page') || 1), url.search),
            tagService.list(),
        ]),
    });
};
