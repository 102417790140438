import { gFetch } from '@/helpers/api/g-fetch';
import { Complex, ComplexListIncomWrapper } from '@/types/api/complexes/incom';

class ComplexService {
    async list(): Promise<Complex[]> {
        const result = await gFetch<ComplexListIncomWrapper>('main', 'complexList');

        return result.complexes;
    }
}

export const complexService = new ComplexService();
