import { FC, Suspense, useState } from 'react';
import {
    useNavigate,
    LoaderFunction,
    defer,
    useLoaderData,
    Await,
    useParams,
} from 'react-router-dom';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { AdviceForm } from '@/components/forms/advice-form';
import { advicesService } from '@/services/advices.service';
import { AdviceForm as IAdviceForm } from '@/types/api/advices/request-body';
import { adviceIncomPlaceholder } from '@/placeholders/advices/incom';
import { Advice } from '@/types/api/advices/incom';

export const EditAdvice: FC = () => {
    const [pending, setPending] = useState(false);

    const navigate = useNavigate();

    const { pageData } = useLoaderData() as { pageData: Advice };

    const params = useParams();

    const onSubmit = async (form: any) => {
        setPending(true);
        try {
            setPending(false);
            await advicesService.edit(+(params.id || 0), form);
            navigate('/advices');
        } catch (e: any) {
            console.error(e);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: 'Полезные советы / Редактировать совет',
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={pageData}>
                    {(pageData: Advice) => {
                        return (
                            <AdviceForm
                                initialValue={pageData || adviceIncomPlaceholder}
                                submitCallback={(form: IAdviceForm) => {
                                    onSubmit(form);
                                }}
                            />
                        );
                    }}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const editAdviceLoader: LoaderFunction = async ({ params }) => {
    return defer({
        pageData: advicesService.one(+(params.id || 0)),
    });
};
