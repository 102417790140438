import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { PerformedJobForm } from '@/components/forms/performed-job-form';
import { complexService } from '@/services/complex.service';
import { tagService } from '@/services/tags.service';
import { Complex } from '@/types/api/complexes/incom';
import { EnhancedTag } from '@/types/api/tags/incom';

import { FC, Suspense, useState } from 'react';
import {
    Await,
    LoaderFunction,
    defer,
    useLoaderData,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { PerformedJobForm as IPerformedJobForm } from '@/types/api/performed-jobs/request-body';
import { performedJobService } from '@/services/performed-job.service';
import { PerformedJob } from '@/types/api/performed-jobs/incom';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';

export const EditPerformedJob: FC = () => {
    const { summary } = useLoaderData() as { summary: [Complex[], EnhancedTag[], PerformedJob] };
    const [pending, setPending] = useState<boolean>(false);

    const navigate = useNavigate();

    const params = useParams();

    const handleSubmit = async (form: IPerformedJobForm): Promise<void> => {
        setPending(true);
        try {
            await performedJobService.update(+(params.id || 0), form);
            setPending(false);
            navigate('/performed-jobs');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    const handlePublish = async (id: number): Promise<void> => {
        setPending(true);
        try {
            await performedJobService.publish(id);
            setPending(false);
            navigate('/performed-jobs');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    const handleDelete = async (id: number): Promise<void> => {
        setPending(true);
        try {
            await performedJobService.remove(id);
            setPending(false);
            navigate('/performed-jobs');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: `Выполненные работы / Редактирование выполненной работы`,
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={summary}>
                    {([complexList, tagList, initialData]: [
                        Complex[],
                        EnhancedTag[],
                        PerformedJob,
                    ]) => (
                        <PerformedJobForm
                            initialData={initialData}
                            complexList={complexList}
                            tagList={tagList}
                            submitCallback={handleSubmit}
                            cancelCallback={() => navigate('/performed-jobs')}
                            publishCallback={handlePublish}
                            deleteCallback={handleDelete}
                        />
                    )}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const editPerformedJobLoader: LoaderFunction = ({ params }) => {
    return defer({
        summary: Promise.all([
            complexService.list(),
            tagService.list(),
            performedJobService.one(+(params.id || 0)),
        ]),
    });
};
