import { FC } from 'react';
import { MenuItem, Pagination, Select, Stack } from '@mui/material';

const rowsPerPage = [10, 15, 25];

export const CPagination: FC<{
    data: { limit: number; page: number; total: number };
    limitChangeCallback: (value: number) => void;
    pageChangeCallback: (value: number) => void;
}> = props => {
    return (
        <Stack
            direction="row"
            spacing={3}
            p={3}
            alignItems="center"
        >
            <span>Строк на странице: </span>

            <Select
                id="page_limit"
                value={props.data.limit}
                onChange={e => props.limitChangeCallback(+e.target.value)}
            >
                {rowsPerPage.map((item, index) => (
                    <MenuItem
                        value={item}
                        key={item}
                    >
                        {item}{' '}
                    </MenuItem>
                ))}
            </Select>
            <Pagination
                color="primary"
                page={props.data.page}
                count={Math.ceil(props.data.total / (props.data.limit || 10))}
                onChange={(e, page) => {
                    props.pageChangeCallback(page);
                }}
                showFirstButton={true}
                showLastButton={true}
                size="large"
            />
        </Stack>
    );
};
