import { FC, Suspense, useState, useEffect } from 'react';
import {
    LoaderFunction,
    Await,
    useLoaderData,
    useSearchParams,
    useNavigate,
    defer,
} from 'react-router-dom';
import { Stack, Grid } from '@mui/material';
import { CListPageWrapper } from '@/components/layout/c-list-page-wrapper/c-list-page-wrapper';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CSearchField } from '@/components/forms/c-search-field';
import { CTable, CTableRow, CTableColumn } from '@/components/Table/c-table';
import { CTableToolbar } from '@/components/Table/c-table-toolbar';
import { CPagination } from '@/components/Table/c-pagination/c-pagination';
import { CSelect, SelectItem } from '@/components/inputs/c-select';
import { advicesService } from '@/services/advices.service';
import { Advice } from '@/types/api/advices/incom';
import { AdvicesQuery, SearchField } from '@/types/api/advices/request-query';
import { WithPagination } from '@/types/IncomWrapper';
import { requestQueryPlaceholder } from '@/placeholders/advices/request-query.placeholder';
import { urlSearchParamsToObject } from '@/helpers/api/url-search-params-to-object';
import { queryParser } from '@/helpers/api/query-parser';

const TABLE_COLUMNS: CTableColumn[] = [
    {
        id: 1,
        label: 'Картинка',
        sortable: false,
    },
    {
        id: 'title',
        label: 'Заголовок',
        sortable: false,
    },
    {
        id: 'activity',
        label: 'Краткое описание',
        sortable: false,
    },
    {
        id: 'created',
        label: 'Дата публикации',
        sortable: false,
    },
];

function adviceToTableRow(data: Advice): CTableRow {
    return [
        data.id,
        [
            {
                data: data.image_cover?.url || '',
                type: 'image',
            },
            {
                data: data.title,
            },
            {
                data: data.description,
            },
            {
                data: data.created_at,
            },
        ],
    ];
}

const searchFieldTypes: SelectItem<string>[] = [
    {
        id: 'title',
        name: 'По названию',
    },
    {
        id: 'description',
        name: 'По описанию',
    },
];

export const Advices: FC = () => {
    const { pageData } = useLoaderData() as {
        pageData: WithPagination<Advice[]>;
    };

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [filters, setFilters] = useState<Required<AdvicesQuery>>({
        ...requestQueryPlaceholder,
        ...urlSearchParamsToObject(searchParams),
    });
    const [selectedAdvices, setSelectedAdvices] = useState<number[]>([]);

    const handleSearchChange = (q: string) => {
        setFilters({ ...filters, q, searchField: 'title' });
    };

    const handlePageChange = (page: number) => {
        setFilters({ ...filters, page });
    };

    const handleLimitChange = (limit: number) => {
        setFilters({ ...filters, limit });
    };

    const handleSearchTypeChange = (ids: SearchField[]) => {
        setFilters({ ...filters, searchField: ids[0] || searchFieldTypes[0].id });
    };

    const handleDeactivate = async (): Promise<void> => {
        try {
            await advicesService.removeList(selectedAdvices);
            navigate(location.pathname + queryParser({ ...filters, page: 1 } as any));
            setSelectedAdvices([]);
        } catch (e: any) {
            console.error(e?.toString() || e);
        }
    };

    useEffect(() => navigate(location.pathname + queryParser(filters)), [filters]);

    return (
        <Suspense fallback={<CSpinner />}>
            <Await resolve={pageData}>
                {(pageData: WithPagination<Advice[]>) => {
                    return (
                        <CListPageWrapper
                            headerContainerProps={{
                                options: {
                                    nameOfSection: 'Полезные советы',
                                    nameOfAction: 'Добавить совет',
                                },
                                actionCreate: '/advices/create-advice',
                            }}
                        >
                            <>
                                <Stack spacing={3}>
                                    <Grid
                                        container
                                        columns={3}
                                        columnSpacing={3}
                                        direction="row"
                                    >
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <CSearchField
                                                label="Поиск"
                                                value={filters.q}
                                                changeCallback={handleSearchChange}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={1}
                                        >
                                            <CSelect<string>
                                                items={searchFieldTypes}
                                                value={[filters.searchField]}
                                                label="Тип поиска"
                                                changeCallback={ids => {
                                                    handleSearchTypeChange(ids as SearchField[]);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <CTable
                                        pagination={
                                            <CPagination
                                                data={{
                                                    limit: pageData.limit,
                                                    page: pageData.page,
                                                    total: pageData.total,
                                                }}
                                                pageChangeCallback={handlePageChange}
                                                limitChangeCallback={handleLimitChange}
                                            />
                                        }
                                        columns={TABLE_COLUMNS}
                                        rows={pageData.result.map(adviceToTableRow)}
                                        selectedItems={selectedAdvices}
                                        toolbar={
                                            <CTableToolbar
                                                controls={[
                                                    {
                                                        buttonLabel: 'Удалить',
                                                        confimation: {
                                                            title: 'Вы действительно хотите удалить выбранные советы?',
                                                            text: '',
                                                        },
                                                        buttonProps: {
                                                            disabled: !selectedAdvices.length,
                                                        },
                                                        submitCallback: handleDeactivate,
                                                    },
                                                ]}
                                                selectedItemsCount={selectedAdvices.length}
                                            />
                                        }
                                        selectedItemsChangeCallback={value =>
                                            setSelectedAdvices(value as number[])
                                        }
                                        rowClickCallback={id =>
                                            navigate(`/advices/${id}/edit-advice`, {
                                                state: {
                                                    previousPathname:
                                                        location.pathname +
                                                        queryParser(filters as any),
                                                },
                                            })
                                        }
                                    />
                                </Stack>
                            </>
                        </CListPageWrapper>
                    );
                }}
            </Await>
        </Suspense>
    );
};

export const advicesLoader: LoaderFunction = async ({ request }) => {
    const url = new URL(request.url);
    const pageNumber = +(url.searchParams.get('page') || 1);

    return defer({
        pageData: advicesService.listByPage(pageNumber, url.search),
    });
};
