import {
    AUTHORIZATION_USER_SUCCESS,
    AUTHORIZATION_USER_FAIL,
    LOGOUT_USER_START,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    CANCEL_USER_AUTHRIZATION_FAILED,
} from '../actions/user';

const initialState = {
    loading: false,
    authorized: false,
    authorizateFailed: false,
};

const user = (state = initialState, { type, data }) => {
    switch (type) {
        case AUTHORIZATION_USER_SUCCESS:
            return {
                ...state,
                authorized: true,
            };
        case AUTHORIZATION_USER_FAIL:
            return {
                ...state,
                authorized: false,
                authorizateFailed: true,
            };
        case LOGOUT_USER_START:
            return {
                ...state,
                loading: true,
            };
        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                authorized: false,
            };
        case LOGOUT_USER_FAIL:
            return {
                ...state,
                loading: false,
                authorizateFailed: true,
            };
        case CANCEL_USER_AUTHRIZATION_FAILED:
            return {
                ...state,
                authorizateFailed: false,
            };
        default:
            return state;
    }
};

export default user;
