import { FC, Suspense, useState } from 'react';
import { Await, LoaderFunction, defer, useLoaderData, useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CEntityPageWrapper } from '@/components/layout/c-entity-page-wrapper/c-entity-page-wrapper';
import { ServiceCategoryForm } from '@/components/forms/service-category-form';
import { serviceCategoriesService } from '@/services/service-categories.service';
import { complexService } from '@/services/complex.service';
import { serviceCategoriesIncomPlaceholder } from '@/placeholders/service-categories/incom';
import { ServiceCategoryForm as RequestServiceCategoryForm } from '@/types/api/service-categories/request-body';
import { Complex } from '@/types/api/complexes/incom';

export const CreateServiceCategory: FC = () => {
    const [pending, setPending] = useState<boolean>(false);

    const { data } = useLoaderData() as {
        data: Complex[];
    };

    const navigate = useNavigate();

    const handleSubmit = async (form: RequestServiceCategoryForm): Promise<void> => {
        setPending(true);
        try {
            await serviceCategoriesService.create(form);
            setPending(false);
            navigate('/service-categories');
        } catch (error: any) {
            // throw new Error(error.message);
            console.error(error);
        }
        setPending(false);
    };

    return (
        <CEntityPageWrapper
            headerContainerProps={{
                options: {
                    nameOfSection: `Категории услуг / Создание категории услуг`,
                    nameOfAction: '',
                },
            }}
            pending={pending}
        >
            <Suspense fallback={<CSpinner />}>
                <Await resolve={data}>
                    {(data: Complex[]) => {
                        return (
                            <Box sx={{ padding: 3 }}>
                                <Stack spacing={2}>
                                    <ServiceCategoryForm
                                        initialValues={serviceCategoriesIncomPlaceholder}
                                        editMode={false}
                                        complexes={data}
                                        submitCallback={handleSubmit}
                                        cancelCallback={() => navigate('/service-categories')}
                                    />
                                </Stack>
                            </Box>
                        );
                    }}
                </Await>
            </Suspense>
        </CEntityPageWrapper>
    );
};

export const createServiceCategoriesLoader: LoaderFunction = () => {
    return defer({
        data: complexService.list(),
    });
};
