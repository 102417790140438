const arrayQueryRegex = /.*\[\]$/;

export function urlSearchParamsToObject(searchParams: URLSearchParams): any {
    return Array.from(searchParams.entries()).reduce((acc: Record<any, any>, [key, value]) => {
        const normalizedValue = isNaN(Number(value)) ? value : +value;
        if (arrayQueryRegex.test(key)) {
            const keyWithoutBraces = key.replace('[]', '');

            acc[keyWithoutBraces]
                ? acc[keyWithoutBraces].push(normalizedValue)
                : (acc[keyWithoutBraces] = [normalizedValue]);
        } else acc[key] = normalizedValue;
        return acc;
    }, {});
}
