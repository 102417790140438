import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    fetchComplexes,
    deleteAdminObjects,
    fetchAdminObjects,
    syncHousingComplexes,
} from '../../../actions/contacts';
import { filterId, clearFilterValue } from '../../../actions/operations';
import connectDataFetchers from '../../../lib/connectDataFetchers';
import { adminObj, housingComplexes } from '../../../etc/notificationMessages';

import ListPage from '../../../components/pages/Contacts/ListPage/ListPage';
import HeaderContainer from '../../Header/HeaderContainer';
import { CSpinner } from '../../../components/layout/c-spinner/c-spinner';
import SnackBar from '../../../components/SnackBar/SnackBar';

class ContactsListPageContainer extends Component {
    constructor() {
        super();
        this.state = {
            snackBarIsOpen: false,
            snackBarMessage: '',
        };
        this.closeSnackBar = this.closeSnackBar.bind(this);
    }
    handleFilterContacts = contacts => {
        this.props.dispatch(filterId(contacts));
    };

    handleDeleteContacts = contacts => {
        this.props.dispatch(deleteAdminObjects(contacts));
    };

    handleSyncHousingComplexes = () => {
        this.props.dispatch(syncHousingComplexes());
    };

    closeSnackBar() {
        this.setState({ snackBarIsOpen: false, snackBarMessage: '' });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const thisObjects = this.props.contacts.adminObjects;
        const nextObjects = nextProps.contacts.adminObjects;
        const thisComplexes = this.props.contacts.houseComplexes;
        const nextComplexes = nextProps.contacts.houseComplexes;

        if (!thisObjects.deleted && nextObjects.deleted) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: adminObj.deleteSeccess });
            this.props.dispatch(fetchAdminObjects());
        }
        if (!thisObjects.deleteFailed && nextObjects.deleteFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: adminObj.deleteFail });
        }
        if (!thisObjects.created && nextObjects.created) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: adminObj.createSuccess });
        }
        if (!thisObjects.createFailed && nextObjects.createFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: adminObj.createFail });
        }
        if (!thisComplexes.synced && nextComplexes.synced) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: housingComplexes.syncSuccess });
        }
        if (!thisComplexes.syncFailed && nextComplexes.syncFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: housingComplexes.syncFail });
        }
    }

    componentWillUnmount() {
        const { filterId } = this.props.operations;
        if (filterId.length !== 0) {
            this.props.dispatch(clearFilterValue());
        }
    }

    render() {
        const { contacts, operations } = this.props;
        const { snackBarIsOpen, snackBarMessage } = this.state;
        const headerOptions = {
            nameOfSection: 'Контакты УК',
            nameOfAction: 'Добавить объект',
        };

        return (
            <div>
                <HeaderContainer
                    options={headerOptions}
                    actionCreate="/contacts/create-contact"
                />
                {contacts.houseComplexes.loading ||
                contacts.adminObjects.loading ||
                contacts.adminObjects.deleting ? (
                    <CSpinner />
                ) : (
                    <ListPage
                        handleCreateContact={this.handleCreateContact}
                        handleDeleteContacts={this.handleDeleteContacts}
                        houseComplexes={contacts.houseComplexes.items}
                        handleFilterContacts={this.handleFilterContacts}
                        handleSyncHousingComplexes={this.handleSyncHousingComplexes}
                        adminObjects={contacts.adminObjects.items}
                        operations={operations}
                    />
                )}
                {snackBarIsOpen ? (
                    <SnackBar
                        snackBarIsOpen={snackBarIsOpen}
                        message={snackBarMessage}
                        closeSnackBar={this.closeSnackBar}
                    />
                ) : null}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    contacts: state.contacts,
    operations: state.operations,
});

export default connect(mapStateToProps)(
    connectDataFetchers(ContactsListPageContainer, [fetchComplexes, fetchAdminObjects]),
);
