import { FC, useState, useEffect } from 'react';
import { Stack, TextField } from '@mui/material';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';
import { CImageUpload } from '@/components/inputs/c-image-upload/c-image-upload';
import { TextEditor } from '../inputs/TextEditor/TextEditor';
import { CFileUpload } from '../inputs/c-file-upload/c-file-upload';
import { CFormControl } from './c-form-control';
import { AdviceForm as IAdviceForm } from '@/types/api/advices/request-body';
import { Advice } from '@/types/api/advices/incom';
import { formValidationConfig, FormValidator } from '@/validators/FormValidator';
import { errorTexts } from '@/validators/errorTexts';
import { trimStringValues } from '@/helpers/transformers';

type FieldName =
    | 'title'
    | 'description'
    | 'article'
    | 'images_ids'
    | 'documents_ids'
    | 'image_cover_id';

interface Props {
    initialValue: Advice;
    submitCallback: (form: IAdviceForm) => void;
}

const initialValuesToForm = (initialValue: Advice): IAdviceForm => {
    const { title, description, article, images, image_cover } = initialValue;

    return {
        title,
        description,
        article,
        images_ids: images.map(image => image.id),
        documents_ids: [],
        image_cover_id: image_cover?.id || undefined,
    };
};

const getValidatorConfig = (): formValidationConfig<IAdviceForm> => ({
    title: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    description: {
        required: {
            order: 1,
            config: [errorTexts.required.default],
        },
    },
    article: {},
    images_ids: {
        notEmptyArray: {
            order: 1,
            config: [errorTexts.required.image],
        },
    },
    documents_ids: {},
    image_cover_id: {},
});

export const AdviceForm: FC<Props> = props => {
    const [form, setForm] = useState<IAdviceForm>(initialValuesToForm(props.initialValue));

    const validator: FormValidator<IAdviceForm> = new FormValidator<IAdviceForm>(
        getValidatorConfig(),
    );

    const [validationState, setValidationState] = useState<
        Record<keyof Partial<IAdviceForm>, string>
    >(validator.validateAsSimple(form, true));

    const handleChange = (
        name: FieldName,
        value: any, // TODO
    ): void => {
        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (): void => {
        const trimmedForm = trimStringValues(form) as IAdviceForm;
        validator.updateValidationConfig(getValidatorConfig());
        setValidationState(validator.validateAsSimple(trimmedForm));
        if (validator.isValid) props.submitCallback(trimmedForm as IAdviceForm);
    };

    useEffect(() => {
        setForm(initialValuesToForm(props.initialValue));
    }, [props.initialValue]);

    return (
        <EntityContentWithAside>
            {{
                content: (
                    <Stack
                        component="form"
                        spacing={3}
                        noValidate
                    >
                        <TextField
                            fullWidth
                            label="Название"
                            required
                            value={form.title}
                            error={!!validationState.title.length}
                            helperText={validationState.title}
                            onChange={event => {
                                handleChange('title', event.target.value);
                            }}
                        />
                        <CImageUpload
                            name="advice-image"
                            entityName="advices"
                            initialData={props.initialValue.images}
                            multiple={true}
                            checkboxLabel="Использовать как обложку"
                            activeFileId={form.image_cover_id || 0}
                            required
                            error={validationState.images_ids}
                            activeFileChangeCallback={id => {
                                setForm({
                                    ...form,
                                    image_cover_id: id,
                                });
                            }}
                            filesChangeCallback={items => {
                                setForm({
                                    ...form,
                                    images_ids: items,
                                });
                            }}
                        />
                        <CFileUpload
                            name="advice-document"
                            entityName="advices"
                            initialData={props.initialValue.documents}
                            error={validationState.documents_ids}
                            required={false}
                            accept=".pdf"
                            multiple={true}
                            filesChangeCallback={items => {
                                setForm({ ...form, documents_ids: items });
                            }}
                        />
                        <TextField
                            label="Краткое описание статьи"
                            multiline
                            value={form.description}
                            minRows={4}
                            required
                            error={!!validationState.description.length}
                            helperText={validationState.description}
                            onChange={event => {
                                handleChange('description', event.target.value);
                            }}
                        />
                        <TextEditor
                            title="Текст статьи"
                            value={form.article}
                            changeCallback={article => {
                                handleChange('article', article);
                            }}
                        />
                    </Stack>
                ),
                aside: (
                    <Stack spacing={3}>
                        <CFormControl
                            controls={[
                                {
                                    label: 'Сохранить',
                                    callback: handleSubmit,
                                    buttonProps: {
                                        variant: 'contained',
                                    },
                                },
                                {
                                    label: 'Отмена',
                                    callback: () => {},
                                    buttonProps: {
                                        variant: 'outlined',
                                    },
                                },
                            ]}
                        />
                    </Stack>
                ),
            }}
        </EntityContentWithAside>
    );
};
