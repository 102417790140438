import { NewsQuery, NewsQueryShort } from '@/types/api/news/request-query';

export const newsQueryPlaceholder: Readonly<NewsQuery> = {
    status: '',
    housing_complexes_ids: [],
    published_to: '',
    limit: 10,
    tags_ids: [],
    q: '',
    search_field: 'title',
    building_ids: [],
    publish_from: '',
    publish_to: '',
};

export const newsQueryShortPlaceholder: Readonly<NewsQueryShort> = {
    status: '',
    housing_complexes_ids: [],
    published_to: '',
};
