import {
    CREATE_VERSION_START,
    CREATE_VERSION_SUCCESS,
    CREATE_VERSION_FAIL,
    EDIT_VERSION_START,
    EDIT_VERSION_SUCCESS,
    EDIT_VERSION_FAIL,
    FETCH_VERSION_START,
    FETCH_VERSION_SUCCESS,
    FETCH_VERSION_FAIL,
    DELETE_VERSION_START,
    DELETE_VERSION_SUCCESS,
    DELETE_VERSION_FAIL,
} from '../actions/appVersions';

const initialState = {
    creating: false,
    createFailed: false,
    created: false,
    editing: false,
    edited: false,
    editFailed: false,
    loading: false,
    loadingFailed: false,
    deleting: false,
    deleted: false,
    deleteFailed: false,
    items: [],
    currentItem: {},
    page: 1,
    limit: 10,
    total: 0,
};

const appVersions = (state = initialState, { type, data }) => {
    switch (type) {
        case FETCH_VERSION_START:
            return {
                ...state,
                loading: true,
                loadingFailed: false,
            };
        case FETCH_VERSION_SUCCESS:
            return {
                ...state,
                loading: false,
                items: data.versions,
                page: data.page,
                limit: data.limit,
                total: data.total,
            };
        case FETCH_VERSION_FAIL:
            return {
                ...state,
                loading: false,
                loadingFailed: true,
            };
        case CREATE_VERSION_START:
            return {
                ...state,
                created: false,
                creating: true,
                createFailed: false,
            };
        case CREATE_VERSION_SUCCESS:
            return {
                ...state,
                creating: false,
                created: true,
            };
        case CREATE_VERSION_FAIL:
            return {
                ...state,
                creating: false,
                createFailed: true,
            };

        case EDIT_VERSION_START:
            return {
                ...state,
                edited: false,
                editing: true,
                editFailed: false,
            };
        case EDIT_VERSION_SUCCESS:
            return {
                ...state,
                editing: false,
                edited: true,
            };
        case EDIT_VERSION_FAIL:
            return {
                ...state,
                edited: false,
                editFailed: true,
            };

        case DELETE_VERSION_START:
            return {
                ...state,
                deleting: true,
                deleted: false,
                deleteFailed: false,
            };
        case DELETE_VERSION_SUCCESS:
            return {
                ...state,
                deleted: true,
                deleting: false,
            };
        case DELETE_VERSION_FAIL:
            return {
                ...state,
                deleting: false,
                deleteFailed: true,
            };

        default:
            return state;
    }
};

export default appVersions;
