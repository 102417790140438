import { ServiceCategory } from '@/types/api/service-categories/incom';

export const serviceCategoriesIncomPlaceholder: Readonly<ServiceCategory> = {
    id: 0,
    name: '',
    complexes: [],
    is_active: false,
    sort: 0,
    created_at: '',
    icon: null,
};
