export const SHOW_MENU = 'SHOW_MENU';

export const showMenu = () => {
    return dispatch => {
        dispatch({ type: SHOW_MENU });
    };
};

export const SEARCH = 'SEARCH';

export function search(searchLine, searchField) {
    return dispatch => {
        const data = { searchLine, searchField };
        return dispatch({ type: SEARCH, data });
    };
}

export const CLEAR_SEARCHLINE = 'CLEAR_SEARCHLINE';

export function clearSearchLine() {
    return dispatch => {
        dispatch({ type: CLEAR_SEARCHLINE });
    };
}

export const FILTER_ID = 'FILTER_ID';

export function filterId(data) {
    return dispatch => {
        return dispatch({ type: FILTER_ID, data });
    };
}

export const CLEAR_FILTER_VALUE = 'CLEAR_FILTER_VALUE';

export function clearFilterValue() {
    return dispatch => {
        dispatch({ type: CLEAR_FILTER_VALUE });
    };
}

export const NOT_FOUND_PAGE = 'NOT_FOUND_PAGE';

export function notFound() {
    return dispatch => {
        return dispatch({ type: NOT_FOUND_PAGE });
    };
}

export const CANCEL_NOT_FOUND_PAGE = 'CANCEL_NOT_FOUND_PAGE';

export function cancelNotFound() {
    return dispatch => {
        return dispatch({ type: CANCEL_NOT_FOUND_PAGE });
    };
}
