import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { authorization } from '@/actions/user';
import { cancelNotFound } from '@/actions/operations';

import LoginPage from '@/components/pages/LoginPage/LoginPage';

class LoginPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
        };
        this.handleAuthSubmit = this.handleAuthSubmit.bind(this);
    }

    componentDidMount() {
        document.title = 'Login | PSN Group';
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user.authorized) {
            this.setState({ redirect: true });
        }
    }

    handleAuthSubmit(data) {
        this.props.authorization(data);
        this.props.cancelNotFound();
    }

    render() {
        const { user } = this.props;
        if (this.state.redirect || user.authorized) return <Navigate to="/news" />;
        return (
            <LoginPage
                handleAuthSubmit={this.handleAuthSubmit}
                errorAuth={user.authorizateFailed}
            />
        );
    }
}

const mapStateToProps = ({ user }) => ({
    user,
});

const mapDispatchToProps = dispatch => ({
    authorization: data => dispatch(authorization(data)),
    cancelNotFound: () => dispatch(cancelNotFound()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);
