import { Component } from 'react';
import { Table, TableBody, TableCell, TableRow, Paper, Checkbox, Fab } from '@mui/material';
import Autorenew from '@mui/icons-material/Autorenew';


import EnhancedTableToolbar from '@/components/Table/TableToolbar';
import EnhancedTableHead from '@/components/Table/TableHead';

import './ListPage.scss';
import { WithRouter } from '@/components/hoc/WithRouter';

export default WithRouter(
    class ContactsListPage extends Component {
        constructor(props) {
            super(props);
            this.state = {
                selected: [],
                data: props.adminObjects.sort((prev, curr) => (prev.id < curr.id ? 1 : -1)),
                page: 0,
                rowsPerPage: 10,
                filterComplexes: [],
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (nextProps.contacts !== this.props.contacts) {
                this.setState({ data: nextProps.contacts });
            }
        }

        handleSelectAllClick = (event, checked) => {
            if (checked) {
                this.setState({ selected: this.state.data.map(n => n.id) });
                return;
            }
            this.setState({ selected: [] });
        };

        handleChoseItem = (event, id, name) => {
            const selectedIndex = this.state[name].indexOf(id);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(this.state[name], id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(this.state[name].slice(1));
            } else if (selectedIndex === this.state[name].length - 1) {
                newSelected = newSelected.concat(this.state[name].slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    this.state[name].slice(0, selectedIndex),
                    this.state[name].slice(selectedIndex + 1),
                );
            }
            if (name === 'filterComplexes') {
                this.props.handleFilterContacts(newSelected);
            }

            this.setState({ [name]: newSelected });
        };

        handleChangePage = (event, page) => {
            this.setState({ page });
        };

        isSelected = (id, name) => this.state[name].indexOf(id) !== -1;

        handleDelete = () => {
            this.props.handleDeleteContacts({ id: this.state.selected });
        };

        handleItemPage(id) {
            this.props.navigate(`/contacts/${id}`);
        }

        render() {
            const { data, selected, rowsPerPage, page } = this.state;
            const { houseComplexes, operations } = this.props;
            const filteredData = data.filter(item =>
                operations.filterId.length === 0
                    ? data
                    : operations.filterId.some(id => id === item.housing_complex.IntegrationId),
            );
            const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
            const columnData = [
                { id: 'name', label: 'Название' },
                { id: 'phoneNumber', label: 'Номер телефона' },
                { id: 'geolocation', label: 'Геопозиция' },
            ];
            const tableStyle = { textAlign: 'center' };
            const flatButtonStyle = { backgroundColor: '#f15f1b' };

            return (
                <div className="listPage">
                    <div className="filterCheckbox">
                        {houseComplexes.map(item => {
                            const isSelected = this.isSelected(
                                item.IntegrationId,
                                'filterComplexes',
                            );
                            return (
                                <div
                                    key={item.IntegrationId}
                                    onClick={event =>
                                        this.handleChoseItem(
                                            event,
                                            item.IntegrationId,
                                            'filterComplexes',
                                        )
                                    }
                                >
                                    <Checkbox
                                        id={item.IntegrationId}
                                        color="primary"
                                        checked={isSelected}
                                    />
                                    <p>{item.Name}</p>
                                </div>
                            );
                        })}
                        <Fab
                            style={flatButtonStyle}
                            id="updateComplexes"
                            onClick={this.props.handleSyncHousingComplexes}
                        >
                            <Autorenew />
                        </Fab>
                    </div>
                    <Paper>
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            handleDelete={this.handleDelete}
                            title="Таблица контактов"
                            deleteMessage="Вы действительно хотите удалить выбранные объекты?"
                        />
                        <div>
                            <Table>
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    onSelectAllClick={this.handleSelectAllClick}
                                    rowCount={data.length}
                                    columnData={columnData}
                                />
                                <TableBody>
                                    {filteredData.map(n => {
                                        const isSelected = this.isSelected(n.id, 'selected');
                                        const address = n.address
                                            ? `${n.address} (${n.latitude}, ${n.longitude})`
                                            : `${n.latitude}, ${n.longitude}`;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.original_id || n.id}
                                                selected={isSelected}
                                                style={tableStyle}
                                            >
                                                <TableCell
                                                    className="tableItemCheckbox"
                                                    onClick={event =>
                                                        this.handleChoseItem(
                                                            event,
                                                            n.id,
                                                            'selected',
                                                        )
                                                    }
                                                >
                                                    <Checkbox checked={isSelected} />
                                                </TableCell>
                                                <TableCell
                                                    onClick={this.handleItemPage.bind(
                                                        this,
                                                        n.id,
                                                        'selected',
                                                    )}
                                                >
                                                    {n.name}
                                                </TableCell>
                                                <TableCell
                                                    onClick={this.handleItemPage.bind(
                                                        this,
                                                        n.id,
                                                        'selected',
                                                    )}
                                                >
                                                    {n.phone_numbers
                                                        ? n.phone_numbers.map((item, index) => (
                                                              <p key={index}>
                                                                  {item}
                                                                  <br />
                                                              </p>
                                                          ))
                                                        : null}
                                                </TableCell>
                                                <TableCell
                                                    onClick={this.handleItemPage.bind(
                                                        this,
                                                        n.id,
                                                        'selected',
                                                    )}
                                                >
                                                    {address}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </div>
            );
        }
    },
);
