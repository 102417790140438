import { Component } from 'react';
import { TableCell, TableHead, TableRow, Checkbox } from '@mui/material';

export default class EnhancedTableHead extends Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { selectAllCallback, numSelected, rowCount, columnData } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount && numSelected > 0}
                            onChange={selectAllCallback}
                        />
                    </TableCell>
                    {columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                sx={{ cursor: 'default' }}
                            >
                                {column.label}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}
