import api from '../apiSingleton';

export const FETCH_COMPLEXES_START = 'FETCH_COMPLEXES_START';
export const FETCH_COMPLEXES_SUCCESS = 'FETCH_COMPLEXES_SUCCESS';
export const FETCH_COMPLEXES_FAIL = 'FETCH_COMPLEXES_FAIL';

export function fetchComplexes() {
    return dispatch => {
        dispatch({ type: FETCH_COMPLEXES_START });

        return api.contacts
            .fetchComplexes()
            .then(data => {
                return dispatch({ type: FETCH_COMPLEXES_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_COMPLEXES_FAIL });
            });
    };
}

export const FETCH_ADMIN_OBJECT_START = 'FETCH_ADMIN_OBJECT_START';
export const FETCH_ADMIN_OBJECT_SUCCESS = 'FETCH_ADMIN_OBJECT_SUCCESS';
export const FETCH_ADMIN_OBJECT_FAIL = 'FETCH_ADMIN_OBJECT_FAIL';

export function fetchAdminObject({ params }) {
    return dispatch => {
        dispatch({ type: FETCH_ADMIN_OBJECT_START });

        return api.contacts
            .fetchAdminObject(params.id)
            .then(data => {
                return dispatch({ type: FETCH_ADMIN_OBJECT_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_ADMIN_OBJECT_FAIL });
            });
    };
}

export const CREATE_ADMIN_OBJECT_START = 'CREATE_ADMIN_OBJECT_START';
export const CREATE_ADMIN_OBJECT_SUCCESS = 'CREATE_ADMIN_OBJECT_SUCCESS';
export const CREATE_ADMIN_OBJECT_FAIL = 'CREATE_ADMIN_OBJECT_FAIL';

export function createAdminObject(contact) {
    return dispatch => {
        dispatch({ type: CREATE_ADMIN_OBJECT_START });

        return api.contacts
            .createAdminObject(contact)
            .then(() => {
                return dispatch({ type: CREATE_ADMIN_OBJECT_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: CREATE_ADMIN_OBJECT_FAIL });
            });
    };
}

export const EDIT_ADMIN_OBJECT_START = 'EDIT_ADMIN_OBJECT_START';
export const EDIT_ADMIN_OBJECT_SUCCESS = 'EDIT_ADMIN_OBJECT_SUCCESS';
export const EDIT_ADMIN_OBJECT_FAIL = 'EDIT_ADMIN_OBJECT_FAIL';

export function editAdminObject(contactData, contactId) {
    return dispatch => {
        dispatch({ type: EDIT_ADMIN_OBJECT_START });

        return api.contacts
            .editAdminObject(contactId, contactData)
            .then(() => {
                return dispatch({ type: EDIT_ADMIN_OBJECT_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: EDIT_ADMIN_OBJECT_FAIL });
            });
    };
}

export const DELETE_ADMIN_OBJECTS_START = 'DELETE_ADMIN_OBJECTS_START';
export const DELETE_ADMIN_OBJECTS_SUCCESS = 'DELETE_ADMIN_OBJECTS_SUCCESS';
export const DELETE_ADMIN_OBJECTS_FAIL = 'DELETE_ADMIN_OBJECTS_FAIL';

export function deleteAdminObjects(contacts) {
    return dispatch => {
        dispatch({ type: DELETE_ADMIN_OBJECTS_START });
        return api.contacts
            .deleteAdminObjects(contacts)
            .then(() => {
                return dispatch({ type: DELETE_ADMIN_OBJECTS_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: DELETE_ADMIN_OBJECTS_FAIL });
            });
    };
}

export const FETCH_ADMIN_OBJECTS_START = 'FETCH_ADMIN_OBJECTS_START';
export const FETCH_ADMIN_OBJECTS_SUCCESS = 'FETCH_ADMIN_OBJECTS_SUCCESS';
export const FETCH_ADMIN_OBJECTS_FAIL = 'FETCH_ADMIN_OBJECTS_FAIL';

export function fetchAdminObjects() {
    return dispatch => {
        dispatch({ type: FETCH_ADMIN_OBJECTS_START });

        return api.contacts
            .fetchAdminObjects()
            .then(data => {
                return dispatch({ type: FETCH_ADMIN_OBJECTS_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_ADMIN_OBJECTS_FAIL });
            });
    };
}

export const FETCH_ADMIN_OBJECTS_TYPE_START = 'FETCH_ADMIN_OBJECTS_TYPE_START';
export const FETCH_ADMIN_OBJECTS_TYPE_SUCCESS = 'FETCH_ADMIN_OBJECTS_TYPE_SUCCESS';
export const FETCH_ADMIN_OBJECTS_TYPE_FAIL = 'FETCH_ADMIN_OBJECTS_TYPE_FAIL';

export function fetchTypeAdminObjects({ params }) {
    return dispatch => {
        dispatch({ type: FETCH_ADMIN_OBJECTS_TYPE_START });

        return api.contacts
            .fetchTypeAdminObjects(params.id)
            .then(data => {
                return dispatch({ type: FETCH_ADMIN_OBJECTS_TYPE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_ADMIN_OBJECTS_TYPE_FAIL });
            });
    };
}

export const CLEAR_CONTACT_CURRENT_ITEM = 'CLEAR_CONTACT_CURRENT_ITEM';

export function clearCurrentItem() {
    return { type: CLEAR_CONTACT_CURRENT_ITEM };
}

export const SYNC_HOUSING_COMPLEXES_START = 'SYNC_HOUSING_COMPLEXES_START';
export const SYNC_HOUSING_COMPLEXES_SUCCESS = 'SYNC_HOUSING_COMPLEXES_SUCCESS';
export const SYNC_HOUSING_COMPLEXES_FAIL = 'SYNC_HOUSING_COMPLEXES_FAIL';

export function syncHousingComplexes() {
    return dispatch => {
        dispatch({ type: SYNC_HOUSING_COMPLEXES_START });

        return api.contacts
            .syncHousingComplexes()
            .then(data => {
                return dispatch({ type: SYNC_HOUSING_COMPLEXES_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: SYNC_HOUSING_COMPLEXES_FAIL });
            });
    };
}
