import api from '../apiSingleton';

export const CREATE_TYPE_START = 'CREATE_TYPE_START';
export const CREATE_TYPE_SUCCESS = 'CREATE_TYPE_SUCCESS';
export const CREATE_TYPE_FAIL = 'CREATE_TYPE_FAIL';

export function createType(type) {
    return dispatch => {
        dispatch({ type: CREATE_TYPE_START });

        return api.typesOfAdminObj
            .createType(type)
            .then(() => {
                return dispatch({ type: CREATE_TYPE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: CREATE_TYPE_FAIL });
            });
    };
}

export const EDIT_TYPE_START = 'EDIT_TYPE_START';
export const EDIT_TYPE_SUCCESS = 'EDIT_TYPE_SUCCESS';
export const EDIT_TYPE_FAIL = 'EDIT_TYPE_FAIL';

export function editType(id, type) {
    return dispatch => {
        dispatch({ type: EDIT_TYPE_START });

        return api.typesOfAdminObj
            .editType(id, type)
            .then(() => {
                return dispatch({ type: EDIT_TYPE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: EDIT_TYPE_FAIL });
            });
    };
}

export const FETCH_TYPES_START = 'FETCH_TYPES_START';
export const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS';
export const FETCH_TYPES_FAIL = 'FETCH_TYPES_FAIL';

export function fetchTypes() {
    return dispatch => {
        dispatch({ type: FETCH_TYPES_START });
        return api.typesOfAdminObj
            .fetchTypes()
            .then(data => {
                return dispatch({ type: FETCH_TYPES_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_TYPES_FAIL });
            });
    };
}

export const DELETE_TYPES_START = 'DELETE_TYPES_START';
export const DELETE_TYPES_SUCCESS = 'DELETE_TYPES_SUCCESS';
export const DELETE_TYPES_FAIL = 'DELETE_TYPES_FAIL';

export function deleteTypes(typeId) {
    return dispatch => {
        dispatch({ type: DELETE_TYPES_START });
        return api.typesOfAdminObj
            .deleteTypes(typeId)
            .then(() => {
                return dispatch({ type: DELETE_TYPES_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: DELETE_TYPES_FAIL });
            });
    };
}
