import { CDateRangeForm } from '@/components/forms/c-date-range-form';
import { CSearchField } from '@/components/forms/c-search-field';
import { AddressInfo, LocationSelect } from '@/components/forms/location-select';
import { CSelect } from '@/components/inputs/c-select';
import { CListPageWrapper } from '@/components/layout/c-list-page-wrapper/c-list-page-wrapper';
import { CSpinner } from '@/components/layout/c-spinner/c-spinner';
import { CPagination } from '@/components/Table/c-pagination/c-pagination';
import { CTable, CTableColumn, CTableRow } from '@/components/Table/c-table';
import { CTableToolbar } from '@/components/Table/c-table-toolbar';
import { queryParser } from '@/helpers/api/query-parser';
import { urlSearchParamsToObject } from '@/helpers/api/url-search-params-to-object';
import { tagListToSelectItemList } from '@/helpers/transformers';
import { newsQueryPlaceholder } from '@/placeholders/news/request-query.placeholder';
import { complexService } from '@/services/complex.service';
import { newsService } from '@/services/news.service';
import { tagService } from '@/services/tags.service';
import { Complex } from '@/types/api/complexes/incom';
import { News } from '@/types/api/news/incom';
import { NewsQuery } from '@/types/api/news/request-query';
import { EnhancedTag } from '@/types/api/tags/incom';
import { WithPagination } from '@/types/IncomWrapper';
import { Paper, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, Suspense, useEffect, useState } from 'react';
import { Await, defer, LoaderFunction, useLoaderData, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const TABLE_COLUMNS: Array<CTableColumn> = [
    {
        id: 1,
        label: 'Картинка',
        sortable: false,
    },
    {
        id: 2,
        label: 'Заголовок',
        sortable: true,
    },
    {
        id: 3,
        label: 'Краткое описание',
        sortable: false,
    },
    {
        id: 5,
        label: 'Дата публикации',
        sortable: true,
    },
];

const newsToTableRow = (news: News): CTableRow => {
    return [
        news.id,
        [
            {
                data: news.image_cover?.url || '',
                type: 'image',
            },
            {
                data: news.title,
            },
            {
                data: news.description,
            },
            {
                data: news.published_at || news.publish_on || 'Не опубликовано',
                type: news.published_at || news.publish_on ? 'dateTime' : 'text',
            },
        ],
    ];
};

export const NewsPage: FC = () => {
    const { pageData } = useLoaderData() as {
        pageData: [WithPagination<Array<News>>, Array<EnhancedTag>];
    };
    const navigate = useNavigate();
    const { page } = useParams();
    const [searchParams] = useSearchParams();
    const [filters, setFilters] = useState<Required<NewsQuery>>({
        ...newsQueryPlaceholder,
        ...urlSearchParamsToObject(searchParams),
    });
    const [selectedNews, setSelectedNews] = useState<Array<number>>([]);

    const handlePageChange = (page: number): void => {
        setFilters({ ...filters, page });
    };

    const handleLimitChange = (limit: number): void => {
        setFilters({ ...filters, page: 1, limit });
    };

    const handleDelete = async (): Promise<void> => {
        try {
            await newsService.batchDelete(selectedNews);
            navigate(location.pathname + queryParser({ ...filters, page: 1 } as any));
            setSelectedNews([]);
        } catch (e: any) {
            console.error(e?.toString() || e);
        }
    };

    const handleFilterChange = (
        type: string,
        value: Array<number> | string | AddressInfo,
    ): void => {
        if (type === 'location') {
            setFilters({
                ...filters,
                // @ts-ignore
                housing_complexes_ids: value.activeComplexesId,
                // @ts-ignore
                building_ids: value.activeHouseId || [],
                page: 1,
            });
        } else {
            // @ts-ignore
            setFilters({ ...filters, [type]: value, page: 1 });
        }
        setSelectedNews([]);
    };

    useEffect(() => {
        if (isEqual(filters, newsQueryPlaceholder)) return;
        navigate(location.pathname + queryParser(filters));
    }, [filters]);

    return (
        <Suspense fallback={<CSpinner />}>
            <Await resolve={pageData}>
                {([news, tags, complexes]: [
                    WithPagination<Array<News>>,
                    Array<EnhancedTag>,
                    Complex[],
                ]) => {
                    return (
                        <CListPageWrapper
                            headerContainerProps={{
                                options: {
                                    nameOfSection: 'Новости',
                                    nameOfAction: 'Добавить новость',
                                },
                                actionCreate: '/news/create-article',
                            }}
                        >
                            <>
                                <Grid
                                    container
                                    columns={2}
                                    spacing={3}
                                    direction="row"
                                >
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CSearchField
                                            label="Поиск по заголовку"
                                            value={filters.q}
                                            changeCallback={q => handleFilterChange('q', q)}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CSelect
                                            multiple
                                            placeholder={'Поиск по тегам'}
                                            value={filters.tags_ids}
                                            items={tagListToSelectItemList(tags)}
                                            changeCallback={selected =>
                                                handleFilterChange('tags_ids', selected)
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <LocationSelect
                                            complexes={complexes}
                                            activeComplexesId={filters.housing_complexes_ids}
                                            activeHouseId={filters.building_ids}
                                            activeEntrancesId={[]}
                                            filter
                                            activeComplexesChangeCallback={data =>
                                                handleFilterChange('location', data)
                                            }
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <CDateRangeForm
                                            dateFromValue={filters.publish_from}
                                            dateToValue={filters.publish_to}
                                            filter
                                            dateFromChangeCallback={value => {
                                                handleFilterChange('publish_from', value);
                                            }}
                                            dateToChangeCallback={value => {
                                                handleFilterChange('publish_to', value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Paper>
                                    <CTable
                                        toolbar={
                                            <CTableToolbar
                                                selectedItemsCount={selectedNews.length}
                                                controls={[
                                                    {
                                                        buttonLabel: 'Удалить',
                                                        buttonProps: {
                                                            disabled: !selectedNews.length,
                                                        },
                                                        confimation: {
                                                            title: 'Удаление',
                                                            text: 'Вы уверены, что хотите удалить выбранные элементы?',
                                                        },
                                                        submitCallback: handleDelete,
                                                    },
                                                ]}
                                            />
                                        }
                                        pagination={
                                            <CPagination
                                                data={{
                                                    limit: filters.limit,
                                                    page: +(news.page || page || 1),
                                                    total: news.total,
                                                }}
                                                pageChangeCallback={handlePageChange}
                                                limitChangeCallback={handleLimitChange}
                                            />
                                        }
                                        columns={TABLE_COLUMNS}
                                        rows={news.result.map(newsToTableRow)}
                                        selectedItems={selectedNews}
                                        selectedItemsChangeCallback={value =>
                                            setSelectedNews(value as number[])
                                        }
                                        rowClickCallback={id =>
                                            navigate(`/news/${id}/edit-article`)
                                        }
                                    />
                                </Paper>
                            </>
                        </CListPageWrapper>
                    );
                }}
            </Await>
        </Suspense>
    );
};

export const newsLoader: LoaderFunction = async ({ request }) => {
    const url = new URL(request.url);

    return defer({
        pageData: Promise.all([
            newsService.listByPage(+(url.searchParams.get('page') || 1), decodeURI(url.search)),
            tagService.list(),
            complexService.list(),
        ]),
    });
};
