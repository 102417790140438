import { Component } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import { CButton } from '../../../Button/CButton';
import Dialog from '../../../layout/Dialog/Dialog';
import pin from '@/assets/images/pin.png';

import './ItemPage.scss';
import { EntityContentWithAside } from '@/layouts/EntityContentWithAside';

export default class ContactsItemPage extends Component {
    constructor() {
        super();
        this.state = {
            dialogIsOpen: false,
        };
    }

    handleDeleteAdminObject = () => {
        this.props.deleteAdminObject({ id: [this.props.contacts.item.id] });
    };

    handleOpenDialog = () => this.setState({ dialogIsOpen: true });

    handleCloseDialog = () => this.setState({ dialogIsOpen: false });

    render() {
        const { contacts, handleEditContact } = this.props;
        const { dialogIsOpen } = this.state;

        const mapCenter =
            contacts.item.latitude !== 0 && contacts.item.longitude !== 0
                ? [contacts.item.latitude, contacts.item.longitude]
                : [55.754734, 37.583314];

        return (
            <>
                <EntityContentWithAside blockClassName="ItemPage">
                    {{
                        content: (
                            <>
                                <div className="propsOfArticle">
                                    <p>Геопозиция</p>
                                    <h3>
                                        Адрес:{' '}
                                        {contacts.item.address ? (
                                            <span>{contacts.item.address}</span>
                                        ) : (
                                            <span>Адрес объекта отсутствует</span>
                                        )}
                                    </h3>
                                    <div className="map">
                                        <YMaps query={{ load: 'package.full' }}>
                                            <Map
                                                defaultState={{
                                                    center: mapCenter,
                                                    zoom: 10,
                                                    controls: ['default'],
                                                }}
                                                width="100%"
                                                height="60vh"
                                            >
                                                <Placemark
                                                    geometry={[
                                                        contacts.item.latitude,
                                                        contacts.item.longitude,
                                                    ]}
                                                    options={{
                                                        iconLayout: 'default#image',
                                                        iconImageHref: pin,
                                                    }}
                                                />
                                            </Map>
                                        </YMaps>
                                    </div>
                                </div>
                            </>
                        ),
                        aside: (
                            <>
                                <center>
                                    <CButton
                                        label="Удалить"
                                        action={this.handleOpenDialog}
                                        variant="contained"
                                    />
                                    <CButton
                                        label="Изменить"
                                        link={handleEditContact}
                                        variant="contained"
                                    />
                                </center>
                                <div className="propsOfArticle">
                                    <div>
                                        <b>Название</b>
                                        <p>{contacts.item.name}</p>
                                    </div>
                                    <div>
                                        <b>ЖК</b>
                                        <p>{contacts.item.housing_complex.Name}</p>
                                    </div>
                                    <div>
                                        <b>Тип объекта</b>
                                        <p>{contacts.item.type ? contacts.item.type.name : ''}</p>
                                    </div>
                                    <div>
                                        <b>Широта</b>
                                        <p>
                                            {contacts.item.latitude !== '0'
                                                ? contacts.item.latitude
                                                : 'Отсутствуют координаты'}
                                        </p>
                                    </div>
                                    <div>
                                        <b>Долгота</b>
                                        <p>
                                            {contacts.item.longitude !== '0'
                                                ? contacts.item.longitude
                                                : 'Отсутствуют координаты'}
                                        </p>
                                    </div>
                                    <div>
                                        <b>E-mail</b>
                                        <p>
                                            {contacts.item.email
                                                ? contacts.item.email
                                                : 'Электорнный адрес отсутствует'}
                                        </p>
                                    </div>
                                    <div>
                                        <b>Телефон</b>
                                        {contacts.item.phone_numbers
                                            ? contacts.item.phone_numbers.map((item, index) => (
                                                  <p key={index}>{item}</p>
                                              ))
                                            : null}
                                        {contacts.item.phone_numbers &&
                                        contacts.item.phone_numbers.length === 0 ? (
                                            <p>Номера отсутствуют</p>
                                        ) : null}
                                    </div>
                                    <div>
                                        <b>Расписание работы</b>
                                        {contacts.item.schedule ? (
                                            contacts.item.schedule.map(item => (
                                                <p
                                                    key={item.id}
                                                >{`${item.days} | ${item.times}`}</p>
                                            ))
                                        ) : (
                                            <p>Расписание работы отсутствует</p>
                                        )}
                                    </div>
                                </div>
                            </>
                        ),
                    }}
                </EntityContentWithAside>
                {dialogIsOpen ? (
                    <Dialog
                        dialogIsOpen={dialogIsOpen}
                        handleCloseDialog={this.handleCloseDialog}
                        handleAction={this.handleDeleteAdminObject}
                        title="Вы действительно хотитие удалить этот объект?"
                        submitButtonText="Удалить"
                        type="delete"
                    />
                ) : null}
            </>
        );
    }
}
