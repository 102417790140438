import api from '../apiSingleton';

export const FETCH_ADVICES_START = 'FETCH_ADVICES_START';
export const FETCH_ADVICES_SUCCESS = 'FETCH_ADVICES_SUCCESS';
export const FETCH_ADVICES_FAIL = 'FETCH_ADVICES_FAIL';

export function fetchAdvices({ query }, ...props) {
    if (Object.keys(query).length === 0) {
        query.page = 1;
    }
    return dispatch => {
        dispatch({ type: FETCH_ADVICES_START });
        return api.advices
            .fetchAdvices(query)
            .then(data => {
                return dispatch({ type: FETCH_ADVICES_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_ADVICES_FAIL });
            });
    };
}

export const CREATE_ADVICE_START = 'CREATE_ADVICE_START';
export const CREATE_ADVICE_SUCCESS = 'CREATE_ADVICE_SUCCESS';
export const CREATE_ADVICE_FAIL = 'CREATE_ADVICE_FAIL';

export function createAdvice(news) {
    return dispatch => {
        dispatch({ type: CREATE_ADVICE_START });

        return api.advices
            .createAdvice(news)
            .then(() => {
                return dispatch({ type: CREATE_ADVICE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: CREATE_ADVICE_FAIL });
            });
    };
}

export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';

export function uploadImage(image) {
    return dispatch => {
        dispatch({ type: UPLOAD_IMAGE_START });
        return api.advices
            .uploadImage(image)
            .then(data => {
                return dispatch({ type: UPLOAD_IMAGE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: UPLOAD_IMAGE_FAIL });
            });
    };
}

export const UPLOAD_DOCUMENT_START = 'UPLOAD_DOCUMENT_START';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_FAIL = 'UPLOAD_DOCUMENT_FAIL';

export function uploadDocument(document) {
    return dispatch => {
        dispatch({ type: UPLOAD_DOCUMENT_START });
        return api.advices
            .uploadDocument(document)
            .then(data => {
                return dispatch({ type: UPLOAD_DOCUMENT_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: UPLOAD_DOCUMENT_FAIL });
            });
    };
}

export const CLEAR_UPLOAD_STATE = 'CLEAR_UPLOAD_STATE';

export function clearUploadState() {
    return dispatch => {
        dispatch({ type: CLEAR_UPLOAD_STATE });
    };
}

export const CLEAR_CURRENT_ITEM = 'CLEAR_CURRENT_ITEM';

export function clearCurrentItem() {
    return dispatch => {
        dispatch({ type: CLEAR_CURRENT_ITEM });
    };
}

export const FETCH_ADVICE_START = 'FETCH_ADVICE_START';
export const FETCH_ADVICE_SUCCESS = 'FETCH_ADVICE_SUCCESS';
export const FETCH_ADVICE_FAIL = 'FETCH_ADVICE_FAIL';

export function fetchAdvice(id) {
    return dispatch => {
        dispatch({ type: FETCH_ADVICE_START });

        return api.advices
            .fetchAdvice(id)
            .then(data => {
                return dispatch({ type: FETCH_ADVICE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: FETCH_ADVICE_FAIL });
            });
    };
}

export const EDIT_ADVICE_START = 'EDIT_ADVICE_START';
export const EDIT_ADVICE_SUCCESS = 'EDIT_ADVICE_SUCCESS';
export const EDIT_ADVICE_FAIL = 'EDIT_ADVICE_FAIL';

export function editAdvice(advice, id) {
    return dispatch => {
        dispatch({ type: EDIT_ADVICE_START });

        return api.advices
            .editAdvice(advice, id)
            .then(() => {
                return dispatch({ type: EDIT_ADVICE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: EDIT_ADVICE_FAIL });
            });
    };
}

export const DELETE_ADVICE_START = 'DELETE_ADVICE_START';
export const DELETE_ADVICE_SUCCESS = 'DELETE_ADVICE_SUCCESS';
export const DELETE_ADVICE_FAIL = 'DELETE_ADVICE_FAIL';

export function deleteAdvice(adviceId) {
    return dispatch => {
        dispatch({ type: DELETE_ADVICE_START });
        return api.advices
            .deleteAdvice(adviceId)
            .then(() => {
                return dispatch({ type: DELETE_ADVICE_SUCCESS });
            })
            .catch(() => {
                return dispatch({ type: DELETE_ADVICE_FAIL });
            });
    };
}

export const PUBLISH_ADVICE_START = 'PUBLISH_ADVICE_START';
export const PUBLISH_ADVICE_SUCCESS = 'PUBLISH_ADVICE_SUCCESS';
export const PUBLISH_ADVICE_FAIL = 'PUBLISH_ADVICE_FAIL';

export function publishAdvice(adviceId) {
    return dispatch => {
        dispatch({ type: PUBLISH_ADVICE_START });
        return api.advices
            .publishAdvice(adviceId)
            .then(data => {
                return dispatch({ type: PUBLISH_ADVICE_SUCCESS, data });
            })
            .catch(() => {
                return dispatch({ type: PUBLISH_ADVICE_FAIL });
            });
    };
}
