import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';

import { fetchTypes, createType, editType, deleteTypes } from '../../../actions/typesOfAdminObj';
import { typesOfAdminObj } from '../../../etc/notificationMessages';

import ListPage from '../../../components/pages/TypesOfAdminObj/ListPage';
import { CSpinner } from '../../../components/layout/c-spinner/c-spinner';
import HeaderContainer from '../../Header/HeaderContainer';
import Dialog from '../../../components/layout/Dialog/Dialog';

import SnackBar from '../../../components/SnackBar/SnackBar';

class TypesOfContactsPageContainer extends Component {
    constructor() {
        super();
        this.state = {
            modalWindowIsOpened: false,
            typeOfMolawWindow: '',
            textFieldValue: '',
            idOfEditItem: null,
            snackBarIsOpen: false,
            snackBarMessage: '',
            validName: true,
        };
        this.closeSnackBar = this.closeSnackBar.bind(this);
    }

    handleInputsChange = event => this.setState({ textFieldValue: event.target.value });

    componentDidMount() {
        this.props.fetchTypes();
    }

    componentDidUpdate(prevProps) {
        if (
            (!prevProps.typesOfAdminObjects.created && this.props.typesOfAdminObjects.created) ||
            (!prevProps.typesOfAdminObjects.edited && this.props.typesOfAdminObjects.edited) ||
            (!prevProps.typesOfAdminObjects.deleted && this.props.typesOfAdminObjects.deleted)
        ) {
            this.props.fetchTypes();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const thisTypes = this.props.typesOfAdminObjects;
        const nextTypes = nextProps.typesOfAdminObjects;

        if (!thisTypes.created && nextTypes.created) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: typesOfAdminObj.createSuccess });
        }
        if (!thisTypes.createFailed && nextTypes.createFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: typesOfAdminObj.createFail });
        }
        if (!thisTypes.deleted && nextTypes.deleted) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: typesOfAdminObj.deleteSeccess });
        }
        if (!thisTypes.deleteFailed && nextTypes.deleteFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: typesOfAdminObj.deleteFail });
        }
        if (!thisTypes.edited && nextTypes.edited) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: typesOfAdminObj.editSuccess });
        }
        if (!thisTypes.editFailed && nextTypes.editFailed) {
            this.setState({ snackBarIsOpen: true, snackBarMessage: typesOfAdminObj.editFail });
        }
    }

    closeSnackBar() {
        this.setState({ snackBarIsOpen: false, snackBarMessage: '' });
    }

    handleCreateType = () => {
        this.setState({ modalWindowIsOpened: false, textFieldValue: '', validName: true });
        this.props.createType({ name: this.state.textFieldValue });
    };

    handleEditType = () => {
        const { textFieldValue, idOfEditItem } = this.state;

        this.setState({ modalWindowIsOpened: false, textFieldValue: '', validName: true });
        this.props.editType(idOfEditItem, { name: textFieldValue });
    };

    handleOpenModalWindowForCreateType = () =>
        this.setState({ modalWindowIsOpened: true, typeOfMolawWindow: 'create' });

    handleOpenModalWindowForEditType = (id, name) => {
        this.setState({
            modalWindowIsOpened: true,
            typeOfMolawWindow: 'edit',
            textFieldValue: name,
            idOfEditItem: id,
        });
    };

    handleCloseDialog = () =>
        this.setState({ modalWindowIsOpened: false, textFieldValue: '', validName: true });

    handleDeleteTypes = types => this.props.deleteTypes(types);

    validNameChange = () => this.setState({ validName: false });

    renderModalWindow = () => {
        const { textFieldValue, modalWindowIsOpened, typeOfMolawWindow, validName } = this.state;

        return (
            <Dialog
                dialogIsOpen={modalWindowIsOpened}
                handleCloseDialog={this.handleCloseDialog}
                handleAction={
                    typeOfMolawWindow === 'create' ? this.handleCreateType : this.handleEditType
                }
                title="Введите имя типа административного объекта"
                submitButtonText="Сохранить"
                textFieldIsValid={textFieldValue !== ''}
                validNameChange={this.validNameChange}
            >
                <TextField
                    label="Название"
                    value={textFieldValue}
                    onChange={this.handleInputsChange}
                    autoFocus
                    fullWidth
                    helperText={!validName ? 'Это поле обязательно для заполнения' : null}
                />
            </Dialog>
        );
    };

    render() {
        const { modalWindowIsOpened, snackBarIsOpen } = this.state;
        const { typesOfAdminObjects } = this.props;
        const { loading, deleting, creating, editing } = typesOfAdminObjects;
        const headerOptions = {
            nameOfSection: 'Типы административных объектов',
            nameOfAction: 'Добавить тип',
            typeOfAction: 'func',
        };

        return (
            <div>
                <HeaderContainer
                    options={headerOptions}
                    actionCreate={this.handleOpenModalWindowForCreateType}
                />
                {loading || deleting || creating || editing ? (
                    <CSpinner />
                ) : (
                    <ListPage
                        handleDeleteTypes={this.handleDeleteTypes}
                        typesOfAdminObjects={typesOfAdminObjects}
                        handleOpenModalWindowForEditType={this.handleOpenModalWindowForEditType}
                    />
                )}
                {modalWindowIsOpened ? this.renderModalWindow() : null}
                {snackBarIsOpen ? (
                    <SnackBar
                        snackBarIsOpen={snackBarIsOpen}
                        message={this.state.snackBarMessage}
                        closeSnackBar={this.closeSnackBar}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    typesOfAdminObjects: state.typesOfAdminObjects,
});

const mapDispatchToProps = dispatch => ({
    fetchTypes: () => dispatch(fetchTypes()),
    createType: name => dispatch(createType(name)),
    editType: (name, id) => dispatch(editType(name, id)),
    deleteTypes: ids => dispatch(deleteTypes(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypesOfContactsPageContainer);
