import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';

import './Button.scss';

export const CButton = (props: any) => {
    const { label, link, action, style, variant, type, disable, href } = props;

    const navigate = useNavigate();

    const styleRaisedButton = {
        backgroundColor: '#f15f1b',
        color: 'white',
    };
    if (disable) {
        styleRaisedButton.backgroundColor = '#9fa09f';
    }
    const styleFlatButton = {
        color: '#f15f1b',
    };
    let originalStyle;

    if (variant === 'text') {
        originalStyle = styleFlatButton;
        if (style) originalStyle = { ...styleFlatButton, ...style };
    }
    if (variant === 'contained') {
        originalStyle = styleRaisedButton;
        if (style) originalStyle = { ...styleRaisedButton, ...style };
    }

    const onclick = (e: any, action: any, link: any, href: string, disable: boolean) => {

        e.preventDefault();
        e.stopPropagation();
        if (disable) return;
        if (link) {
            const data = link();
            return navigate(data.to, { state: { props: data } });
        } else if (action) return action(e);
        else if (href) navigate(href);
    };

    return (
        <div className="buttonUi">
            <Button
                variant={variant}
                size="medium"
                style={originalStyle}
                type={type}
                disabled={disable}
                onClick={e => onclick(e, action, link, href, disable)}
            >
                {label}
            </Button>
        </div>
    );
};
