import React, { PureComponent } from 'react';

import Dialog from '../../../components/layout/Dialog/Dialog';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { pattern, required } from '../../../validators';

class AppVersionDialog extends PureComponent {
    state = {
        platform: 'android',
        version: '',
        required: false,
        errors: {},
        appVersion: {},
    };

    static getDerivedStateFromProps(nextProps, { appVersion, modalWindowIsOpened }) {
        if (!nextProps.appVersion) {
            if (
                appVersion.platform ||
                appVersion.version ||
                appVersion.required ||
                modalWindowIsOpened === undefined ||
                modalWindowIsOpened !== nextProps.modalWindowIsOpened
            ) {
                return {
                    platform: 'android',
                    version: '',
                    required: false,
                    appVersion: {},
                    modalWindowIsOpened: nextProps.modalWindowIsOpened,
                };
            }

            return {};
        }

        const stringVersion = [
            nextProps.appVersion.version.major,
            nextProps.appVersion.version.minor,
            nextProps.appVersion.version.patch,
        ].join('.');

        if (
            !appVersion.platform ||
            appVersion.platform !== nextProps.appVersion.platform ||
            !appVersion.version ||
            appVersion.version !== stringVersion ||
            appVersion.required === undefined ||
            appVersion.required !== nextProps.appVersion.required
        ) {
            const state = {
                platform: nextProps.appVersion.platform,
                version: stringVersion,
                required: nextProps.appVersion.required,
            };

            state.appVersion = { ...state };

            return state;
        }

        return {};
    }

    getValues = () => {
        const { platform, version, required } = this.state;
        const [major, minor, patch] = version.split('.');

        return {
            platform,
            version: {
                major,
                minor,
                patch,
            },
            required,
        };
    };

    handleChangePlatform = event => {
        this.setState({
            platform: event.target.value,
        });
    };

    handleChangeRequired = (event, required) => {
        this.setState({ required });
    };

    handleChangeVersion = event => {
        this.setState(
            {
                version: event.target.value,
            },
            this.validate,
        );
    };

    handleSubmit = () => {
        this.submitted = true;
        if (!this.validate()) {
            return;
        }

        const { idOfEditItem, typeOfModalWindow, onCreate, onEdit, onCloseDialog } = this.props;

        onCloseDialog();

        if (typeOfModalWindow === 'create') {
            onCreate(this.getValues());
        } else {
            onEdit(idOfEditItem, this.getValues());
        }
    };

    render() {
        const { modalWindowIsOpened, onCloseDialog } = this.props;
        const { platform, version, required, errors } = this.state;
        const dialogStyle = { maxWidth: 480 };
        const platformStyle = { width: '100%' };
        const versionStyle = { marginTop: 8 };

        return (
            <Dialog
                dialogIsOpen={modalWindowIsOpened}
                handleCloseDialog={onCloseDialog}
                handleAction={this.handleSubmit}
                title="Заполните поля версии"
                submitButtonText="Сохранить"
                textFieldIsValid
            >
                <div style={dialogStyle}>
                    <FormControl
                        className="formControl handleValid"
                        style={platformStyle}
                    >
                        <InputLabel htmlFor="selectPlatform">Платформа</InputLabel>
                        <Select
                            value={platform}
                            onChange={this.handleChangePlatform}
                        >
                            <MenuItem value="android">
                                <ListItemText primary="android" />
                            </MenuItem>
                            <MenuItem value="ios">
                                <ListItemText primary="ios" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Версия"
                        value={version}
                        onChange={this.handleChangeVersion}
                        error={!!errors.version}
                        helperText={errors.version}
                        style={versionStyle}
                        fullWidth
                        required
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={this.handleChangeRequired}
                                checked={required}
                            />
                        }
                        label="Обязательная версия"
                    />
                </div>
            </Dialog>
        );
    }

    validate = () => {
        if (!this.submitted) {
            return false;
        }

        const { version } = this.state;
        const errorVersion =
            required(version, 'Это поле обязательно для заполнения') ||
            pattern(version, /^\d+\.\d+\.\d+$/, 'Это поле должно быть в формате ЧИСЛО.ЧИСЛО.ЧИСЛО');

        this.setState({
            errors: {
                version: errorVersion,
            },
        });

        return !errorVersion;
    };
}

export default AppVersionDialog;
